<div
  class="email-message flex align-items-start gap-2 cursor-pointer px-3 relative"
  [ngClass]="{ 'bg-white': !isEmailRead, 'email-message__selected': selectedEmail()?.sno == email().sno }"
>
  <div class="flex align-items-center gap-2">
    <div
      class="email-message__status border-circle"
      [ngClass]="{ 'bg-tertiary-1': isEmailRead, 'bg-danger-500': !isEmailRead }"
    ></div>
    <div
      [pTooltip]="isSelected() ? 'Tap to deselect' : 'Tap to select'"
      tooltipPosition="left"
      tooltipStyleClass="text-xs"
      class="email-message__avatar-wrapper relative"
      (click)="selectEmail($event)"
    >
      <div
        class="email-message__avatar bg-tertiary-50 border border-circle flex justify-content-center align-items-center"
      >
        <fa-icon icon="user" class="text-lg" />
      </div>
      <!-- Check Icon -->
      @if (isSelected()) {
        <fa-icon
          [icon]="'check'"
          class="email-message__avatar-check flex justify-content-center align-items-center border-circle border-2 border-primary-500 text-color-primary-500"
        ></fa-icon>
      }
    </div>
  </div>

  <div class="email-message__main flex justify-content-between w-full">
    <div class="email-message__content flex-grow-1">
      <div class="flex justify-content-between align-items-center text-color-tertiary-500">
        <span [ngClass]="{ 'font-bold': !isEmailRead }" class="flex align-items-center gap-1">
          @if (mailsbox.DELETED == currentMailbox()) {
            <fa-icon icon="trash-can" class="text-color-Tertiary-350" />
          }
          <span class="email-message__name">{{ emailTitle }}</span>
        </span>
        <span
          class="email-message__date"
          [ngClass]="{ 'text-color-danger-500 text-sm': mailsbox.SCHEDULED == currentMailbox() }"
        >
          {{ formatDate(email().date) }}
        </span>
      </div>
      <div class="flex justify-content-between align-items-center">
        <div>
          <span
            class="email-message__title text-color-tertiary-350"
            [ngClass]="{ 'font-semibold text-color-tertiary-500  ': !isEmailRead }"
          >
            {{ email().subject }}
          </span>
          <div class="email-message__preview text-color-tertiary-300">
            {{ email().body }}
          </div>
        </div>
        <div>
          @if (loading()) {
            <p-progressSpinner
              styleClass="w-1rem h-1rem"
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          } @else {
            <app-priority-icon [highPriority]="!!email().important" (click)="toggleFavorite($event)" />
          }
        </div>
      </div>
    </div>
  </div>
</div>
