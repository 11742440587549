import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IContactsData, ICountryDataWithPhoneNumber } from '@app/shared/models/contacts.model';

@Injectable({
  providedIn: 'root',
})
export class ContactsState {
  SelectedContact = new BehaviorSubject<IContactsData | null>(null);
  countriesList = new BehaviorSubject<ICountryDataWithPhoneNumber[] | null>(null);
  isLoadingState = new BehaviorSubject<boolean>(true);
  allContactsData = new BehaviorSubject<IContactsData[] | null>(null);

  setSelectedContact(contact: IContactsData) {
    this.SelectedContact.next(contact);
  }

  getSelectedContact(): Observable<IContactsData | null> {
    return this.SelectedContact.asObservable();
  }

  setCountriesList(countryList: ICountryDataWithPhoneNumber[]) {
    this.countriesList.next(countryList);
  }

  getCountriesList(): Observable<ICountryDataWithPhoneNumber[] | null> {
    return this.countriesList.asObservable();
  }

  setIsLoadingState(state: boolean) {
    this.isLoadingState.next(state);
  }

  getIsLoadingState(): Observable<boolean> {
    return this.isLoadingState.asObservable();
  }

  setAllContactsData(contacts: IContactsData[]) {
    this.allContactsData.next(contacts);
  }

  getAllContactsData(): Observable<IContactsData[] | null> {
    return this.allContactsData.asObservable();
  }

  editContactsData(contact: IContactsData, userImageUrl: string | undefined, country: ICountryDataWithPhoneNumber) {
    contact.countryName = country.name;
    contact.countryCode = country.code;
    if (userImageUrl) contact.contactPic = userImageUrl;
    let newContactsData = this.allContactsData.value?.map((contactData: IContactsData) =>
      contactData.contactId === contact.contactId ? { ...contactData, ...contact } : contactData
    );
    if (newContactsData) this.allContactsData.next(newContactsData);
  }

  addContactsData(contact: IContactsData, userImageUrl: string | undefined, country: ICountryDataWithPhoneNumber) {
    contact.countryName = country.name;
    contact.countryCode = country.code;

    if (userImageUrl) contact.contactPic = userImageUrl;
    let contacts = this.allContactsData.value;
    contacts?.push(contact);
    this.allContactsData.next(contacts);
  }

  deleteContact(contact: IContactsData) {
    const allContactsData = this.allContactsData.value;
    if (allContactsData) {
      const updatedData = allContactsData.filter(
        (contactData: IContactsData) => contactData.contactId !== contact.contactId
      );
      this.allContactsData.next(updatedData);
    }
  }

  addRemoveContactFromFavorite(contact: IContactsData) {
    contact.favStatus = Number(!contact.favStatus);
    let newContactsData = this.allContactsData.value?.map((contactData: IContactsData) =>
      contactData.contactId === contact.contactId ? { ...contactData, ...contact } : contactData
    );
    if (newContactsData) this.allContactsData.next(newContactsData);
  }
}
