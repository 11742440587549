import { Component, DestroyRef, EventEmitter, inject, input, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { DividerModule } from 'primeng/divider';
import { IconsModule } from '@app/shared/modules/icons.module';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DateSelectArg, EventClickArg } from '@fullcalendar/core';
import { ChipsModule } from 'primeng/chips';
import { FloatLabelModule } from 'primeng/floatlabel';
import { StyleClassModule } from 'primeng/styleclass';
import { EditorModule } from 'primeng/editor';
import { CalendarFacade } from '@app/pages/calendar/services/calendar.facade';
import { assetUrl } from '@src/single-spa/asset-url';
import { OButtonDirective } from 'o-suite-lib';
import { DomSanitizer } from '@angular/platform-browser';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ICalendarEvent, IEventsApiResponse, IGuest } from '@app/shared/models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-view-event-popup',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    InputTextModule,
    CheckboxModule,
    FormsModule,
    ConfirmDialogModule,
    FaIconComponent,
    CalendarModule,
    DropdownModule,
    ReactiveFormsModule,
    DividerModule,
    IconsModule,
    ConfirmPopupModule,
    InputNumberModule,
    RadioButtonModule,
    ChipsModule,
    FloatLabelModule,
    StyleClassModule,
    EditorModule,
    OButtonDirective,
    ProgressSpinnerModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './view-event-popup.component.html',
  styleUrl: './view-event-popup.component.scss',
})
export class ViewEventPopupComponent implements OnInit {
  private confirmationService = inject(ConfirmationService);
  private calendarFacade = inject(CalendarFacade);
  private sanitizer = inject(DomSanitizer);
  private destroyRef = inject(DestroyRef);

  @Input('selectedEventInfo') set setSelectedDayInfo(value: {
    selectedEventInfo: EventClickArg;
    isOpenPopup: boolean;
  }) {
    if (value) {
      if (value.isOpenPopup) this.closeViewEvent();
      this.selectInfo = value.selectedEventInfo;
      this.getEventById();
    }
  }

  @Output('eventDeleted') eventDeleted: EventEmitter<boolean> = new EventEmitter();

  selectInfo!: EventClickArg;
  sanitizedHtml: any;
  event!: ICalendarEvent;
  organizer!: IGuest;
  profileEmailId: string = '';
  isDeletingEvent: boolean = false;
  currentAttendStatus!: string;
  isLoadingChangeAttendanceStatus: string = '';
  attendingStatusMap: { [key: string]: { src: string; alt: string } } = {
    ATTENDING: { src: 'icons/approved-badge.svg', alt: 'approved' },
    REJECTING: { src: 'icons/not-approved-badge.svg', alt: 'not-approved' },
    PENDING: { src: 'icons/pending-approve.svg', alt: 'pending' },
  };

  ngOnInit() {
    this.profileEmailId = sessionStorage.getItem('profileEmailId') || '';
  }

  getEventById() {
    this.calendarFacade
      .getEventById(this.selectInfo.event._def.publicId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: ICalendarEvent) => {
        this.event = res;

        this.formatEventData();
        this.getAttendingStatusForCurrentUser();
        this.openViewEventPopup();
      });
  }

  formatEventData() {
    const index = this.event.invitees.findIndex((invitee: any) => invitee.isOrganiser);
    if (index !== -1) this.organizer = this.event.invitees.splice(index, 1)[0];
    this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(this.event?.description);
  }

  openViewEventPopup() {
    this.confirmationService.confirm({
      target: this.selectInfo?.jsEvent?.target as EventTarget,
      key: 'custom',
    });
  }

  closeViewEvent() {
    this.confirmationService.close();
  }

  deleteEvent() {
    this.isDeletingEvent = true;
    this.calendarFacade.deleteEvent(this.selectInfo.event._def.publicId).subscribe((res) => {
      this.eventDeleted.emit();
      this.isDeletingEvent = false;
      this.closeViewEvent();
    });
  }

  openOmeetLink(link: string) {
    window.open(link, '_blank');
  }

  getAttendingStatusForCurrentUser() {
    const currentUserInvitationInfo =
      this.organizer.inviteeEmailId === this.profileEmailId
        ? this.organizer
        : this.event.invitees.find((invitee: IGuest) => invitee.inviteeEmailId === this.profileEmailId);

    this.currentAttendStatus = currentUserInvitationInfo?.attendStatus || 'PENDING';
  }

  sendAttendStatus(attendStatus: string) {
    if (this.currentAttendStatus !== attendStatus) {
      this.isLoadingChangeAttendanceStatus = attendStatus;
      this.calendarFacade
        .setAttendance(attendStatus, this.event.eventId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(
          (res) => {
            this.currentAttendStatus = attendStatus;
            this.getEventById();
            this.isLoadingChangeAttendanceStatus = '';
          },
          (error) => {
            this.isLoadingChangeAttendanceStatus = '';
          }
        );
    }
  }

  isBeforeNow(date: string): boolean {
    const today = new Date();
    const inputDate = new Date(date);
    return inputDate < today;
  }

  protected readonly assetUrl = assetUrl;
}
