<div class="email-chip-input flex gap-2 flex-wrap w-full">
  <!-- Display existing chips -->
  <div class="chip-container flex gap-2 flex-wrap">
    <p-chip
      *ngFor="let email of emails"
      styleClass="custom-chip text-xs flex justify-content-center align-items-center"
      [label]="email"
      removable="true"
      (onRemove)="removeEmail(email)"
    >
      <ng-template pTemplate="removeicon">
        <span class="custom-remove-icon flex justify-content-center align-items-center" role="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </span>
      </ng-template>
    </p-chip>
  </div>

  <!-- Input field for new email -->
  <input
    type="text"
    [placeholder]="emails.length == 0 ? placeholder : ''"
    [(ngModel)]="enteredEmail"
    (keydown.enter)="addEmail()"
    (keydown.tab)="addEmail()"
    (keydown.space)="addEmail()"
    (blur)="addEmail()"
    class="email-input inputtext component input-filled border-none flex-grow-1"
  />

  <!-- Error Message -->
  <small class="error-message" *ngIf="errorMessage">{{ errorMessage }}</small>
</div>
