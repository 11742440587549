import { Component, EventEmitter, inject, input, Input, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { Button } from 'primeng/button';
import { assetUrl } from '../../../../single-spa/asset-url';
import { OtrimFacade } from '../../../services/otrim.facade';

@Component({
  selector: 'app-share-dialog',
  standalone: true,
  imports: [DialogModule, Button],
  templateUrl: './share-dialog.component.html',
  styleUrl: './share-dialog.component.scss',
})
export class ShareDialogComponent {
  private otrimFacade = inject(OtrimFacade);

  @Input('visible') visible: boolean = false;
  oTrimLink = input.required<string>();
  @Output() fileUploaded = new EventEmitter<File>();
  copySuccessMessage: string = 'Copy link';
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() navigateToFilesList = new EventEmitter<boolean>();

  closeDialog() {
    this.visible = false;
  }

  copyToClipboard(text: string): void {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.copySuccessMessage = 'Copied to clipboard!';
        setTimeout(() => {
          this.copySuccessMessage = 'Copy link';
        }, 2000);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        this.copySuccessMessage = 'Failed to copy!';
      });
  }

  viewLinkDetails() {
    this.closeDialog();
    this.navigateToFilesList.emit();
    this.otrimFacade.isScrollToLinksTableSignal.set(true);
  }

  protected readonly assetUrl = assetUrl;
}
