import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  allowedTypes: string[] = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/tiff',
    'audio/mp3',
    'audio/wav',
    'audio/aac',
    'video/mp4',
    'video/avi',
    'video/mov',
    'video/wmv',
    'video/mkv',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/plain',
    'text/rtf',
    'application/zip',
    'application/x-rar-compressed',
    'application/x-7z-compressed',
    'text/csv',
    'text/html',
    'application/vnd.visio',
  ];

  maxFileSizeMB: number = 10;

  validateFile(file: File): boolean {
    if (!this.allowedTypes.includes(file.type)) {
      alert('File type not supported.');
      return false;
    }

    if (file.size > this.maxFileSizeMB * 1024 * 1024) {
      alert(`File size exceeds ${this.maxFileSizeMB}MB.`);
      return false;
    }

    return true;
  }
}
