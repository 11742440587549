import { Component } from '@angular/core';

@Component({
  selector: 'app-create-contact',
  standalone: true,
  imports: [],
  templateUrl: './create-contact.component.html',
  styleUrl: './create-contact.component.scss',
})
export class CreateContactComponent {}
