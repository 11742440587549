<div class="create-email bg-white-100" [formGroup]="emailForm">
  <!-- Header -->
  <div class="create-email__header flex justify-content-between align-items-center px-3">
    <h3 class="create-email__title font-bold m-0 text-color-tertiary-500">{{ emailTitle }} Email</h3>
    <fa-icon
      icon="xmark"
      class="create-email__close-icon cursor-pointer text-color-danger-500 border-circle border-1 flex justify-content-center align-items-center"
      (click)="onClose()"
    ></fa-icon>
  </div>

  <!-- Subject Field -->
  <div class="create-email__field flex justify-content-between align-items-center px-3 border-bottom-1 border-top-1">
    <div class="flex align-items-center flex-1">
      <label for="subject" class="create-email__label text-color-tertiary-450">Subject:</label>
      <input
        id="subject"
        type="text"
        formControlName="subject"
        placeholder="Enter the subject"
        class="create-email__input flex-grow-1"
      />
    </div>
    <app-priority-icon [highPriority]="highPriority" (click)="changePriority()" />
  </div>

  <!-- To Field -->
  <div class="create-email__field flex align-items-center px-3 border-bottom-1 justify-content-between">
    <div class="flex align-items-center gap-1 flex-1">
      <label for="to" class="create-email__label text-color-tertiary-450">To:</label>
      <app-email-chip-input
        [emails]="to"
        [placeholder]="'Enter email address'"
        (emailsChange)="updateEmails($event, 'to')"
      ></app-email-chip-input>
    </div>
    <span class="create-email__links ml-2">
      @if (!showCC) {
        <a href="#" (click)="toggleCC(); $event.preventDefault()" class="text-color-primary-500">CC</a>
      }
      @if (!showBCC) {
        <a href="#" (click)="toggleBCC(); $event.preventDefault()" class="text-color-primary-500 ml-3">BCC</a>
      }
    </span>
  </div>

  <!-- CC Field -->
  @if (showCC) {
    <div class="create-email__field flex align-items-center justify-content-between px-3 border-bottom-1">
      <div class="flex align-items-center gap-1">
        <label for="cc" class="create-email__label text-color-tertiary-450">CC:</label>
        <app-email-chip-input
          [emails]="cc"
          [placeholder]="'Enter CC address'"
          (emailsChange)="updateEmails($event, 'cc')"
        ></app-email-chip-input>
      </div>
      <span class="create-email__remove-cc text-sm cursor-pointer text-color-danger-500" (click)="toggleCC()">
        Remove CC
      </span>
    </div>
  }

  <!-- BCC Field -->
  @if (showBCC) {
    <div class="create-email__field flex justify-content-between align-items-center px-3 border-bottom-1">
      <div class="flex align-items-center gap-1">
        <label for="bcc" class="create-email__label">BCC:</label>
        <app-email-chip-input
          [emails]="bcc"
          [placeholder]="'Enter BCC address'"
          (emailsChange)="updateEmails($event, 'bcc')"
        ></app-email-chip-input>
      </div>
      <span class="create-email__remove-cc text-sm cursor-pointer text-color-danger-500" (click)="toggleBCC()">
        Remove BCC
      </span>
    </div>
  }
</div>
