@if (isMyProfileLoading()) {
  <div class="loader-wrapper h-screen absolute right-0">
    <o-loader [bgColor]="'#f8f9fb'" />
  </div>
} @else {
  <div class="profile-container">
    <div class="profile-container__header pl-3 py-3 border-bottom-1">
      <p class="m-0 text-lg font-bold">My profile</p>
    </div>

    <div class="profile-container__content flex flex-row align-items-start p-6">
      @if (myProfileData()?.profilePic && myProfileData()?.profilePic !== '') {
        <p-avatar
          [image]="environment.cloudfrontBaseUrl + myProfileData()?.profilePic"
          class="w-full h-full bg-cover w-15rem h-13rem"
        ></p-avatar>
      } @else {
        <p-avatar icon="pi pi-user" class="text-white w-full h-full bg-cover w-15rem h-13rem" />
      }
      <div class="profile-container__content-info ml-5">
        <p class="text-2xl m-0 font-semibold">{{ myProfileData()?.name }}</p>
        <div class="flex flex-row align-items-center mt-5">
          <fa-icon [icon]="'phone-volume'" size="lg" class="icon"></fa-icon>
          @if (myProfileData()?.mobileNumber) {
            <span class="ml-3 info-detail font-medium">{{ myProfileData()?.mobileNumber }}</span>
          } @else {
            <span class="ml-3 info-detail font-medium">Number is not available</span>
          }
        </div>
        <div class="flex flex-row align-items-center mt-3">
          <fa-icon [icon]="'envelope'" size="lg" class="icon"></fa-icon>
          <span class="ml-3 info-detail font-medium">{{ myProfileData()?.emailId }}</span>
        </div>
        <div class="flex flex-row align-items-center mt-3">
          <fa-icon [icon]="'location-dot'" size="lg" class="icon"></fa-icon>
          @if (myProfileData()?.location) {
            <span class="ml-3 info-detail font-medium">{{ myProfileData()?.location }}</span>
          } @else {
            <span class="ml-3 info-detail font-medium">Location is not available</span>
          }
        </div>
        <div class="flex flex-row align-items-center mt-3">
          <fa-icon [icon]="'calendar-check'" size="lg" class="icon"></fa-icon>
          <span class="ml-3 info-detail font-medium">{{ myProfileData()?.joinedDate | date }}</span>
        </div>
      </div>
    </div>
  </div>
}
