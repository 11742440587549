<div class="flex flex-column justify-content-center align-items-center h-full px-4">
  <p class="font-semibold text-color-tertiary-500">Tips to Increase Engagement</p>

  <div class="border-round py-3 mt-2 min-w-full customer-transaction-item--empty">
    <p class="my-0 text-sm text-center text-color-tertiary-500">Reach out to previous customers with special offers</p>
  </div>
  <div class="border-round py-3 mt-2 min-w-full customer-transaction-item--empty">
    <p class="my-0 text-sm text-center text-color-tertiary-500">
      Consider customer loyalty programs to encourage repeat business
    </p>
  </div>
  <div class="border-round py-3 mt-2 min-w-full customer-transaction-item--empty">
    <p class="my-0 text-sm text-center text-color-tertiary-500">Enhance your marketing efforts through social media</p>
  </div>

  <p class="text-color-primary-500 text-center">Lets work towards filling this space with your success stories!</p>
</div>
