import { ICalendarDropdownOptions, IDayRepeatOption } from '@app/shared/models';

export const everyOptions: ICalendarDropdownOptions[] = [
  {
    name: 'Week',
    value: 'week',
  },
  {
    name: 'Month',
    value: 'month',
  },
  {
    name: 'Year',
    value: 'year',
  },
];

export const endsAfterOptions: ICalendarDropdownOptions[] = [
  {
    name: '10 occurrences',
    value: '10',
  },
  {
    name: '15 occurrences',
    value: '15',
  },
  {
    name: '25 occurrences',
    value: '25',
  },
];

export const repeatOptions: ICalendarDropdownOptions[] = [
  {
    name: 'Do not repeat',
    value: 'dontRepeat',
  },
  {
    name: 'Daily',
    value: 'daily',
  },
  {
    name: 'Weekly on Thursday',
    value: 'weeklyOnThursday',
  },
  {
    name: 'Custom',
    value: 'custom',
  },
];

export const dayOptionsRepeat: IDayRepeatOption[] = [
  {
    id: 0,
    key: 'S',
    dayName: 'sunday',
  },
  {
    id: 1,
    key: 'M',
    dayName: 'monday',
  },
  {
    id: 2,
    key: 'T',
    dayName: 'tuesday',
  },
  {
    id: 3,
    key: 'W',
    dayName: 'wednesday',
  },
  {
    id: 4,
    key: 'T',
    dayName: 'thursday',
  },
  {
    id: 5,
    key: 'F',
    dayName: 'friday',
  },
  {
    id: 6,
    key: 'S',
    dayName: 'saturday',
  },
];
