import { Component, DestroyRef, effect, EventEmitter, inject, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { OtrimFacade } from '../../services/otrim.facade';
import { BulkTrimDialogComponent } from '../../shared/shared-components/bulk-trim-dialog/bulk-trim-dialog.component';
import { DialogComponent, OButtonDirective } from 'o-suite-lib';
import { IApiResponseWithData, IFileStatData, ITrimmedLinksData } from '../../models';
import { FileLinksDialogComponent } from '../../shared/shared-components/file-links-modal/file-links-dialog.component';
import { ShareDialogComponent } from '../../shared/shared-components/share-dialog/share-dialog.component';
import { ToastModule } from 'primeng/toast';
import { IActionDialogData } from 'o-suite-lib';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-shortify-url-panel',
  standalone: true,
  imports: [
    ButtonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    CommonModule,
    InputTextModule,
    BulkTrimDialogComponent,
    DialogComponent,
    FileLinksDialogComponent,
    ShareDialogComponent,
    ToastModule,
    ReactiveFormsModule,
    CommonModule,
    OButtonDirective,
  ],
  templateUrl: './shortify-url-panel.component.html',
  styleUrl: './shortify-url-panel.component.scss',
})
export class ShortifyUrlPanelComponent {
  private fb = inject(FormBuilder);
  private otrimFacade = inject(OtrimFacade);
  private destroyRef = inject(DestroyRef);

  @Output('navigateToFilesList') navigateToFilesList: EventEmitter<boolean> = new EventEmitter();

  shortUrlForm: FormGroup = this.fb.group({
    url: ['', [Validators.required, Validators.maxLength(2000), this.urlValidator]],
    customName: ['', [Validators.minLength(3), Validators.maxLength(30)]],
    aliasName: ['', [Validators.minLength(2), Validators.maxLength(16)]],
    shortUrlPreview: [{ value: 'https://Otrim.com/', disabled: true }],
  });

  isLoading: boolean = false;
  isShowBulkModal: boolean = false;
  isShowSuccessBulkModal: boolean = false;
  isShowFileModal: boolean = false;
  isShowSuccessTrimModal: boolean = false;

  fileData: IFileStatData | null = null;
  submitted: boolean = false;
  trimmedUrl: string = '';
  faCrown = faCrown;

  successModalData: IActionDialogData = {
    title: '',
    description: '',
    imageSrc: '',
    buttonSeverity: 'primary',
    buttonText: '',
  };

  targetUrl = this.otrimFacade.getLinkToEdit();

  constructor() {
    effect(() => {
      if (this.targetUrl()) {
        this.updateFormValues();
      }
    });
  }

  urlValidator(control: any) {
    try {
      const value = control.value;
      if (!value) return null;
      const url = new URL(value);
      return url.protocol === 'http:' || url.protocol === 'https:' ? null : { invalidUrl: true };
    } catch (e) {
      return { invalidUrl: true };
    }
  }

  onSubmit(): void {
    this.submitted = true;
    if (!this.shortUrlForm.valid)
      return;

      if (!this.targetUrl())
      {
        this.isLoading = true;
        this.otrimFacade
          .trimSingleLink({
            url: this.shortUrlForm.get('url')?.value,
            customName: this.shortUrlForm.get('customName')?.value,
            aliasName: this.shortUrlForm.get('aliasName')?.value !== '' ? this.shortUrlForm.get('aliasName')?.value : 'NA',
            isSpamUrl: false,
          })
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (res: IApiResponseWithData<ITrimmedLinksData>) => {
              // ToDo: use data in the success modal
              this.otrimFacade.setLinkToEdit(null);
              this.isLoading = false;
              this.isShowSuccessTrimModal = true;
              this.trimmedUrl = environment.otrimApiUrl + res?.result?.data?.shortEndUrl;
              this.otrimFacade.addTrimmedLink(res?.result?.data);
              this.shortUrlForm.reset();
            },
            error: (error) => {
              this.otrimFacade.setLinkToEdit(null);
              this.isLoading = false;
              this.isShowSuccessBulkModal = true;

              this.successModalData = {
                title: 'Invalid link',
                description: error?.error?.message ? error?.error?.message : 'Something went wrong!',
                imageSrc: environment.publicPath + '/assets/icons/invalid.svg',
                extraLink: this.shortUrlForm.get('url')?.value,
                buttonSeverity: 'primary',
                buttonText: 'Create a new short URL',
              };
            },
          });
      }
      else {
        const params = {
          inpurl: this.shortUrlForm.get('url')?.value,
          customName: this.shortUrlForm.get('customName')?.value,
          aliasName: this.shortUrlForm.get('aliasName')?.value,
          trimUrlId: this.targetUrl()?.trimUrlId ,
        };

        this.isLoading = true;
        this.otrimFacade.UpdatedTrimmedLink(params).subscribe(
          () => {
            this.otrimFacade.setLinkToEdit(null);
            this.isLoading = false;
            this.isShowSuccessTrimModal = true;

            this.shortUrlForm.reset();
          },
          (error) => {
            this.otrimFacade.setLinkToEdit(null);
            this.isLoading = false;
            this.isShowSuccessBulkModal = true;

            this.successModalData = {
              title: 'Invalid link',
              description: error?.error?.message ? error?.error?.message : 'Something went wrong!',
              imageSrc: environment.publicPath + '/assets/icons/invalid.svg',
              extraLink: this.shortUrlForm.get('url')?.value,
              buttonSeverity: 'primary',
              buttonText: 'Create a new short URL',
            };
          }
        );
    }
  }

  private updateFormValues(): void {
    this.shortUrlForm.patchValue({
      url: this.targetUrl()!.destinationUrl ?? '',
      aliasName: this.targetUrl()!.aliasName ?? '',
      customName: this.targetUrl()!.shortEndUrl ?? '',
    });
  }

  openBulkInsertionModal() {
    // todo: check user subscription and change the logic depends on it.
    this.isShowBulkModal = true;
  }

  openCloseSuccessModal(value?: IFileStatData) {
    if (!this.isShowSuccessBulkModal && value) {
      this.fileData = value;
      this.successModalData = {
        title: 'Bulk file imported successfully! ',
        description: `Your file with ${value.totalLinks - value.invalidLinks} out of ${value.totalLinks} results has been successfully imported and is now ready to use!`,
        imageSrc: environment.publicPath + '/assets/icons/success.svg',
        buttonSeverity: 'primary',
        buttonText: 'Review shorten links',
      };
    }
    this.isShowBulkModal = false;
    this.isShowSuccessBulkModal = !this.isShowSuccessBulkModal;
  }

  reviewLinks() {
    if (this.successModalData.title === 'Invalid link') {
      this.isShowSuccessBulkModal = false;
      this.shortUrlForm.reset();
    } else {
      this.isShowSuccessBulkModal = false;
      this.isShowFileModal = true;
    }
  }

  navigateToFilesListTable() {
    this.isShowSuccessTrimModal = false;
    this.navigateToFilesList.emit();
  }
}
