import { ChangeDetectionStrategy, Component } from '@angular/core';
import { svgIconModule } from '@app/shared/modules';

@Component({
  selector: 'app-unlock-potenial',
  standalone: true,
  imports: [svgIconModule],
  templateUrl: './unlock-potenial.component.html',
  styleUrl: './unlock-potenial.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnlockPotenialComponent {}
