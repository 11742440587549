<div class="marketplace flex flex-column justify-content-between p-2 m-2">
  <div class="flex justify-content-between align-items-center">
    <div class="flex gap-2 align-items-center mt-1">
      <svg-icon [name]="'marketplace'" class="menu-icon mt-1"></svg-icon>
      <span class="text-lg font-bold">Marketplace</span>
    </div>
  </div>
  <div class="mt-1">
    <app-marketplace-carousel></app-marketplace-carousel>
  </div>
  <div class="flex align-items-center mt-2">
    <span class="text-lg font-bold">Explore by category</span>
  </div>
  <div class="flex mt-3 flex">
    <p-button [rounded]="true" [disabled]="loading" [outlined]="filter !== FilterType.ALL" class="h-full mr-2"
              (click)="fillterAll();">
      <span>All Products</span>
    </p-button>
    <p-button [rounded]="true" [disabled]="loading" [outlined]="filter !== FilterType.PURCHASED"
              (click)="fillterPurchased();">
      <span>Purchased</span>
    </p-button>
    <p-divider layout="vertical" />
    <p-button [rounded]="true" [disabled]="loading" [outlined]="!filterFavorite" o-button (click)="fillterFavorites();">
      <fa-icon icon="heart" class="clock-icon"></fa-icon>
      <span class="px-2">Favorite</span>
    </p-button>
  </div>
  <div>
    <div class="grid-container mt-3 gap-2">
      @if (loading) {
        @for (n of [0, 1, 2]; track n) {
          <div class="col  border-round-lg shadow-1 p-1 h-15rem bg-white h-19rem">
            <div class="container">
              <div class="">
                <div class="m-3 mb-2 flex justify-content-between mt-3">
                  <p-skeleton height="42px" width="42px" styleClass="mt-2" />
                </div>
                <div class="m-3">
                  <div class="font-bold text-xl">
                    <p-skeleton height="1rem" width="10rem" styleClass="mb-2" />
                  </div>
                  <div class="mt-2 text-sm font-semibold">
                    <p-skeleton height="10px" width="5rem" styleClass="mb-2" />
                  </div>
                  <div class="mt-1 font-medium text-xs">
                    <p-skeleton height="10px" width="5rem" styleClass="mb-2" />
                  </div>
                </div>
              </div>
              <div class="flex justify-content-between mx-3 mb-4">
                <div class="font-medium text-xs flex align-items-center ">
                </div>
              </div>
            </div>
          </div>
        }
      } @else {
        @for (card of cards; track card) {
          <div class="col  border-round-lg shadow-1 p-1 h-15rem bg-white h-19rem">
            <div class="container">
              <div class="">
                <div class="m-3 mb-2 flex justify-content-between mt-3">
                  <fa-icon icon="square" [size]="'3x'" class="text-color-alternative-500"></fa-icon>
                  <p-button [rounded]="true" [text]="true" severity="secondary" [disabled]="loading"
                            (click)="addFav(card)">
                    <fa-icon icon="heart"
                             [ngClass]="{'text-color-danger-500': card.isFavorite, 'color-denim-gray-20': !card.isFavorite}" />
                  </p-button>
                </div>
                <div class="m-3 mt-0">
                  <div class="font-bold text-xl">
                    {{ card.productName }}
                  </div>
                  <div class="mt-2 text-sm font-semibold">
                    ${{ card.packageDetail?.actual_price }}
                    @if (card.packageDetail?.plan_name === 'LIFE-TIME') {
                      /lifetime
                    } @else {
                      /month
                    }
                  </div>
                  <div class="mt-1 font-medium text-xs">
                    {{ card.productDescription }}
                  </div>
                </div>
              </div>
              <div class="flex justify-content-between mx-3 mb-4">
                <div class="font-medium text-xs flex align-items-center ">
                  <fa-icon icon="lock" class="clock-icon mr-2"></fa-icon>
                  Secure transaction
                </div>
                <p-button [rounded]="true" size="small" class="h-2rem" o-button (click)="subscribeFun(card)" [loading]="card.isloading">
                <span class="mx-2">
                      Subscribe
                </span>
                </p-button>
              </div>
            </div>
          </div>
        }
      }
    </div>
  </div>
</div>
