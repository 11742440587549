import { HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ToasterService } from '@app/services/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  toasterService = inject(ToasterService);

  handleError(error: any, req: HttpRequest<any>): void {
    if (req.url.includes('/alternateEmailId')) {
      return;
    }
    const errorMessage = error.error?.message ?? 'Something went wrong!';
    this.toasterService.setToasterState(true, 'error', errorMessage);
  }
}
