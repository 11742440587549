import { Component } from '@angular/core';

@Component({
  selector: 'app-auto-follow-up',
  standalone: true,
  imports: [],
  templateUrl: './auto-follow-up.component.html',
  styleUrl: './auto-follow-up.component.scss',
})
export class AutoFollowUpComponent {}
