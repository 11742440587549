import { faCalculator } from '@fortawesome/free-solid-svg-icons';

export const onFusionApps = {
  frequentlyUsed: [
    { id: 1, name: 'O-Mailo', icon: faCalculator, backgroundColor: '#ED389C' },
    { id: 2, name: 'O-Shortify', icon: faCalculator, backgroundColor: '#38A8ED' },
    { id: 3, name: 'O-Meet', icon: faCalculator, backgroundColor: '#3842ED' },
    { id: 4, name: 'O-Ecoverse', icon: faCalculator, backgroundColor: '#ED5F38' }
  ],
  others: [
    { id: 6, name: 'OnDesk', icon: faCalculator },
    { id: 7, name: 'OMart', icon: faCalculator },
    { id: 8, name: 'OShop', icon: faCalculator },
    { id: 9, name: 'OStaff', icon: faCalculator },
    { id: 10, name: 'OChat', icon: faCalculator },
    { id: 11, name: 'OWallet', icon: faCalculator },
    { id: 12, name: 'ODomain', icon: faCalculator },
    { id: 13, name: 'OCommerce', icon: faCalculator },
    { id: 14, name: 'OCreate', icon: faCalculator },
    { id: 15, name: 'OVirtual', icon: faCalculator },
    { id: 16, name: 'OPost', icon: faCalculator }
  ]
};
