import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { svgIconModule } from '@app/shared/modules';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-signature-pad',
  standalone: true,
  imports: [svgIconModule],
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements OnInit {
  signaturePad!: SignaturePad;
  @ViewChild('canvas') canvasEl!: ElementRef;
  signatureImg!: string;
  @Output() signatureStatusChange = new EventEmitter<boolean>();

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  isClicked() {
    if (!this.signaturePad.isEmpty()) {
      this.signatureStatusChange.emit(true);
    } else {
      this.signatureStatusChange.emit(false);
    }
  }

  clearPad() {
    this.signatureStatusChange.emit(false);
    this.signaturePad.clear();
  }

  getValue() {
    return this.signaturePad.toDataURL();
  }
}
