import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Email, EmailPageRequest, EmailResponse, Folder } from '@app/shared/models/omail';
import { environment } from '../../environments/environment';
import { TOTAL_EMAILS_IN_PAGE } from '@app/constants';
import { OToastV2Service } from 'o-suite-lib';

@Injectable({
  providedIn: 'root',
})
export class SocketV2Service implements OnDestroy {
  private ws!: WebSocket;
  private socketUrl = environment.socketURL;
  private isConnected = false;
  private resendRequestAfterScrolling = false;
  toaster = Inject(OToastV2Service);
  private activeListeners = new Set<string>();

  private folderData: Record<string, { emails: any[]; folderDetails: Folder }> = {};
  private folderDetails: Folder[] = [];
  private folderDetailsSubject = new BehaviorSubject<Folder[]>([]);
  private dynamicFolderDetails: Folder[] = [];
  private dynamicFolderDetailsSubject = new BehaviorSubject<Folder[]>([]);

  private mailboxSubjects: Record<
    string,
    Subject<{
      emails: Email[];
      folderDetails: Folder;
    }>
  > = {};

  // Loader state
  private loaderSubject = new BehaviorSubject<boolean>(false);

  connect(): void {
    if (this.isConnected && this.ws) return;
    this.setLoader(true);
    this.ws = new WebSocket(this.socketUrl);

    this.ws.onopen = () => {
      this.isConnected = true;
      this.requestMailboxData('INBOX');
    };

    this.ws.onclose = () => {
      this.isConnected = false;
      this.setLoader(false);
      console.error('WebSocket disconnected');
    };

    this.ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    this.ws.onmessage = (messageEvent) => {
      try {
        const msg = JSON.parse(messageEvent.data) as EmailResponse;

        if (msg.folderDetails && msg.folderDetails.length > 0) {
          this.handleFolderUpdates(msg.folderDetails);
        }

        if (msg.dynamicfolderDetails && msg.dynamicfolderDetails.length > 0) {
          this.handleDynamicFolderUpdates(msg.dynamicfolderDetails);
        }

        if (msg.folderName && this.activeListeners.has(msg.folderName.toLowerCase())) {
          const folderKey = msg.folderName.toLowerCase();
          const startIndex = ((msg.pageNo || 1) - 1) * TOTAL_EMAILS_IN_PAGE;
          const endIndex = startIndex + TOTAL_EMAILS_IN_PAGE;

          const emails = [...(this.folderData[folderKey]?.emails || [])];

          emails.splice(startIndex, TOTAL_EMAILS_IN_PAGE, ...msg.listofMails);

          this.folderData[folderKey] = {
            emails,
            folderDetails: msg.folderDetails.find((f) => f.folderName === msg.folderName)!,
          };

          this.resendRequestAfterScrolling = false;
          this.mailboxSubjects[folderKey]?.next(this.folderData[folderKey]);
        }

        this.setLoader(false);
      } catch (error) {
        if (messageEvent.data === 'heartbeat') {
          return;
        }
        console.error('Invalid JSON message:', error);
        this.setLoader(false);
      }
    };
  }

  requestMailboxData(folder: string, options: EmailPageRequest = {}, resendRequest: boolean = false): void {
    if (!this.isConnected) {
      console.warn('WebSocket is not connected. Skipping message send.');
      return;
    }

    const folderKey = folder.toLowerCase();

    this.resendRequestAfterScrolling = resendRequest;

    if (!this.activeListeners.has(folderKey) || resendRequest) {
      !this.activeListeners.has(folderKey) && this.activeListeners.add(folderKey);

      if (!this.mailboxSubjects[folderKey]) {
        this.mailboxSubjects[folderKey] = new Subject<{
          emails: Email[];
          folderDetails: Folder;
        }>();
      }

      const defaultRequest: EmailPageRequest = {
        folder,
        pageNo: 1,
        size: TOTAL_EMAILS_IN_PAGE,
        searchBy: 'all',
        sortBy: null,
        sortOrder: null,
        searchValue: null,
        oldMailIds: null,
        singleMail: false,
      };

      const emailPageRequest = { ...defaultRequest, ...options };

      // Set the loader state
      this.setLoader(true);

      this.ws.send(JSON.stringify(emailPageRequest));
    }
  }

  getMailboxDataStream(folder: string): Observable<{
    emails: Email[];
    folderDetails: Folder;
  }> {
    const folderKey = folder.toLowerCase();

    if (!this.mailboxSubjects[folderKey]) {
      this.mailboxSubjects[folderKey] = new Subject<{
        emails: Email[];
        folderDetails: Folder;
      }>();
    }

    return this.mailboxSubjects[folderKey].asObservable();
  }

  getLoaderState(): Observable<boolean> {
    return this.loaderSubject.asObservable();
  }

  private setLoader(isLoading: boolean): void {
    this.loaderSubject.next(isLoading);
  }

  private handleFolderUpdates(newFolderDetails: Folder[]): void {
    this.folderDetails = newFolderDetails;
    this.folderDetailsSubject.next(this.folderDetails);
  }

  private handleDynamicFolderUpdates(newDynamicFolderDetails: Folder[]): void {
    this.dynamicFolderDetails = newDynamicFolderDetails;
    this.dynamicFolderDetailsSubject.next(this.dynamicFolderDetails);
  }

  getFolderDetailsStream(): Observable<Folder[]> {
    return this.folderDetailsSubject.asObservable();
  }

  getDynamicFolderDetailsStream(): Observable<Folder[]> {
    return this.dynamicFolderDetailsSubject.asObservable();
  }

  getFolderDetails(): Folder[] {
    return this.folderDetails;
  }

  getDynamicFolderDetails(): Folder[] {
    return this.dynamicFolderDetails;
  }

  getFolderData(folder: string): { emails: Email[]; folderDetails: Folder } | undefined {
    return this.folderData[folder.toLowerCase()];
  }

  disconnect(): void {
    if (this.ws && this.isConnected) {
      this.ws.close();
    }
  }

  ngOnDestroy(): void {
    this.disconnect();
  }

  updateDynamicFolder(folder: Partial<Folder>, action?: string): void {
    const existingIndex = this.dynamicFolderDetails.findIndex((f) => f.folderId === folder.folderId);

    // If the folder exists, update it; otherwise, add it
    if (existingIndex !== -1) {
      if (action === 'delete') {
        this.dynamicFolderDetails.splice(existingIndex, 1);
      } else {
        this.dynamicFolderDetails[existingIndex] = { ...this.dynamicFolderDetails[existingIndex], ...folder };
      }
    } else {
      this.dynamicFolderDetails.push(folder as Folder);
    }

    // Emit the updated dynamic folders
    this.dynamicFolderDetailsSubject.next(this.dynamicFolderDetails);
  }

  updateEmailDetails(email: Partial<Email>): void {
    const folderKey = email.folderName?.toLowerCase() || '';

    const emailIndex = this.folderData[folderKey].emails.findIndex((mail) => mail.messageId === email.messageId);

    const existingEmail = this.folderData[folderKey].emails[emailIndex];
    this.folderData[folderKey].emails[emailIndex] = { ...existingEmail, ...email };

    this.mailboxSubjects[folderKey]?.next(this.folderData[folderKey]);
  }

  deleteEmails(payload: { mailIds: number[]; folder: string }): void {
    const folderKey = payload.folder.toLowerCase();
    const emails = this.folderData[folderKey].emails.filter((mail) => !payload.mailIds.includes(mail.sno));

    this.folderData[folderKey].emails = emails;
    this.mailboxSubjects[folderKey]?.next(this.folderData[folderKey]);
  }
  markAsReadOrImportant(payload: { msgs: number[]; folder: string; important?: boolean; read?: boolean }): void {
    const folderKey = payload.folder.toLowerCase();
    const emails = this.folderData[folderKey].emails.map((mail: Email) => {
      if (payload.msgs.includes(mail.sno)) {
        if (payload.important !== undefined) {
          return { ...mail, important: payload.important };
        } else {
          return { ...mail, seen: payload.read };
        }
      }

      return mail;
    });

    this.folderData[folderKey].emails = emails;
    this.mailboxSubjects[folderKey]?.next(this.folderData[folderKey]);
  }
}
