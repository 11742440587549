import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ChipModule } from 'primeng/chip';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-email-chip-input',
  standalone: true,
  imports: [ChipModule, FormsModule, CommonModule],
  templateUrl: './email-chip-input.component.html',
  styleUrls: ['./email-chip-input.component.scss'],
})
export class EmailChipInputComponent {
  @Input() emails: string[] = [];
  @Input() placeholder: string = 'Enter email';
  @Output() emailsChange = new EventEmitter<string[]>();

  enteredEmail: string = '';
  errorMessage: string | null = null;

  private isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  // Add a valid email as a chip
  addEmail(): void {
    const email = this.enteredEmail.trim();

    // Validation and feedback
    if (!email) {
    } else if (!this.isValidEmail(email)) {
      this.errorMessage = 'Invalid email format.';
    } else if (this.emails.includes(email)) {
      this.errorMessage = 'Email already exists.';
    } else {
      this.emails = [...this.emails, email]; // Update array immutably
      this.emailsChange.emit(this.emails); // Emit the updated email list
      this.errorMessage = null; // Clear error message
      this.enteredEmail = '';
    }
  }

  // Remove email from the list
  removeEmail(email: string): void {
    this.emails = this.emails.filter((e) => e !== email);
    this.emailsChange.emit(this.emails); // Emit the updated email list
  }
}
