<div>
  @if(isImageLoading){
  <div
    class="cursor-pointer flex flex-column justify-content-center align-items-center w-13rem h-12rem upload-container custom-border-radius"
  >
    <o-loader bgColor="transparent" />
  </div>
  } @else if(!userImage && !isImageLoading){
  <div
    (click)="triggerFileUpload(fileInput)"
    class="cursor-pointer flex flex-column justify-content-center align-items-center w-13rem h-12rem upload-container custom-border-radius"
  >
    <p-avatar shape="circle" class="mr-2 text-white mb-3" size="xlarge">
      <fa-icon icon="user" />
    </p-avatar>
    <span class="text-primary">Upload contact photo</span>
    <input #fileInput type="file" multiple (change)="onFileSelected($event)" hidden accept="image/*" />
  </div>
  } @else {
  <div
    class="flex justify-content-center align-items-center w-13rem h-12rem relative overflow-hidden custom-border-radius image-conatiner"
  >
    <img [src]="imgHost + userImage" class="w-full h-full bg-cover" />
    <div class="absolute bottom-0 right-0 flex gap-2 p-1">
      <div
        class="border-circle w-2rem h-2rem flex justify-content-center bg-white align-items-center cursor-pointer"
        (click)="handleClearImage()"
      >
        <fa-icon [icon]="faX" size="lg" />
      </div>
      <div
        class="border-circle w-2rem h-2rem flex justify-content-center bg-white align-items-center cursor-pointer"
        (click)="triggerFileUpload(fileInput)"
      >
        <input #fileInput type="file" multiple (change)="onFileSelected($event)" hidden accept="image/*" />
        <fa-icon [icon]="faCamera" size="lg" />
      </div>
    </div>
  </div>

  }
</div>
