import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TextToSpeechService {
  private voices: SpeechSynthesisVoice[] = [];
  private selectedVoice: SpeechSynthesisVoice | null = null;

  constructor() {
    this.loadVoices();
  }

  // Load available voices
  private loadVoices(): void {
    this.voices = window.speechSynthesis.getVoices();

    // Listen for voiceschanged event to refresh voices
    window.speechSynthesis.onvoiceschanged = () => {
      this.voices = window.speechSynthesis.getVoices();
    };
  }

  getVoices(): Promise<SpeechSynthesisVoice[]> {
    return new Promise((resolve) => {
      if (this.voices.length) {
        resolve(this.voices);
      } else {
        window.speechSynthesis.onvoiceschanged = () => {
          this.voices = window.speechSynthesis.getVoices();
          resolve(this.voices);
        };
      }
    });
  }

  // Set the selected voice
  setVoice(voice: SpeechSynthesisVoice): void {
    this.selectedVoice = voice || this.voices[0];
  }

  // Start speaking the provided text
  speakText(text: string): void {
    if (!text || !this.selectedVoice) return;

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voice = this.selectedVoice;

    window.speechSynthesis.cancel(); // Stop any ongoing speech
    window.speechSynthesis.speak(utterance);
  }

  // Stop the current speech
  stopSpeech(): void {
    window.speechSynthesis.cancel();
  }
}
