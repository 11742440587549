<div class="mailbox-sidebar p-3">
  <!-- Title -->
  <h3 class="mailbox-sidebar__title mb-3">{{ title() }}</h3>

  @if (buttonText()) {
    <div class="mb-3">
      <p-button
        o-button
        [oButtonSeverity]="'alternative'"
        [label]="buttonText()"
        [disabled]="isLoadingState() && title() === 'My Contacts'"
        icon="pi pi-plus"
        class="mailbox-sidebar__btn p-button-rounded"
        (onClick)="onButtonClick()"
      />
    </div>
  }
  <ng-content></ng-content>
</div>
