<div class="video-dialog flex flex-column align-items-center">
  <h1 class="text-center">Record Video</h1>

  <!-- Small text with max recording time -->

  <!-- Video Preview -->
  <div class="video-preview w-full mb-3">
    <video #cameraPreview autoplay muted class="w-full h-full border-round"></video>
  </div>

  <!-- Controls Section -->
  <div class="controls flex gap-3 justify-content-end align-items-center w-full">
    <div class="mb-2 text-lg flex align-items-center gap-2">
      @if (isRecording) {
        <span class="text-color-tertiary-400">Rec {{ formatTime(currentTime) }} / 03:00</span>
      } @else {
        <div class="text-color-tertiary-300">Max limit 3 mins</div>
      }

      @if (isRecording && !isPaused) {
        <fa-icon
          icon="circle-pause"
          class="p-button-warning flex-1 text-4xl cursor-pointer text-color-tertiary-400"
          pTooltip="Pause Recording"
          tooltipPosition="bottom"
          tooltipStyleClass="text-xs"
          (click)="pauseRecording()"
        ></fa-icon>
      } @else if (isRecording && isPaused) {
        <fa-icon
          icon="circle-play"
          class="p-button-warning flex-1 text-4xl cursor-pointer text-color-tertiary-400"
          pTooltip="Resume Recording"
          tooltipPosition="bottom"
          tooltipStyleClass="text-xs"
          (click)="resumeRecording()"
        ></fa-icon>
      }
    </div>
    <div>
      @if (!isRecording && !videoUrl) {
        <p-button
          o-button
          label="Start Recording"
          class="button-success flex-1 border-round-xl"
          tooltipPosition="bottom"
          tooltipStyleClass="text-xs"
          oButtonSeverity="success"
          (click)="startRecording()"
        ></p-button>
      } @else if (isRecording) {
        <p-button
          o-button
          label="End Recording"
          oButtonSeverity="danger"
          tooltipPosition="bottom"
          tooltipStyleClass="text-xs"
          (click)="stopRecording()"
        ></p-button>
      }
    </div>
  </div>
</div>
