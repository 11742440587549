<p-dialog
  [modal]="true"
  (onHide)="closeDialog()"
  [(visible)]="visible"
  [draggable]="false"
  [dismissableMask]="true"
  class="custom-dialog"
>
  @if(currentStepIndex===0){
  <div class="flex h-full">
    <div class="w-6 py-4 px-5 h-full flex flex-column justify-content-between">
      <div class="flex flex-column justify-content-center h-full">
        <h2 class="font-bold my-0">Welcome to the OnFusion Ecosystem!</h2>
        <p class="my-0 text-600 text-sm font-medium">Oct 10, 2024</p>
        <div>
          <h3 class="font-bold text-lg m-0 mt-5 text-xl">
            Thanks for joining our O-Ecoverse where you’ll fulfill all of your operational needs moving towards endless
            success!
          </h3>
          <ul class="list-none pl-0 m-0 mt-3">
            <li class="flex align-items-center mb-4">
              <svg-icon class="mr-2" name="path"></svg-icon>
              <p class="my-0">Hassle-free communications.</p>
            </li>
            <li class="flex align-items-center mb-4">
              <svg-icon class="mr-2" name="path"></svg-icon>
              <p class="my-0">Stronger connections.</p>
            </li>
            <li class="flex align-items-center mb-4">
              <svg-icon class="mr-2" name="path"></svg-icon>
              <p class="my-0">Smoother day to day operations.</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex gap-3 justify-content-end">
        <p-button
          label="Next"
          class="text-base box-shadow-none p-button-text-white"
          styleClass="border-round-2xl py-2 px-5"
          severity="primary"
          (click)="goToNextStep()"
        />
      </div>
    </div>
    <div class="w-6">
      <img [src]="assetUrl('onboarding/step0.png')" alt="Step Image" class="w-full" />
    </div>
  </div>
  } @else if(currentStepIndex===1){
  <div class="h-full flex flex-column justify-content-between py-4 px-5">
    <div>
      <h2 class="font-bold text-xl mb-2 p-d-flex justify-content-center text-center text-3xl mb-6">
        Welcome to Marketplace
      </h2>
      <div class="flex justify-content-between w-full px-3 gap-3">
        <div class="flex flex-column align-items-center">
          <img [src]="assetUrl('onboarding/marketplace.png')" alt="Image" class="bg-cover step-image" />
          <p class="mt-2 text-center">Your go-to ecosystem for all your daily workflows and operations.</p>
        </div>
        <div class="flex flex-column align-items-center">
          <img [src]="assetUrl('onboarding/onfusion.png')" alt="Image" class="bg-cover step-image" />
          <p class="mt-2 text-center">Check “your profile" and add your personal details easily!</p>
        </div>
        <div class="flex flex-column align-items-center">
          <img [src]="assetUrl('onboarding/category.png')" alt="Image" class="bg-cover step-image" />
          <p class="mt-2 text-center">
            Keep an eye on our “announcements and updates” to know our latest achievements!
          </p>
        </div>
      </div>
    </div>

    <div class="flex gap-3 justify-content-end">
      <p-button
        label="Back"
        class="text-base box-shadow-none p-button-text-white"
        styleClass="border-round-2xl py-2 px-5"
        [outlined]="true"
        (click)="goToPreviousStep()"
      />
      <p-button
        label="Next"
        class="text-base box-shadow-none p-button-text-white"
        styleClass="border-round-2xl py-2 px-5"
        (click)="goToNextStep()"
      />
    </div>
  </div>
  } @else if(currentStepIndex===2) {
  <div class="flex h-full">
    <div class="w-6">
      <img [src]="assetUrl('onboarding/step3.png')" alt="Step Image" class="w-full h-full test" />
    </div>
    <div class="w-6 py-4 px-5 h-full flex flex-column justify-content-between">
      <div class="flex flex-column h-full">
        <h2 class="font-bold my-0">O-Meet</h2>
        <p class="mt-6">
          Through O-Meet, you’ll enjoy the smoothest video conferencing experience. Connect and collaborate anytime,
          anywhere.
        </p>
        <ul class="list-none pl-0 m-0 mt-3">
          <li class="flex align-items-center mb-4">
            <svg-icon class="mr-2" name="path"></svg-icon>
            <p class="my-0">Easy meeting creation</p>
          </li>
          <li class="flex align-items-center mb-4">
            <svg-icon class="mr-2" name="path"></svg-icon>
            <p class="my-0">High quality video and audio</p>
          </li>
          <li class="flex align-items-center mb-4">
            <svg-icon class="mr-2" name="path"></svg-icon>
            <p class="my-0">Features that simplify interactions</p>
          </li>
        </ul>
      </div>

      <div class="flex gap-3 justify-content-end">
        <p-button
          label="Back"
          class="text-base box-shadow-none p-button-text-white"
          styleClass="border-round-2xl py-2 px-5"
          [outlined]="true"
          (click)="goToPreviousStep()"
        />
        <p-button
          label="Next"
          class="text-base box-shadow-none p-button-text-white"
          styleClass="border-round-2xl py-2 px-5"
          (click)="goToNextStep()"
        />
      </div>
    </div>
  </div>
  } @else if(currentStepIndex===3){
  <div class="flex h-full">
    <div class="w-6">
      <img [src]="assetUrl('onboarding/step4.png')" alt="Step Image" class="w-full h-full" />
    </div>
    <div class="w-6 py-4 px-5 h-full flex flex-column justify-content-between">
      <div class="flex flex-column h-full">
        <h2 class="font-bold my-0">O-Mailo</h2>
        <p class="mt-6">
          O-Mailo provides a one-of-a-kind communication experience through advanced features, from text reader to video
          recording, you’ll step up your communication game.
        </p>

        <ul class="list-none pl-0 m-0 mt-3">
          <li class="flex align-items-center mb-4">
            <svg-icon class="mr-2" name="path"></svg-icon>
            <p class="my-0">Send and receive emails smoothly</p>
          </li>
          <li class="flex align-items-center mb-4">
            <svg-icon class="mr-2" name="path"></svg-icon>
            <p class="my-0">Organize your emails using folders</p>
          </li>
          <li class="flex align-items-center mb-4">
            <svg-icon class="mr-2" name="path"></svg-icon>
            <p class="my-0">Create events through your calendar</p>
          </li>
        </ul>
      </div>

      <div class="flex gap-3 justify-content-end">
        <p-button
          label="Back"
          class="text-base box-shadow-none p-button-text-white"
          styleClass="border-round-2xl py-2 px-5"
          [outlined]="true"
          (click)="goToPreviousStep()"
        />
        <p-button
          label="Got it"
          styleClass="border-round-2xl py-2 px-5 bg-alternative-500  border-0"
          (click)="closeDialog()"
        />
      </div>
    </div>
  </div>
  }
</p-dialog>
