<div class="email-editor flex flex-column h-full w-full scroll-container" [formGroup]="emailForm">
  <div class="relative h-full w-full scroll-container flex-1 overflow-y-hidden">
    <p-editor #pEditor id="editDoc" class="custom-editor" formControlName="email" [bounds]="'.custom-editor'">
      <ng-template pTemplate="header">
        <span class="ql-formats flex justify-content-between flex-wrap w-full py-1">
          <div class="flex-1 flex align-items-center flex-wrap">
            <!-- Header Dropdown -->
            <div
              pTooltip="Heading Levels"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
              style="display: inline-block; position: relative"
            >
              <select class="ql-header">
                <option value="1">H1</option>
                <option value="2">H2</option>
                <option value="3">H3</option>
                <option selected>Normal</option>
              </select>
            </div>
            <!-- Bold, Italic, Underline -->
            <button
              class="ql-bold"
              aria-label="Bold"
              pTooltip="Bold"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
            ></button>
            <button
              class="ql-italic"
              aria-label="Italic"
              pTooltip="Italic"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
            ></button>
            <button
              class="ql-underline"
              aria-label="Underline"
              pTooltip="Underline"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
            ></button>

            <!-- Strikethrough -->
            <button
              class="ql-strike"
              aria-label="Strikethrough"
              pTooltip="Strikethrough"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
            ></button>

            <!-- Color and Background -->
            <div
              pTooltip="Text Color"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
              style="display: inline-block; position: relative"
            >
              <select class="ql-color"></select>
            </div>

            <!-- Background Color -->
            <div
              pTooltip="Background Color"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
              style="display: inline-block; position: relative"
            >
              <select class="ql-background"></select>
            </div>

            <!-- Text Alignment -->
            <div
              pTooltip="Text Alignment"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
              style="display: inline-block; position: relative"
            >
              <select class="ql-align"></select>
            </div>
            <!-- Lists -->
            <button
              class="ql-list"
              value="ordered"
              aria-label="Ordered List"
              pTooltip="Ordered List"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
            ></button>
            <button
              class="ql-list"
              value="bullet"
              aria-label="Bullet List"
              pTooltip="Bullet List"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
            ></button>

            <!-- Indentation -->
            <button
              class="ql-indent"
              value="-1"
              aria-label="Decrease Indent"
              pTooltip="Decrease Indent"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
            ></button>
            <button
              class="ql-indent"
              value="+1"
              aria-label="Increase Indent"
              pTooltip="Increase Indent"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
            ></button>

            <!-- Blockquote -->
            <button
              class="ql-blockquote"
              aria-label="Blockquote"
              pTooltip="Blockquote"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
            ></button>

            <!-- Code Block -->
            <button
              class="ql-code-block"
              aria-label="Code Block"
              pTooltip="Code Block"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
            ></button>

            <!-- Link, Image, Video -->
            <button
              class="ql-link"
              aria-label="Insert Link"
              pTooltip="Insert Link"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
            ></button>
            <button
              class="ql-image"
              aria-label="Insert Image"
              pTooltip="Insert Image"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
            ></button>
            <button
              class="ql-video"
              aria-label="Insert Video"
              pTooltip="Insert Video Link"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
            ></button>

            <!-- Clear Formatting -->
            <button
              class="ql-clean"
              aria-label="Clear Formatting"
              pTooltip="Clear Formatting"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
            ></button>
          </div>

          <div class="flex gap-2">
            <fa-icon
              icon="video"
              (click)="showVideoModal = true"
              class="cursor-pointer bg-primary-100 text-color-primary-500 custom-btn text-sm w-3rem h-2rem flex align-items-center justify-content-center"
              pTooltip="Add video"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
            ></fa-icon>

            <fa-icon
              icon="microphone"
              class="cursor-pointer custom-btn text-sm w-3rem h-2rem flex align-items-center justify-content-center"
              [pTooltip]="isRecognizing ? 'Recording...' : 'Speech to text'"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
              (click)="toggleMicrophone()"
              [ngClass]="{
                'custom-btn--active bg-danger-100 text-color-danger-500': isRecognizing,
                ' bg-primary-100 text-color-primary-500': !isRecognizing,
              }"
            ></fa-icon>
          </div>
        </span>
      </ng-template>
    </p-editor>
  </div>
  @if (videoUrl) {
    <div class="video-preview m-0">
      <video [src]="videoUrl" controls class="border-round m-0 ml-3"></video>
    </div>
  }
  <div class="chip-container flex gap-2 flex-wrap py-2 px-3">
    @for (attachment of attachments; track $index) {
      <p-chip
        styleClass="custom-chip text-xs flex justify-content-center align-items-center px-3 py-2 bg-tertiary-1"
        removable="true"
        (onRemove)="removeAttachment(attachment)"
      >
        <!-- Override the chip's text content -->
        <span class="text-color-primary-600 font-bold">
          {{ attachment.fileName }}
        </span>
        <span class="ml-1 text-color-Tertiary-350">({{ attachment.fileSize }} KB)</span>

        <!-- Custom remove icon -->
        <ng-template pTemplate="removeicon">
          <fa-icon icon="xmark" class="text-base text-color-tertiary-500 cursor-pointer"></fa-icon>
        </ng-template>
      </p-chip>
    }
  </div>

  @if (action() !== emailAction.DRAFT && action() !== emailAction.NEW_EMAIL) {
    <div class="original-email-body mt-4 px-5 scroll-container">
      <div class="original-email-body__content border-top-1 py-3">
        <div class="email-body-text px-4 pt-4" [innerHTML]="formatBodyHtml(actionBody)"></div>
      </div>
    </div>
  }

  <p-dialog [(visible)]="showVideoModal" [modal]="true" [style]="{ width: '500px' }">
    @if (showVideoModal) {
      <app-record-video
        (recordingComplete)="handleVideoRecorded($event)"
        (close)="showVideoModal = false"
      ></app-record-video>
    }
  </p-dialog>
</div>
