import { Component } from '@angular/core';

@Component({
  selector: 'app-deleted',
  standalone: true,
  imports: [],
  templateUrl: './deleted.component.html',
  styleUrl: './deleted.component.scss',
})
export class DeletedComponent {}
