import { Component, DestroyRef, inject, OnInit } from '@angular/core';

import { RevenueComponent } from './revenue/revenue.component';
import { UnlockPotenialComponent } from './unlock-potenial/unlock-potenial.component';
import { EChartsOption } from 'echarts';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize } from 'rxjs';
import { CommonModule } from '@angular/common';
import { IDashboardRevenue } from '@app/model';
import { IApiResponseWithData } from '@app/model/api.model';

@Component({
  selector: 'app-revenue-overview',
  standalone: true,
  imports: [RevenueComponent, UnlockPotenialComponent, CommonModule],
  templateUrl: './revenue-overview.component.html',
  styleUrls: ['./revenue-overview.component.scss']
})
export class RevenueOverviewComponent implements OnInit {
  options: EChartsOption = {};
  selectedMonthIndex: number | null = null;
  averageRevenue!: number;
  isChartVisible = true;
  isLoading: boolean = true;

  private osuiteFacade = inject(OsuiteFacade);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.osuiteFacade
      .fetchDashboardRevenue(new Date().getFullYear(), new Date().getFullYear(), 1, 12)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res: IApiResponseWithData<IDashboardRevenue>) => {
          if (res) {
            this.osuiteFacade.setDashboardRevenue(res.data);
            this.isLoading = false;
            if (res.data.averageRevenue === 0) {
              this.isChartVisible = false;
            }
            this.averageRevenue = res.data.averageRevenue;
            this.options = {
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'none'
                },
                formatter: (params: any) => {
                  const data = params[0];
                  return `
              <div class="text-center">
                <strong>${data.axisValue}</strong><br />
                <strong class="text-sm">$${data.value.toLocaleString()}</strong>
              </div>`;
                },
                backgroundColor: '#fff',
                borderColor: '#ccc',
                borderWidth: 1,
                textStyle: {
                  color: '#333'
                }
              },
              grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                splitLine: { show: false },
                axisLine: { show: false },
                axisTick: { show: false }
              },
              yAxis: {
                type: 'value',
                position: 'right'
              },
              series: [
                {
                  data: res.data.earningsDistributedByMonthAndYear?.map((item) => item.totalRevenue),
                  type: 'bar',
                  barWidth: '50%',
                  itemStyle: {
                    color: (params) => {
                      return params.dataIndex === this.selectedMonthIndex ? '#4a90e2' : '#f5f6f7';
                    },
                    borderRadius: [8, 8, 0, 0]
                  },
                  emphasis: {
                    itemStyle: {
                      color: '#4264EF'
                    }
                  }
                }
              ]
            };
          }
        },
        error: () => {
          this.isLoading = false;
          this.isChartVisible = false;
        }
      });
  }
}
