import { NgModule } from '@angular/core';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faEnvelope,
  faCalendarDays,
  faIdBadge,
  faGear,
  faLifeRing,
  faInbox,
  faBookmark,
  faNoteSticky,
  faBox,
  faBoxOpen,
  faFileLines,
  faEnvelopeCircleCheck,
  faTrashCan,
  faFolderOpen,
  faChevronDown,
  faChevronRight,
  faPlus,
  faStar,
  faUsers,
  faCheck,
  faCopy,
  faEnvelopeOpen,
  faReply,
  faReplyAll,
  faShare,
  faCaretDown,
  faXmark,
  faFloppyDisk,
  faLink,
  faMicrophone,
  faVideo,
  faPaperclip,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFileImage,
  faFileVideo,
  faFileAlt,
  faFile,
  faClock,
  faHeart,
  faPhoneVolume,
  faLocationDot,
  faCalendarCheck,
  faX,
  faCamera,
  faPen,
  faEllipsis,
  faCirclePause,
  faCirclePlay,
  faAngleDown,
  faUserGroup,
  faNotesMedical,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class IconsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faEnvelope,
      faEnvelopeCircleCheck,
      faTrashCan,
      faBoxOpen,
      faCalendarDays,
      faIdBadge,
      faGear,
      faLifeRing,
      faInbox,
      faBookmark,
      faNoteSticky,
      faFolderOpen,
      faBox,
      faFileLines,
      faChevronDown,
      faChevronRight,
      faPlus,
      faStar,
      faUsers,
      faCheck,
      faCopy,
      faEnvelopeOpen,
      faEllipsis,
      faReply,
      faReplyAll,
      faShare,
      faCaretDown,
      faXmark,
      faFloppyDisk,
      faLink,
      faMicrophone,
      faVideo,
      faPaperclip,
      faFilePdf,
      faFileWord,
      faFileExcel,
      faFileImage,
      faFileVideo,
      faFileAlt,
      faFile,
      faClock,
      faHeart,
      faPhoneVolume,
      faLocationDot,
      faCalendarCheck,
      faX,
      faCamera,
      faPen,
      faEllipsis,
      faPen,
      faCirclePause,
      faCirclePlay,
      faAngleDown,
      faUserGroup,
      faNotesMedical,
      faUser
    );
  }
}
