<div class="all-contacts-container pt-3 w-20rem h-screen overflow-auto">
  <div class="all-contacts-container__contacts">
    @for (contact of contacts; track contact?.contactId; let index = $index) {
      <div
        [class.all-contacts-container__contacts__selected-contact]="contact.contactId === selectedContact.contactId"
        [id]="'mail_contacts_contact_' + contact?.contactId"
        class="all-contacts-container__contacts__contact cursor-pointer pl-5 relative pr-3 py-2 mt-2 flex flex-row justify-content-between align-items-center"
      >
        @if (contact.contactId === selectedContact.contactId) {
          <span
            class="all-contacts-container__contacts__selected-contact__active-label h-3rem border-round-right-md absolute left-0"
          ></span>
        }
        <div
          class="all-contacts-container__contacts__contact-details flex flex-row justify-content-start align-items-center"
          (click)="selectContact(contact)"
        >
          @if (getContactPic(contact)) {
            <img
              [src]="getContactPic(contact)"
              alt="Avatar"
              class="all-contacts-container__avatar border-circle mr-2"
            />
          } @else {
            <div
              class="all-contacts-container__avatar bg-tertiary-50 border border-circle mr-2 flex justify-content-center align-items-center"
            >
              <fa-icon icon="user" class="text-lg" />
            </div>
          }
          <div class="contact-name-email flex flex-column justify-content-center ml-1">
            <p class="m-0 font-bold">{{ contact?.firstName + ' ' + contact?.lastName }}</p>
            <p class="m-0 font-semibold emailId-text">{{ contact?.omailEmailId }}</p>
          </div>
        </div>

        @if (!contact.isFavoriteLoading) {
          <fa-icon
            [icon]="'heart'"
            (click)="addRemoveContactFromFavorite(index)"
            class="all-contacts-container__contacts__contact__favorite-icon"
            [id]="'mail_contacts_button_favorite' + contact.contactId"
            [class.favorite-contact]="contact.favStatus"
          ></fa-icon>
        } @else {
          <p-progressSpinner
            styleClass="w-1rem h-1rem"
            strokeWidth="4"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        }
      </div>
    }
  </div>
</div>
