import { Component } from '@angular/core';
import { SettingsSidebarComponent } from '@app/components/settings/settings-sidebar/settings-sidebar.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [SettingsSidebarComponent, RouterModule],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent {}
