import { Component } from '@angular/core';
import { assetUrl } from '@src/single-spa/asset-url';
import { ButtonDirective } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { PrimeTemplate } from 'primeng/api';

interface Marketplace {
  image: string;
}

@Component({
  selector: 'app-marketplace-carousel',
  standalone: true,
  imports: [
    ButtonDirective,
    CarouselModule,
    PrimeTemplate,
  ],
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
})
export class CarouselComponent {

  markets: Marketplace[] = [
    {
      image: 'marketplace.png',
    }
  ];

  protected readonly assetUrl = assetUrl;
}
