<p-dialog
  [modal]="true"
  (onHide)="onHide()"
  [(visible)]="visible"
  [draggable]="false"
  [dismissableMask]="true"
  class="edit-contact"
>
  <div class="flex flex-column align-items-center justify-content-center">
    <p class="font-bold edit-contact__title text-3xl m-0 mb-6">
      {{ !isEditModal ? 'Add new contact' : 'Edit contact' }}
    </p>
    <div class="dialog-content w-full flex flex-row">
      <app-upload-profile-pic
        [userImage]="contactPic"
        (userImageChange)="userImageChange($event)"
        (userImageUrlChange)="userImageUrlChange($event)"
      ></app-upload-profile-pic>

      <form [formGroup]="addContactForm" (ngSubmit)="onSubmitAdd()" class="w-full ml-5 edit-contact-form">
        <p class="text-color-tertiary-500 font-bold text-xl mt-0">Contact info</p>
        <div class="form-inputs">
          <div class="flex flex-column">
            <label class="font-medium text-sm mb-1 flex flex-row align-items-center">
              First Name
              <span class="text-color-danger ml-1">*</span>
            </label>

            <input
              type="text"
              pInputText
              placeholder="Please enter First Name"
              formControlName="firstName"
              [ngClass]="{
                'ng-invalid ng-dirty': submitted && addContactForm.get('firstName')?.invalid,
              }"
            />
            @if(submitted && addContactForm.get('firstName')?.errors?.['required']){
              <small class="ml-2 text-red-500">First name is required.</small>
            }
          </div>

          <div class="flex flex-column">
            <label class="font-medium text-sm mb-1 flex flex-row align-items-center">
              Last Name
              <span class="text-color-danger ml-1">*</span>
            </label>

            <input
              type="text"
              pInputText
              placeholder="Please enter Last Name"
              formControlName="lastName"
              [ngClass]="{
                'ng-invalid ng-dirty': submitted && addContactForm.get('lastName')?.invalid,
              }"
            />
            @if(submitted && addContactForm.get('lastName')?.errors?.['required']){
              <small class="ml-2 text-red-500">Last name is required.</small>
            }
          </div>

          <div class="flex flex-column">
            <label class="font-medium text-sm mb-1">Date of birth</label>
            <p-calendar formControlName="dateOfBirth" [iconDisplay]="'input'" [showIcon]="true" inputId="icondisplay" />
          </div>

          <div class="flex flex-column">
            <label class="font-medium text-sm mb-1">Phone number</label>
            <p-inputGroup>
              <p-inputGroupAddon>
                <o-country-dropdown [countries]="countryList" [formControl]="country"></o-country-dropdown>
              </p-inputGroupAddon>
              <p-inputNumber
                class="phone-input"
                placeholder="*** *** ***"
                formControlName="contactNumber"
                mode="decimal"
                inputId="withoutgrouping"
                [useGrouping]="false"
              />
            </p-inputGroup>
          </div>

          <div class="flex flex-column">
            <label class="font-medium text-sm mb-1">Company Name</label>
            <input type="text" pInputText placeholder="Please enter Company Name" formControlName="companyName" />
          </div>

          <div class="flex flex-column">
            <label class="font-medium text-sm mb-1">Job Title</label>
            <input type="text" pInputText placeholder="Please enter Job Title" formControlName="jobTitle" />
          </div>
        </div>

        <p class="text-color-tertiary-500 text-xl font-bold">Personal info</p>

        <div class="form-inputs mt-3">
          <div class="flex flex-column">
            <label class="font-medium text-sm mb-1">OMail email</label>
            <input
              pInputText
              class="omail-input"
              type="text"
              placeholder="Auto-filled based on alternative email"
              formControlName="omailEmailId"
            />
          </div>

          <div class="flex flex-column">
            <label class="font-medium text-sm mb-1 flex flex-row align-items-center">
              Alternative email address
              <span class="text-color-danger ml-1">*</span>
            </label>

            <input
              type="text"
              pInputText
              placeholder="Please enter Alternate email address"
              formControlName="alternateEmailId"
              [ngClass]="{
                'ng-invalid ng-dirty': submitted && addContactForm.get('alternateEmailId')?.invalid,
              }"
            />
            @if(submitted && addContactForm.get('alternateEmailId')?.errors?.['required']){
              <small class="ml-2 text-red-500">Alternate email is required.</small>
            } @else if (submitted && addContactForm.get('alternateEmailId')?.errors?.['email']){
              <small class="ml-2 text-red-500">Please enter a valid email address.</small>
            }
          </div>

          <div class="flex flex-column">
            <label class="font-medium text-sm mb-1">Address</label>
            <input type="text" pInputText placeholder="Please enter Address" formControlName="address" />
          </div>

          <div class="flex flex-column">
            <label class="font-medium text-sm mb-1 flex flex-row align-items-center">
              Country
              <span class="text-color-danger ml-1">*</span>
            </label>

            <p-dropdown
              [options]="countryList"
              formControlName="countryId"
              optionLabel="name"
              placeholder="Select a Country"
              [ngClass]="{
                'ng-invalid ng-dirty': submitted && addContactForm.get('countryId')?.invalid,
              }"
            />
            @if(submitted && addContactForm.get('countryId')?.errors?.['required']){
              <small class="ml-2 text-red-500">Country is required.</small>
            }
          </div>

          <div class="flex flex-column">
            <label class="font-medium text-sm mb-1">State</label>
            <p-dropdown
              [options]="states"
              formControlName="stateId"
              optionLabel="name"
              placeholder="Select a State"
              [disabled]="isSelectedStateLoading"
              [loading]="isSelectedStateLoading"
            />
          </div>

          <div class="flex flex-column">
            <label class="font-medium text-sm mb-1">Zip code</label>
            <input type="text" pInputText placeholder="Please enter Zip code" formControlName="zipcode" />
          </div>
        </div>
        <div class="actions flex flex-row w-full align-items-center justify-content-end mt-6">
          <p-button
            id="mail_editContact_button_cancel"
            class="action-btn"
            o-button
            [outlined]="true"
            [label]="'Cancel'"
            [oButtonSeverity]="'primary'"
            (onClick)="onHide()"
          ></p-button>
          <p-button
            id="mail_editContact_button_save"
            o-button
            class="ml-3 action-btn"
            [loading]="isAddLoading"
            [label]="!isEditModal ? 'Add' : 'Save'"
            [oButtonSeverity]="'success'"
            type="submit"
          ></p-button>
        </div>
      </form>
    </div>
  </div>
</p-dialog>
