<div class="email-header flex flex-full align-items-center justify-content-between p-4">
  <div class="email-header__subject flex align-items-center gap-2">
    <span class="email-header__title font-bold text-color-tertiary-500">{{ emailDetails()?.subject }}</span>
    <div class="mt-2" pTooltip="Mark as Priority" tooltipPosition="bottom" tooltipStyleClass="text-xs">
      @if (loading()) {
        <p-progressSpinner
          styleClass="w-1rem h-1rem"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      } @else {
        <app-priority-icon [highPriority]="highPriority()" (click)="toggleFavorite($event)" />
      }
    </div>
  </div>

  <div class="email-header__actions flex align-items-center gap-3">
    <app-text-to-speech [textToRead]="formattedEmail" />
    @if (showActions()) {
      <fa-icon
        icon="reply"
        (click)="navigateToCreateEmail(emailActions.REPLY)"
        class="email-header__icon text-color-tertiary-300"
        pTooltip="Reply"
        tooltipPosition="bottom"
        tooltipStyleClass="text-xs"
      ></fa-icon>
      <fa-icon
        icon="reply-all"
        (click)="navigateToCreateEmail(emailActions.REPLY_ALL)"
        class="email-header__icon text-color-tertiary-300"
        pTooltip="Reply All"
        tooltipStyleClass="text-xs"
        tooltipPosition="bottom"
      ></fa-icon>
      <fa-icon
        icon="share"
        class="email-header__icon text-color-tertiary-300"
        (click)="navigateToCreateEmail(emailActions.FORWARD)"
        pTooltip="Forward"
        tooltipStyleClass="text-xs"
        tooltipPosition="bottom"
      ></fa-icon>
    }

    <fa-icon
      icon="ellipsis-h"
      class="email-header__icon text-color-tertiary-300"
      pTooltip="More actions"
      tooltipStyleClass="text-xs"
      tooltipPosition="bottom"
      (click)="op.toggle($event)"
    ></fa-icon>
  </div>
</div>
<p-overlayPanel #op>
  @if (deleteLoading()) {
    <p-progressSpinner
      styleClass="w-1rem h-1rem"
      strokeWidth="8"
      fill="var(--surface-ground)"
      animationDuration=".5s"
    />
    Move To Trash
  } @else {
    <div class="cursor-pointer" (click)="moveToTrash()">Move To Trash</div>
  }
</p-overlayPanel>
