import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IBillingInfo, IProfileInfo } from '@app/model/myProfile.model';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { IconsModule, svgIconModule } from '@app/shared/modules';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faCalendarCheck,
  faEnvelope,
  faLocationDot,
  faPhoneVolume,
  faUser,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { DividerModule } from 'primeng/divider';
import { SkeletonModule } from 'primeng/skeleton';
import { finalize } from 'rxjs';
import { CommonModule } from '@angular/common';
import { AvatarModule } from 'primeng/avatar';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-user-card',
  standalone: true,
  imports: [IconsModule, DividerModule, FontAwesomeModule, SkeletonModule, CommonModule, AvatarModule, svgIconModule],
  templateUrl: './user-card.component.html',
  styleUrl: './user-card.component.scss'
})
export class UserCardComponent implements OnInit {
  faPhone: IconDefinition = faPhoneVolume;
  faEnvelope: IconDefinition = faEnvelope;
  faLocation: IconDefinition = faLocationDot;
  faCalendar: IconDefinition = faCalendarCheck;
  faUser: IconDefinition = faUser;

  profileInfo!: IProfileInfo | null;
  billingInfo!: IBillingInfo | null;
  imgHost = environment.imgHostUrl;
  isProfileLoading: boolean = true;
  isBillingLoading: boolean = true;

  private osuiteFacade = inject(OsuiteFacade);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.osuiteFacade
      .getProfileInfo()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: IProfileInfo | null) => {
        if (res) {
          this.profileInfo = { ...res, joinedDate: res?.joinedDate.split('T')[0] };
          this.isProfileLoading = false;
        }
      });

    this.osuiteFacade
      .getBillingAddress()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: IBillingInfo | null) => {
        if (res) {
          this.billingInfo = res;
          this.isBillingLoading = false;
        }
      });
  }
}
