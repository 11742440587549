import { inject, Injectable } from '@angular/core';
import { ToasterService } from '../services/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  toasterService = inject(ToasterService);

  handleError(error: any): void {
    const errorMessage = error.error?.message ?? 'Something went wrong!';
    this.toasterService.setToasterState(true, 'error', errorMessage);
  }
}
