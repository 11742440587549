import { inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '@app/interceptors/error-handler.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  errorHandlerService = inject(ErrorHandlerService);

  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('/assets')) {
      return next.handle(req);
    }

    const token = '';

    // Clone the request to add headers
    const clonedRequest = req.clone({
      withCredentials: true
      // setHeaders: {
      //   Authorization: 'Bearer ' + token
      // }
    });

    // Handle request and catch any errors globally
    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandlerService.handleError(error, req);
        throw error;
      })
    );
  }
}
