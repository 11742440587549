import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TableModule } from 'primeng/table';
import { environment } from '../../../../environments/environment';
import { faEllipsis, faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  IApiResponseWithData,
  IFileData,
  IFileLinksPayload,
  IFileStatData,
  IFileTrimmedLinksData,
  ITrimmedLinksData,
} from '../../../models';
import { OtrimFacade } from '../../../services/otrim.facade';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import FileSaver from 'file-saver';
import { MenuItem, MenuItemCommandEvent } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, mergeMap, Observable, switchMap } from 'rxjs';
import { TooltipModule } from 'primeng/tooltip';
import { assetUrl } from '../../../../single-spa/asset-url';
import { ContextMenuModule } from 'primeng/contextmenu';
import { Router } from '@angular/router';
import { OToastV2Service } from 'o-suite-lib';
import { SkeletonModule } from 'primeng/skeleton';
import { fileLinksLoadingData } from '../../../components/shortified-files-table/file-table.const';

@Component({
  selector: 'app-file-links-dialog',
  standalone: true,
  imports: [
    DialogModule,
    FaIconComponent,
    TableModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    ToastModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    TooltipModule,
    ContextMenuModule,
    SkeletonModule,
  ],
  templateUrl: './file-links-dialog.component.html',
  styleUrl: './file-links-dialog.component.scss',
})
export class FileLinksDialogComponent implements OnInit {
  private otrimFacade = inject(OtrimFacade);
  private router = inject(Router);
  private oToastV2Service = inject(OToastV2Service);

  @Input() visible: boolean = false;
  @Input('fileInfo') set setFileInfo(value: IFileData | IFileStatData | null) {
    this.fileInfo = value;
    if (value) {
      this.getData();
    }
  }

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
  @Output() fileInfoChange: EventEmitter<any> = new EventEmitter();

  fileInfo: IFileData | IFileStatData | null = null;
  fileTrimmedLinksData: IFileTrimmedLinksData[] = Array(5).fill({ ...fileLinksLoadingData });
  exportLoading: boolean = false;
  deleteLoading: boolean = false;
  searchControl = new FormControl('');
  targetUrl?: ITrimmedLinksData;
  contextMenuItems: MenuItem[] = [
    {
      label: 'Copy',
      command: () => {
        let linkToCopy = environment.baseLink + this.targetUrl?.customName;
        navigator.clipboard
          .writeText(linkToCopy)
          .then(() => {
            this.oToastV2Service.add({
              severity: 'success',
              summary: 'Link copied to clipboard.',
              icon: environment.publicPath + '/assets/icons/toast/success.svg',
              life: environment.ToastMessageTime,
            });
          })
          .catch((err) => {
            console.error(err);
            this.oToastV2Service.add({
              severity: 'error',
              summary: 'Could not copy link',
              icon: environment.publicPath + '/assets/icons/toast/error.svg',
              life: environment.ToastMessageTime,
            });
          });
      },
    },
    {
      label: 'Edit',
      command: (event: MenuItemCommandEvent) => {
        if (this.targetUrl) {
          this.otrimFacade.setLinkToEdit(this.targetUrl);
          this.closeDialog();
          document.body.scrollTop = 0;
        }
      },
    },
  ];

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(700),
        distinctUntilChanged(),
        switchMap(
          (query): Observable<IApiResponseWithData<IFileTrimmedLinksData[]>> =>
            this.getFileLinks(this.fileInfo ? this.fileInfo?.fileId : 0, query || '')
        )
      )
      .subscribe({
        next: (results: IApiResponseWithData<IFileTrimmedLinksData[]>) => {
          this.otrimFacade.isLinksFileDataLoadingSignal.set(false);
          this.fileTrimmedLinksData = results.result.data;
        },
        error: (err) => {
          this.fileTrimmedLinksData = [];
          this.otrimFacade.isLinksFileDataLoadingSignal.set(false);
          console.error('Error:', err);
        },
      });
  }

  closeDialog() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    this.fileInfo = null;
    this.fileInfoChange.emit(this.fileInfo);
  }

  getData() {
    if (this.fileInfo) {
      this.getFileLinks(this.fileInfo?.fileId).subscribe(
        (res: IApiResponseWithData<IFileTrimmedLinksData[]>) => {
          this.otrimFacade.isLinksFileDataLoadingSignal.set(false);
          this.fileTrimmedLinksData = res.result.data;
        },
        (error) => {
          this.otrimFacade.isLinksFileDataLoadingSignal.set(false);
        }
      );
    }
  }

  getFileLinks(fileId: number, searchKey: string = '') {
    let params: IFileLinksPayload = {
      pageSize: 50,
      pageIndex: 0,
      searchKey: searchKey,
      orderBy: 'asc',
      fileId: fileId,

      fromDate: '',
      isCustom: true,
      sortBy: '',
      toDate: '',
      userId: 0,
    };

    return this.otrimFacade.getDateRange().pipe(
      mergeMap((res: any) => {
        return this.otrimFacade.getFileLinks({ ...params, ...res });
      })
    );
  }

  deleteFile() {
    if (this.fileInfo && !this.deleteLoading) {
      this.deleteLoading = true;

      this.otrimFacade.deleteFile(this.fileInfo.fileId.toString()).subscribe(
        (res: IApiResponseWithData<string>) => {
          this.oToastV2Service.add({
            severity: 'success',
            summary: res?.result?.data,
            icon: environment.publicPath + '/assets/icons/toast/success.svg',
            life: environment.ToastMessageTime,
          });
          this.closeDialog();
          this.deleteLoading = false;
          this.otrimFacade.deleteFileRecord(this.fileInfo ? this.fileInfo.fileId : 0);
        },
        (error) => {
          this.oToastV2Service.add({
            severity: 'error',
            summary: error?.error?.message ? error?.error?.message : 'Something went wrong!',
            icon: environment.publicPath + '/assets/icons/toast/error.svg',
            life: environment.ToastMessageTime,
          });
          this.deleteLoading = false;
        }
      );
    }
  }

  downloadFile() {
    if (this.fileInfo && !this.exportLoading) {
      this.exportLoading = true;
      this.otrimFacade.exportFileAsCsv(this.fileInfo.fileId.toString()).subscribe((data) => {
        this.saveFileAs(data, '.csv', this.fileInfo?.fileName ? this.fileInfo?.fileName : 'links_file');
        this.oToastV2Service.add({
          severity: 'success',
          summary: 'File Downloaded Successfully',
          icon: environment.publicPath + '/assets/icons/toast/success.svg',
          life: environment.ToastMessageTime,
        });
        this.exportLoading = false;
      });
    }
  }

  saveFileAs(response: any, type: any, fileName: any) {
    const blob = new Blob([response], { type: 'text/plain' });
    const file = fileName + type;
    FileSaver.saveAs(blob, file);
  }

  setTargetUrl(url: ITrimmedLinksData) {
    this.targetUrl = url;
    sessionStorage.setItem('targetShortEndUrl', url?.customName);
  }

  handleShowAnalytics(url: ITrimmedLinksData) {
    this.setTargetUrl(url);
    this.router.navigate(['/O-Shortify/link', url?.trimUrlId]);
  }

  protected readonly environment = environment;
  protected readonly faEllipsis = faEllipsis;
  protected readonly assetUrl = assetUrl;
  protected readonly isLinksFileDataLoading = this.otrimFacade.isLinksFileDataLoadingSignal;
  protected readonly faSearch = faSearch;
}
