import { ICustomer, ICustomerOrder } from '@app/model';

export const customers: ICustomer[] = [
  { id: 1, name: 'Brian', image: 'assets/person.png' },
  { id: 2, name: 'Nina', image: null },
  { id: 3, name: 'Ollie', image: 'assets/person.png' },
  { id: 4, name: 'Brian', image: null },
  { id: 5, name: 'Nina', image: null },
  { id: 6, name: 'Ollie', image: 'assets/person.png' },
  { id: 7, name: 'Nina', image: 'assets/person.png' },
  { id: 8, name: 'Ollie', image: 'assets/person.png' },
  { id: 9, name: 'Nina', image: null },
  { id: 10, name: 'Ollie', image: 'assets/person.png' },
  { id: 11, name: 'Brian', image: 'assets/person.png' },
  { id: 12, name: 'Nina', image: 'assets/person.png' },
  { id: 13, name: 'Ollie', image: null },
  { id: 14, name: 'Brian', image: null },
  { id: 15, name: 'Nina', image: 'assets/person.png' },
  { id: 16, name: 'Ollie', image: 'assets/person.png' },
  { id: 17, name: 'Nina', image: 'assets/person.png' },
  { id: 18, name: 'Ollie', image: null },
  { id: 19, name: 'Nina', image: 'assets/person.png' },
  { id: 20, name: 'Ollie', image: 'assets/person.png' }
];
