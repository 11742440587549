import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CALENDER_ROUTES, CONTACTS_ROUTES, MAILBOX_ROUTES, SETTINGS_ROUTES } from '@app/constants';
import { IconsModule } from '@app/shared/modules/icons.module';
import { findMatchingItem } from '@app/utils/matching-item';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  activeIcon = 'mail';
  icons = [
    {
      id: 'mail',
      iconName: 'envelope',
      route: MAILBOX_ROUTES.MAIL,
    },
    {
      id: 'calendar',
      iconName: 'calendar-days',
      route: CALENDER_ROUTES.CALENDER,
    },
    {
      id: 'my-contacts',
      iconName: 'id-badge',
      route: CONTACTS_ROUTES.MY_CONTACTS,
    },
    {
      id: 'settings',
      iconName: 'gear',
      route: SETTINGS_ROUTES.SETTINGS,
    },
  ];

  private routerEventsSubscription!: Subscription;
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.routerEventsSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => {
        this.setActiveIconBasedOnRoute(event.url);
      });

    this.setActiveIconBasedOnRoute(this.router.url);
  }

  ngOnDestroy(): void {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  onIconClick(icon: { id: string; iconName: string; route: string }): void {
    this.activeIcon = icon.id;
    this.router.navigate(['/' + icon.route]);
  }

  private setActiveIconBasedOnRoute(currentRoute: string): void {
    const matchingIcon = findMatchingItem(this.icons, currentRoute);
    if (matchingIcon) {
      this.activeIcon = matchingIcon.id;
    } else if (!matchingIcon && currentRoute === '/o-mailo/new-email?action=New_Email') {
      this.activeIcon = 'mail';
    }
  }
}
