import {
  Component,
  DestroyRef,
  effect,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TableModule } from 'primeng/table';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { OtrimFacade } from '../../services/otrim.facade';
import { ITrimmedLinksData, IRangeDatePayloads, IApiResponseWithData } from '../../models';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { finalize, map, mergeMap, of, take } from 'rxjs';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmModalComponent, OToastV2Service } from 'o-suite-lib';
import { ToastModule } from 'primeng/toast';
import { assetUrl } from '../../../single-spa/asset-url';
import { SkeletonModule } from 'primeng/skeleton';
import { deleteUrlConfirmationData, loadingData } from './url-table.const';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-shortified-url-table',
  standalone: true,
  imports: [
    TableModule,
    FontAwesomeModule,
    CommonModule,
    TooltipModule,
    DropdownModule,
    ContextMenuModule,
    ConfirmDialogModule,
    ConfirmModalComponent,
    ToastModule,
    SkeletonModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './shortified-url-table.component.html',
  styleUrl: './shortified-url-table.component.scss',
})
export class ShortifiedUrlTableComponent implements OnInit {
  @Input() selectedItem!: ITrimmedLinksData;
  @Output() selectedItemChange = new EventEmitter<any>();

  private otrimFacade = inject(OtrimFacade);
  private router = inject(Router);
  private oToastV2Service = inject(OToastV2Service);
  private destroyRef = inject(DestroyRef);

  contextMenuItems: MenuItem[] = [
    {
      label: 'Copy',
      command: () => {
        let linkToCopy = environment.baseLink + this.targetUrl?.shortEndUrl;
        navigator.clipboard
          .writeText(linkToCopy)
          .then(() => {
            this.oToastV2Service.add({
              severity: 'success',
              summary: 'Link copied to clipboard',
              icon: environment.publicPath + '/assets/icons/toast/success.svg',
              life: environment.ToastMessageTime,
            });
          })
          .catch((err) => {
            console.error(err);
            this.oToastV2Service.add({
              severity: 'error',
              summary: 'Could not copy link',
              icon: environment.publicPath + '/assets/icons/toast/error.svg',
              life: environment.ToastMessageTime,
            });
          });
      },
    },
    {
      label: 'Edit',
      command: () => {
        if (this.targetUrl) {
          this.otrimFacade.setLinkToEdit(this.targetUrl);
          document.body.scrollTop = 0;
        }
      },
    },
    {
      label: 'Delete',
      command: () => {
        this.isShowConfirmModal = true;
      },
    },
  ];
  faEllipsis = faEllipsis;
  trimmedLinks: ITrimmedLinksData[] = Array(8).fill({ ...loadingData });
  isShowConfirmModal: boolean = false;
  targetUrl?: ITrimmedLinksData;
  @ViewChild('urlTableElement') urlTableElement!: ElementRef;


  constructor() {
    effect(
      () => {
        const isScrollToLinksTable = this.otrimFacade.isScrollToLinksTable();
        if (isScrollToLinksTable) {
          this.urlTableElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        this.otrimFacade.setIsScrollToLinksTable(false);
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit() {
    this.otrimFacade
      .getDateRange()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        mergeMap((dateRange: IRangeDatePayloads) =>
          this.otrimFacade.getTrimAnalytics(dateRange).pipe(
            map((response: IApiResponseWithData<ITrimmedLinksData[]>) => {
              this.trimmedLinks = response?.result?.data || [];
              this.otrimFacade.setTrimmedLinks(this.trimmedLinks);
              return this.trimmedLinks;
            })
          )
        ),
        mergeMap(() => {
          return of(this.otrimFacade.getFilteredLinks()());
        }),
        take(1),
        finalize(() => {
          this.otrimFacade.isLinksDataLoadingSignal.set(false);
        })
      )
      .subscribe({
        next: (data: ITrimmedLinksData[]) => {
          if (data && data?.length > 0) {
            this.trimmedLinks = data;
          }
        },
        error: () => {
          this.trimmedLinks = [];
        }
      });
  }

  setTargetUrl(url: ITrimmedLinksData) {
    this.targetUrl = url;
    sessionStorage.setItem('targetShortEndUrl', url?.shortEndUrl);
  }

  deleteFile() {
    this.otrimFacade.deleteTrimmedLink(this.targetUrl?.trimUrlId?.toString() || '').subscribe(
      () => {
        this.isShowConfirmModal = false;
        this.otrimFacade.deleteTrimmedLinkById(this.targetUrl?.trimUrlId || 0);
        this.closeConfirmDialog();
        this.oToastV2Service.add({
          severity: 'success',
          summary: 'Link Deleted Successfully.',
          icon: environment.publicPath + '/assets/icons/toast/success.svg',
          life: environment.ToastMessageTime,
        });
      },
      (error) => {
        this.isShowConfirmModal = false;
        this.oToastV2Service.add({
          severity: 'error',
          summary: error.error,
          icon: environment.publicPath + '/assets/icons/toast/error.svg',
          life: environment.ToastMessageTime,
        });
      }
    );
  }

  closeConfirmDialog() {
    this.isShowConfirmModal = false;
  }

  handleShowAnalytics(url: ITrimmedLinksData) {
    this.setTargetUrl(url);
    this.router.navigate(['/O-Shortify/link', url?.trimUrlId]);
  }

  protected readonly environment = environment;
  protected readonly deleteUrlConfirmationData = deleteUrlConfirmationData;
  protected readonly assetUrl = assetUrl;
  protected isLinksDataLoading = this.otrimFacade.isLinksDataLoadingSignal;
}
