import { Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MarketplaceComponent } from './pages/marketplace/marketplace.component';
import { BASE_ROUTE_URL } from '@app/shared/constants';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { MyContactsComponent } from './pages/my-contacts/my-contacts.component';

const ROUTES = {
  MARKETPLACE: `${BASE_ROUTE_URL}marketplace`,
  HOME: `${BASE_ROUTE_URL}home`,
  MYPROFILE: `${BASE_ROUTE_URL}myProfile`,
  MYCONTACTS: `${BASE_ROUTE_URL}my-contacts`
};

export const routes: Routes = [
  { path: '', redirectTo: ROUTES.HOME, pathMatch: 'full' },
  { path: ROUTES.MARKETPLACE, component: MarketplaceComponent, pathMatch: 'full' },
  { path: ROUTES.HOME, component: DashboardComponent, pathMatch: 'full' },
  { path: ROUTES.MYPROFILE, component: MyProfileComponent, pathMatch: 'full' },
  { path: ROUTES.MYCONTACTS, component: MyContactsComponent, pathMatch: 'full' },
  { path: '**', redirectTo: ROUTES.HOME }
];
