import { Component, inject } from '@angular/core';
import { MailSidebarComponent } from '../../components/mail/mail-sidebar/mail-sidebar.component';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { EmailService } from '@app/services/Email.service';
import { mockEmailResponse } from '@app/mocks/mail';
import { filter } from 'rxjs';

@Component({
  selector: 'app-mail',
  standalone: true,
  imports: [MailSidebarComponent, RouterModule],
  templateUrl: './mail.component.html',
  styleUrl: './mail.component.scss',
})
export class MailComponent {
  route = inject(ActivatedRoute);
  router = inject(Router);
  emailService = inject(EmailService);
}
