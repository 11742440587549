<p-dialog [modal]="true" [(visible)]="visible" [draggable]="false" (onHide)="handleFinish()">
  <div
    class="flex h-full"
    [ngClass]="{
      'flex-row': steps[currentStep].textAlign === 'LEFT',
      'flex-row-reverse': steps[currentStep].textAlign === 'RIGHT'
    }"
  >
    <div class="w-6 step-image-container border-round-xl">
      @if(steps[currentStep].imgPath.length){
      <img [src]="steps[currentStep].imgPath" />
      }
    </div>
    <div
      class="w-6 text-align flex flex-column justify-content-between py-2 px-4 step-text-container border-round-xl"
      [ngStyle]="{ 'text-align': steps[currentStep].textAlign === 'LEFT' ? 'left' : 'right' }"
    >
      <div class="">
        <h1 class="mb-0">{{ steps[currentStep].headerText }}</h1>
        <p class="my-0 mt-1 text-sm text-color-tertiary-300 font-medium">{{ steps[currentStep].date }}</p>
        <p>{{ steps[currentStep].headerText }}</p>
        <div [innerHTML]="steps[currentStep].content" class="overflow-auto h-20rem"></div>
      </div>

      <div class="flex justify-content-end gap-3 mb-4">
        @if(currentStep!==0){
        <p-button [outlined]="true" (click)="handleBack()" styleClass="pl-2 px-4 border-round-xl">Back</p-button>
        } @if(currentStep!==steps.length-1){
        <p-button (click)="handleNext()" styleClass="pl-2 px-4 border-round-xl">Next</p-button>
        } @if(currentStep===steps.length-1){
        <p-button (click)="handleFinish()" styleClass="pl-2 px-4 border-round-xl bg-alternative-500 border-0">
          Got it
        </p-button>
        }
      </div>
    </div>
  </div>
</p-dialog>
