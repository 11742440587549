import { Component, EventEmitter, inject, input, Output } from '@angular/core';
import { AppsItem } from '../../../app.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import {
  BASE_ROUTE_URL_DASHBOARD,
  BASE_ROUTE_URL_OMAIL,
  BASE_ROUTE_URL_OMEET,
  BASE_ROUTE_URL_TRIM
} from '../../../shared/constants/global.constant';
import { Router } from '@angular/router';

@Component({
  selector: 'app-apps-list',
  standalone: true,
  imports: [FontAwesomeModule, CommonModule],
  templateUrl: './apps-list.component.html',
  styleUrl: './apps-list.component.scss'
})
export class AppsListComponent {
  apps = input<AppsItem[]>();
  title = input<string>();

  @Output() appClickedChange: EventEmitter<boolean> = new EventEmitter();

  private router = inject(Router);

  openApp(id: number) {
    if (id === 1) {
      this.router.navigate([BASE_ROUTE_URL_OMAIL]);
    }
    if (id === 2) {
      this.router.navigate([BASE_ROUTE_URL_TRIM]);
    }
    if (id === 3) {
      this.router.navigate([BASE_ROUTE_URL_OMEET]);
    }
    if (id === 4) {
      this.router.navigate([BASE_ROUTE_URL_DASHBOARD]);
    }
    this.appClickedChange.emit(false);
  }
}
