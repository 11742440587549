import {
  Component,
  effect,
  EventEmitter,
  inject,
  input,
  Input,
  OnInit,
  Output,
  Signal,
  ViewChild,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { DividerModule } from 'primeng/divider';
import { IconsModule } from '@app/shared/modules/icons.module';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { InputNumberModule } from 'primeng/inputnumber';
import { ICalendarDropdownOptions, ICalendarEventPayload, IGuest } from '@app/shared/models';
import { repeatOptions } from '@app/pages/calendar/calendar.constants';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DateSelectArg } from '@fullcalendar/core';
import { ChipsModule } from 'primeng/chips';
import { FloatLabelModule } from 'primeng/floatlabel';
import { StyleClassModule } from 'primeng/styleclass';
import { Editor, EditorModule } from 'primeng/editor';
import { CalendarFacade } from '@app/pages/calendar/services/calendar.facade';
import { IMyProfileData } from '@app/shared/models/settings.model';
import { emailArrayValidator, timeRangeValidator } from '@app/pages/calendar/services/calendar-validators';

@Component({
  selector: 'app-create-event-popup',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    InputTextModule,
    CheckboxModule,
    FormsModule,
    ConfirmDialogModule,
    FaIconComponent,
    CalendarModule,
    DropdownModule,
    ReactiveFormsModule,
    DividerModule,
    IconsModule,
    ConfirmPopupModule,
    InputNumberModule,
    RadioButtonModule,
    ChipsModule,
    FloatLabelModule,
    StyleClassModule,
    EditorModule,
  ],
  providers: [ConfirmationService, DatePipe],
  templateUrl: './create-event-popup.component.html',
  styleUrl: './create-event-popup.component.scss',
})
export class CreateEventPopupComponent {
  private fb = inject(FormBuilder);
  private confirmationService = inject(ConfirmationService);
  private calendarFacade = inject(CalendarFacade);
  private datePipe = inject(DatePipe);

  @Input('selectedDayInfo') set setSelectedDayInfo(value: { selectedDayInfo: DateSelectArg; isOpenPopup: boolean }) {
    if (value) {
      if (value.isOpenPopup) this.closeCreatEvent();
      this.selectInfo = value.selectedDayInfo;
      this.eventForm.get('date')?.setValue(this.selectInfo.start);
      this.eventForm.get('fromTime')?.setValue(this.selectInfo.start);
      this.eventForm.get('toTime')?.setValue(this.selectInfo.end);
      this.openCreateEventPopup();
    }
  }

  @Output('eventCreated') eventCreated: EventEmitter<boolean> = new EventEmitter();

  profileEmailId = input.required<string>();
  isCreateEventLoading = this.calendarFacade.isCreateEventLoading;

  customRepeatVisibility: boolean = false;
  repeatOptions: ICalendarDropdownOptions[] = repeatOptions;
  selectInfo!: DateSelectArg;
  displayFormat: string = 'DD, MM d, yy';
  submitted: boolean = false;
  minDate!: Date;
  @ViewChild('pEditor') pEditor!: Editor;

  eventForm: FormGroup = this.fb.group(
    {
      eventName: ['', Validators.required],
      date: [undefined, Validators.required],
      fromTime: [undefined, Validators.required],
      toTime: [undefined, Validators.required],
      guests: [[], [Validators.required, emailArrayValidator()]],
      location: [''],
      description: [''],
    },
    {
      validators: timeRangeValidator('fromTime', 'toTime'),
    }
  );

  constructor() {
    effect(
      () => {
        const isCreateEventLoading = this.isCreateEventLoading();
        if (!isCreateEventLoading) {
          this.eventCreated.emit();
          this.closeCreatEvent();
        }
      },
      { allowSignalWrites: true }
    );
  }

  onRepeatChange() {
    if (this.eventForm.get('repeatOption')?.value?.value === 'custom') {
      this.customRepeatVisibility = true;
    }
  }

  openCreateEventPopup() {
    this.confirmationService.confirm({
      target: this.selectInfo?.jsEvent?.target as EventTarget,
      key: 'custom',
    });
  }

  resetRepeatOption() {
    this.eventForm.get('repeatOption')?.setValue({ name: 'Do not repeat', value: 'dontRepeat' });
  }

  closeCreatEvent() {
    this.submitted = false;
    this.eventForm.reset();
    this.confirmationService.close();
  }

  createEvent() {
    this.submitted = true;
    if (this.eventForm.valid) {
      let guests: IGuest[] = this.eventForm.get('guests')?.value?.map(
        (email: any): IGuest => ({
          inviteeEmailId: email,
          availabilityStatus: 0,
          inviteeId: 0,
          isOrganiser: false,
        })
      );
      if (this.profileEmailId()) {
        guests.push({
          inviteeEmailId: this.profileEmailId(),
          availabilityStatus: 0,
          inviteeId: 0,
          isOrganiser: true,
        });
      }
      const fromTime = this.datePipe.transform(this.eventForm.get('fromTime')?.value, 'yyyy-MM-dd HH:mm:ss') || '';
      const endTime = this.datePipe.transform(this.eventForm.get('toTime')?.value, 'yyyy-MM-dd HH:mm:ss') || '';
      const eventDate = this.datePipe.transform(this.eventForm.get('date')?.value, 'yyyy-MM-ddTHH:mm:ss') || '';

      const event: ICalendarEventPayload = {
        sub: this.eventForm.get('eventName')?.value ? this.eventForm.get('eventName')?.value : '',
        eventDate: eventDate,
        startTime: fromTime,
        endTime: endTime,
        allDays: false,
        invitees: guests,
        location: this.eventForm.get('location')?.value ? this.eventForm.get('location')?.value : '',
        description: this.eventForm.get('description')?.value ? this.eventForm.get('description')?.value : '',
        repeatEvent: 0,
        shareStatus: 0,
        eventId: 0,
      };

      let fd: FormData = new FormData();
      fd.append('data', JSON.stringify(event));
      fd.append('action', '1');

      this.calendarFacade.CreateEvent(fd);
      this.submitted = false;
    }
  }
}
