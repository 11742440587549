<div
  id="suite_dashboard_totalCustomers_container"
  class="total-customers h-full w-full flex flex-column justify-content-center align-items-center border-round-lg shadow-1"
>
  <div
    id="suite_dashboard_totalCustomers_icon-container"
    class="icon-container flex justify-content-center align-items-center border-round-lg"
  >
    <fa-icon icon="users"></fa-icon>
  </div>
  <div id="suite_dashboard_totalCustomers_title" class="title mt-3 text-base text-center">Total customers</div>
  <div id="suite_dashboard_totalCustomers_count" class="count mt-0 font-bold text-center text-6xl">
    {{ customerInfo()?.totalUsers }}
  </div>
</div>
