import { inject, Injectable, Signal, signal } from '@angular/core';
import { OtrimState } from './otrim.state';
import { OtrimApi } from './otrim.api';
import { Observable } from 'rxjs';
import {
  IFileData,
  IFileLinksPayload,
  IFilesPayload,
  IFileTrimmedLinksData,
  IApiResponse,
  IApiResponseWithData,
  ITrimmedLinksData,
  IRangeDatePayloads,
  ITrimSingleLink,
  IDashboard,
  IDashboardPayload,
  IBulkTemplatePayload,
  ISearchAliasPayload,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class OtrimFacade {
  private otrimApi = inject(OtrimApi);
  private otrimState = inject(OtrimState);

  private isDashboardDataLoadingSignal = signal(false);
  isDashboardDataLoading = this.isDashboardDataLoadingSignal.asReadonly();

  isLinksDataLoadingSignal = signal(false);
  isLinksFileDataLoadingSignal = signal(false);
  isScrollToLinksTableSignal = signal(false);
  isScrollToLinksTable = this.isScrollToLinksTableSignal.asReadonly();

  setDashboardDataLoadingState(isLoading: boolean): void {
    this.isDashboardDataLoadingSignal.set(isLoading);
  }

  setIsScrollToLinksTable(value: boolean) {
    this.isScrollToLinksTableSignal.set(value);
  }

  trimSingleLink(payload: ITrimSingleLink): Observable<IApiResponseWithData<ITrimmedLinksData>> {
    return this.otrimApi.insertCustomTrim(payload);
  }

  getDashboardData(payload: IDashboardPayload): Observable<IApiResponse<IDashboard>> {
    this.setDashboardDataLoadingState(true);
    return this.otrimApi.getDashboardData(payload);
  }

  setDashboardStatistics(stat: IDashboard): void {
    this.otrimState.setDashboardStatistics(stat);
  }

  getDashboardStatistics(): Signal<IDashboard | null> {
    return this.otrimState.dashboardStatistics;
  }

  getTrimAnalytics(payload: IRangeDatePayloads): Observable<IApiResponseWithData<ITrimmedLinksData[]>> {
    this.isLinksDataLoadingSignal.set(true);
    return this.otrimApi.getTrimAnalytics(payload);
  }

  getAllFilesData(payload: IFilesPayload): Observable<IApiResponseWithData<IFileData[]>> {
    return this.otrimApi.getAllFilesData(payload);
  }

  getFileLinks(payload: IFileLinksPayload): Observable<IApiResponseWithData<IFileTrimmedLinksData[]>> {
    this.isLinksFileDataLoadingSignal.set(true);
    return this.otrimApi.fetchRecordByFile(payload);
  }

  getSampleCsv(payload: IBulkTemplatePayload): Observable<Blob> {
    return this.otrimApi.getSampleCsv(payload);
  }

  fileUploader(url: any, params: any): Observable<IApiResponseWithData<string>> {
    return this.otrimApi.checkFileName(url, params);
  }

  downloadLinksAsCsv(payload: IRangeDatePayloads) {
    return this.otrimApi.getTrimUrlsCsv(payload);
  }

  exportFileAsCsv(payload: string) {
    return this.otrimApi.exportFileAsCsv(payload);
  }

  searchAlias(payload: ISearchAliasPayload): Observable<IApiResponseWithData<ITrimmedLinksData[]>> {
    return this.otrimApi.searchAlias(payload);
  }

  setFilteredLinks(data: ITrimmedLinksData[]): void {
    this.otrimState.setFilteredLinks(data);
  }

  getFilteredLinks(): Signal<ITrimmedLinksData[]> {
    return this.otrimState.filteredLinks;
  }

  deleteFile(fileId: string): Observable<IApiResponseWithData<string>> {
    return this.otrimApi.deleteFile(fileId);
  }

  setFilesRecords(data: IFileData[]) {
    this.otrimState.setFilesRecords(data);
  }

  deleteFileRecord(fileId: number): void {
    this.otrimState.deleteFileRecord(fileId);
  }

  setDateRange(data: IRangeDatePayloads) {
    this.otrimState.setDateRange(data);
  }

  getDateRange(): Observable<IRangeDatePayloads> {
    return this.otrimState.getDateRange();
  }

  deleteTrimmedLink(payload: string) {
    return this.otrimApi.deleteTrimmedLink(payload);
  }

  setTrimmedLinks(data: ITrimmedLinksData[]) {
    this.otrimState.setTrimmedLinks(data);
  }

  getTrimmedLinksRecords(): Signal<ITrimmedLinksData[] | null> {
    return this.otrimState.trimmedLinks;
  }

  deleteTrimmedLinkById(trimUrlId: number): void {
    this.otrimState.deleteTrimmedLinkById(trimUrlId);
  }

  setLinkToEdit(linkData: ITrimmedLinksData | null) {
    this.otrimState.setLinkToEdit(linkData);
  }

  getLinkToEdit(): Signal<ITrimmedLinksData | null> {
    return this.otrimState.linkToEdit;
  }

  UpdatedTrimmedLink(payload: any) {
    return this.otrimApi.UpdatedTrimmedLink(payload);
  }

  addTrimmedLink(trimmedLink: ITrimmedLinksData) {
    this.otrimState.addTrimmedLink(trimmedLink);
  }
}
