<p-dataView #dv [value]="socialsData" class="socials-list-view__container">
  <ng-template pTemplate="list" let-socials>
    <div class="grid grid-nogutter mt-2">
      @for (social of socials; track social?.social) {
        <div class="col-12 socials__container">
          <div class="flex justify-content-between align-items-center">
            <div class="flex gap-2 align-items-center my-2">
              <div>
                <img
                  class="w-3rem h-3rem border-circle bg-cover flag-img"
                  [alt]="social?.social"
                  [src]="assetUrl('icons/' + social?.socialSource?.toLowerCase() + '.svg')"
                />
              </div>
              <span class="text-lg">{{ social?.socialSource }}</span>
            </div>

            <span class="text-primary text-lg font-bold">{{ social?.count }}</span>
          </div>
        </div>
      }
    </div>
  </ng-template>
</p-dataView>
