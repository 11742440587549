import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LinkComponent } from './pages/link/link.component';
import { BASE_ROUTE_URL } from './shared/constants/global.constant';

const ROUTES = {
  HOME: `${BASE_ROUTE_URL}home`,
  LINK_ID: `${BASE_ROUTE_URL}link/:id`,
};

export const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: ROUTES.HOME, component: HomeComponent },
  { path: ROUTES.LINK_ID, component: LinkComponent },
  { path: '**', redirectTo: ROUTES.HOME },
];
