<ul class="menu list-none p-0">
  @for (item of menuItems(); track item.id) {
    <li
      class="menu__item flex align-items-center gap-2 cursor-pointer border-round-sm"
      [ngClass]="{ 'menu__item--active  bg-primary-100': activeItem() == item.id }"
      (click)="onClick($event, item.id)"
    >
      <fa-icon
        [icon]="item.icon"
        class="menu__icon"
        [ngClass]="{
          'text-color-primary-500 font-semibold': activeItem() == item.id,
          'text-color-tertiary-200': activeItem() != item.id,
        }"
      ></fa-icon>
      <span
        class="menu__text"
        [ngClass]="{
          'text-color-primary-500 font-semibold': activeItem() == item.id,
          'text-color-tertiary-500': activeItem() != item.id,
        }"
      >
        {{ item.text }}
      </span>
      @if (item.badge) {
        <span
          class="menu__badge flex justify-content-center align-items-center border-circle text-xs bg-danger-500 text-white"
        >
          {{ item.badge }}
        </span>
      }
    </li>
  }
</ul>
