import { inject, Injectable, signal } from '@angular/core';
import { CalendarState } from '@app/pages/calendar/services/calendar.state';
import { CalendarApi } from '@app/pages/calendar/services/calendar.api';
import {
  IApiResponse,
  IApiResponseData,
  ICalendarDataPayload,
  ICalendarEvent,
  ICalendarEventPayload,
  ICalendarEventsData,
  IEventsApiResponse,
} from '@app/shared/models';
import { debounceTime, Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IMyProfileData } from '@app/shared/models/settings.model';
import { environment } from '@src/environments/environment';
import { OToastV2Service } from 'o-suite-lib';

@Injectable({
  providedIn: 'root',
})
export class CalendarFacade {
  private calendarState = inject(CalendarState);
  private calendarApi = inject(CalendarApi);
  oToastV2Service = inject(OToastV2Service);

  isCreateEventLoading$ = signal(false);
  isCreateEventLoading = this.isCreateEventLoading$.asReadonly();

  getProfileInfo(): Observable<IApiResponseData<IMyProfileData>> {
    return this.calendarApi.getProfileInfo();
  }

  getCalendarEventsData(data: ICalendarDataPayload) {
    this.calendarApi
      .getCalendarEventsData(data)
      .pipe(debounceTime(500))
      .subscribe((res: IEventsApiResponse<ICalendarEventsData[]>) => {
        this.calendarState.setCalendarEvents(res?.events);
      });
  }

  getCalendarEvents(): Observable<ICalendarEventsData[] | null> {
    return this.calendarState.getCalendarEvents();
  }

  getEventById(id: string): Observable<ICalendarEvent> {
    return this.calendarApi.getEventById(id);
  }

  deleteEvent(id: string) {
    return this.calendarApi.deleteEvent(id);
  }

  setAttendance(attendStatus: string, eventId: number) {
    return this.calendarApi.setAttendance(attendStatus, eventId);
  }

  CreateEvent(fd: FormData) {
    this.isCreateEventLoading$.set(true);
    return this.calendarApi.CreateEvent(fd).subscribe(
      (res: IApiResponseData<any>) => {
        const message = res?.message ?? 'Event sent successfully!';
        this.oToastV2Service.add({
          severity: 'success',
          summary: message,
          icon: environment.publicPath + '/assets/icons/toast/success.svg',
        });
        this.isCreateEventLoading$.set(false);
      },
      (error) => {
        this.isCreateEventLoading$.set(false);
      }
    );
  }
}
