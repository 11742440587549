import {
  TopCustomersComponent,
  TotalRevenueComponent,
  RevenueOverviewComponent,
  TotalCustomersComponent,
  TimelineComponent,
  UpcomingProductsComponent
} from '@app/components/dashboard';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { IconsModule, svgIconModule } from '@app/shared/modules';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { CustomerTransactionViewComponent } from '@app/components/dashboard/customer-transaction-view/customer-transaction-view.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize } from 'rxjs';
import { IApiResponseWithData } from '@app/model/api.model';
import { ICustomerInfo } from '@app/model';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { AffiliateButtonComponent } from '../../components/dashboard/affiliate-button/affiliate-button.component';
import { ICountry, IProfileInfo } from '@app/model/myProfile.model';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    TotalRevenueComponent,
    RevenueOverviewComponent,
    TotalCustomersComponent,
    TimelineComponent,
    UpcomingProductsComponent,
    IconsModule,
    CustomerTransactionViewComponent,
    TopCustomersComponent,
    svgIconModule,
    ButtonModule,
    TooltipModule,
    AffiliateButtonComponent
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit {
  private osuiteFacade = inject(OsuiteFacade);
  private destroyRef = inject(DestroyRef);
  isUserInfoLoading: boolean = true;
  isCountriesLoading: boolean = true;

  ngOnInit(): void {
    this.fetchDashboardTotalRevenue();
    this.fetchCustomerInfo();
    this.fetchUserInfo();
    this.fetchCountries();
  }

  fetchDashboardTotalRevenue() {
    this.osuiteFacade
      .fetchDashboardTotalRevenue()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {})
      )
      .subscribe((res: any) => {});
  }

  fetchCustomerInfo() {
    this.osuiteFacade
      .fetchCustomerInfo()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {})
      )
      .subscribe((res: IApiResponseWithData<ICustomerInfo>) => {
        this.osuiteFacade.setCustomerInfo(res.data);
      });
  }

  fetchUserInfo() {
    this.osuiteFacade
      .fetchProfileInfo()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
          this.isUserInfoLoading = false;
        })
      )
      .subscribe({
        next: (res: IApiResponseWithData<IProfileInfo>) => {
          this.osuiteFacade.setProfileInfo(res.data);
        }
      });
  }

  fetchCountries() {
    this.osuiteFacade
      .fetchCountries()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
          this.isCountriesLoading = false;
        })
      )
      .subscribe({
        next: (res: IApiResponseWithData<ICountry[]>) => {
          this.osuiteFacade.setCountries(res.body);
        }
      });
  }
}
