import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, input, Input, signal, SimpleChanges } from '@angular/core';
import { IconsModule } from '@app/shared/modules/icons.module';
import { TooltipModule } from 'primeng/tooltip';
import { PriorityIconComponent } from '@app/shared/svg/priority-icon/priority-icon.component';
import { Router } from '@angular/router';
import { EMAIL_ACTIONS, MAILBOX_ROUTES } from '@app/constants';
import {
  Email,
  IDeleteEmailPayload,
  IImportantEmailPayload,
  IReadEmailPayload,
  IReadEmailResponse,
} from '@app/shared/models/omail';
import { EmailService } from '@app/services/Email.service';
import { TextToSpeechComponent } from './text-to-speech/text-to-speech.component';
import { EmailFormatterService } from '@app/services/email-format.service';
import { SocketV2Service } from '@app/services/socket-v2.service';
import { OToastV2Service } from 'o-suite-lib';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { environment } from '@src/environments/environment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-email-header',
  standalone: true,
  imports: [
    CommonModule,
    OverlayPanelModule,
    TooltipModule,
    IconsModule,
    PriorityIconComponent,
    TextToSpeechComponent,
    ProgressSpinnerModule,
  ],
  templateUrl: './email-header.component.html',
  styleUrl: './email-header.component.scss',
})
export class EmailHeaderComponent {
  showActions = input(true);
  router = inject(Router);
  emailService = inject(EmailService);
  emailFormatterService = inject(EmailFormatterService);
  socketService = inject(SocketV2Service);
  loading = signal(false);
  deleteLoading = signal(false);
  toaster = inject(OToastV2Service);
  private destroyRef = inject(DestroyRef);

  emailActions = EMAIL_ACTIONS;
  highPriority = signal<boolean>(false);
  emailDetails = input<IReadEmailResponse>();
  formattedEmail: string = '';
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['emailDetails']) {
      this.formattedEmail = this.emailFormatterService.formatEmailForReading(this.emailDetails());
      this.highPriority.set(this.emailDetails()?.important ?? false);
    }
  }

  toggleFavorite(event: Event) {
    event.stopPropagation();
    if (this.emailDetails() != undefined) {
      const payload: IImportantEmailPayload = {
        msgs: [this.emailDetails()?.sno!],
        folder: this.emailDetails()?.folderName || '',
        important: !this.emailDetails()?.important,
      };
      this.loading.set(true);
      this.emailService
        .markAsFavouriteOrImportant(payload)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.socketService.updateEmailDetails({
              messageId: this.emailDetails()?.messageId,
              folderName: this.emailDetails()?.folderName,
              important: !this.highPriority,
            });
            this.highPriority.set(!this.highPriority);
            this.loading.set(false);
          },
          error: (err) => {
            this.loading.set(false);
            this.toaster.add({
              severity: 'error',
              summary: err?.error?.message,
              icon: environment.publicPath + '/assets/icons/toast/error.svg',
            });
          },
        });
    }
  }

  moveToTrash() {
    const payload: IDeleteEmailPayload = {
      mailIds: [this.emailDetails()?.sno!],
      folder: this.emailDetails()?.folderName || '',
    };
    this.deleteLoading.set(true);
    this.emailService
      .deleteEmail(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.router.navigate([MAILBOX_ROUTES.MAIL, MAILBOX_ROUTES.INBOX]);
          this.toaster.add({
            severity: 'success',
            summary: res.message,
            icon: environment.publicPath + '/assets/icons/toast/success.svg',
          });
          this.socketService.deleteEmails({
            mailIds: [this.emailDetails()?.sno!],
            folder: this.emailDetails()?.folderName || '',
          });

          this.deleteLoading.set(false);
        },
        error: (err) => {
          this.toaster.add({
            severity: 'error',
            summary: err?.error?.message,
            icon: environment.publicPath + '/assets/icons/toast/error.svg',
          });
          this.deleteLoading.set(false);
        },
      });
  }

  navigateToCreateEmail(action: string): void {
    const emailDetails = this.emailDetails();

    if (emailDetails) {
      this.emailService.setCurrentEmail(emailDetails);
      this.router.navigate([`${MAILBOX_ROUTES.MAIL}/${MAILBOX_ROUTES.NEW_EMAIL}/${emailDetails?.sno}`], {
        queryParams: { action },
      });
    }
  }
  showOverlay(event: MouseEvent, overlayPanel: any) {
    overlayPanel.toggle(event);
  }
}
