import { enableProdMode, NgZone, provideZoneChangeDetection } from '@angular/core';
import { Router, NavigationStart, provideRouter } from '@angular/router';
import { singleSpaAngular, getSingleSpaExtraProviders } from 'single-spa-angular';
import { singleSpaPropsSubject } from './single-spa/single-spa-props';
import { bootstrapApplication, provideClientHydration } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { APP_BASE_HREF } from '@angular/common';
import { routes } from './app/app.routes';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { encryptInterceptor } from './app/services/encrypt.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { OIconService, OToastV2Service } from 'o-suite-lib';

// if (environment.production) {
enableProdMode();
// }

const lifecycles = singleSpaAngular({
  bootstrapFunction: (singleSpaProps) => {
    singleSpaPropsSubject.next(singleSpaProps);
    return bootstrapApplication(AppComponent, {
      providers: [
        MessageService,
        OToastV2Service,
        OIconService,
        { provide: APP_BASE_HREF, useValue: '/' },
        getSingleSpaExtraProviders(),
        provideClientHydration(),
        provideRouter(routes),
        provideHttpClient(withInterceptors([encryptInterceptor])),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
      ],
    });
  },
  template: '<app-trim />',
  Router,
  NavigationStart,
  NgZone,
  domElementGetter: () => document.getElementById('trim-root') as HTMLElement,
});

export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;
