import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DataViewModule } from 'primeng/dataview';
import { environment } from '../../../../environments/environment.dev';
import { ICityListItemModel } from '../../../models';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-city-list-view',
  standalone: true,
  imports: [DataViewModule, CommonModule, FaIconComponent],
  templateUrl: './city-list-view.component.html',
  styleUrl: './city-list-view.component.scss',
})
export class CityListViewComponent {
  @Input() cities!: ICityListItemModel[];
  countryFlagUrl = environment.COUNTRY_SVG_URL;

  onImageError(index: number): void {
    this.cities[index].isSrcError = true;
  }

  protected readonly faFlag = faFlag;
}
