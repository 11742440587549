import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IApiResponse, IApiResponseData, IContactsData, ICountryData, IStatesData } from '@app/shared/models';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactsApi {
  oesUserApiUrl = environment.oesUserApiUrl;
  oMailApiUrl = environment.oMailApiUrl;

  private httpClient = inject(HttpClient);

  private readonly options = {
    withCredentials: true,
  };

  getAllContacts(): Observable<IApiResponse<IContactsData[]>> {
    return this.httpClient.get<IApiResponse<IContactsData[]>>(this.oesUserApiUrl + 'getAllContacts', this.options);
  }

  getCountries(): Observable<IApiResponse<ICountryData[]>> {
    return this.httpClient.get<IApiResponse<ICountryData[]>>(this.oesUserApiUrl + 'getCountries', this.options);
  }

  editContact(formData: FormData): Observable<IApiResponse<any>> {
    return this.httpClient.post<IApiResponse<any>>(this.oesUserApiUrl + 'saveOrUpdateContact', formData, this.options);
  }

  deleteContact(contactsIds: number[]) {
    return this.httpClient.post<IApiResponse<any>>(
      this.oesUserApiUrl + 'deleteContact',
      { ids: contactsIds },
      this.options
    );
  }

  addRemoveContactFromFavorite(contactsIds: number[]) {
    return this.httpClient.put<IApiResponse<any>>(
      this.oesUserApiUrl + 'contacts/favorite',
      { ids: contactsIds },
      this.options
    );
  }

  getStatesByCountryId(countryId: number): Observable<IApiResponse<IStatesData[]>> {
    return this.httpClient.get<IApiResponse<IStatesData[]>>(
      this.oesUserApiUrl + 'getStatesByCountryId/' + countryId,
      this.options
    );
  }

  getContactInfoByAlternativeEmail(alternateEmailId: string): Observable<IApiResponseData<IContactsData>> {
    return this.httpClient.get<IApiResponseData<IContactsData>>(
      this.oesUserApiUrl + 'alternateEmailId/' + alternateEmailId,
      this.options
    );
  }
}
