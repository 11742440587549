import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IUpdate } from '../model/header.model';

@Injectable({
  providedIn: 'root'
})
export class OheaderState {
  showUpdatesBanner = new BehaviorSubject<boolean>(true);
  bannerUpdates = new BehaviorSubject<IUpdate[] | null>(null);

  setShowUpdatesBanner(state: boolean) {
    this.showUpdatesBanner.next(state);
  }
  getShowUpdatesBanner() {
    return this.showUpdatesBanner.asObservable();
  }

  setBannerUpdates(bannerUpdates: IUpdate[]) {
    this.bannerUpdates.next(bannerUpdates);
  }
  getBannerUpdates() {
    return this.bannerUpdates.asObservable();
  }
}
