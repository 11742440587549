<div class="bg-white border-round p-4 mt-4 shadow-1" id="suite_myProfile_UserInfoCard">
  <div class="flex align-content-start gap-4 px-2" id="suite_myProfile_UserInfoCard_MainContainer">
    <div class="w-13rem h-12rem border-round overflow-hidden" id="suite_myProfile_UserInfoCard_ProfilePic">
      @if(isBillingLoading && isProfileLoading){
      <p-skeleton width="100%" height="100%" />
      }@else{ @if(profileInfo?.profilePic){
      <img [src]="imgHost + profileInfo?.profilePic" class="w-full h-full" />
      } @else {
      <div
        class="flex flex-column justify-content-center align-items-center w-13rem h-12rem upload-container custom-border-radius"
      >
        <p-avatar shape="circle" class="mr-2 text-white mb-3" size="xlarge">
          <fa-icon icon="user" />
        </p-avatar>
      </div>
      } }
    </div>
    <div class="flex-1" id="suite_myProfile_UserInfoCard_UserDetails">
      @if(isBillingLoading && isProfileLoading){
      <div class="my-4">
        <p-skeleton width="20%" animation="none" />
      </div>
      }@else{ @if(profileInfo?.firstName && profileInfo?.lastName ){
      <h3 class="mt-1 mb-4 text-xl">{{ profileInfo?.firstName + ' ' + profileInfo?.lastName }}</h3>
      }@else{
      <h3 class="mt-1 mb-4 text-xl">Profile cannot be provided</h3>
      } }
      <div class="flex flex-column user-info--container" id="suite_myProfile_UserInfoCard_ContactInfo">
        <div id="suite_myProfile_UserInfoCard_Phone">
          @if(isBillingLoading && isProfileLoading){
          <div class="mt-2">
            <p-skeleton height=".7rem" width="20%" />
          </div>
          }@else { @if(profileInfo?.mobileNumber){
          <div class="flex align-items-center">
            <fa-icon icon="phone" class="text-color-primary-500" />
            <span class="ml-2 font-medium">{{ profileInfo?.mobileNumber }}</span>
          </div>
          }@else{
          <div class="flex align-items-center">
            <fa-icon [icon]="faPhone" class="text-color-primary-500" />
            <span class="ml-2 font-medium">Number not available</span>
          </div>
          } }
        </div>
        <div id="suite_myProfile_UserInfoCard_Email">
          @if(isBillingLoading && isProfileLoading){
          <div class="mt-2">
            <p-skeleton height=".7rem" width="20%" />
          </div>
          }@else { @if(profileInfo?.emailId){
          <div class="flex align-items-center">
            <fa-icon [icon]="faEnvelope" class="text-color-primary-500" />
            <span class="ml-2 font-medium">{{ profileInfo?.emailId }}</span>
          </div>
          }@else{
          <div class="flex align-items-center">
            <fa-icon [icon]="faEnvelope" class="text-color-primary-500" />
            <span class="ml-2 font-medium">Email Id not available</span>
          </div>
          } }
        </div>
        <div id="suite_myProfile_UserInfoCard_Location">
          @if(isBillingLoading && isProfileLoading){
          <div class="mt-2">
            <p-skeleton height=".7rem" width="20%" />
          </div>
          }@else { @if(profileInfo?.location){
          <div class="flex align-items-center">
            <fa-icon [icon]="faLocation" class="text-color-primary-500" />
            <span class="ml-2 font-medium">{{ profileInfo?.location }}</span>
          </div>
          }@else{
          <div class="flex align-items-center">
            <fa-icon [icon]="faLocation" class="text-color-primary-500" />
            <span class="ml-2 font-medium">Location not available</span>
          </div>
          } }
        </div>
        <div id="suite_myProfile_UserInfoCard_JoinedDate">
          @if(isBillingLoading && isProfileLoading){
          <div class="mt-2">
            <p-skeleton height=".7rem" width="20%" />
          </div>
          }@else { @if(profileInfo?.joinedDate){
          <div class="flex align-items-center">
            <fa-icon [icon]="faCalendar" class="text-color-primary-500" />
            <span class="ml-2 font-medium">{{ profileInfo?.joinedDate }}</span>
          </div>
          }@else{
          <div class="flex align-items-center">
            <fa-icon [icon]="faCalendar" class="text-color-primary-500" />
            <span class="ml-2 font-medium">Date not available</span>
          </div>
          } }
        </div>
      </div>
    </div>
  </div>
  <p-divider class="divider-color" id="suite_myProfile_UserInfoCard_Divider" />

  <div class="px-2 billing-container" id="suite_myProfile_UserInfoCard_Billing">
    <p class="text-lg">Billing address</p>
    <div class="flex flex-wrap gap-8">
      <div class="col-2 p-0" id="suite_myProfile_UserInfoCard_Billing_Address1">
        <span class="text-base font-medium mb-2 text-color-tertiary-400">Address line 1</span>
        @if(isBillingLoading && isProfileLoading){
        <div class="mt-2">
          <p-skeleton height=".7rem" />
        </div>
        }@else{ @if( billingInfo?.primaryAddress){
        <p class="text-lg font-semibold">{{ billingInfo?.primaryAddress }}</p>
        }@else {
        <p class="text-sm text-color-tertiary-200">Address not available</p>
        } }
      </div>
      <div class="col-2 p-0" id="suite_myProfile_UserInfoCard_Billing_Address2">
        <span class="text-base font-medium text-color-tertiary-400">Address line 2</span>
        @if(isBillingLoading && isProfileLoading){
        <div class="mt-2">
          <p-skeleton height=".7rem" />
        </div>
        }@else{ @if(billingInfo?.secondaryAddress){
        <p class="text-lg font-semibold mt-2">{{ billingInfo?.secondaryAddress }}</p>
        }@else{
        <p class="text-sm text-color-tertiary-200">Address not available</p>
        } }
      </div>
      <div class="col-2 p-0" id="suite_myProfile_UserInfoCard_Billing_Country">
        <span class="text-base font-medium text-color-tertiary-400">Country</span>
        @if(isBillingLoading && isProfileLoading){
        <div class="mt-2">
          <p-skeleton height=".7rem" />
        </div>
        }@else{ @if(billingInfo?.country){
        <p class="text-lg font-semibold mt-2">{{ billingInfo?.country }}</p>
        }@else {
        <p class="text-sm text-color-tertiary-200">Country not available</p>
        } }
      </div>
    </div>
    <div class="flex flex-wrap gap-8 mt-4">
      <div class="col-2 p-0" id="suite_myProfile_UserInfoCard_Billing_State">
        <span class="text-base font-medium text-color-tertiary-400">State/Province/Region</span>
        @if(isBillingLoading && isProfileLoading){
        <div class="mt-2">
          <p-skeleton height=".7rem" />
        </div>
        }@else{ @if(billingInfo?.state){
        <p class="text-lg font-semibold mt-2">{{ billingInfo?.state }}</p>
        }@else{
        <p class="text-sm text-color-tertiary-200">State not available</p>
        } }
      </div>
      <div class="col-2 p-0" id="suite_myProfile_UserInfoCard_Billing_City">
        <span class="text-base font-medium text-color-tertiary-400">City</span>
        @if(isBillingLoading && isProfileLoading){
        <div class="mt-2">
          <p-skeleton height=".7rem" />
        </div>
        }@else{ @if( billingInfo?.city){
        <p class="text-lg font-semibold mt-2">{{ billingInfo?.city }}</p>
        }@else{
        <p class="text-sm text-color-tertiary-200">City not available</p>
        } }
      </div>
      <div class="col-2 p-0" id="suite_myProfile_UserInfoCard_Billing_ZipCode">
        <span class="text-base font-medium text-color-tertiary-400">Zip/Pin code</span>
        @if(isBillingLoading && isProfileLoading){
        <div class="mt-2">
          <p-skeleton height=".7rem" />
        </div>
        }@else{ @if(billingInfo?.zipCode){
        <p class="text-lg font-semibold mt-2">{{ billingInfo?.zipCode }}</p>
        }@else{
        <p class="text-sm text-color-tertiary-200">Zipcode not available</p>
        } }
      </div>
    </div>
  </div>
</div>
