import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { IconsModule } from '@app/shared/modules';
import { CarouselModule, CarouselPageEvent } from 'primeng/carousel';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { AvatarModule } from 'primeng/avatar';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ITopCustomer } from '@app/model';
import { assetUrl } from '@src/single-spa/asset-url';
import { SkeletonModule } from 'primeng/skeleton';
import { IApiResponseWithData } from '@app/model/api.model';
import { environment } from '@src/environments/environment';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-top-customers',
  standalone: true,
  imports: [CommonModule, CarouselModule, IconsModule, FontAwesomeModule, AvatarModule, SkeletonModule, TooltipModule],
  templateUrl: './top-customers.component.html',
  styleUrl: './top-customers.component.scss'
})
export class TopCustomersComponent implements OnInit {
  customers: ITopCustomer[] = [];
  currentPage: number | undefined = 0;
  isLastPage = false;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  isLoading: boolean = true;
  readonly imgHostUrl = environment.imgHostUrl;

  private osuiteFacade = inject(OsuiteFacade);
  private destroyRef = inject(DestroyRef);

  protected readonly assetUrl = assetUrl;

  ngOnInit(): void {
    this.osuiteFacade
      .fetchTopCustomers()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res: IApiResponseWithData<ITopCustomer[]>) => {
          if (res) {
            this.isLoading = false;
            this.customers = res.data;
          }
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  onPageChange(event: CarouselPageEvent) {
    this.currentPage = event.page;
    const totalPages = Math.ceil(this.customers.length / this.itemsPerPage);
    this.isLastPage = event.page === totalPages - 1;
  }

  readonly itemsPerPage = 6;
}
