export const environment = {
  publicPath: 'https://o-suite-mail-dev.onfusiondemo.cc',
  COUNTRY_SVG_URL: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/',
  production: false,
  oesUserApiUrl: 'https://oes-api-dev.onfusiondemo.cc/oesuser/',
  DEFAULT_KEY: '*$_0x8d91f7i*[^',
  oMailApiUrl: 'https://oes-api-dev.onfusiondemo.cc/omail/',
  cloudfrontBaseUrl: 'https://d2bsezkvf0wpv4.cloudfront.net/',
  socketURL: 'wss://oes-api-dev.onfusiondemo.cc/omail/email-updates',
};
