// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[0].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[0].use[2]!../node_modules/primeicons/primeicons.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
}

@font-face {
  font-family: "PrimeIcons";
  src: url("https://o-suite-mail-dev.onfusiondemo.cc/assets/fonts/primeicons.woff2") format("woff2"), url("https://o-suite-mail-dev.onfusiondemo.cc/assets/fonts/primeicons.woff") format("woff"), url("https://o-suite-mail-dev.onfusiondemo.cc/assets/fonts/primeicons.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/styles.scss"],"names":[],"mappings":"AAAA,8EAAA;AAGA;EACE,SAAA;AAAF;;AAGA;EACE,yBAAA;EACA,+RACE;EAGF,mBAAA;EACA,kBAAA;AAHF","sourcesContent":["/* You can add global styles to this file, and also import other style files */\n@import 'primeicons/primeicons.css';\n\nbody {\n  margin: 0;\n}\n\n@font-face {\n  font-family: 'PrimeIcons';\n  src:\n    url('https://o-suite-mail-dev.onfusiondemo.cc/assets/fonts/primeicons.woff2') format('woff2'),\n    url('https://o-suite-mail-dev.onfusiondemo.cc/assets/fonts/primeicons.woff') format('woff'),\n    url('https://o-suite-mail-dev.onfusiondemo.cc/assets/fonts/primeicons.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
