import { AfterViewInit, Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SidebarComponent } from '@app/core/sidebar/sidebar.component';
import { OToastV2Component } from 'o-suite-lib';
import { SocketV2Service } from './services/socket-v2.service';
import Quill from 'quill';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime } from 'rxjs';
import { IApiResponseData } from '@app/shared/models';
import { IMyProfileData } from '@app/shared/models/settings.model';
import { CalendarFacade } from '@app/pages/calendar/services/calendar.facade';

@Component({
  selector: 'mailo-root',
  standalone: true,
  imports: [RouterOutlet, SidebarComponent, OToastV2Component],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'o-suite-mail';
  socketService = inject(SocketV2Service);
  calendarFacade = inject(CalendarFacade);
  destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.calendarFacade
      .getProfileInfo()
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
      .subscribe((res: IApiResponseData<IMyProfileData>) => {
        sessionStorage.setItem('profileEmailId', res?.data?.emailId);
      });

    this.socketService.connect();
  }

  ngAfterViewInit(): void {
    // Force quill style loading in single-spa
    new Quill('#main', {});
  }

  ngOnDestroy(): void {
    this.socketService.disconnect();
  }
}
