<div
  id="suite_dashboard_transactionView_container"
  class="h-full flex flex-column bg-white shadow-1 rounded-md border-round-lg"
>
  @if(myEarnings.length){
  <div class="title flex gap-2 align-items-center px-3 pt-3">
    <div class="icon-container border-circle flex justify-content-center align-items-center">
      <svg-icon name="transactionViewIcon" />
    </div>
    <h2 id="suite_dashboard_transactionView_title" class="text-base font-medium m-0 text-color-tertiary-400">
      Customers recent transactions
    </h2>
  </div>
  <div id="suite_dashboard_transactionView_table-container" class="flex-1 overflow-hidden mt-2">
    <p-table id="suite_dashboard_transactionView_table" [value]="myEarnings" [scrollable]="true" scrollHeight="flex">
      <ng-template pTemplate="header">
        <tr id="suite_dashboard_transactionView_table-header">
          <th id="suite_dashboard_transactionView_column-orderId" class="text-xs font-bold w-1">Order ID</th>
          <th id="suite_dashboard_transactionView_column-name" class="text-xs font-bold w-4">Customer name</th>
          <th id="suite_dashboard_transactionView_column-date" class="text-xs font-bold w-4">Date</th>
          <th id="suite_dashboard_transactionView_column-amount" class="text-xs font-bold w-1">Amount</th>
          <th id="suite_dashboard_transactionView_column-earnings" class="text-xs font-bold w-3">You earn</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-customer>
        <tr id="suite_dashboard_transactionView_row_{{ customer.transactionId }}">
          <td
            id="suite_dashboard_transactionView_orderId_{{ customer.transactionId }}"
            class="text-xs font-semibold w-1"
          >
            @if(isLoading){
            <p-skeleton width="4rem" />
            }@else{
            {{ customer.transactionId }}
            }
          </td>
          <td id="suite_dashboard_transactionView_date_{{ customer.referralName }}" class="text-xs font-semibold w-4">
            @if(isLoading){
            <p-skeleton width="4rem" />
            }@else{
            {{ customer.referralName }}
            }
          </td>
          <td id="suite_dashboard_transactionView_date_{{ customer.transactionId }}" class="text-xs font-semibold w-4">
            @if(isLoading){
            <p-skeleton width="4rem" />
            }@else{
            {{ customer.createdDate | date : 'MMMM d, y' }}
            }
          </td>
          <td
            id="suite_dashboard_transactionView_amount_{{ customer.transactionId }}"
            class="text-xs font-semibold w-1"
          >
            @if(isLoading){
            <p-skeleton />
            }@else{
            {{ customer.paidamount | currency }}
            }
          </td>
          <td
            id="suite_dashboard_transactionView_earnings_{{ customer.transactionId }}"
            class="text-xs font-semibold w-3"
          >
            @if(isLoading){
            <p-skeleton />
            }@else{
            {{ '$' + customer.withdrawable || '—' }}
            }
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  }@else{
  <app-customer-transaction-empty class="h-full" />
  }
</div>
