import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
  inject,
  signal,
  DestroyRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IconsModule } from '@app/shared/modules/icons.module';
import { CheckboxModule } from 'primeng/checkbox';
import { PriorityIconComponent } from '@app/shared/svg/priority-icon/priority-icon.component';
import { TooltipModule } from 'primeng/tooltip';
import { Email, IDeleteEmailPayload, IImportantEmailPayload } from '@app/shared/models/omail';
import { MoveFolderModalComponent } from '../../../../shared/components/move-folder-modal/move-folder-modal.component';
import { assetUrl } from '../../../../../single-spa/asset-url';
import { EmailService } from '@app/services/Email.service';
import { SocketV2Service } from '@app/services/socket-v2.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { OToastV2Service } from 'o-suite-lib';
import { environment } from '@src/environments/environment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email-header',
  standalone: true,
  imports: [
    CommonModule,
    CheckboxModule,
    FormsModule,
    IconsModule,
    PriorityIconComponent,
    TooltipModule,
    MoveFolderModalComponent,
    ProgressSpinnerModule,
  ],
  templateUrl: './email-header.component.html',
  styleUrls: ['./email-header.component.scss'],
})
export class EmailHeaderComponent implements OnChanges {
  @Input() selectedEmails: Email[] = [];
  @Output() selectAll = new EventEmitter<void>();
  isChecked = false;
  isModalVisible: boolean = false;
  socketService = inject(SocketV2Service);
  emailService = inject(EmailService);
  toaster = inject(OToastV2Service);
  router = inject(Router);
  isMoveToFolderOptionVisible = false;

  private destroyRef = inject(DestroyRef);

  loading = signal({
    delete: false,
    important: false,
    read: false,
  });

  ngOnInit(): void {
    const currentUrl = this.router.url;
    if (currentUrl.includes('inbox') || currentUrl.includes('folder')) {
      this.isMoveToFolderOptionVisible = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedEmails']) {
      this.isChecked = this.selectedEmails.length > 0;
    }
  }

  openModal() {
    this.isModalVisible = true;
  }

  toggleSelectAll() {
    this.selectAll.emit();
  }

  getLabelText(): string {
    const selectedCount = this.selectedEmails.length;

    if (selectedCount === 0) {
      return 'Select All';
    } else {
      return `Unselected (${selectedCount})`;
    }
  }

  moveToTrash() {
    const mailIds = this.selectedEmails.map((email) => email.sno);
    const payload: IDeleteEmailPayload = {
      mailIds: mailIds,
      folder: this.selectedEmails[0]?.folderName || '',
    };
    this.loading.set({ ...this.loading(), delete: true });
    this.emailService
      .deleteEmail(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.toggleSelectAll();
          this.socketService.deleteEmails({ mailIds: mailIds, folder: this.selectedEmails[0]?.folderName || '' });
          this.toaster.add({
            severity: 'success',
            summary: res.message || 'Email moved to trash',
            icon: environment.publicPath + '/assets/icons/toast/success.svg',
          });
          this.loading.set({ ...this.loading(), delete: false });
        },
        error: (err) => {
          this.toaster.add({
            severity: 'error',
            summary: err?.error?.message || 'Error moving email to trash',
            icon: environment.publicPath + '/assets/icons/toast/error.svg',
          });
          this.loading.set({ ...this.loading(), delete: false });
        },
      });
  }

  moveToImportant() {
    const mailIds = this.selectedEmails.map((email) => email.sno) || [];
    const payload: IImportantEmailPayload = {
      msgs: mailIds,
      folder: this.selectedEmails[0]?.folderName || '',
      important: true,
    };
    this.loading.set({ ...this.loading(), important: true });
    this.emailService
      .markAsFavouriteOrImportant(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.toggleSelectAll();
          this.socketService.markAsReadOrImportant({
            msgs: mailIds,
            folder: this.selectedEmails[0]?.folderName || '',
            important: true,
          });
          this.toaster.add({
            severity: 'success',
            summary: res.message || 'Email marked as important',
            icon: environment.publicPath + '/assets/icons/toast/success.svg',
          });
          this.loading.set({ ...this.loading(), important: false });
        },
        error: (err) => {
          this.toaster.add({
            severity: 'error',
            summary: err?.error?.message || 'Error marking email as important',
            icon: environment.publicPath + '/assets/icons/toast/error.svg',
          });
          this.loading.set({ ...this.loading(), important: false });
        },
      });
  }

  markAsRead() {
    const mailIds = this.selectedEmails.map((email) => email.sno) || [];
    const payload: IImportantEmailPayload = {
      msgs: mailIds,
      folder: this.selectedEmails[0]?.folderName || '',
      read: true,
    };
    this.loading.set({ ...this.loading(), read: true });
    this.emailService
      .markAsReadEmail(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.toggleSelectAll();
          this.socketService.markAsReadOrImportant({
            msgs: mailIds,
            folder: this.selectedEmails[0]?.folderName || '',
            read: true,
          });
          this.toaster.add({
            severity: 'success',
            summary: res.message || 'Email marked as read',
            icon: environment.publicPath + '/assets/icons/toast/success.svg',
          });
          this.loading.set({ ...this.loading(), read: false });
        },
        error: (err) => {
          this.toaster.add({
            severity: 'error',
            summary: err?.error?.message || 'Error marking email as read',
            icon: environment.publicPath + '/assets/icons/toast/error.svg',
          });
          this.loading.set({ ...this.loading(), read: false });
        },
      });
  }

  protected readonly assetUrl = assetUrl;
}
