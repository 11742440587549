import { Component, DestroyRef, effect, inject, OnInit, signal } from '@angular/core';
import { svgIconModule } from '@app/shared/modules';
import { FilterOptionsComponent } from '../../components/my-contacts/filter-options/filter-options.component';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IApiResponseWithData, PaginatedResponse } from '@app/model/api.model';
import { IContact } from '@app/model/my-contacts.model';
import { MyContactsFilterType } from '@app/shared/enums/ filter.enum';
import { ContactListComponent } from '../../components/my-contacts/contact-list/contact-list.component';
import { ContactCardComponent } from '../../components/my-contacts/contact-card/contact-card.component';
import { ButtonModule } from 'primeng/button';
import { OButtonDirective } from 'o-suite-lib';
import { EditContactModalComponent } from '@app/components/my-contacts/edit-contact-modal/edit-contact-modal.component';
import { OsuiteState } from '@app/services/osuite.state';
import { debounceTime, finalize, Subject } from 'rxjs';

@Component({
  selector: 'app-my-contacts',
  standalone: true,
  imports: [
    svgIconModule,
    FilterOptionsComponent,
    ContactListComponent,
    ContactCardComponent,
    ButtonModule,
    OButtonDirective,
    OButtonDirective,
    EditContactModalComponent
  ],
  templateUrl: './my-contacts.component.html',
  styleUrl: './my-contacts.component.scss'
})
export class MyContactsComponent {
  protected readonly pageSize: number = 20;
  protected readonly FilterType = MyContactsFilterType;
  isEditModalOpen: boolean = false;

  contactsList!: PaginatedResponse<IContact> | null;
  currentPage = 0;
  filter: MyContactsFilterType = MyContactsFilterType.CONTACTS;
  filterFavorite: boolean = false;
  selectedContact: IContact | null = null;
  searchValue = signal<string>('');
  isContactsLoading: boolean = true;

  private searchValue$ = new Subject<string>();
  private osuiteFacade = inject(OsuiteFacade);
  private destroyRef = inject(DestroyRef);
  private osuiteState = inject(OsuiteState);
  isFirstTime = true;

  constructor() {
    this.searchValue$.pipe(debounceTime(1000), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (!this.isFirstTime) {
        this.fetchMyContacts();
      }
      this.isFirstTime = false;
    });

    effect(() => {
      const value = this.searchValue();
      this.handleSearchChangeSignal(value);
    });

    this.osuiteState.setIsEditModalOpen(false);
    this.osuiteState.getIsEditModalOpen().subscribe((isEditModalOpen) => {
      this.isEditModalOpen = isEditModalOpen;
      if (!isEditModalOpen){
        this.fetchMyContacts();
      }
    });

    this.osuiteState.getIsContactFavourite().subscribe((value: boolean) => {
      this.filterFavorite = value;
      this.fetchMyContacts();
    });
  }

  fetchMyContacts() {
    this.isContactsLoading = true;
    this.osuiteFacade
      .fetchContacts({
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
        sortOrder: '',
        badgeId: null,
        searchKey: this.searchValue(),
        favorite: this.filterFavorite ? 1 : null,
        trash: 0
      })
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
          this.isContactsLoading = false;
        })
      )
      .subscribe({
        next: (res: IApiResponseWithData<PaginatedResponse<IContact>>) => {
          this.contactsList = res.data;
          if (res.data.records.length) {
            [(this.selectedContact = res.data.records[0])];
            this.osuiteFacade.getStatesByCountryId(res.data.records[0].countryId).subscribe({
              next: (value) => {
                if (this.selectedContact?.stateId) {
                  this.osuiteState.setStateList(
                    value.body.find((item) => item.id === this.selectedContact?.stateId)?.name
                  );
                } else {
                  this.osuiteState.setStateList(null);
                }
              }
            });
          }
        }
      });
  }

  filterChange(value: MyContactsFilterType) {
    this.filter = value;
  }

  handleSelectContact(value: IContact) {
    this.selectedContact = value;
    this.osuiteFacade.getStatesByCountryId(value.countryId).subscribe({
      next: (value) => {
        if (this.selectedContact?.stateId) {
          this.osuiteState.setStateList(value.body.find((item) => item.id === this.selectedContact?.stateId)?.name);
        } else {
          this.osuiteState.setStateList(null);
        }
      }
    });
  }

  handleFavouriteContact(contactId: number) {
    this.osuiteFacade
      .favoriteContact([contactId])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.fetchMyContacts();
        }
      });
  }

  handleSearchChange(searchValue: string) {
    this.searchValue$.next(searchValue);
    this.searchValue.set(searchValue);
  }

  handleRefetchAfterDelete() {
    this.fetchMyContacts();
  }

  handleCloseEditContact() {
    this.isEditModalOpen = false;
  }

  handleOpenEditContact() {
    this.osuiteState.setEditModalData(null);
    this.osuiteState.setIsEditModalOpen(true);
  }

  handleSearchChangeSignal(value: any) {
    this.isContactsLoading = true;
    this.searchValue$.next(this.searchValue());
  }
}
