import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { BecomeAffiliateComponent } from './become-affiliate/become-affiliate.component';
import { MyAffiliateLinkComponent } from './my-affiliate-link/my-affiliate-link.component';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { IProfileInfo } from '@app/model/myProfile.model';

@Component({
  selector: 'app-affiliate-button',
  standalone: true,
  imports: [BecomeAffiliateComponent, MyAffiliateLinkComponent],
  templateUrl: './affiliate-button.component.html',
  styleUrl: './affiliate-button.component.scss'
})
export class AffiliateButtonComponent implements OnInit {
  isActive: boolean = false;
  private osuiteFacade = inject(OsuiteFacade);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.osuiteFacade
      .getProfileInfo()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (value: IProfileInfo | null) => {
          if (value?.isndasigned === 2) {
            this.isActive = true;
          }
        }
      });
  }
}
