<p-dialog
  [(visible)]="display"
  [modal]="true"
  class="create-folder-dialog"
  [contentStyle]="{ overflow: 'visible' }"
  [draggable]="false"
  [resizable]="false"
  (onHide)="onCancel()"
>
  <!-- Dialog Content -->
  <h2 class="create-folder-dialog__title text-center font-bold text-color-primary-500 text-3xl text-color-tertiary-500">
    Create Folder
  </h2>
  <form [formGroup]="form" class="" (ngSubmit)="onSave()">
    <!-- Folder Name -->
    <div class="create-folder-dialog__content flex flex-column gap-3 m-auto">
      <div class="flex flex-column">
        <label for="folderName" class="mb-2 text-xs text-color-tertiary-400 font-medium">Folder name</label>
        <input
          pInputText
          id="folderName"
          placeholder="Enter folder name"
          formControlName="folderName"
          class="w-full border-round-2xl"
        />
        @if (
          (form.get('folderName')?.invalid && form.get('folderName')?.touched) ||
          (form.get('folderName')?.invalid && isSubmitted)
        ) {
          <div class="text-red-500 text-xs mt-1">Folder name is required.</div>
        }
      </div>

      <!-- Folder Color -->
      <div class="flex flex-column mb-4">
        <label for="folderColor" class="mb-2 text-xs text-color-tertiary-400 font-medium">Folder color</label>
        <p-dropdown
          id="folderColor"
          [options]="colors"
          formControlName="folderColor"
          placeholder="Select color"
          class="w-full"
        >
          <ng-template pTemplate="item" let-colorOption>
            <div
              [class]="'flex align-items-center h-1rem gap-2 p-1 bg-' + colorOption.value + '-500'"
              style="border-radius: 4px"
            ></div>
          </ng-template>

          <ng-template pTemplate="selectedItem" let-selectedOption>
            <div
              [class]="'flex align-items-center h-1rem gap-2 p-1 bg-' + selectedOption.value + '-500'"
              style="border-radius: 4px; padding: 4px"
            ></div>
          </ng-template>
        </p-dropdown>
        @if (
          (form.get('folderColor')?.invalid && form.get('folderColor')?.touched) ||
          (isSubmitted && form.get('folderColor')?.invalid)
        ) {
          <div class="text-red-500 text-xs mt-1">Folder color is required.</div>
        }
      </div>

      <!-- Passcode Toggle and Input -->
      <div class="flex align-items-center flex-1">
        <p-inputSwitch formControlName="isPasscodeEnabled" class="flex align-items-center"></p-inputSwitch>
        <label class="m-0 ml-2 mr-4" [ngClass]="{ 'text-color-tertiary-200': !form.get('isPasscodeEnabled')?.value }">
          Passcode
        </label>

        <p-password
          formControlName="passcode"
          [toggleMask]="true"
          [feedback]="false"
          class="flex-grow-1 border-round-2xl"
          [disabled]="!form.get('isPasscodeEnabled')?.value"
          [ngClass]="{ 'bg-tertiary-300 border-null noEnabled': !form.get('isPasscodeEnabled')?.value }"
        ></p-password>
        @if (
          (form.get('passcode')?.invalid && form.get('isPasscodeEnabled')?.value && form.get('passcode')?.touched) ||
          (isSubmitted && form.get('passcode')?.invalid)
        ) {
          <div class="text-red-500 text-xs mt-1">Passcode is required when enabled.</div>
        }
      </div>
    </div>
    <!-- Footer -->
    <div class="flex justify-content-end gap-3 mt-6">
      <p-button o-button label="Cancel" [outlined]="true" (click)="onCancel()" class="w-8rem cancel"></p-button>

      <p-button
        o-button
        class="action-btn w-8rem"
        [loading]="loading"
        [label]="'Save'"
        [oButtonSeverity]="'success'"
        type="submit"
      ></p-button>
    </div>
  </form>
</p-dialog>
