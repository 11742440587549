import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, input, OnChanges, output, signal, SimpleChanges } from '@angular/core';
import { IconsModule } from '@app/shared/modules/icons.module';
import { PriorityIconComponent } from '@app/shared/svg/priority-icon/priority-icon.component';
import { Email, IImportantEmailPayload } from '@app/shared/models/omail';
import { TooltipModule } from 'primeng/tooltip';
import { MAILBOX_ROUTES } from '@app/constants';
import { formatDate } from '@app/utils/format-date';
import { EmailService } from '@app/services/Email.service';
import { environment } from '@src/environments/environment';
import { assetUrl } from '../../../../../single-spa/asset-url';
import { SocketV2Service } from '@app/services/socket-v2.service';
import { OToastV2Service } from 'o-suite-lib';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-email-message',
  standalone: true,
  imports: [CommonModule, IconsModule, PriorityIconComponent, TooltipModule, ProgressSpinnerModule],
  templateUrl: './email-message.component.html',
  styleUrl: './email-message.component.scss',
})
export class EmailMessageComponent implements OnChanges {
  emailService = inject(EmailService);
  socketService = inject(SocketV2Service);
  toaster = inject(OToastV2Service);

  email = input.required<Email>();
  currentMailbox = input<string>();
  isSelected = input(false);
  select = output();
  favoriteToggle = output();

  loading = signal(false);
  selectedEmail = this.emailService.getSelectedEmail;

  emailTitle = '';
  isEmailRead = false;
  mailsbox = MAILBOX_ROUTES;

  private destroyRef = inject(DestroyRef);
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['email']) {
      this.isEmailRead = this.email().seen;
    }
    if (changes['currentMailbox']) {
      switch (this.currentMailbox()) {
        case MAILBOX_ROUTES.SENT:
          this.emailTitle = `To: ${this.email().to}`;
          break;
        case MAILBOX_ROUTES.DRAFT:
          this.emailTitle = 'Draft';
          break;
        case MAILBOX_ROUTES.OUTBOX:
          this.emailTitle = `To: ${this.email().to}`;
          break;
        case MAILBOX_ROUTES.SCHEDULED:
          this.emailTitle = `To: ${this.email().to}`;
          break;
        default:
          this.emailTitle = this.email().from;
          break;
      }
    }
  }

  // Handle favorite toggle
  toggleFavorite(event: Event) {
    event.stopPropagation();

    const payload: IImportantEmailPayload = {
      msgs: [this.email().sno],
      folder: this.email().folderName || '',
      important: !this.email().important,
    };
    this.loading.set(true);
    this.emailService
      .markAsFavouriteOrImportant(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.socketService.updateEmailDetails({
            ...this.email(),
            important: !this.email().important,
          });
          this.emailService.updatedPriorityEmail(this.email().messageId, !this.email().important);

          this.loading.set(false);
        },
        error: (err) => {
          this.loading.set(false);

          this.toaster.add({
            severity: 'error',
            summary: err?.error.message,
            icon: `${environment.publicPath}/assets/icons/toast/error.svg`,
          });
        },
      });
  }

  // Handle email selection
  selectEmail(event: Event) {
    event.stopPropagation();
    this.select.emit();
  }

  formatDate(date: string | number): string {
    return formatDate(date);
  }

  protected readonly environment = environment;
  protected readonly assetUrl = assetUrl;
}
