import { OButtonDirective } from 'o-suite-lib';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IInviteTemplate } from '@app/model';
import { IApiResponseWithData } from '@app/model/api.model';
import { IProfileInfo } from '@app/model/myProfile.model';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { ToasterService } from '@app/services/toaster.service';
import { svgIconModule } from '@app/shared/modules';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { finalize, take } from 'rxjs';
import { AddSpaceBetweenUnitsPipe } from '@app/shared/pipes/add-space-between-strings';

@Component({
  selector: 'app-my-affiliate-link',
  standalone: true,
  imports: [
    svgIconModule,
    OverlayPanelModule,
    ButtonModule,
    DialogModule,
    OButtonDirective,
    FormsModule,
    DropdownModule,
    DividerModule,
    InputTextModule,
    CheckboxModule,
    TableModule,
    SkeletonModule,
    AddSpaceBetweenUnitsPipe,
    FormsModule,
    ReactiveFormsModule
  ],

  templateUrl: './my-affiliate-link.component.html',
  styleUrl: './my-affiliate-link.component.scss'
})
export class MyAffiliateLinkComponent implements OnInit {
  private osuiteFacade = inject(OsuiteFacade);
  private destroyRef = inject(DestroyRef);
  private toasterService = inject(ToasterService);
  private fb = inject(FormBuilder);

  affiliateLink!: string;
  isDialogVisible: boolean = false;
  isSendSelected: boolean = false;
  templatesList: IInviteTemplate[] = [];
  templatesSlice: IInviteTemplate[] = [];
  selectedTemplateId: number = 0;
  checked: boolean = true;
  invitationTypeList = [{ name: 'Invitation template' }];
  productList = [{ name: 'O-mailo' }];
  currentSlice: number = 0;
  isLoading: boolean = false;
  myInvites: any = [];
  isTemplatesLoading: boolean = true;
  myAffiliateFrom: FormGroup = this.fb.group({
    emailValue: ['', [Validators.required, Validators.email]]
  });
  submitted = false;

  ngOnInit(): void {
    this.osuiteFacade
      .getProfileInfo()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: IProfileInfo | null) => {
        if (res) {
          this.affiliateLink = res.affiliationLink;
        }
      });

    this.fetchInvitationTemplate();
    this.fetchMyInvites();
  }
  copyLink() {
    navigator.clipboard
      .writeText(this.affiliateLink)
      .then(() => {
        this.toasterService.setToasterState(true, 'success', 'Link copied');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  }
  closeDialog() {
    this.myAffiliateFrom.get('emailValue')?.setValue('');
    this.myAffiliateFrom.get('emailValue')?.markAsPristine();

    this.isDialogVisible = false;
  }
  openDialog() {
    this.isDialogVisible = true;
    this.fetchMyInvites();
  }
  fetchInvitationTemplate() {
    this.osuiteFacade
      .fetchInvitationTemplates('O-Mailo')
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
          this.isTemplatesLoading = false;
        })
      )
      .subscribe({
        next: (res: IApiResponseWithData<IInviteTemplate[]>) => {
          this.templatesList = res.data;
          this.templatesSlice = res.data.slice(0, 2);
          if (res.data.length) {
            this.selectedTemplateId = res.data[0].id;
          }
        }
      });
  }
  fetchMyInvites() {
    this.osuiteFacade
      .fetchInvites({ pageNumber: 0, pageSize: 5, emailId: '', sortOrder: '' })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.myInvites = res.data;
        }
      });
  }
  setSelectedTemplate(id: number) {
    this.selectedTemplateId = id;
  }
  handlePaginateBack() {
    if (this.currentSlice - 2 >= 0) {
      this.currentSlice -= 2;
      this.templatesSlice = this.templatesList?.slice(this.currentSlice, this.currentSlice + 2);
    }
  }
  handlePaginateNext() {
    if (this.currentSlice + 2 < this.templatesList.length) {
      this.currentSlice += 2;
      this.templatesSlice = this.templatesList?.slice(this.currentSlice, this.currentSlice + 2);
    }
  }
  handleSendInvite() {
    this.submitted = true;
    if (this.myAffiliateFrom.get('emailValue')?.invalid) {
      this.myAffiliateFrom.get('emailValue')?.markAsDirty();
      return;
    }
    this.isLoading = true;
    this.osuiteFacade
      .sendInvites({ promotionId: this.selectedTemplateId, emailId: this.myAffiliateFrom.get('emailValue')?.value })
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
          this.submitted = false;
          this.isLoading = false;
        })
      )
      .subscribe((res) => {
        this.submitted = false;
        if (res.status === 'Success') {
          this.isDialogVisible = false;
          this.toasterService.setToasterState(true, 'success', 'Invitation sent successfully');
          this.fetchMyInvites();
        }
      });
  }
}
