<div
  id="suite_dashboard_upcomingProducts_container"
  class="upcoming-products overflow-hidden h-full w-full border-1 overflow-hidden border-round-lg shadow-1 p-3 flex flex-column justify-content-between"
>
  <h3 class="header-title flex font-bold align-items-center flex m-0 mb-2 gap-2">
    <div class="icon border-circle flex align-items-center justify-content-center">
      <svg-icon name="box" />
    </div>
    <span>Upcoming Products</span>
  </h3>
  @if(!isLoading){ @if(products){
  <p-carousel
    [value]="products"
    [numVisible]="1"
    [numScroll]="1"
    [circular]="true"
    [autoplayInterval]="3000"
    [showNavigators]="false"
    class="custom-carousel flex-1 mt-2"
    id="suite_dashboard_upcomingProducts_carousel"
  >
    <ng-template pTemplate="item" let-product>
      <div
        class="flex flex-md-row product-card gap-4 w-full pl-5 mt-2"
        id="suite_dashboard_upcomingProducts_carouselItem"
      >
        <!-- Product Information -->
        <div class="mb-md-0 pr-md-4 product-info">
          <h4 class="product-title font-bold m-0 mb-2">{{ product?.product_name }}</h4>
          <p class="product-description text-xs mb-3">
            {{ product?.product_tag_line }}
          </p>
        </div>

        <!-- Product Image -->
        <div class="product-image text-center">
          <img
            [src]="'https://d2bsezkvf0wpv4.cloudfront.net/ECOSYSTEM/QA/OES/CUSTOMER/OnLogo.png'"
            height="50"
            width="100"
            alt="upcoming_product_logo"
          />
        </div>
      </div>
    </ng-template>
  </p-carousel>
  } @else {
  <div class="w-full h-full flex-column flex justify-content-center align-items-center px-4">
    <div class="w-4 flex justify-content-center align-items-center">
      <img
        [src]="'https://d2bsezkvf0wpv4.cloudfront.net/ECOSYSTEM/QA/OES/CUSTOMER/OnLogo.png'"
        height="50"
        width="100"
        alt="upcoming_product_logo"
      />
    </div>
    <div class="flex justify-content-center align-items-center">
      <p class="text-color-tertiary-500 font-semibold text-sm">Stay tuned for our upcoming products</p>
    </div>
  </div>
  }}@else{
  <div class="h-full flex justify-content-center align-items-center gap-4">
    <p-skeleton width="25rem" height="8rem" />
  </div>
  }
</div>
