<div class="flex align-items-center gap-2 cursor-pointer" (click)="myAffiliateDropdown.toggle($event)">
  <svg-icon name="shareAffiliate" />
  <span class="text-color-primary-500 font-semibold mb-1">My affiliate link</span>
</div>
<p-overlayPanel #myAffiliateDropdown>
  <div class="w-19rem">
    <p class="text-color-tertiary-500 my-0">Share your link affilliate and earn rewards!</p>
    <div
      class="flex align-items-center gap-2 w-full copy-button_container px-2 py-1 my-2 border-round cursor-pointer"
      (click)="copyLink()"
    >
      <p class="white-space-nowrap overflow-hidden text-overflow-ellipsis w-full my-1">{{ affiliateLink }}</p>
      <svg-icon name="copy" />
    </div>
    <p-button styleClass="py-1" (click)="openDialog()">
      <div class="w-full flex gap-1 justify-content-center">
        <p class="my-0">Send invitation</p>
        <div class="pt-1">
          <svg-icon name="arrow-right" />
        </div>
      </div>
    </p-button>
  </div>
</p-overlayPanel>
<p-dialog
  [modal]="true"
  (onHide)="closeDialog()"
  [(visible)]="isDialogVisible"
  [draggable]="false"
  [dismissableMask]="true"
>
  <div class="px-4 pb-3 h-full flex flex-column">
    <h2 class="text-center px-4 font-bold text-3xl mt-2 mb-3">My affiliate link</h2>

    <div class="flex justify-content-center gap-3 mt-2">
      <p-button
        label="Send invite"
        [outlined]="isSendSelected"
        styleClass="px-3 py-2 border-round-2xl"
        (click)="isSendSelected = false"
      />
      <p-button
        label="View invite"
        [outlined]="!isSendSelected"
        styleClass="px-3 py-2 border-round-2xl"
        (click)="isSendSelected = true"
      />
    </div>

    @if(!isSendSelected){
    <div class="flex gap-4 w-full mt-4 flex-1">
      <div class="w-6">
        <label for="countryDetails" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">
          Invitation type
        </label>
        <p-dropdown [options]="invitationTypeList" optionLabel="name" />
      </div>
      <div class="w-6">
        <label for="countryDetails" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">Select product</label>
        <p-dropdown [options]="productList" optionLabel="name" />
      </div>
    </div>

    <div class="mt-4">
      <div class="flex justify-content-between align-items-center">
        <h3>Select layout</h3>
        <div class="flex gap-5 align-items-center">
          <div class="cursor-pointer" (click)="handlePaginateBack()"><svg-icon name="left-arrow" /></div>
          <div class="cursor-pointer" (click)="handlePaginateNext()">
            <svg-icon name="right-arrow" />
          </div>
        </div>
      </div>
      <div class="flex gap-3">
        @if(isTemplatesLoading){
        <p-skeleton class="w-6" height="30rem" />
        <p-skeleton class="w-6" height="30rem" />
        }@else { @for(template of templatesSlice; track template.id){
        <div
          class="template-container flex flex-column border-2 border-round-lg p-3 cursor-pointer w-6"
          [ngClass]="{ 'template-container--active': template.id === selectedTemplateId }"
          (click)="setSelectedTemplate(template.id)"
        >
          <div class="w-full flex justify-content-end">
            <p-checkbox [ngModel]="template.id === selectedTemplateId" binary="true"></p-checkbox>
          </div>
          <div class="flex gap-3 align-items-center">
            <svg-icon name="invitation-template" />
            <h3>
              {{ template.productName }}
            </h3>
          </div>
          <div [innerHTML]="template.templateContent"></div>

          <div class="flex gap-3 w-full mt-3">
            <p-button
              label="Reject"
              [outlined]="true"
              styleClass="px-4 py-2 border-1 border-tertiary-300 text-color-tertiary-300  border-round-2xl font-semibold"
              class="w-full"
              [disabled]="true"
            />
            <p-button
              label="Accept"
              styleClass="px-4 py-2 border-round-2xl font-semibold"
              class="w-full"
              [disabled]="true"
            />
          </div>
        </div>
        } }
      </div>
    </div>

    <p-divider />
    <form [formGroup]="myAffiliateFrom" class="pb-4">
      <label for="to" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">To</label>
      <input
        type="text"
        pInputText
        class="w-full px-2 py-2 custom-border-radius text-sm text-color-tertiary-500"
        placeholder="Type email"
        formControlName="emailValue"
      />
      @if(submitted && myAffiliateFrom.get('emailValue')?.errors?.['required']){
        <small class="ml-2 text-red-500">Alternate email is required.</small>
      } @else if (submitted && myAffiliateFrom.get('emailValue')?.errors?.['email']){
        <small class="ml-2 text-red-500">Please enter a valid email address.</small>
      }
    </form>

    <div class="flex justify-content-end gap-3 mt-4 pb-4">
      <p-button
        label="Cancel"
        [outlined]="true"
        styleClass="px-4 py-2 custom-border-radius font-semibold"
        (click)="closeDialog()"
      />
      <p-button
        o-button
        [oButtonSeverity]="'success'"
        label="Send invite"
        styleClass="px-4 py-2 custom-border-radius font-semibold"
        type="submit"
        (click)="handleSendInvite()"
        [loading]="isLoading"
      />
    </div>
    } @else {

    <div class="flex-1 flex flex-column">
      <h3 class="text-color-primary-500">Invitations</h3>
      <div class="flex-1">
        <p-table
          [value]="myInvites.records"
          [scrollable]="true"
          scrollHeight="flex"
          styleClass="border-round-lg invite-table_conatiner"
        >
          <ng-template pTemplate="header">
            <tr>
              <th class="text-xs font-bold">Email ID</th>
              <th class="text-xs font-bold">Time left</th>
              <th class="text-xs font-bold">Status</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-invite>
            <tr>
              <td class="text-xs font-semibold">
                {{ invite.emailId }}
              </td>
              <td class="text-xs font-semibold">
                {{ invite.timeLeft | addSpaceBetweenUnits }}
              </td>
              <td class="text-xs font-semibold">
                @if(invite.status===0){
                <div class="flex gap-2 align-items-center">
                  <svg-icon name="pending" />
                  <span class="pending text-color-warning-600">Pending</span>
                </div>
                } @else if(invite.status===4){
                <div class="flex gap-2 align-items-center">
                  <svg-icon name="expired" />
                  <span class="expired">Expired</span>
                </div>
                }
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div class="flex justify-content-end gap-3 mt-4">
      <p-button label="Cancel" [outlined]="true" styleClass="px-4 py-2 custom-border-radius" (click)="closeDialog()" />
    </div>
    }
  </div>
</p-dialog>
