import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UserCardComponent } from '../../components/my-profile/user-card/user-card.component';
import { EditProfileModal } from '../../components/my-profile/edit-profile-modal/edit-profile-modal.component';
import { ButtonModule } from 'primeng/button';
import { ResetPasswordModal } from '@app/components/my-profile/reset-password-modal/reset-password-modalcomponent';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { forkJoin } from 'rxjs';
import { svgIconModule } from '@app/shared/modules';

@Component({
  selector: 'app-my-profile',
  standalone: true,
  imports: [UserCardComponent, EditProfileModal, ResetPasswordModal, ButtonModule, svgIconModule],
  templateUrl: './my-profile.component.html',
  styleUrl: './my-profile.component.scss'
})
export class MyProfileComponent implements OnInit {
  isEditModalOpen: boolean = false;
  isChangePasswordModalOpen: boolean = false;
  isEditProfileLDisabled: boolean = true;
  private osuiteFacade = inject(OsuiteFacade);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    forkJoin([
      this.osuiteFacade.fetchProfileInfo(),
      this.osuiteFacade.fetchBillingAddress(),
      this.osuiteFacade.fetchCountries()
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([profileInfo, billingInfo, countries]) => {
        this.osuiteFacade.setProfileInfo({
          ...profileInfo.data,
          joinedDate: profileInfo.data.joinedDate.split('T')[0]
        });
        this.osuiteFacade.setBillingAddress(billingInfo.data);
        this.isEditProfileLDisabled = false;
        this.osuiteFacade.setCountries(countries.body);
      });
  }

  handleOpenEditProfile() {
    if (this.isEditProfileLDisabled) {
      return;
    }
    this.isEditModalOpen = true;
  }

  handleOpenChangePassword() {
    if (this.isEditProfileLDisabled) {
      return;
    }
    this.isChangePasswordModalOpen = true;
  }
}
