import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function emailArrayValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const emails = control.value;

    if (!Array.isArray(emails)) {
      return { notArray: true };
    }

    const invalidEmails = emails.filter((email: string) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return !emailRegex.test(email);
    });

    return invalidEmails.length > 0 ? { invalidEmails: invalidEmails } : null;
  };
}

export function timeRangeValidator(fromTimeKey: string, toTimeKey: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const fromTime = formGroup.get(fromTimeKey)?.value;
    const toTime = formGroup.get(toTimeKey)?.value;

    if (fromTime && toTime) {
      const fromDate = new Date(fromTime);
      const toDate = new Date(toTime);

      if (fromDate.getTime() === toTime.getTime()) {
        return { sameTimeRange: true };
      } else if (fromDate > toDate) {
        return { invalidTimeRange: true };
      }
    }

    return null;
  };
}
