import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { RecordRTCService } from '@app/services/record-RTC.service';
import { OButtonDirective } from 'o-suite-lib';
import { IconsModule } from '@app/shared/modules/icons.module';

@Component({
  selector: 'app-record-video',
  standalone: true,
  imports: [ButtonModule, CommonModule, TooltipModule, OButtonDirective, IconsModule],
  templateUrl: './record-video.component.html',
  styleUrls: ['./record-video.component.scss'],
})
export class RecordVideoComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('cameraPreview') cameraPreview!: ElementRef<HTMLVideoElement>;
  @Output() recordingComplete = new EventEmitter<Blob>();
  @Output() close = new EventEmitter<void>();

  isRecording = false;
  isPaused = false;
  videoUrl: string | null = null;

  // Timer-related
  currentTime = 0; // in seconds
  maxTime = 180; // 3 minutes
  private intervalId: any;

  recordRTCService = inject(RecordRTCService);

  ngOnInit(): void {}

  async ngAfterViewInit(): Promise<void> {
    if (this.cameraPreview) {
      try {
        await this.recordRTCService.openCamera(this.cameraPreview.nativeElement);
      } catch (error) {
        alert('Error accessing camera: ' + error);
      }
    }
  }

  async startRecording(): Promise<void> {
    try {
      await this.recordRTCService.startRecording();
      this.isRecording = true;
      this.isPaused = false;
      this.videoUrl = null;

      // Start or reset the timer
      this.currentTime = 0;
      this.startTimer();
    } catch (error) {
      alert('Error starting recording: ' + error);
    }
  }

  pauseRecording(): void {
    this.recordRTCService.pauseRecording();
    this.isPaused = true;

    // Stop counting time, but don't reset
    this.stopTimer();
  }

  resumeRecording(): void {
    this.recordRTCService.resumeRecording();
    this.isPaused = false;

    // Resume the timer
    this.startTimer();
  }

  async stopRecording(): Promise<void> {
    if (this.isRecording) {
      // Stop the timer
      this.stopTimer();

      const blob = await this.recordRTCService.stopRecording();
      this.videoUrl = URL.createObjectURL(blob);
      this.isRecording = false;
      this.isPaused = false;
      this.currentTime = 0;
      this.recordingComplete.emit(blob);
      this.closeDialog();
    }
  }

  closeDialog(): void {
    this.cleanup();
    this.close.emit();
  }

  ngOnDestroy(): void {
    this.cleanup();
  }

  cleanup(): void {
    this.recordRTCService.cleanup();
    this.isRecording = false;
    this.isPaused = false;
    this.videoUrl = null;
    this.stopTimer();
  }

  private startTimer(): void {
    // If already running, do nothing
    if (this.intervalId) return;

    this.intervalId = setInterval(() => {
      // Only increment if not paused and still recording
      if (this.isRecording && !this.isPaused) {
        this.currentTime++;
        // Auto-stop if 3 minutes are reached
        if (this.currentTime >= this.maxTime) {
          this.stopRecording();
        }
      }
    }, 1000);
  }

  private stopTimer(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  // Helper method to format seconds into mm:ss
  formatTime(seconds: number): string {
    const mm = String(Math.floor(seconds / 60)).padStart(2, '0');
    const ss = String(seconds % 60).padStart(2, '0');
    return `${mm}:${ss}`;
  }
}
