<div id="suite_dashboard_timeline_container" class="timeline flex flex-column w-full h-full overflow-hidden">
  <div id="suite_dashboard_timeline_title" class="title flex align-items-center p-3 pb-0">
    <div
      id="suite_dashboard_timeline_icon-container"
      class="icon border-circle mr-2 flex justify-content-center align-items-center"
    >
      <fa-icon icon="clock" class="avatar text-xs"></fa-icon>
    </div>
    <h3 id="suite_dashboard_timeline_heading" class="m-0">Timeline</h3>
  </div>
  <div id="suite_dashboard_timeline_content" class="overflow-auto pb-2 ml-2 mt-3">
    @if(!isLoading){ @for (event of groupedTransactions; let idx = $index; track idx) {
    <div id="suite_dashboard_timeline_date_{{ idx }}" class="timeline-date pl-2 flex flex-column py-0 px-0 relative">
      <ul
        id="suite_dashboard_timeline_items_{{ idx }}"
        class="timeline-items flex flex-column gap-3 border-left-2 mx-3 m-0 p-0"
      >
        <span
          id="suite_dashboard_timeline_date-label_{{ idx }}"
          class="date-label px-2 py-1 font-bold text-xs relative"
          [ngClass]="{ 'mt-3': idx !== 0 }"
        >
          {{ event.date | date : 'MMMM d, y' }}
        </span>
        @for (item of event.transactions; track item.time) {
        <li
          id="suite_dashboard_timeline_item_{{ item.time }}"
          class="timeline-item flex justify-content-between relative align-items-center h-full"
        >
          <div
            id="suite_dashboard_timeline_item-content_{{ item.time }}"
            class="content flex-grow-1 my-0 mx-3 p-2 border-round-2xl flex align-items-center"
          >
            <span class="amount font-bold text-xs">${{ item.bonusEarned }} Commission</span>
            <span class="granted-by text-xs font-semibold inline-block px-1">Granted by</span>
            <span class="amount font-bold text-xs">
              {{ item.customerName }}
            </span>
          </div>

          <div id="suite_dashboard_timeline_item-time_{{ item.time }}" class="time">
            <div
              id="suite_dashboard_timeline_item-time-box_{{ item.time }}"
              class="time-box flex align-items-center justify-content-center font-bold text-sm p-2 border-round-xl"
            >
              <i class="clock-icon w-1rem h-1rem inline-block"></i>
              <span class="text-xs">{{ item.time | date : 'HH:mm' }}</span>
            </div>
          </div>
        </li>
        }
      </ul>
    </div>
    } } @else{
    <div class="flex flex-column gap-4 mt-3">
      <div class="flex justify-content-between px-3">
        <p-skeleton width="20rem" height="2rem" />
        <p-skeleton width="5rem" height="2rem" />
      </div>
      <div class="flex justify-content-between px-3">
        <p-skeleton width="20rem" height="2rem" />
        <p-skeleton width="5rem" height="2rem" />
      </div>
      <div class="flex justify-content-between px-3">
        <p-skeleton width="20rem" height="2rem" />
        <p-skeleton width="5rem" height="2rem" />
      </div>
    </div>

    }
  </div>
</div>
