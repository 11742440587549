@if(appInitializationService.isLoggedIn()) {
<o-toast
  [visible]="toasterService.toasterVisibility()"
  [key]="'register-success'"
  [severity]="toasterService.toasterSeverity()"
  [summary]="toasterService.toasterSummary()"
  (visibleChange)="closeToast()"
></o-toast>
@if( this.showUpdatesBanner && this.contentLength!==0 ){
<app-updates-banner />
}
<router-outlet></router-outlet>
}
