import { Component, inject, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { OnfusionsAppsComponent } from '../onfusions-apps/onfusions-apps.component';
import { ActivatedRoute } from '@angular/router';
import { svgIconModule } from '../../shared/modules';
import { assetUrl } from '../../../single-spa/asset-url';

@Component({
  selector: 'app-logo',
  standalone: true,
  imports: [FontAwesomeModule, OnfusionsAppsComponent, svgIconModule],
  templateUrl: './logo.component.html'
})
export class LogoComponent implements OnInit {
  private route = inject(ActivatedRoute);
  faBars = faBars;
  title = 'OnFusion';
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['app']) this.title = params['app'];
    });
  }
  onMenuClick() {}
  protected readonly assetUrl = assetUrl;
}
