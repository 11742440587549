import { Component, DestroyRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { CountrySelectComponent, OButtonDirective, OToastV2Service } from 'o-suite-lib';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputGroupModule } from 'primeng/inputgroup';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, distinctUntilChanged, mergeMap, of, tap } from 'rxjs';
import { Button } from 'primeng/button';
import { CommonModule, NgClass } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { UploadProfilePicComponent } from '@app/components/my-contacts/upload-profile-pic/upload-profile-pic.component';
import { OsuiteFacade } from '@app/services/osuite.facade';
import {
  IContactsData,
  ICountryData,
  ICountryDataWithPhoneNumber,
  IStatesData
} from '@app/shared/modules/contacts.model';
import { IApiResponseWithData } from '@app/model/api.model';
import { catchError } from 'rxjs/operators';
import { OsuiteState } from '@app/services/osuite.state';
import { IContact } from '@app/model/my-contacts.model';
import { ToasterService } from '@app/services/toaster.service';

@Component({
  selector: 'app-edit-contact-modal',
  standalone: true,
  imports: [
    DialogModule,
    Button,
    OButtonDirective,
    ReactiveFormsModule,
    InputTextModule,
    NgClass,
    InputGroupModule,
    InputGroupAddonModule,
    CountrySelectComponent,
    DropdownModule,
    CalendarModule,
    InputNumberModule,
    UploadProfilePicComponent,
    CommonModule
  ],
  templateUrl: './edit-contact-modal.component.html',
  styleUrl: './edit-contact-modal.component.scss'
})
export class EditContactModalComponent implements OnInit {
  private fb = inject(FormBuilder);
  private destroyRef = inject(DestroyRef);
  private contactsFacade = inject(OsuiteFacade);
  private contactsState = inject(OsuiteState);
  private toasterService = inject(ToasterService);

  @Input('visible') visible: boolean = false;
  selectedContact!: IContact | null;
  @Output() visibleChange = new EventEmitter<boolean>();
  isAddLoading = false;
  submitted: boolean = false;
  contactImageFile: File | null = null;
  addContactForm: FormGroup = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    jobTitle: [''],
    companyName: [''],
    alternateEmailId: ['', [Validators.required, Validators.email]],
    omailEmailId: [''],
    countryId: ['', Validators.required],
    countryCode: [''],
    contactNumber: [null],
    dateOfBirth: [''],
    stateId: [''],
    zipcode: [''],
    address: [''],
    contactPic: [''],
    favStatus: [''],
    badgeId: [0],
    countryCallCode: ['']
  });
  countryList: ICountryDataWithPhoneNumber[] = [];
  states: IStatesData[] = [];
  contactPic: string = '';
  userImageUrl: string = '';
  country: FormControl<any> = new FormControl([]);
  isSelectedStateLoading: boolean = false;
  isEditModal = false;
  contactId!: number;

  ngOnInit(): void {
    this.contactsState.getEditModalData().subscribe({
      next: (value: any) => {
        if (value) {
          const pic = value.contactPic ? value.contactPic : '';
          this.contactId = value.contactId;
          this.isEditModal = true;
          this.selectedContact = value;
          this.userImageUrl = pic;
          this.contactPic = pic;
          this.contactImageFile = pic;
          this.addContactForm.patchValue({
            firstName: value.firstName,
            lastName: value.lastName,
            jobTitle: value.jobTitle,
            companyName: value.companyName,
            alternateEmailId: value.alternateEmailId,
            omailEmailId: value.omailEmailId,
            dateOfBirth: new Date(value.dateOfBirth),
            zipcode: value.zipcode,
            address: value.address,
            contactPic: pic,
            favStatus: value.favStatus,
            badgeId: value.badgeId,
            contactNumber: value.contactNumber
          });
        }
      }
    });

    this.contactsFacade.getCountriesList().subscribe((countries: any | null) => {
      if (countries) {
        this.countryList = countries.body.map((country: ICountryData) => ({
          flag_32: country.flag_32,
          id: country.id,
          round_flag: country.round_flag,
          name: country.name,
          code: country.iso2,
          number: `+${country.phoneCode}`
        }));
        const def = this.countryList[0];
        if (this.selectedContact) {
          const saveCountry = this.countryList.find((item) => item.id === this.selectedContact?.countryId);
          const saveCountryMobile = this.countryList.find(
            (item) => item.number === this.selectedContact?.countryCallCode
          );

          this.country?.setValue(saveCountryMobile);
          this.addContactForm.get('countryId')?.setValue(saveCountry);
        } else {
          this.country?.setValue(def);
          this.addContactForm.get('countryCallCode')?.setValue(def.number);
        }
      }
    });

    this.addContactForm
      .get('countryId')
      ?.valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((value) => {
          this.isSelectedStateLoading = true;

          if (value) {
            return value.id;
          }
          return of(false);
        }),
        mergeMap((value) => {
          if (value) {
            return this.contactsFacade.getStatesByCountryId(value.id);
          }
          return of(null);
        })
      )
      .subscribe(
        (res: IApiResponseWithData<IStatesData[]> | null) => {
          if (res && res.body.length > 0) {
            this.states = res.body;
            this.addContactForm
              .get('stateId')
              ?.setValue(res.body.find((item) => item.id === this.selectedContact?.stateId));
          }
          this.isSelectedStateLoading = false;
        },
        (error) => {
          this.isSelectedStateLoading = false;
        }
      );

    this.country.valueChanges.subscribe((value: any) => {
      if (value) {
        this.addContactForm.get('countryCallCode')?.setValue(value.number);
      }
    });

    this.addContactForm
      .get('alternateEmailId')
      ?.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
        mergeMap((email) => {
          if (email) {
            return this.contactsFacade.getContactInfoByAlternativeEmail(email).pipe(
              catchError((error) => {
                this.addContactForm.get('omailEmailId')?.setValue('');
                return of(null);
              })
            );
          }
          return of(null);
        })
      )
      .subscribe((res: null | IApiResponseWithData<IContactsData>) => {
        if (res) {
          this.addContactForm.get('omailEmailId')?.setValue(res?.data?.omailEmailId);
        }
      });
  }

  onHide() {
    this.contactsState.setIsEditModalOpen(false);
    this.visible = false;
    this.visibleChange.emit(false);
  }

  onSubmitAdd() {
    this.submitted = true;
    if (this.addContactForm.valid && !this.isAddLoading) {
      let fd: FormData = new FormData();
      if (this.contactImageFile) {
        fd.append('file', this.contactImageFile);
      } else {
        this.addContactForm.get('contactPic')?.setValue('');
      }
      fd.append(
        'contact',
        JSON.stringify({
          ...this.addContactForm.value,
          countryId: this.addContactForm.get('countryId')?.value?.id,
          stateId: this.addContactForm.get('stateId')?.value?.id ? this.addContactForm.get('stateId')?.value?.id : '',
          contactNumber: this.addContactForm.get('contactNumber')?.value
            ? this.addContactForm.get('contactNumber')?.value?.toString()
            : '',

          dateOfBirth: this.addContactForm.get('dateOfBirth')?.value
            ? this.contactsFacade.formatDate(this.addContactForm.get('dateOfBirth')?.value)
            : '',

          contactId: this.contactId
        })
      );
      fd.append('alternateEmailId', this.addContactForm.get('alternateEmailId')?.value);
      this.addContact(fd, this.userImageUrl);
    }
  }

  userImageChange(file: File) {
    this.contactImageFile = file;
  }

  userImageUrlChange(userImageUrl: string) {
    this.userImageUrl = userImageUrl;
  }

  addContact(formData: FormData, userImageUrl: string) {
    this.isAddLoading = true;
    this.contactsFacade.editContact(formData).subscribe(
      (res) => {
        this.toasterService.setToasterState(true, 'success', res?.message);
        this.onHide();
        let contact = formData.get('contact');
        if (contact && typeof contact === 'string') {
          this.addContactsData(JSON.parse(contact), userImageUrl);
        }
        this.isAddLoading = false;
      },
      (error) => {
        this.isAddLoading = false;
      }
    );
  }

  addContactsData(contact: IContactsData, userImageUrl: string | undefined) {
    if (userImageUrl) contact.contactPic = userImageUrl;
  }
}
