import { Component, Input, Output, EventEmitter, input, output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import type { MenuItem } from '@app/shared/models/menu-items-sidebar';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-sidebar-menu-item',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, RouterModule],
  templateUrl: './sidebar-menu-item.component.html',
  styleUrls: ['./sidebar-menu-item.component.scss'],
})
export class SidebarMenuItemComponent {
  activeItem = input<string>();
  menuItems = input<MenuItem[]>();
  router = inject(Router);
  route = inject(ActivatedRoute);

  onClick(event: Event, id: string): void {
    event.stopPropagation();
    this.router.navigate([id], { relativeTo: this.route });
  }
}
