<p-dialog [modal]="true" (onHide)="closeDialog()" [(visible)]="visible" [draggable]="false" [dismissableMask]="true">
  <div class="flex flex-column align-items-center justify-content-center bulk-dialog">
    <p class="mb-2 mt-0 text-center text-3xl font-bold w-full bulk-dialog__title">Bulk trim</p>
    <p class="text-xl line-height-2">
      O-Shortify Bulk shortener helps you create ,control, and track shortened URLs of multiple links in seconds.
    </p>

    <div class="bulk-dialog__step flex flex-row align-items-center mt-3 w-full">
      <span
        class="border-circle bulk-dialog__step-circle font-bold w-2rem mr-3 h-2rem flex align-items-center justify-content-center"
      >
        1
      </span>
      <span class="text-xl">
        <a (click)="downloadTemplateFile()" class="no-underline cursor-pointer">Download O-Shortify CSV template</a>
        to fill it up with URLs.
      </span>
    </div>

    <div class="bulk-dialog__step flex flex-row align-items-center mt-3 w-full">
      <span
        class="border-circle bulk-dialog__step-circle font-bold w-2rem mr-3 h-2rem flex align-items-center justify-content-center"
      >
        2
      </span>
      <span class="text-xl">Upload O-Shortify CSV file to be shortened.</span>
    </div>

    <div class="bulk-dialog__file-upload mt-4">
      <div
        class="bulk-dialog__drop-zone border-round-xl p-4 text-center cursor-pointer transition-colors"
        [class.bulk-dialog__drag-over]="isDragOver"
        (drop)="onDrop($event)"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave()"
      >
        <ng-container>
          <img [src]="assetUrl('icons/upload.svg')" width="68" height="49" alt="" />
          <p class="text-xl font-semibold bulk-dialog__main-text">Choose file or drag drop it here</p>
          <p class="bulk-dialog__sub-text mb-4">
            Note: Only CSV file is supported and the duplicate custom name is not allowed in the custom name column
          </p>
          <p-button
            [loading]="file"
            [disabled]="file"
            class="upload-btn"
            label="Browse File"
            [outlined]="true"
            (onClick)="fileInput.click()"
          ></p-button>
          <input type="file" accept=".csv" #fileInput (change)="onFileSelected($event)" hidden />
        </ng-container>
      </div>
    </div>
  </div>
</p-dialog>
