<div class="dashboard flex flex-column justify-content-between p-3 gap-3">
  <div class="dashboard-header flex justify-content-between align-items-center">
    <div class="flex gap-2 align-items-center">
      <svg-icon name="dashboard" />
      <span class="text-lg font-bold">Dashboard</span>
    </div>

    <div class="affiliate_container flex align-items-center">
      @if(!isUserInfoLoading && !isCountriesLoading){
      <app-affiliate-button />
      }
    </div>
  </div>
  <div class="dashboard-grid flex-1">
    <div class="total-revenue">
      <app-total-revenue />
    </div>
    <div class="top-customers">
      <app-top-customers />
    </div>
    <div class="customer-transaction">
      <app-customer-transaction-view />
    </div>

    <div class="revenue-overview">
      <app-revenue-overview />
    </div>
    <div class="total-customers">
      <app-total-customers />
    </div>
    <div class="timeline">
      <app-timeline />
    </div>
    <div class="upcoming-products">
      <app-upcoming-products />
    </div>
  </div>
</div>
