import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { DividerModule } from 'primeng/divider';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { OheaderFacade } from '../../services/oheader.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize } from 'rxjs';
import { IDialogStep, IProfileInfo, IUpdate } from '../../model/header.model';
import { IApiResponseWithData } from '../../model/api.model';
import { IconsModule, svgIconModule } from '../../shared/modules';
import { UpdatesPopupComponent } from '../updates-popup/updates-popup.component';
import { Router } from '@angular/router';
import { BASE_ROUTE_URL_DASHBOARD } from '../../shared/constants/global.constant';
import { SkeletonModule } from 'primeng/skeleton';
import { environment } from '../../../environments/environment';
import { assetUrl } from '../../../single-spa/asset-url';
import { UpdateDialogComponent } from '../update-dialog/update-dialog.component';

@Component({
  selector: 'app-personal-info',
  standalone: true,
  imports: [
    ButtonModule,
    AvatarModule,
    BadgeModule,
    FontAwesomeModule,
    DividerModule,
    OverlayPanelModule,
    svgIconModule,
    UpdatesPopupComponent,
    IconsModule,
    SkeletonModule,
    UpdateDialogComponent
  ],
  templateUrl: './personal-info.component.html',
  styleUrl: './personal-info.component.scss'
})
export class PersonalInfoComponent implements OnInit {
  faChevronDown = faChevronDown;
  updatesCount: number = 0;
  userInfo!: IProfileInfo | null;
  isPopupOpen: boolean = false;
  imgHost = environment.imgHostUrl;
  steps: IDialogStep[] = [
    {
      id: 0,
      imgPath: '',
      headerText: '',
      textAlign: '',
      content: '',
      date: ''
    }
  ];
  visible: boolean = false;
  currentStep: number = 0;

  protected readonly assetUrl = assetUrl;
  private oheaderFacade = inject(OheaderFacade);
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);

  protected readonly environment = environment;

  ngOnInit(): void {
    this.getBannerUpdates();
    this.fetchProfileInfo();
  }

  showBanner() {
    let result = null;
    this.oheaderFacade
      .getShowUpdatesBanner()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {})
      )
      .subscribe((res: boolean) => {
        result = res;
      });
    this.oheaderFacade.setShowUpdatesBanner(!result);
  }

  getBannerUpdates() {
    this.oheaderFacade
      .getBannerUpdates()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {})
      )
      .subscribe((res: IUpdate[] | null) => {
        if (res) {
          this.updatesCount = res.length;

          this.steps = res.map((update: IUpdate) => ({
            id: update.id,
            imgPath: update.newsImage || assetUrl('default-update.png'),
            headerText: update.headLine,
            textAlign: update.newsPosition === 1 ? 'LEFT' : 'RIGHT',
            content: update.newsDescription,
            date: this.formatReadableDate(update.createdDate)
          }));
        }
      });
  }

  fetchProfileInfo() {
    this.oheaderFacade
      .fetchProfileInfo()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: IApiResponseWithData<IProfileInfo>) => {
        if (res) {
          this.userInfo = res.data;
        }
      });
  }

  handleRouteProfile() {
    this.router.navigate([BASE_ROUTE_URL_DASHBOARD + 'myProfile']);
  }

  handleLogout() {
    this.oheaderFacade
      .logout()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        window.location.replace(environment.frontendUrlSSO);
      });
  }

  handleClose(newValue: boolean) {
    this.visible = newValue;
  }

  showUpdates(data: IUpdate) {
    this.currentStep = this.steps.findIndex((update: IDialogStep) => update.id === data.id);
    this.visible = true;
  }
  formatReadableDate(isoString: string): string {
    const date = new Date(isoString);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',

      hour12: false
    };

    return date.toLocaleString('en-US', options);
  }
}
