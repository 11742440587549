import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SupSidebarComponent } from '@app/shared/components/sup-sidebar/sup-sidebar.component';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule } from 'primeng/tabview';
import { ICalendarTabsOptions } from '@app/shared/models/calendar.model';

@Component({
  selector: 'app-calendar-sidebar',
  standalone: true,
  imports: [
    SupSidebarComponent,
    FormsModule,
    CalendarModule,
    SelectButtonModule,
    DividerModule,
    DropdownModule,
    TabViewModule,
  ],
  templateUrl: './calendar-sidebar.component.html',
  styleUrl: './calendar-sidebar.component.scss',
})
export class CalendarSidebarComponent implements OnInit {
  date: Date = new Date();
  tabs: ICalendarTabsOptions[] = [
    {
      key: 'Day',
      value: 'timeGridDay',
    },
    {
      key: 'Week',
      value: 'timeGridWeek',
    },
    {
      key: 'Month',
      value: 'dayGridMonth',
    },
  ];

  selectedTab: string = '';
  minDate: Date = new Date();

  @Input('returnToToday') set setReturnToToday(value: boolean) {
    this.goToToday();
  }

  @Output('selectedOption') selectedOption: EventEmitter<string> = new EventEmitter();
  @Output('selectedDate') selectedDate: EventEmitter<string> = new EventEmitter();
  @Output('createEvent') createEvent: EventEmitter<string> = new EventEmitter();
  @ViewChild('datepicker') calendarComponent!: CalendarModule;

  ngOnInit() {
    this.selectTab('dayGridMonth');
  }

  selectTab(tabValue: string): void {
    this.selectedTab = tabValue;
    this.selectedOption.emit(this.selectedTab);
  }

  onCreateEvent() {
    this.createEvent.emit();
  }

  updateLibraryCalendar(selectedDate: Date | undefined) {
    if (selectedDate) {
      const formattedDate = this.formatDateToLocal(selectedDate);
      this.selectedDate.emit(formattedDate);
    }
  }

  formatDateToLocal(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  goToToday() {
    this.date = new Date();
    const todayMonth = this.date.getMonth();
    const todayYear = this.date.getFullYear();
    const calendarInstance = this.calendarComponent as any;

    if (calendarInstance) {
      calendarInstance.updateUI(todayMonth, todayYear);
    }
  }
}
