<div class="flex justify-content-between align-items-center">
  <div class="flex">
    <p-button
      label="Contacts"
      styleClass="border-round-xl py-2"
      [outlined]="filter !== FilterType.CONTACTS"
      class="mr-2"
      (click)="filterContacts()"
    />
    <p-button
      label="Groups"
      styleClass="border-round-xl py-2"
      [outlined]="filter !== FilterType.GROUPS"
      (click)="filterGroups()"
    />
    <p-divider layout="vertical" />
    <p-button styleClass="border-round-xl py-2" [outlined]="!filterFavorite" o-button (click)="filterFavourites()">
      <fa-icon icon="heart" class="clock-icon" />
      <span class="px-2">Favorite</span>
    </p-button>
  </div>
  <div>
    <!-- <p-button
      label="Export CSV"
      styleClass="border-round-xl py-2"
      [outlined]="true"
      class="h-full mr-2"
      (click)="exportCSV()"
    /> -->
  </div>
</div>
