<div class="bg-white border-round-lg shadow-1 h-full flex flex-column">
  <div class="flex align-items-center gap-2 p-3">
    <p-iconField iconPosition="left" class="w-full">
      <fa-icon icon="magnifying-glass" class="text-color-tertiary-200 search-icon" />
      <input
        type="text"
        pInputText
        placeholder="Search contacts"
        class="w-full border-round-xl"
        value=""
        (input)="onInputChange($event)"
      />
    </p-iconField>
<!--    <div-->
<!--      class="border-1 border-color-tertiary-200 border-round-lg cursor-pointer contact-filter-button flex justify-content-center align-items-center"-->
<!--    >-->
<!--      <fa-icon icon="filter" class="text-color-primary-500" />-->
<!--    </div>-->
    <div
      (click)="handleOpenEditContact()"
      class="bg-success-500 border-round-lg cursor-pointer contact-filter-button flex justify-content-center align-items-center"
    >
      <fa-icon icon="plus" class="text-white" />
    </div>
  </div>
  <p-divider styleClass="my-0" />
  <div class="p-3 flex flex-column gap-1 overflow-auto h-full">
    @if(!isLoading){ @for(contact of contactList?.records; track contact.contactId){
    <div
      (click)="handleSelectContact(contact)"
      class="cursor-pointer flex justify-content-between align-items-center border-1 p-2 border-round-xl border-denim-gray-20 contact-container"
      [ngClass]="{ 'border-primary-500': selectedContact?.contactId === contact.contactId }"
    >
      <div class="flex gap-2 align-items-center w-full">
        @if (contact?.contactPic?.includes('contacts')) {
        <p-avatar [image]="environment.imgHostUrl + contact?.contactPic" size="large" shape="circle"></p-avatar>
        } @else {
        <p-avatar size="normal" shape="circle"><fa-icon icon="user" class="text-white" /></p-avatar>
        }
        <p
          class="my-1 text-tertiary-400 font-medium text-sm white-space-nowrap overflow-hidden text-overflow-ellipsis contact-card-text"
        >
          {{ contact.firstName + ' ' + contact.lastName }}
          <br />
          <span class="font-normal text-xs">{{ contact.alternateEmailId }}</span>
        </p>
        <div class="cursor-pointer" (click)="favouriteContact($event, contact.contactId)">
          <fa-icon icon="heart" class="favourite" [ngClass]="{ 'favourite--active': contact.favStatus }" />
        </div>
      </div>
    </div>
    } } @else{
    <div>
      <div class="flex align-items-center mb-3">
        <p-skeleton shape="circle" size="4rem" styleClass="mr-2" />
        <div class="pt-2">
          <p-skeleton width="10rem" styleClass="mb-2" />
          <p-skeleton width="10rem" styleClass="mb-2" />
        </div>
      </div>
      <div class="flex align-items-center mb-3">
        <p-skeleton shape="circle" size="4rem" styleClass="mr-2" />
        <div class="pt-2">
          <p-skeleton width="10rem" styleClass="mb-2" />
          <p-skeleton width="10rem" styleClass="mb-2" />
        </div>
      </div>
      <div class="flex align-items-center mb-3">
        <p-skeleton shape="circle" size="4rem" styleClass="mr-2" />
        <div class="pt-2">
          <p-skeleton width="10rem" styleClass="mb-2" />
          <p-skeleton width="10rem" styleClass="mb-2" />
        </div>
      </div>
    </div>
    }
  </div>
</div>
