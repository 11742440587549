import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, inject, DestroyRef, output, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EmailService } from '@app/services/Email.service';
import { SocketV2Service } from '@app/services/socket-v2.service';
import { Email, Folder, moveEmailToFolderPayload } from '@app/shared/models/omail';
import { OButtonDirective, OToastV2Service } from 'o-suite-lib';
import { Button, ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { Subscription } from 'rxjs';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-move-folder-modal',
  standalone: true,
  imports: [DialogModule, CommonModule, ButtonModule, OButtonDirective, Button],
  templateUrl: './move-folder-modal.component.html',
  styleUrls: ['./move-folder-modal.component.scss'],
})
export class MoveFolderModalComponent {
  @Input() selectedEmails: Email[] = [];
  @Input() showDialog: boolean = false;
  @Output() showDialogChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() unselectAllEmails = new EventEmitter();
  loader: { copy: boolean; move: boolean } = { copy: false, move: false };
  toater = inject(OToastV2Service);

  emailService = inject(EmailService);
  socketService = inject(SocketV2Service);
  folderList: Folder[] = [];
  private folderListSubscription!: Subscription;
  selectedFolder: Folder | null = null;
  destroyRef = inject(DestroyRef);

  selectFolder(folder: Folder) {
    this.selectedFolder = folder;
  }

  ngOnInit(): void {
    this.folderListSubscription = this.socketService.getDynamicFolderDetailsStream().subscribe((folders: Folder[]) => {
      this.folderList = folders.filter(
        (folder) => folder.folderName.toLowerCase() !== this.selectedEmails[0]?.folderName?.toLocaleLowerCase()
      );
      if (this.selectedEmails[0]?.folderName?.toLocaleLowerCase() !== 'inbox') {
        this.folderList = [
          {
            folderName: 'INBOX',
            folderColorCode: 'primary',
            folderId: 0,
          },
          ...this.folderList,
        ];
      }
    });
  }

  ngOnDestroy(): void {
    if (this.folderListSubscription) {
      this.folderListSubscription.unsubscribe();
    }
  }

  addNewFolder() {}

  cancel() {
    this.showDialog = false;
    this.showDialogChange.emit(false);
  }

  performAction(action: 'copy' | 'move'): void {
    const mailIds = this.selectedEmails.map((email) => email.sno);
    const payload: moveEmailToFolderPayload = {
      action,
      mailIds,
      destinationFolder: this.selectedFolder?.folderName!,
      folder: this.selectedEmails[0]?.folderName!,
      role: false,
    };

    this.loader[action] = true;

    this.emailService
      .moveEmail(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res: any) => {
          this.toater.add({
            severity: 'success',
            summary: res?.message || `Emails ${action === 'copy' ? 'copied' : 'moved'} successfully`,
            icon: environment.publicPath + '/assets/icons/toast/success.svg',
          });
          this.unselectAllEmails.emit();
          this.loader[action] = false;
          this.selectedFolder = null;
          this.closeDialog();
        },
        error: (error) => {
          this.loader[action] = false;
          this.toater.add({
            severity: 'error',
            summary: error?.error?.message || `Error while ${action === 'copy' ? 'copying' : 'moving'} emails`,
            icon: environment.publicPath + '/assets/icons/toast/error.svg',
          });
        },
      });
  }

  private closeDialog() {
    this.showDialog = false;
    this.showDialogChange.emit(false);
  }
}
