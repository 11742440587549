<p-carousel
  [value]="markets"
  [numVisible]="1"
  [numScroll]="1"
  [circular]="true"
  [autoplayInterval]="false"
  [showNavigators]="false"
  class="flex-1"
>
  <ng-template pTemplate="item" let-product>
    <div class="">
      <img [src]="assetUrl(product.image)" alt="" width="100%" height="100%" />
    </div>
  </ng-template>
</p-carousel>
