<div class="email-detail px-4 py-2 border-bottom-1 border-top-1 bg-alert-50">
  <!-- From -->

  <div class="email-detail__field">
    <span>From:</span>
    <strong class="ml-1">{{ emailDetails()?.fromAliasName }}</strong>
    <span class="email-detail__email">&lt;{{ emailDetails()?.from }}&gt;</span>
    <span>{{ ', ' }}{{ formatDate(emailDetails()?.date || '') }}</span>
  </div>

  <div class="email-detail__field">
    <span>To:</span>
    <strong class="ml-1">{{ emailDetails()?.to }}</strong>
    <fa-icon
      icon="caret-down"
      class="cursor-pointer"
      (click)="op.toggle($event)"
      pTooltip="Show details"
      tooltipPosition="bottom"
      tooltipStyleClass="text-xs"
    ></fa-icon>
  </div>

  <!-- OverlayPanel -->
  <p-overlayPanel #op [style]="{ width: '450px' }">
    <div class="popup-content flex flex-column gap-3">
      <div class="flex gap-2">
        <span class="text-color-tertiary-300">"From":</span>
        <span class="text-color-tertiary-500 font-semibold">{{ emailDetails()?.from }}</span>
      </div>

      <div class="flex gap-2">
        <span class="text-color-tertiary-300">To:</span>
        <span class="text-color-tertiary-500 font-semibold">
          {{ emailDetails()?.to }}
        </span>
      </div>
      @if ((emailDetails()?.cc?.length ?? 0) > 0) {
        <div class="flex gap-2">
          <span class="text-color-tertiary-300">CC:</span>
          <span class="text-color-tertiary-500 font-semibold">{{ emailDetails()?.cc }}</span>
        </div>
      }
      <div class="flex gap-2">
        <span class="text-color-tertiary-300">"Date":</span>
        <span class="text-color-tertiary-500 font-semibold">{{ formatDate(emailDetails()?.date || '') }}</span>
      </div>
      <div class="flex gap-2">
        <span class="text-color-tertiary-300">"Subject":</span>
        <span class="text-color-tertiary-500 font-semibold">{{ emailDetails()?.subject }}</span>
      </div>
    </div>
  </p-overlayPanel>
</div>
