import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-transaction-empty',
  standalone: true,
  imports: [],
  templateUrl: './customer-transaction-empty.component.html',
  styleUrl: './customer-transaction-empty.component.scss'
})
export class CustomerTransactionEmptyComponent {}
