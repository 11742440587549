import { Component, DestroyRef, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { Dialog, DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { svgIconModule } from '@app/shared/modules';
import { IconFieldModule } from 'primeng/iconfield';
import { SignaturePadComponent } from '../../signature-pad/signature-pad.component';
import { OButtonDirective } from 'o-suite-lib';
import { ToasterService } from '@app/services/toaster.service';
import { CheckboxModule } from 'primeng/checkbox';
import { InputIconModule } from 'primeng/inputicon';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ICountry, IProfileInfo } from '@app/model/myProfile.model';
import { DropdownModule } from 'primeng/dropdown';
import { IApiResponseWithData } from '@app/model/api.model';
@Component({
  selector: 'app-become-affiliate',
  standalone: true,
  imports: [
    ButtonModule,
    OButtonDirective,
    DialogModule,
    DividerModule,
    ReactiveFormsModule,
    CommonModule,
    InputTextModule,
    CalendarModule,
    svgIconModule,
    IconFieldModule,
    SignaturePadComponent,
    CheckboxModule,
    InputIconModule,
    FormsModule,
    DropdownModule
  ],
  templateUrl: './become-affiliate.component.html',
  styleUrl: './become-affiliate.component.scss',
  providers: [DatePipe]
})
export class BecomeAffiliateComponent implements OnInit {
  isDialogVisible: boolean = false;
  ndaForm!: FormGroup;
  isFormSubmitted: boolean = false;
  checkboxes: string[] = [];
  countries: { name: string }[] = [];
  base64Signature!: string;
  isSignatureEmpty: boolean = false;
  isSubmitLoading = false;
  NdaText = '';
  fullname!: string;

  @ViewChild(SignaturePadComponent) signatureComponent!: SignaturePadComponent;

  private fb = inject(FormBuilder);
  private osuiteFacade = inject(OsuiteFacade);
  private destroyRef = inject(DestroyRef);
  private datePipe = inject(DatePipe);
  private toasterService = inject(ToasterService);

  ngOnInit(): void {
    this.ndaForm = this.fb.group({
      firstName: [{ value: '', disabled: true }],
      lastName: [{ value: '', disabled: true }],
      date: [{ value: ' ', disabled: true }],
      email: [{ value: '', disabled: true }],
      nationality: ['', [Validators.required]]
    });
    this.osuiteFacade
      .getProfileInfo()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: IProfileInfo | null) => {
        if (res) {
          this.fullname = res.firstName + ' ' + res.lastName;
          this.NdaText = `
  <div>
    <h1>Non-Disclosure and Confidentiality Agreement</h1>
    <p>This Non-Disclosure and Confidentiality Agreement [Hereinafter, this "Agreement"] is effective as of the date of signature or acknowledgement of accepting the agreement as provided through a registered office of the Company, by and between:</p>

    <h2>First Party:</h2>
    <p>OnFusion L.L.C hereinafter (May Referred to as “OnFusion, Company, Ecosystem Platform, “OUR”, “US”, “WE” Or Disclosing Party).</p>

    <h2>And</h2>

    <h2>Second Party:</h2>
    <p>Mr./ Mrs. / Ms. ${res.firstName} ${res.lastName}, Holding Id No. ${res.cutomerAccountID}, (Hereinafter referred to as “User/Founder/Customer/You/ Member/ Client)”, (which expression shall mean and include its successors and permitted assigns) of the second party. Receiving Party)</p>

    <p>Either Receiving/Disclosing Party herein may be referred to, here individually as a "Party" or collectively as "Parties."</p>

    <h2>1. Preamble of the Agreement</h2>
    <ul>
        <li>WHEREAS this Agreement is made on the basis that the Receiving Party shall not disclose the Confidential information disclosed by the Disclosing Party.</li>
        <li>WHEREAS the Disclosing Party desire to ensure that the Receiving Party maintains the confidentiality of its Confidential Information.</li>
        <li>WHEREAS, this Agreement is intended to describe how the Parties have agreed that the Receiving Party (as defined below) shall keep confidential and shall not disclose to any third party the Confidential Information disclosed to them by the Disclosing Party (as defined below) and how the Receiving Party shall only use the Confidential Information for the Purpose of this agreement, except as expressly permitted by this Agreement.</li>
        <li>THEREFORE, the present Agreement is drawn between the Parties hereto who declared that they have full capacity to conclude and enter into the Agreement, and that their wills, respectively, are free from any defect that would impair their consent hereto. All Parties have also acknowledged that this present Agreement's stipulations are valid against their legal successors.</li>
        <li>THEREFORE, the Parties agree on the conditions hereinafter set forth, Proprietary Confidential Information owned by OnFusion.</li>
        <li>Receiving Party has undertaken, acknowledged and agreed that OnFusion Confidential Information, Proprietary Information, Invention, Patents, Copyrights, Trademark and other Intellectual Property Rights are important assets of the Company and has committed to maintain it.</li>
        <li>The Above Preamble shall form an integral part of this Agreement.</li>
    </ul>

    <h2>2. Definitions and Interpretation</h2>
    <p>The following words shall have the meanings attributed to them as follows:</p>

    <h3>2.1 Agreement</h3>
    <p>Shall mean this Mutual Non-Disclosure Agreement.</p>

    <h3>2.2 Confidential Information</h3>
    <p>Shall mean information which is confidential including Proprietary Information and other information related to the business of the Company, its affiliates or any third parties with which the Company associates, whether or not such information is expressly marked or designated as confidential information and includes but is not limited to: information of value or significance to the Company or its competitors (including potential competitors) such as:</p>
    <ol type="i">
        <li>data of or about the Company or its vendors, customers, Founder/Customers, advisors, mentors, service providers or consultants, in particular, contact information and sales information;</li>
        <li>data about Company’s compliance with applicable law including data about licenses, permissions, approvals, permissions or consents applied for, requested by, granted to or denied to the Company or its promoters;</li>
        <li>data about all filings and official submissions made by the Company to governmental authorities and the content of the discussion and communication by the Company with such authorities;</li>
        <li>data related to the Company’s business, its existing and upcoming products, services, business strategy, Marketing Campaigns details, terms of engagement with its vendors or customers, pricing data, payment and refund policies and plan, business plans, users/founders/customers data, policies and plans which directly and indirectly related to the Company.</li>
        <li>any data, documents, sketches, designs, plans, drawings, photographs, reports, communication, technical information, information about Intellectual Property Rights, user information, compilation, subscription details, asset information, know-how, research and development, internal policies;</li>
        <li>Any information related to the Company’s technology, software, hardware, code, design, business strategy, business plan, internal systems, and business architecture.</li>
        <li>financial data, in particular, concerning budgets, fees and revenue calculations, sales figures, bonus plans, financial statements, profit expectations and inventories of the Company.</li>
        <li>training data, particularly documents, videos, webinars, photographs, website data, processes, multimedia files, presentations and any such training resources that the Founder/Customer gains access to during his association with the Company.</li>
        <li>security information (including passwords, login credentials) used to access any resource owned or operated by the Company, its affiliates, clients or third-party agents;</li>
        <li>client or user data, user credits, user analytics, user preferences, feedback information.</li>
    </ol>
    <p>a) any information which may be reasonably understood by its nature, or by the context of its disclosure, to be confidential; and</p>
    <p>b) any information derived from any of the above-mentioned information; and</p>
    <p>c) original information supplied by the Company or information provided to the Company by third parties which the Company is obligated to keep confidential</p>
    <ol type="xi">
        <li>prohibition of postings about other companies or other business opportunities or programs, including their links;</li>
        <li>prohibition of sharing posting and comment that reflects a race, sex, cast, religion, political agenda, team competition and cross-recruiting as well,</li>
        <li>prohibition of vigorous and impassioned sharing of ideas, negative languages, webinar/videos/community/data of the company;</li>
        <li>participate in constructive online dialogues and use our best judgment when posting to external or internal social media.</li>
    </ol>

    <h3>2.3 Notice of Dispute</h3>
    <p>is a written notice served by one Party to notify the other Party that a dispute has arisen.</p>

    <h3>2.4 Representatives</h3>
    <p>Shall mean directors and/or officers and/or attorneys and/or any other persons, whether individuals or legal entities, acting on behalf of or in the interest of a Party.</p>

    <h3>2.5 Affiliate</h3>
    <p>with respect to either party means any person or entity who directly or indirectly controls, is controlled by, or is under the common control of such parties. The term “Control” is used in the sense of the possession by a person or a group of persons acting in concert, directly or indirectly, of the right to direct or cause the direction of the management, policies, and actions of another person, whether through the board of directors or ownership of voting rights by such other Person, by the Articles of Association, contract or otherwise. A person or a group of persons acting in concert shall be deemed to be in control of a body corporate if such Person or group of Persons is in a position to appoint or appoints the majority of the directors of such body corporate.</p>

    <h3>2.6 Intellectual Property Rights</h3>
    <p>means and includes, without limitation, any patents, copyrights, trademarks, trade secrets, service marks, database right, design right, moral right or any other property rights (in each case, whether registered or not and including applications for registration, if any) that grant similar rights as the foregoing, anywhere in the world.</p>

    <h3>2.7 Company Intellectual Property</h3>
    <p>means any Proprietary Information created, conceived, developed, and improved by the Company.</p>

    <h3>2.8 Proprietary Information</h3>
    <p>means and includes, but is not limited to, Company Intellectual Property information about software programs, software designs, software architectures, source code, object code, algorithms, trade secrets, formulae, data, designs, technical know-how, domain names, processes, applications, ideas, user/ founder/customers data and records, techniques, documents, notes, presentations, works of authorship, business plans, customer lists, user information, vendor data, customer data, operational data, terms of compensation and performance levels of Founder/Customer, Marketing Campaigns and other information concerning the actual or anticipated business, research or development, prices and pricing structures, marketing and sales information, competitive analysis, and any information and materials relating thereto, or which is received in confidence by or for the Company or its Affiliates from any other person, whether or not it is in written or permanent form.</p>

    <h3>2.9 Registration Suspension Policy</h3>
    <p>Founder/Customer account will be suspended By Us, when an NDA violation is suspected, and Founder’s account will be placed in Temporary Suspension pending the outcome of an investigation and compliance surveillance activity. we may send a Notice of Warning will contain instructions to contact OnFusion via an account-associated email at legal@onpassive.com citing time limits. If offending Founder does not respond to the Notice of Warning, does not respond to the Notice of Warning with allotted time, or does not produce vindicating evidence or explanation through valid a current, valid e-mail address that is associated with the offending Founders account, the Founder’s account [with full registration] shall be terminated without notice.</p>

    <h2>3. Term and Termination</h2>

    <h3>3.1</h3>
    <p>This Agreement shall be effective on the date of commencement of service of YOU / User/ Founder/ Customer with the Us and continue in force till 10 (Ten) Years after the date of termination/end of the Agreement for whatsoever reason.</p>

    <h3>3.2</h3>
    <p>This Agreement shall run for the whole period where the parties are doing business with each other however, and as mentioned before, Receiving Party’s obligations under this Agreement shall survive for a period of ten (10) years from the termination or expiration of this Agreement between the Parties and shall be binding upon the Receiving Party's heirs, successors, and assigns upon termination or expiration of this Agreement, or upon written request of the Disclosing Party.</p>

    <h3>3.3</h3>
    <p>This Agreement may not be renewed or otherwise extended unless by the written renewal/extension agreement signed between the Parties and this Agreement may only be amended or modified by a written document, signed by both Parties.</p>

    <h2>4. Confidentiality Obligations</h2>

    <h3>4.1</h3>
    <p>The Founder/Customer/ User acknowledges and agrees that during the term of this Agreement with the Company and notwithstanding any termination the Founder/Customer/User shall have access to Confidential Information through oral, visual, electronic or written means.</p>

    <h3>4.2</h3>
    <p>The User/ Founder/Customer understands and acknowledges that the Confidential Information is of immense value to the Company and its Affiliates and/or its present, past or prospective clients. The Founder/Customer understands that any use or disclosure of such Confidential Information including any inadvertent disclosure can cause immense and irreparable harm, loss, damage and injury to the Company and its Affiliates and its reputation and hence undertakes to keep such Confidential Information confidential.</p>

    <h3>4.3</h3>
    <p>The User/ Founder/Customer agrees and undertakes that at all times during the term of this Agreement and thereafter the termination of this Agreement for whatever reason to hold in the strictest confidence, and not to use, except for the benefit of the Company and its Affiliates, and absolutely refrain from in any manner divulging, discussing, disclosing, or otherwise releasing, the Confidential Information to any third party or in any manner directly or indirectly using the Confidential Information without the written authorization of the Company.</p>

    <h3>4.4</h3>
    <p>The User/ Founder/Customer recognizes that the Company and its Affiliates have received and, in the future, will receive from third parties, information that would be confidential and proprietary in nature to such third parties.</p>

    <h3>4.5</h3>
    <p>The User/ Founder/Customer further undertakes not to make copies of, or disseminate, such Confidential Information except as authorized by the Company by written. Nothing contained in this Agreement shall be construed as granting or conferring any rights either as a license or otherwise in the Confidential Information. The User/ Founder/Customer shall not claim or represent to hold any interest by way of Company ship, assignment or otherwise in the Confidential Information.</p>

    <h3>4.6 Insider Trading and Non-Public Information</h3>
    <p>User/ Founder/Member/ Client/Customer, his/her family and relatives are prohibited under Company policy as well as by Law from purchasing, selling and dealing in Company stock/ marketing campaigns, strategy, plan/ policies and platforms or the stock of any other company, such as existing or potential customers or suppliers, directly or indirectly, on the basis of material non-public information. All non-public information about the Company should be considered confidential information. To use non-public information for personal financial benefit or to “tip” others who might make an investment decision on the basis of this information is unethical, against Company policy, and illegal. ‘Founder/Member/ Client/Customer his/her family and relatives do not have right to engaged in term of employment whether (full time/part time/consultant and other)’.</p>

    <h3>4.7 Sustentive Personal Data or Information (“SPDI”)</h3>
    <p>(a) means passwords, financial information, such as bank account or credit card or debit card or other payment instrument, any detail relating to the above as provided to Company for providing service; and any information received under the above by Company for processing, stored or processed under lawful contract or otherwise,</p>
    <p>(b) Company is required to obtain prior consent from the information provider regarding the purpose of usage of the SPDI. Such information should be collected only if it is essential and required for a lawful purpose connected with the functioning of the Company,</p>
    <p>(c) Founder/Customer / User has no right to share/disclose the SPDI details in public and, if found to be in violation, that Company has legal right to terminate his/her registration without notice,</p>
    <p>(d) Founder/Customer / User agrees and consents that Company has right to use and disclosed his/her data for the information sought by governmental agencies or under applicable legal provisions and for Company business requirements, and further agrees Founder/ Customer has no rights to claim, sue, or approach for any Legal remedy for such disclosure and This Clause shall survive the expiration or termination of this Agreement.</p>

    <h2>5. Restrictions</h2>

    <h3>A. Except for the express written consent of Company, Founder/Customer acknowledges, undertakes and agrees:</h3>
    <ol>
        <li>Not to use or disclose to another person or entity any confidential information of Company;</li>
        <li>Not to make, or cause to be made, any copies, facsimiles or other reproductions including data files of any documents containing confidential information of Company; and</li>
        <li>To use all other reasonable means to maintain the secrecy and confidentiality of the confidential information of Company.</li>
        <li>The Company has the full right to decline/ reject any request from any User to become an Affiliate and to join the Affiliate program without reason as OnFusion deems appropriate.</li>
        <li>The Company has the full right to cancel, terminate, pan and suspend the Affiliate Program membership for any customer as deems appropriate at any time and without prior notice.</li>
    </ol>

    <h3>B. Founder/Customer further agrees, at the request of Company:</h3>
    <ol>
        <li>To immediately return to Company all items in the possession of Founder/Customer which relate to or which disclose in whole or in part any confidential information of Company; and</li>
        <li>To refrain from using or disclosing to any other person or entity any confidential information of Company.</li>
    </ol>

    <h2>6. Representations and Warranties</h2>

    <h3>The Founder/Customer represents and warrants that:</h3>
    <ol>
        <li>The Founder/Customer’s performance of all terms of this Agreement and as a Founder/Customer of the Company does not and will not breach any prior agreement with others keep in confidential information acquired by the Founder/Customer in Confidence or in trust prior to the registration.</li>
        <li>The Founder/Customer has not and shall not enter into agreement, either written or oral, in conflict with this agreement.</li>
    </ol>

    <h2>7. Covenant not to Sue</h2>
    <p>Founder/Customer shall not institute any action or suit at law or in equity against Company, nor institute, prosecute or in any way aid in the institution or prosecution of any claim, demand, action, or cause of action arising out of the Confidential Information or any Intellectual Property thereof, including but not limited to, claim, demand, action, or cause of action for invalidating any Intellectual Property and Confidential Information of the Company.</p>

    <h2>8. Payment Policy</h2>
    <p>Founder/Customer shall be eligible for our various service/facilities after making valid/authorized payment (through the modes as explained/mentioned in our website). Payment policies and structure are defined by Company at its sole discretion, and it will change periodically as per business strategies and plans (as required).</p>

    <h2>9. Damages and Specific Performance</h2>
    <p>Founder/Customer agrees that should Founder/Customer breach any portion contained in this Agreement that Company would suffer irreparable harm and Company would be without adequate remedy at law and that Company may obtain injunctive relief, including specific performance of the Agreement, as well as monetary award for damages suffered by Company for Founder/Customer’s breach of this Agreement.</p>

    <h2>10. Severability</h2>
    <p>Should a court of competent jurisdiction find that any portion of this Agreement is invalid, illegal, or unenforceable, the remaining provisions shall remain in full force and effect, and the parties shall use reasonable efforts to substitute a valid, legal, and enforceable provision that implements purposes of the provision so held invalid, illegal, or unenforceable to any extent permissible under the law.</p>

    <h2>11. Notice</h2>
    <ol>
        <li>Correspondences served through facsimile, e-mail, or any other simultaneous electronic valid and available medium shall enjoy the same legal effect as the traditionally signed documents in terms of evidence and legal value.</li>
        <li>Any Notice or communication required or allowed under this agreement, regardless of the communication method, shall take effect upon actual delivery of the notice at the legal domicile, residence or mailing address of the receiving party. If party alters its notification address or mailing address, it shall notify the other party of its new address within 3 (three) days after the alteration, otherwise, the defaulting party shall be held responsible for all consequent legal liabilities. In cases where no legal domicile, residence or mailing address of the receiving party is known or available, attempts to contact that party will be made via all means available to include digital media and social platforms. In case where no contact can be made through reasonable attempts, the Company shall not be held responsible for consequent liabilities arising from inability to contact receiving party.</li>
    </ol>

    <h2>12. Governing Law and Jurisdiction</h2>
    <p>The establishment, validity, execution and dispute settlement of this Agreement shall be governed by the laws of the Country. The Courts located at the registered office of the Company shall have the exclusive jurisdiction to entertain and resolve all the disputes between the parties.</p>

    <h2>13. Return or Destruction of Confidential Information</h2>

    <h3>13.1</h3>
    <p>If discussions in relation to the Purpose cease, or either Party requests in writing at any time, or this Agreement terminates for any reason, the Receiving Party must immediately:</p>
    <ol type="a">
        <li>Destroy or return (as required) to the Disclosing Party all documents and materials (including copies) containing, reflecting, incorporating, or based on the Disclosing Party's Confidential Information promptly and in any event within seven (7) days of receipt of notice from the Disclosing Party.</li>
        <li>Destroy all copies of materials (whether written or electronic and whether in its possession or another's pursuant to the terms of this Agreement) into which Confidential Information has been incorporated (to the extent possible);</li>
        <li>Erase all the Disclosing Party's Confidential Information from its computer systems or which is stored in electronic form (to the extent possible).</li>
    </ol>
</div>
          `;

          this.ndaForm.patchValue({
            firstName: res.firstName,
            lastName: res.lastName,
            date: this.datePipe.transform(new Date(), 'dd, MMM yyyy'),
            email: res.emailId,
            nationality: { name: res.Nationality }
          });
        }
      });

    this.osuiteFacade
      .getCountries()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: ICountry[] | null) => {
        if (res) {
          this.countries = res.map((country: ICountry) => ({ name: country.countryName }));
        }
      });
  }

  handleShowDialog() {
    this.isDialogVisible = true;
  }

  closeDialog() {
    this.isDialogVisible = false;
  }

  signatureStatusChange(event: boolean) {
    this.isSignatureEmpty = event;
  }

  onSubmit() {
    this.isSubmitLoading = true;
    const formData = new FormData();
    formData.append(
      'data',
      `{"nationality":"${this.ndaForm.get('nationality')?.value.name}","saveNda":true,"saveAgreement":true}`
    );
    formData.append('file', this.base64ToFile(this.signatureComponent.getValue(), 'example.png', 'image/png'));

    this.osuiteFacade
      .saveNDA(formData)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        if (res.status === 'Success') {
          this.isDialogVisible = false;
          this.isSubmitLoading = false;

          this.toasterService.setToasterState(true, 'success', 'Application sent successfully');

          this.osuiteFacade
            .fetchProfileInfo()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
              next: (res: IApiResponseWithData<IProfileInfo>) => {
                this.osuiteFacade.setProfileInfo(res.data);
              }
            });
        }
      });
  }

  base64ToFile(base64: string, fileName: string, mimeType: string): File {
    const byteString = atob(base64.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: mimeType });
    return new File([blob], fileName, { type: mimeType });
  }
}
