import { parseISO, isSameDay, format } from 'date-fns';

export function formatDate(date: string | number, fullDate?: boolean): string {
  let parsedDate: Date;

  if (typeof date === 'number') {
    parsedDate = new Date(date);
  } else {
    parsedDate = parseISO(date);
  }

  const today = new Date();

  if (isSameDay(parsedDate, today)) {
    return parsedDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  }

  return fullDate ? format(parsedDate, 'MMM d, yyyy h:mm a') : format(parsedDate, 'MMM d');
}
