import { Component } from '@angular/core';
import { IconsModule } from '@app/shared/modules/icons.module';

@Component({
  selector: 'app-no-email-selected',
  standalone: true,
  imports: [IconsModule],
  templateUrl: './no-email-selected.component.html',
  styleUrl: './no-email-selected.component.scss',
})
export class NoEmailSelectedComponent {}
