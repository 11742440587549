import { Component } from '@angular/core';
import { MyContactsSidebarComponent } from '@app/components/my-contacts/my-contacts-sidebar/my-contacts-sidebar.component';
import { RouterModule } from '@angular/router';
import { AllContactsSidebarComponent } from '@app/components/my-contacts/all-contacts-sidebar/all-contacts-sidebar.component';
import { svgIconModule } from '@app/shared/modules/svg-icons.module';

@Component({
  selector: 'app-my-contacts',
  standalone: true,
  imports: [MyContactsSidebarComponent, RouterModule, AllContactsSidebarComponent, svgIconModule],
  providers: [svgIconModule],
  templateUrl: './my-contacts.component.html',
  styleUrl: './my-contacts.component.scss',
})
export class MyContactsComponent {}
