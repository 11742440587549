<p-button
  pTooltip="Coming soon"
  tooltipPosition="right"
  styleClass="bg-alternative-500 border-round-xl border-0 font-semibold py-2"
  (click)="handleShowDialog()"
>
  Become an affiliate
</p-button>

<p-dialog
  [modal]="true"
  (onHide)="closeDialog()"
  [(visible)]="isDialogVisible"
  [draggable]="false"
  [dismissableMask]="true"
  [focusOnShow]="false"
>
  <h2 class="text-center px-4 font-bold text-3xl mt-2 mb-3">NON-DISCLOSURE AND CONFIDENTIALITY AGREEMENT</h2>
  <div class="px-3">
    <div class="flex">
      <div class="py-2"><svg-icon name="onfusionLogo" /></div>
      <p-divider layout="vertical" type="solid" styleClass="ml-6 mr-4" />
      <div class="py-2">
        <p class="my-0 tex-color-tertiary-500">Office Address</p>
        <p class="my-0 tex-color-tertiary-500">
          Burj Khalifa, Floor 134 1 Sheikh Mohammed bin Rashid Blvd - Downtown Dubai
        </p>
        <p class="my-0 tex-color-tertiary-500">Dubai - United Arab Emirates</p>
        <p class="my-0 tex-color-tertiary-500">
          E-Mail Address:
          <span class="text-color-primary-500">{{ 'legal@onpassive.com' }}</span>
        </p>
      </div>
    </div>
    <div class="mt-4 nda-text-area py-3 pl-3 pr-2 h-27rem border-1 border-denim-gray-20">
      <div class="h-full overflow-auto pr-2" [innerHTML]="NdaText"></div>
    </div>
    <div>
      <form [formGroup]="ndaForm" class="flex flex-wrap mt-4">
        <div class="field col col-4 mb-2">
          <label for="firstName" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">First name</label>
          <input
            type="text"
            pInputText
            class="w-full px-2 py-2 custom-border-radius text-sm text-color-tertiary-500"
            formControlName="firstName"
          />
        </div>
        <div class="field col col-4 mb-2">
          <label for="firstName" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">Last name</label>
          <input
            type="text"
            pInputText
            class="w-full px-2 py-2 custom-border-radius text-sm text-color-tertiary-500"
            formControlName="firstName"
          />
        </div>
        <div class="field col col-4 mb-2 flex flex-column">
          <label for="firstName" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">Date</label>

          <p-iconField iconPosition="right">
            <p-inputIcon>
              <svg-icon name="calendar-select" />
            </p-inputIcon>
            <input
              type="text"
              pInputText
              class="w-full px-2 py-2 custom-border-radius text-sm text-color-tertiary-500"
              formControlName="date"
            />
          </p-iconField>
        </div>
        <div class="field col col-4 mb-2">
          <label for="email" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">E-mail addresss</label>
          <input
            type="text"
            pInputText
            class="w-full px-2 py-2 custom-border-radius text-sm text-color-tertiary-500"
            formControlName="email"
          />
        </div>
        <!-- <div class="field col col-4 mb-2">
          <label for="nationality" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">
            Nationality
            <span class="text-xs star text-color-danger-500">*</span>
          </label>

          <p-dropdown
            class="w-full px-2 py-2 custom-border-radius text-sm text-color-tertiary-500"
            formControlName="nationality"
            [options]="countries"
            optionLabel="name"
            placeholder="Select a Country"
            [ngClass]="{ 'ng-invalid ng-dirty': isFormSubmitted &&
                  ndaForm.get('nationality')?.invalid, }"
            [virtualScroll]="true"
            [virtualScrollItemSize]="38"
          />
        </div> -->
      </form>
    </div>

    <div>
      <label class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">Signature</label>
      <div class="nda-text-area border-round">
        <app-signature-pad (signatureStatusChange)="signatureStatusChange($event)" />
      </div>
    </div>

    <div class="flex flex-column gap-2 mt-4">
      <p-checkbox
        label="I have carefully read and I agree on the above mentioned terms and conditions & agree to sign the NDA"
        name="readNDA"
        value="readNDA"
        [(ngModel)]="checkboxes"
        [autofocus]="false"
      />
      <p-checkbox
        [label]="'I confirmed that I am ' + fullname + ' and / or an authorised person to manage this account'"
        name="confirmSelf"
        value="confirmSelf"
        [(ngModel)]="checkboxes"
        [autofocus]="false"
      />
      <div class="flex gap-2">
        <p-checkbox value="readAffiliate" inputId="readAffiliate" [(ngModel)]="checkboxes" [autofocus]="false" />
        <label for="readAffiliate">
          I have carefully read
          <span class="text-color-primary-500 underline">the affiliation agreement</span>
          and l agreed to become affiliate
        </label>
      </div>
    </div>
  </div>

  <div class="flex justify-content-end gap-3 mt-2">
    <p-button label="Cancel" [outlined]="true" styleClass="px-4 py-2 custom-border-radius" (click)="closeDialog()" />
    <p-button
      o-button
      [oButtonSeverity]="'success'"
      label="Accept"
      styleClass="px-4 py-2 custom-border-radius"
      type="submit"
      (click)="onSubmit()"
      [loading]="isSubmitLoading"
      [disabled]="checkboxes.length !== 3 || !isSignatureEmpty"
    />
  </div>
</p-dialog>
