import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordNotMatchValidator(new1Password: string, new2Password: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const new1PasswordControl = control.get(new1Password);
    const new2PasswordControl = control.get(new2Password);

    if (new1PasswordControl && new2PasswordControl) {
      if (new1PasswordControl.value !== new2PasswordControl.value) {
        const existingErrors = new2PasswordControl.errors || {};
        new2PasswordControl.setErrors({ ...existingErrors, passwordNotMatch: true });
        return { passwordNotMatch: true };
      } else {
        const existingErrors = new2PasswordControl.errors;
        if (existingErrors) {
          delete existingErrors['passwordNotMatch'];
          if (Object.keys(existingErrors).length === 0) {
            new2PasswordControl.setErrors(null);
          } else {
            new2PasswordControl.setErrors(existingErrors);
          }
        }
      }
    }
    return null;
  };
}
