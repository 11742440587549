import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, input, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCamera, faXmark } from '@fortawesome/free-solid-svg-icons';
import { AvatarModule } from 'primeng/avatar';
import { environment } from '@src/environments/environment';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OLoaderComponent } from 'o-suite-lib';
import { finalize } from 'rxjs';
@Component({
  selector: 'app-upload',
  standalone: true,
  imports: [AvatarModule, FontAwesomeModule, CommonModule, OLoaderComponent],
  templateUrl: './upload.component.html',
  styleUrl: './upload.component.scss'
})
export class UploadComponent {
  faCamera = faCamera;
  faX = faXmark;
  imgHost = environment.imgHostUrl;
  isImageLoading = false;

  @Input() userImage!: string | null;

  private osuiteFacade = inject(OsuiteFacade);
  private destroyRef = inject(DestroyRef);

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files) {
      const files = Array.from(input.files);
      if (!files[0].type.startsWith('image/')) {
        console.error('Selected file is not an image');
      } else {
        const file: File = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        this.handleUserImageChange(file);
      }
    }
  }

  triggerFileUpload(fileInput: HTMLInputElement): void {
    fileInput.click();
  }

  handleClearImage() {
    this.isImageLoading = true;
    this.osuiteFacade
      .clearProfilePicture()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
          this.isImageLoading = false;
        })
      )
      .subscribe((res: any) => {
        if (res.statusCode === 200) {
          let temp = {};
          this.userImage = '';
          this.osuiteFacade.getProfileInfo().subscribe((profileInfo: any) => {
            temp = profileInfo;
          });
          this.osuiteFacade.setProfileInfo({
            ...temp,
            profilePic: res.data
          });
        }
      });
  }

  handleUserImageChange(file: File): void {
    let formData = new FormData();
    formData.append('file', file);
    this.isImageLoading = true;
    this.osuiteFacade
      .uploadProfilePic(formData)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
          this.isImageLoading = false;
        })
      )
      .subscribe((res: any) => {
        if (res) {
          let temp = {};
          this.osuiteFacade
            .getProfileInfo()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((profileInfo: any) => {
              temp = profileInfo;
            });
          this.osuiteFacade.setProfileInfo({
            ...temp,
            profilePic: res.data
          });
        }
      });
  }
}
