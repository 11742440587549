import { ChangeDetectionStrategy, Component } from '@angular/core';
import { svgIconModule } from '@app/shared/modules';

@Component({
  selector: 'app-timeline-info',
  standalone: true,
  imports: [svgIconModule],
  templateUrl: './timeline-info.component.html',
  styleUrl: './timeline-info.component.scss'
})
export class TimelineInfoComponent {}
