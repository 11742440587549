import { Component, EventEmitter, Input, model, Output } from '@angular/core';
import { OnboardingStep } from '../models/onboarding-step.model';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { assetUrl } from '@src/single-spa/asset-url';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'app-onboarding-tour',
  templateUrl: './onboarding-tour.component.html',
  standalone: true,
  imports: [DialogModule, ButtonModule, CommonModule, SvgIconComponent],
  styleUrls: ['./onboarding-tour.component.scss']
})
export class OnboardingTourComponent {
  steps: OnboardingStep[] = [];
  currentStepIndex = 0;
  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  goToNextStep() {
    if (this.currentStepIndex < 3) {
      this.currentStepIndex++;
    }
  }

  goToPreviousStep() {
    if (this.currentStepIndex !== 0) {
      this.currentStepIndex--;
    }
  }

  closeDialog() {
    this.visible = false;
    this.visibleChange.emit(false);
  }

  protected readonly assetUrl = assetUrl;
}
