<div class="email-list flex flex-column">
  <app-email-header [selectedEmails]="selectedEmails" (selectAll)="toggleSelectAll()" />
  <div class="overflow-auto flex-1" (scroll)="onScroll($event)">
    @if (listofMailsValue.length > 0) {
      @for (email of listofMailsValue; track email.sno) {
        <app-email-message
          [email]="email"
          (click)="onEmailClick(email)"
          [isSelected]="checkIfSelected(email.sno)"
          [id]="email.sno"
          (select)="toggleSelect(email.sno)"
          [currentMailbox]="currentMailbox"
        ></app-email-message>
      }
    }
    @if (!((loadingNextPage() && nextPage()) || loading()) && listofMailsValue.length === 0) {
      <div
        class="text-center text-color-tertiary-300 flex flex-column align-items-center justify-content-center flex-1 h-full"
      >
        <p class="m-0">No Messages yet.</p>
        <p class="m-0">Your {{ currentMailbox }} is all clear!</p>
      </div>
    }
    @if ((loadingNextPage() && nextPage()) || loading()) {
      @for (item of [1, 2, 3, 4, 5, 6, 7, 8]; track $index) {
        <app-email-skeleton />
      }
    }
  </div>
</div>
