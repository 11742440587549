import { inject, Injectable } from '@angular/core';
import { OheaderState } from './oheader.state';
import { OheaderApi } from './oheaderApi';
import { IUpdate } from '../model/header.model';

@Injectable({
  providedIn: 'root'
})
export class OheaderFacade {
  private oheaderState = inject(OheaderState);
  private oheaderApi = inject(OheaderApi);

  setShowUpdatesBanner(state: boolean) {
    this.oheaderState.setShowUpdatesBanner(state);
  }

  getShowUpdatesBanner() {
    return this.oheaderState.getShowUpdatesBanner();
  }

  fetchBannerUpdates() {
    return this.oheaderApi.fetchBannerUpdates();
  }

  setBannerUpdates(bannerUpdated: IUpdate[]) {
    this.oheaderState.setBannerUpdates(bannerUpdated);
  }

  getBannerUpdates() {
    return this.oheaderState.getBannerUpdates();
  }

  fetchProfileInfo() {
    return this.oheaderApi.fetchProfileInfo();
  }

  logout() {
    return this.oheaderApi.logout();
  }
}
