<div [ngClass]="{ 'sidebar-menu': true, collapsed: isCollapsed() }">
  @for (item of menuItems; track $index) {
  <div [ngClass]="{ 'item-container': true, active: item.isSelected }">
    @if(item.isSelected) {
    <div class="active-indication"></div>
    }
    <div class="flex justify-content-between w-full" (click)="redirectTo(item)">
      <div class="item-button">
        <svg-icon [name]="item.icon" class="menu-icon"></svg-icon>
        <div class="item-button-label">{{ item.label }}</div>
      </div>
      @if(item.soon) {
      <p-tag severity="success" value="Soon" styleClass="custom-tag" />
      }
    </div>
  </div>
  }
</div>
