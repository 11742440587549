import { APP_INITIALIZER, NgZone, provideZoneChangeDetection } from '@angular/core';
import { NavigationStart, provideRouter, Router } from '@angular/router';
import { getSingleSpaExtraProviders, singleSpaAngular } from 'single-spa-angular';
import { singleSpaPropsSubject } from './single-spa/single-spa-props';
import { bootstrapApplication, provideClientHydration } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { APP_BASE_HREF } from '@angular/common';
import { routes } from './app/app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HttpInterceptorService } from './app/interceptors/http.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppInitializationService } from './app/services/app-Initialization.service';

export function appInitializerFactory(appInitService: AppInitializationService): () => Promise<void> {
  return () => appInitService.initializeApp();
}

const lifecycles = singleSpaAngular({
  bootstrapFunction: (singleSpaProps) => {
    singleSpaPropsSubject.next(singleSpaProps);
    return bootstrapApplication(AppComponent, {
      providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        provideClientHydration(),
        provideAnimations(),
        getSingleSpaExtraProviders(),
        provideRouter(routes),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideAnimationsAsync(),
        provideHttpClient(withInterceptorsFromDi()),
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpInterceptorService,
          multi: true
        },
        {
          provide: APP_INITIALIZER,
          useFactory: appInitializerFactory,
          deps: [AppInitializationService],
          multi: true
        }
      ]
    });
  },
  template: '<app-header />',
  Router,
  NavigationStart,
  NgZone,
  domElementGetter: () => document.getElementById('header-root') as HTMLElement
});
export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;
