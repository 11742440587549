<div class="email-body w-full flex flex-1 justify-content-between flex-column">
  @if (emailDetails && !loading()) {
    <app-email-header [emailDetails]="emailDetails" [showActions]="!selectedAction" />

    <p-scrollPanel class="email-body-content overflow-auto flex-1">
      <app-email-detail [emailDetails]="emailDetails" />
      @if (selectedAction) {
        <div class="flex align-items-center justify-content-between px-3 bg-tertiary-1 border-round-sm m-3 mb-0">
          <div class="flex align-items-center gap-3">
            <fa-icon [icon]="selectedAction.iconName" class="text-color-tertiary-200"></fa-icon>
            <p class="text-color-tertiary-350 m-0">
              {{ selectedAction.text }}
            </p>
          </div>
          <p-button
            id="mail_editContact_button_cancel"
            class="action-btn"
            [text]="true"
            o-button
            [outlined]="true"
            [label]="selectedAction.textButton"
            [oButtonSeverity]="'primary'"
            (click)="selectedAction.clickButton()"
          ></p-button>
        </div>
      }
      <div class="email-body-text px-4 pt-4" [innerHTML]="formatBodyHtml(emailDetails.body)"></div>
    </p-scrollPanel>
  } @else if (loading()) {
    <div class="loader-wrapper h-screen">
      <o-loader [bgColor]="'none'" />
    </div>
  } @else {
    <div class="no-email-selected flex w-full h-full flex-column justify-content-center align-items-center">
      <fa-icon
        icon="envelope"
        class="text-7xl bg-tertiary-1 text-white p-4 border-circle text-color-tertiary-200 flex justify-content-center align-items-center"
      ></fa-icon>
      <h3 class="text-color-primary-500 mb-0">Select any email to read</h3>
      <p class="text-color-tertiary-500">No Item Selected</p>
    </div>
  }
</div>
