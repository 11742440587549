<form [formGroup]="emailForm" class="flex flex-column flex-1">
  <!-- Header: to, cc, bcc, subject, priority -->

  <app-create-email-header
    [action]="action"
    [emailForm]="emailForm"
    (close)="onSaveDraftClick(true).subscribe()"
    [draft]="draftData"
  ></app-create-email-header>

  <!-- Editor: the body of the email -->
  <app-email-editor
    [emailDetails]="emailDetails"
    [action]="action"
    [uploadedFile]="uploadedFile"
    [emailForm]="emailForm"
    (sendVideoUrl)="handleVideoUrl($event)"
  ></app-email-editor>

  <!-- Footer: handles file uploads, has the submit button -->
  <app-create-email-footer
    (fileUploaded)="handleFileUpload($event)"
    [emailForm]="emailForm"
    (submitEmail)="onSubmitEmail()"
    [loading]="loading()"
    [draftLoading]="draftLoading()"
    (saveDraft)="onSaveDraftClick(false).subscribe()"
  ></app-create-email-footer>
  @if (readLoading()) {
    <div class="loader-wrapper h-screen absolute w-full h-full flex justify-content-center align-items-center">
      <o-loader bgColor="null" />
    </div>
  }
  @if (closePageLoading()) {
    <div class="loader-wrapper loader">
      <o-loader bgColor="null" />
    </div>
  }
</form>
