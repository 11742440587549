import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IApiResponseWithData, PaginatedResponse } from '@app/model/api.model';
import { GetContactsOptions, IContact } from '@app/model/my-contacts.model';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyContactsApi {
  osuite_ApiUrl = environment.apiUrl;
  osuite_SSO_ApiUrl = environment.apiUrlSSO;

  private httpClient = inject(HttpClient);

  fetchContacts(options: GetContactsOptions): Observable<IApiResponseWithData<PaginatedResponse<IContact>>> {
    return this.httpClient.post<IApiResponseWithData<PaginatedResponse<IContact>>>(
      this.osuite_ApiUrl + 'getContacts',
      options
    );
  }

  favoriteContact(contactId: number[]): Observable<IApiResponseWithData<undefined>> {
    return this.httpClient.put<IApiResponseWithData<undefined>>(this.osuite_ApiUrl + `contacts/favorite`, {
      ids: contactId
    });
  }

  exportContacts(exportData: any): Observable<IApiResponseWithData<undefined>> {
    return this.httpClient.post<IApiResponseWithData<undefined>>(
      this.osuite_ApiUrl + `exportContactsToCSV`,
      exportData
    );
  }

  deleteContact(idsToDelet: number[]): Observable<IApiResponseWithData<undefined>> {
    return this.httpClient.post<IApiResponseWithData<undefined>>(this.osuite_ApiUrl + `deleteContact`, {
      ids: idsToDelet
    });
  }
}
