<div class="button-section ml-2">
  <p-splitButton [model]="voiceMenuItems" class="p-button-secondary" size="small">
    <ng-template pTemplate="button">
      <img
        [src]="assetUrl('icons/voice.svg')"
        alt="voice"
        class="cursor-pointer"
        pTooltip="Convert to voice"
        tooltipPosition="bottom"
        tooltipStyleClass="text-xs"
        (click)="startTextToSpeech()"
        height="20px"
        width="20px"
      />
    </ng-template>
  </p-splitButton>
</div>
