import { Component } from '@angular/core';

@Component({
  selector: 'app-email-rules',
  standalone: true,
  imports: [],
  templateUrl: './email-rules.component.html',
  styleUrl: './email-rules.component.scss',
})
export class EmailRulesComponent {}
