<div
  id="suite_dashboard_revenueOverview_title-container"
  class="header flex justify-content-between align-items-center"
>
  <div class="title flex align-items-center gap-2">
    <div
      id="suite_dashboard_revenueOverview_icon-container"
      class="icon-revenue border-circle flex justify-content-center align-items-center"
    >
      <fa-icon icon="chart-column" class="avatar text-xs"></fa-icon>
    </div>
    <span id="suite_dashboard_revenueOverview_title" class="text-md font-medium">Revenue overview</span>
  </div>
  @if (!isLoading) {
  <span
    id="suite_dashboard_revenueOverview_commission-badge"
    class="commission-badge border-round-md px-2 py-1 font-semibold"
  >
    Avg per month = ${{ averageRevenue || 0 }}
  </span>
  }
</div>
<div
  id="suite_dashboard_revenueOverview_chart"
  echarts
  [loading]="isLoading"
  [options]="options"
  class="echart w-full"
></div>
