import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { IApiResponseWithData } from '../model/api.model';
import { environment } from '../../environments/environment';
import { IProfileInfo, IUpdate } from '../model/header.model';

@Injectable({
  providedIn: 'root'
})
export class OheaderApi {
  osuite_ApiUrl = environment.apiUrl;
  sso_ApiUrl = environment.apiUrlSSO;

  private httpClient = inject(HttpClient);

  fetchBannerUpdates(): Observable<IUpdate[]> {
    const url = `${this.osuite_ApiUrl}news`;

    return this.httpClient.get<IUpdate[]>(url);
  }

  fetchProfileInfo(): Observable<IApiResponseWithData<IProfileInfo>> {
    const url = `${this.osuite_ApiUrl}api/v2/users/profile`;

    return this.httpClient.get<IApiResponseWithData<IProfileInfo>>(url);
  }

  logout(): Observable<IApiResponseWithData<undefined>> {
    const url = `${this.sso_ApiUrl}api/user/logout`;
    return this.httpClient.post<IApiResponseWithData<undefined>>(url, {});
  }
}
