import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { Button } from 'primeng/button';
import { CountrySelectComponent, OButtonDirective } from 'o-suite-lib';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { NgClass } from '@angular/common';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { UploadProfilePicComponent } from '@app/pages/my-contacts/upload-profile-pic/upload-profile-pic.component';
import {
  IApiResponse,
  IApiResponseData,
  IContactsData,
  ICountryDataWithPhoneNumber,
  IStatesData,
} from '@app/shared/models';
import { ContactsFacade } from '@app/pages/my-contacts/services/contacts.facade';
import { catchError, debounceTime, distinctUntilChanged, mergeMap, of, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-add-contact-dialog',
  standalone: true,
  imports: [
    DialogModule,
    Button,
    OButtonDirective,
    ReactiveFormsModule,
    InputTextModule,
    NgClass,
    InputGroupModule,
    InputGroupAddonModule,
    CountrySelectComponent,
    DropdownModule,
    CalendarModule,
    InputNumberModule,
    UploadProfilePicComponent,
  ],
  templateUrl: './add-contact-dialog.component.html',
  styleUrl: './add-contact-dialog.component.scss',
})
export class AddContactDialogComponent implements OnInit {
  private fb = inject(FormBuilder);
  private destroyRef = inject(DestroyRef);
  private contactsFacade = inject(ContactsFacade);

  @Input('visible') visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();

  submitted: boolean = false;
  contactImageFile: File | null = null;
  addContactForm: FormGroup = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    jobTitle: [''],
    companyName: [''],
    alternateEmailId: ['', [Validators.required, Validators.email]],
    omailEmailId: [''],
    countryId: ['', Validators.required],
    countryCode: [''],
    contactNumber: [null],
    dateOfBirth: [''],
    stateId: [''],
    zipcode: [''],
    address: [''],
    contactPic: [''],
    favStatus: [''],
    badgeId: [0],
    countryCallCode: [''],
  });

  countryList: ICountryDataWithPhoneNumber[] = [];

  states: IStatesData[] = [];

  contactPic: string = '';
  userImageUrl: string = '';
  country: FormControl<any> = new FormControl(null);
  isSelectedStateLoading: boolean = false;
  maxDate: Date = new Date();

  ngOnInit(): void {
    this.contactsFacade.getCountriesList().subscribe((countries: ICountryDataWithPhoneNumber[] | null) => {
      if (countries) {
        this.countryList = countries;
      }
    });

    this.addContactForm
      .get('countryId')
      ?.valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((value) => {
          this.isSelectedStateLoading = true;

          if (value) {
            return value.id;
          }
          return of(false);
        }),
        mergeMap((value) => {
          if (value) {
            return this.contactsFacade.getStatesByCountryId(value.id);
          }
          return of(null);
        })
      )
      .subscribe(
        (res: IApiResponse<IStatesData[]> | null) => {
          if (res && res.body.length > 0) {
            this.states = res.body;
          }
          this.isSelectedStateLoading = false;
        },
        (error) => {
          this.isSelectedStateLoading = false;
        }
      );

    this.country.valueChanges.subscribe((value: any) => {
      if (value) {
        this.addContactForm.get('countryCallCode')?.setValue(value.number);
      }
    });

    this.addContactForm
      .get('alternateEmailId')
      ?.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
        mergeMap((email) => {
          if (email) {
            return this.contactsFacade.getContactInfoByAlternativeEmail(email).pipe(
              catchError((error) => {
                this.addContactForm.get('omailEmailId')?.setValue('');
                return of(null);
              })
            );
          }
          return of(null);
        })
      )
      .subscribe((res: null | IApiResponseData<IContactsData>) => {
        if (res) {
          this.addContactForm.get('omailEmailId')?.setValue(res?.data?.omailEmailId);
        }
      });
  }

  onHide() {
    this.visible = false;
    this.visibleChange.emit(false);
  }

  onSubmitAdd() {
    this.submitted = true;
    if (this.addContactForm.valid && !this.contactsFacade.isAddLoading$()) {
      let fd: FormData = new FormData();
      fd.append(
        'contact',
        JSON.stringify({
          ...this.addContactForm.value,
          countryId: this.addContactForm.get('countryId')?.value?.id,
          stateId: this.addContactForm.get('stateId')?.value?.id ? this.addContactForm.get('stateId')?.value?.id : '',
          contactNumber: this.addContactForm.get('contactNumber')?.value
            ? this.addContactForm.get('contactNumber')?.value?.toString()
            : '',

          dateOfBirth: this.addContactForm.get('dateOfBirth')?.value
            ? this.contactsFacade.formatDate(this.addContactForm.get('dateOfBirth')?.value)
            : '',
        })
      );
      fd.append('alternateEmailId', this.addContactForm.get('alternateEmailId')?.value);

      if (this.contactImageFile) {
        fd.append('file', this.contactImageFile);
      }

      this.contactsFacade.addContact(fd, this.userImageUrl, this.addContactForm.get('countryId')?.value);
    }
  }

  userImageChange(file: File) {
    this.contactImageFile = file;
  }

  userImageUrlChange(userImageUrl: string) {
    this.userImageUrl = userImageUrl;
  }

  isAddLoading = this.contactsFacade.isAddLoading$;
}
