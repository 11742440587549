import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {OToastV2Component} from "o-suite-lib";

@Component({
  selector: 'app-trim',
  standalone: true,
  imports: [CommonModule, RouterOutlet, OToastV2Component],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {}
