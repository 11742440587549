import { enableProdMode, NgZone, provideZoneChangeDetection } from '@angular/core';

import { NavigationStart, provideRouter, Router } from '@angular/router';

import { getSingleSpaExtraProviders, singleSpaAngular } from 'single-spa-angular';

import { singleSpaPropsSubject } from './single-spa/single-spa-props';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { routes } from '@app/app.routes';
import { APP_BASE_HREF } from '@angular/common';
import { AppComponent } from '@app/app.component';
import { bootstrapApplication, provideClientHydration } from '@angular/platform-browser';
import { provideEcharts } from 'ngx-echarts';
import { HttpInterceptorService } from '@app/interceptors/http.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

// if (environment.production) {
enableProdMode();
// }

const lifecycles = singleSpaAngular({
  bootstrapFunction: (singleSpaProps) => {
    singleSpaPropsSubject.next(singleSpaProps);
    return bootstrapApplication(AppComponent, {
      providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        provideHttpClient(withInterceptorsFromDi()),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideAnimationsAsync(),
        provideClientHydration(),
        getSingleSpaExtraProviders(),
        provideEcharts(),
        provideAnimations(),
        provideRouter(routes),
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpInterceptorService,
          multi: true,
        }
      ],
    });
  },
  template: '<app-dashboard />',
  Router,
  NavigationStart,
  NgZone,
  domElementGetter: () => document.getElementById('dashboard-root') as HTMLElement,
});

export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;
