import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ActiveTimelineComponent } from './active-timeline/active-timeline.component';
import { TimelineInfoComponent } from './timeline-info/timeline-info.component';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { ITimeline } from '@app/model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { IApiResponseWithData } from '@app/model/api.model';

@Component({
  selector: 'app-timeline',
  standalone: true,
  imports: [ActiveTimelineComponent, TimelineInfoComponent, CommonModule],
  templateUrl: './timeline.component.html',
  styleUrl: './timeline.component.scss'
})
export class TimelineComponent implements OnInit {
  timelineData: ITimeline[] = [
    {
      transactionId: 0,
      customerName: '',
      bonusEarned: 0,
      createdDate: ''
    }
  ];
  isLoading: boolean = true;
  private osuiteFacade = inject(OsuiteFacade);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.osuiteFacade
      .fetchTimelineData()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res: IApiResponseWithData<ITimeline[]>) => {
          if (res) {
            this.timelineData = res.data;
            this.isLoading = false;
          }
        },
        error: () => {
          this.timelineData = [];
          this.isLoading = false;
        }
      });
  }
}
