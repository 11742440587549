import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

import { EEnvironment } from "./types";
import { O_CONNECT_HOST_URL } from "./envs.constants";
import "./styles.css";

const routes = constructRoutes(microfrontendLayout);

const getCurrentEnv = () => {
  const currentPath = window.location.origin;
  if (currentPath.includes("localhost")) {
    return EEnvironment.local;
  }
  if (currentPath.includes("-dev")) {
    return EEnvironment.dev;
  }
  if (currentPath.includes("-qa")) {
    return EEnvironment.qa;
  }
  if (currentPath.includes("-staging")) {
    return EEnvironment.staging;
  }
  return EEnvironment.prod;
};

const env = getCurrentEnv();
const iFrameUrl = O_CONNECT_HOST_URL[env];

const handleIframeMessage = (event: MessageEvent) => {
  if (event.origin !== iFrameUrl) {
    return; // Ignore messages from unauthorized sources
  }

  const message = event.data as { type: string; url?: string };
  if (message.type === "CHANGE_PARENT_URL" && message.url) {
    window.location.replace(message.url);
  }
};

const createIframe = () => {
  const newIFrame = document.createElement("iframe");
  newIFrame.src = iFrameUrl;
  newIFrame.width = "100%";
  newIFrame.height = "100%";
  newIFrame.allow = "clipboard-read; clipboard-write";
  newIFrame.style.border = "none";
  newIFrame.id = "iframe-o-meet";
  return newIFrame;
};

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    if (name === "@single-spa/welcome") {
      return import(/* webpackIgnore: true */ name);
    }

    // If the app is @onfusion/o-meet, load it in an iframe
    if (name === "@onfusion/o-meet") {
      return new Promise((resolve, reject) => {
        let iframe = null; // Track the latest iframe

        resolve({
          bootstrap: () => Promise.resolve(),
          mount: () => {
            iframe = createIframe(); // Create iframe dynamically

            document.body.appendChild(iframe);
            iframe.onload = () => {
              // Post message to notify the iframe has been mounted
              const iframeOrigin = new URL(iframe.src).origin;
              const checkIframeReady = setInterval(() => {
                if (iframe.contentWindow) {
                  iframe.contentWindow.postMessage(
                    { type: "MEET_MOUNTED" },
                    iframeOrigin
                  );
                  clearInterval(checkIframeReady); // Stop checking once message is sent
                }
              }, 100); // Check every 100ms until contentWindow is available

              // Ensure that we are listening to messages from the current iframe
              window.addEventListener("message", handleIframeMessage);
            };
            return Promise.resolve();
          },
          unmount: () => {
            // When unmounting, we only clean up if the iframe matches
            if (iframe) {
              const iframeOrigin = new URL(iframe.src).origin;
              iframe.contentWindow?.postMessage(
                { type: "MEET_UNMOUNTED" },
                iframeOrigin
              );

              // Clean up iframe from DOM
              iframe.parentElement?.removeChild(iframe);
              iframe = null; // Clear reference

              // Clean up message event listener
              window.removeEventListener("message", handleIframeMessage);
            }
            return Promise.resolve();
          },
        });

        // Reject if iframe fails to load
        iframe.onerror = (e) => reject(e);
      });
    }

    return System.import(/* webpackIgnore: true */ name);
  },
});

const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);

layoutEngine.activate();
start();
