@if (!isLoadingState()) {
  @if (contact) {
    <div class="contact-info">
      <div class="contact-info__header flex flex-row align-items-center justify-content-between pl-3 pr-4 py-3">
        <div class="contact-info__header__name-avatar flex flex-row align-items-center">
          @if (getContactPic(contact)) {
            <img
              [src]="getContactPic(contact)"
              alt="Sender Avatar"
              class="contact-info__header__avatar border-circle"
            />
          } @else {
            <div
              class="contact-info__header__avatar bg-tertiary-50 border border-circle flex justify-content-center align-items-center"
            >
              <fa-icon icon="user" class="text-lg" />
            </div>
          }
          <p class="m-0 ml-2 text-lg font-bold">{{ contact.firstName + ' ' + contact.lastName }}</p>
        </div>
        <div class="contact-info__header__actions flex flex-row align-items-center">
          <p-button
            [outlined]="true"
            class="action-buttons"
            id="mail_contacts_button_sendEmail"
            (onClick)="sendEmail()"
          >
            <fa-icon [icon]="'envelope'" size="lg"></fa-icon>
            <p class="m-0 ml-3">Send email</p>
          </p-button>

          <div #contextTarget class="cursor-pointer border-color-primary-500 border-1 ellipses-bg border-circle ml-3">
            <fa-icon [icon]="'ellipsis'" [size]="'lg'" class="text-color-primary-500" />
          </div>
          <p-contextMenu [target]="contextTarget" [model]="contextMenuItems" [appendTo]="'body'" triggerEvent="click" />
        </div>
      </div>

      <div class="contact-info__details pt-6 pl-6">
        @for (detail of allContactsStructure; track detail.key) {
          <div class="flex flex-row align-items-center">
            <span
              class="contact-icon-bg-color w-2rem h-2rem flex align-items-center justify-content-center border-round-sm"
            >
              @if (detail.key !== 'countryName') {
                <fa-icon [icon]="detail.iconName" size="lg" class="contact-icon-color"></fa-icon>
              } @else {
                <img
                  [src]="environment.COUNTRY_SVG_URL + contact.countryCode.toUpperCase() + '.svg'"
                  alt=""
                  class="border-circle bg-cover flag-img"
                />
              }
            </span>
            <div class="flex flex-column ml-2">
              <p class="m-0 text-xs">{{ detail.name }}</p>
              @if (contact && detail.key !== 'stateId' && detail.key !== 'dateOfBirth') {
                <p class="m-0 text-lg">{{ contact[detail.key] }}</p>
              } @else if (contact && detail.key === 'stateId') {
                <p class="m-0 text-lg">{{ contactState }}</p>
              } @else if (contact && detail.key === 'dateOfBirth') {
                <p class="m-0 text-lg">{{ contact[detail.key] | date }}</p>
              }
            </div>
          </div>
        }
      </div>
    </div>

    @if (isEditModalOpen$()) {
      <app-edit-contact-dialog [(visible)]="isEditModalOpen$" [contact]="contact"></app-edit-contact-dialog>
    }
  } @else {
    <div
      class="bg-white h-screen flex flex-column align-items-center justify-content-center absolute right-0 loader-empty-wrapper"
    >
      <svg-icon name="contact-empty-state" />
      <p class="m-0 font-bold text-2xl text-color-tertiary-500 mt-4">No contacts yet.</p>
      <p class="m-0 text-xl text-color-tertiary-500 mt-2">Add your first contact to get started!</p>
    </div>
  }
} @else {
  <div class="loader-empty-wrapper h-screen absolute right-0">
    <o-loader [bgColor]="'#f8f9fb'" />
  </div>
}

<o-confirm-modal
  [(visible)]="showDeleteConfirmation"
  [modalIcon]="environment.publicPath + '/assets/icons/t-alert.svg'"
  (acceptConfirmation)="deleteContact()"
  (rejectConfirmation)="closeConfirmation()"
  [data]="confirmDeleteData"
></o-confirm-modal>
