<o-toast
  [visible]="toasterService.toasterVisibility()"
  [key]="'register-success'"
  [severity]="toasterService.toasterSeverity()"
  [summary]="toasterService.toasterSummary()"
  (visibleChange)="closeToast()"
></o-toast>

<div class="o-suite-dashboard-container flex">
  <div class="o-suite-dashboard-container-sidebar">
    <app-sidebar />
  </div>
  <div class="o-suite-dashboard-container-body">
    <router-outlet />
  </div>
</div>
@if (showOnbording){
<app-onboarding-tour [visible]="showOnbording" (visibleChange)="handleCloseOnboarding()" />
}
