import { Component, DestroyRef, effect, inject, OnInit } from '@angular/core';
import { DividerModule } from 'primeng/divider';
import { Button } from 'primeng/button';
import { allContactsStructure } from '@app/pages/my-contacts/all-contacts/all-contacts.constant';
import { IContactsData, IStatesData } from '@app/shared/models/contacts.model';
import { EditContactDialogComponent } from '@app/pages/my-contacts/edit-contact-dialog/edit-contact-dialog.component';
import { ContactsFacade } from '@app/pages/my-contacts/services/contacts.facade';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NgIf } from '@angular/common';
import { IconsModule } from '@app/shared/modules/icons.module';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuItem } from 'primeng/api';
import { ConfirmModalComponent, IConfirmDialogData, OLoaderComponent } from 'o-suite-lib';
import { environment } from '../../../../environments/environment';
import { SvgIconComponent } from 'angular-svg-icon';
import { svgIconModule } from '@app/shared/modules/svg-icons.module';
import { ActivatedRoute, Router } from '@angular/router';
import { EMAIL_ACTIONS, MAILBOX_ROUTES } from '@app/constants';
import { mergeMap, of } from 'rxjs';
import { diffDays } from '@fullcalendar/core/internal';
import { IApiResponse, IApiResponseData } from '@app/shared/models';

@Component({
  selector: 'app-all-contacts',
  standalone: true,
  imports: [
    DividerModule,
    Button,
    IconsModule,
    EditContactDialogComponent,
    NgIf,
    ContextMenuModule,
    ConfirmModalComponent,
    OLoaderComponent,
    SvgIconComponent,
    svgIconModule,
  ],
  templateUrl: './all-contacts.component.html',
  styleUrl: './all-contacts.component.scss',
})
export class AllContactsComponent implements OnInit {
  private contactsFacade = inject(ContactsFacade);
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  contact: IContactsData | null = null;
  confirmDeleteData!: IConfirmDialogData;

  showDeleteConfirmation: boolean = false;
  contactState: string = '';

  contextMenuItems: MenuItem[] = [
    {
      label: 'Edit contact',
      command: () => {
        this.openEditContactDialog();
      },
    },
    {
      label: 'Delete contact',
      command: () => {
        this.showDeleteConfirmation = true;
      },
    },
  ];
  private readonly defaultAvatar = 'https://primefaces.org/cdn/primeng/images/demo/avatar/asiyajavayant.png';

  isLoadingState = toSignal(this.contactsFacade.getIsLoadingState$());

  constructor() {
    effect(
      () => {
        const isShowDeleteConfirmation = this.contactsFacade.isShowDeleteConfirmation();
        if (!isShowDeleteConfirmation) {
          this.showDeleteConfirmation = false;
        }
      },
      { allowSignalWrites: true }
    );
  }
  ngOnInit() {
    this.contact = null;
    this.contactsFacade.setIsLoadingState(true);
    this.contactsFacade.getAllContacts();

    this.contactsFacade
      .getSelectedContact()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        mergeMap((contact: IContactsData | null) => {
          if (contact) {
            this.contact = contact;
            this.confirmDeleteData = {
              header: 'Delete Contact',
              message: `Are you sure you want to delete ${this.contact.firstName + ' ' + this.contact.lastName} contact?`,
              acceptBtnLabel: 'Yes',
              rejectBtnLabel: 'No',
            };
          } else {
            this.contact = null;
          }
          const countryId = this.contact?.countryId;
          if (countryId) return this.contactsFacade.getStatesByCountryId(countryId);
          return of(null);
        })
      )
      .subscribe((res: IApiResponse<IStatesData[]> | null) => {
        if (res && res.body && this.contact?.stateId) {
          const selectedState = res.body.find((state: any) => state?.id === this.contact?.stateId);
          if (selectedState) this.contactState = selectedState.name;
        }
      });
  }

  openEditContactDialog() {
    this.contactsFacade.isEditModalOpen$.set(true);
  }

  getContactPic(contact: any): string {
    if (!contact?.contactPic) {
      return '';
    }
    return contact.contactPic.includes('contacts')
      ? environment.cloudfrontBaseUrl + contact.contactPic
      : contact.contactPic;
  }

  handleImageError(contact: any): void {
    contact.contactPic = this.defaultAvatar;
  }

  deleteContact(): void {
    if (this.contact) this.contactsFacade.deleteContact(this.contact);
  }

  closeConfirmation() {
    this.showDeleteConfirmation = false;
  }

  sendEmail() {
    this.router.navigate(['/o-mailo/new-email'], {
      queryParams: { action: EMAIL_ACTIONS.NEW_EMAIL },
    });
  }

  protected readonly allContactsStructure = allContactsStructure;
  protected isEditModalOpen$ = this.contactsFacade.isEditModalOpen$;
  protected readonly environment = environment;
}
