import { Injectable, signal } from '@angular/core';
import { OToastSeverity } from 'o-suite-lib';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  private isVisible = signal<boolean>(false);
  toasterVisibility = this.isVisible.asReadonly();

  private severity = signal<OToastSeverity>('info');
  toasterSeverity = this.severity.asReadonly()!;

  private summary = signal<string>('');
  toasterSummary = this.summary.asReadonly();

  show(message: string) {
    this.isVisible.set(true);
  }

  hide() {
    this.isVisible.set(false);
  }

  setSummary(summary: string) {
    this.summary.set(summary);
  }

  setSeverity(severity: OToastSeverity) {
    this.severity.set(severity);
  }

  setToasterState(isVisible: boolean, severity: OToastSeverity, summary: string) {
    this.setSummary(summary);
    this.setSeverity(severity);
    this.isVisible.set(isVisible);
  }
}
