import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICalendarEventsData, IContactsData } from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class CalendarState {
  calendarEvents = new BehaviorSubject<ICalendarEventsData[] | null>(null);

  setCalendarEvents(eventsData: ICalendarEventsData[]) {
    this.calendarEvents.next(eventsData);
  }

  getCalendarEvents(): Observable<ICalendarEventsData[] | null> {
    return this.calendarEvents.asObservable();
  }
}
