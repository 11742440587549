import { Component, input } from '@angular/core';

@Component({
  selector: 'app-priority-icon',
  standalone: true,
  imports: [],
  templateUrl: './priority-icon.component.html',
  styleUrls: ['./priority-icon.component.scss'],
})
export class PriorityIconComponent {
  color = input();
  highPriority = input(false);
}
