<div class="custom-tree">
  <div class="flex justify-content-between align-items-center flex-1 gap-2 cursor-pointer">
    <div
      class="custom-tree__node py-2 pr-2 flex align-items-center flex-1 gap-2 cursor-pointer"
      (click)="toggleFolder()"
    >
      <fa-icon
        [icon]="isExpanded ? 'chevron-down' : 'chevron-right'"
        class="custom-tree__chevron text-color-tertiary-200"
      ></fa-icon>
      <fa-icon icon="folder-open" class="custom-tree__folder-icon text-color-tertiary-200"></fa-icon>
      <span class="font-normal">Folders</span>
    </div>
    <fa-icon
      icon="plus"
      class="mailbox-sidebar__plus-icon cursor-pointer text-color-primary-500"
      (click)="showCreateFolderDialog($event)"
    ></fa-icon>
  </div>
  @if (isExpanded) {
    <ul class="custom-tree__list m-0 pl-4">
      @for (subNode of treeData; track $index) {
        <li
          class="custom-tree__item flex justify-content-between align-items-center flex-1 cursor-pointer"
          (click)="handleClickOnFolder(subNode)"
        >
          <div class="custom-tree__node py-1 pr-1 flex align-items-center gap-2">
            <div [class]="'custom-tree__node-icon border-circle bg-' + subNode.folderColorCode + '-500'"></div>
            <span class="font-normal text-color-tertiary-500">{{ subNode.folderName }}</span>
          </div>
          <div class="custom-tree__actions flex align-items-center gap-3">
            <fa-icon
              icon="pen"
              class="custom-tree__chevron text-color-tertiary-200 cursor-pointer"
              (click)="showCreateFolderDialog($event, subNode)"
            ></fa-icon>
            @if (loadingDeletedFolder === subNode.folderName) {
              <p-progressSpinner
                styleClass="w-1rem h-1rem"
                strokeWidth="8"
                fill="var(--surface-ground)"
                animationDuration=".5s"
                class="custom-tree__chevron"
              />
            } @else {
              <fa-icon
                icon="trash-can"
                class="custom-tree__chevron text-color-tertiary-200 cursor-pointer"
                (click)="deleteFolder($event, subNode)"
              ></fa-icon>
            }
          </div>
        </li>
      }
    </ul>
  }
</div>
@if (showDialog) {
  <app-create-folder [(display)]="showDialog" [folder]="folderSelected" />
}
