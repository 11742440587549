 import { inject, Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { ErrorHandlerService } from "./error-handler.service";

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
    errorHandlerService = inject(ErrorHandlerService);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                this.errorHandlerService.handleError(error);
                throw error;
            })
        );
    }
}
