import { Component, inject, OnInit, signal, DestroyRef } from '@angular/core';
import { EmailListComponent } from '@app/components/mail/email-list/email-list.component';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { SocketV2Service } from '@app/services/socket-v2.service';
import { Email } from '@app/shared/models/omail';
import { filter, map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-inbox',
  standalone: true,
  imports: [EmailListComponent, RouterOutlet],
  templateUrl: './Inbox.component.html',
  styleUrl: './Inbox.component.scss',
})
export class InboxComponent implements OnInit {
  socketService = inject(SocketV2Service);

  loading = signal(true);
  boxName = 'INBOX';
  listofMails = signal<Email[]>([]);
  router = inject(Router);
  route = inject(ActivatedRoute);
  showEmailDetail = signal(false);
  destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.fetchFolderData();
    this.socketService
      .getMailboxDataStream(this.boxName)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((emailResponse) => {
        this.listofMails.set(emailResponse.emails);
        this.loading.set(false);
      });

    this.route.firstChild?.paramMap
      .pipe(map((paramMap) => paramMap.get('emailId')))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((emailId) => {
        this.showEmailDetail.set(!!emailId);
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.route.firstChild?.snapshot.paramMap.get('emailId')) this.showEmailDetail.set(true);
      });
  }

  private fetchFolderData(): void {
    const cachedData = this.socketService.getFolderData(this.boxName);

    if (cachedData) {
      this.listofMails.set(cachedData.emails);
      this.loading.set(false);
    } else {
      this.loading.set(true);
      this.socketService.requestMailboxData(this.boxName);
    }
  }
}
