<p-dialog [modal]="true" (onHide)="closeDialog()" [(visible)]="visible" [draggable]="false" [dismissableMask]="true">
  <div class="flex flex-column align-items-center justify-content-center share-dialog">
    <p class="mb-2 mt-0 text-center text-3xl font-bold w-full share-dialog__title">Your link is ready!</p>
    <p class="text-xl line-height-2 share-dialog__description">
      Copy the link below to share it or choose a platform to share it to.
    </p>

    <div
      class="share-dialog__link-container p-5 w-full flex flex-column border-round-2xl align-items-center justify-content-center"
    >
      <a [href]="oTrimLink()" target="_blank" class="share-dialog__link text-2xl font-bold text-center">
        {{ oTrimLink() }}
      </a>
      <div class="flex flex-row align-items-center justify-content-center mt-5">
        <p-button label="View link details" [outlined]="true" (onClick)="viewLinkDetails()">
          <img
            [src]="assetUrl('icons/copy-icon.svg')"
            class="mr-2 blue-icon"
            width="14"
            height="16"
            alt="View link details"
          />
        </p-button>
        <p-button [label]="copySuccessMessage" class="ml-3" (onClick)="copyToClipboard(oTrimLink())">
          <img
            [src]="assetUrl('icons/copy-icon.svg')"
            class="mr-2 white-copy-icon"
            width="14"
            height="16"
            alt="View link details"
          />
        </p-button>
      </div>
    </div>

    <!--    <div class="flex align-items-center justify-content-between w-full mt-5">-->
    <!--      <a-->
    <!--        href=""-->
    <!--        target="_blank"-->
    <!--        *ngFor="let elem of CSocialMediaData"-->
    <!--        class="border-round-md flex flex-column cursor-pointer align-items-center justify-content-center no-underline share-dialog__social-link"-->
    <!--      >-->
    <!--        <div class="share-dialog__social-card p-4">-->
    <!--          <img [src]="assetUrl('/icons/' + elem.icon + '.svg')" width="40" alt="" />-->
    <!--        </div>-->
    <!--        <span class="share-dialog__social-card-text text-sm mt-2">{{ elem.name }}</span>-->
    <!--      </a>-->
    <!--    </div>-->
  </div>
</p-dialog>
