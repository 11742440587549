import { Component, inject } from '@angular/core';
import { NgClass } from '@angular/common';

import { IconsModule, svgIconModule } from '@app/shared/modules';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { SidebarSliderComponent } from './components/sidebar-slider/sidebar-slider.component';
import { SidebarService } from './sidebar.service';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    SidebarMenuComponent,
    SidebarSliderComponent,
    OverlayPanelModule,
    svgIconModule,
    IconsModule,
    NgClass,
    TooltipModule,
    ButtonModule
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  sidebarService = inject(SidebarService);

  isCollapsed = this.sidebarService.isSidebarCollapsed;

  onHandleSidebarToggle() {
    this.sidebarService.handleToggleSidebar();
  }
}
