import { Component } from '@angular/core';

@Component({
  selector: 'app-my-email-dashboard',
  standalone: true,
  imports: [],
  templateUrl: './my-email-dashboard.component.html',
  styleUrl: './my-email-dashboard.component.scss',
})
export class MyEmailDashboardComponent {}
