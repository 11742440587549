<p-dialog
  [modal]="true"
  (onHide)="closeDialog()"
  [(visible)]="visible"
  [draggable]="false"
  [dismissableMask]="true"
  id="suite_myProfile_dialog_editProfile"
>
  <h2 class="text-center px-4 font-bold text-3xl mt-2 mb-3">Edit profile</h2>
  <form [formGroup]="profileInfoFrom" id="suite_myProfile_form_editProfile">
    <div class="flex gap-4 px-4">
      <app-upload [userImage]="userImage" />
      <div class="flex-1">
        <h3 class="my-0 text-color-tertiary-500">Details</h3>
        <div class="formgrid grid mt-2">
          <div class="field col col-6 mb-2">
            <label for="firstName" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">
              First name
              <span class="text-xs star text-color-danger-500">*</span>
            </label>
            <input
              type="text"
              pInputText
              class="w-full px-2 py-2 custom-border-radius text-sm"
              formControlName="firstName"
              [ngClass]="{ 'ng-invalid ng-dirty': submitted &&
              profileInfoFrom.get('firstName')?.invalid, }"
            />
          </div>
          <div class="field col col-6 mb-2">
            <label for="lastName" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">
              Last name
              <span class="text-xs star text-color-danger-500">*</span>
            </label>
            <input
              type="text"
              pInputText
              class="w-full px-2 py-2 custom-border-radius text-sm"
              formControlName="lastName"
              [ngClass]="{
              'ng-invalid ng-dirty': submitted && profileInfoFrom.get('lastName')?.invalid,
            }"
            />
          </div>
          <div class="field col col-6 mb-2">
            <label for="omailAddress" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">
              OMail address
            </label>
            <input
              type="text"
              pInputText
              class="w-full px-2 py-2 custom-border-radius text-sm"
              formControlName="omailAddress"
              [ngClass]="{
              'ng-invalid ng-dirty': submitted && profileInfoFrom.get('omailAddress')?.invalid,
            }"
            />
          </div>
          <div class="field col col-6 mb-2">
            <label for="alternateEmail" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">
              Alternate email address
              @if (!profileInfoFrom.get('alternateEmail')?.value){
                <span class="text-xs star" [ngClass]="{
              'text-color-danger-500': submitted && profileInfoFrom.get('alternateEmail')?.invalid,
            }">*</span>
              }
            </label>
            <input
              type="text"
              pInputText
              class="w-full px-2 py-2 custom-border-radius text-sm"
              formControlName="alternateEmail"
              [ngClass]="{
              'ng-invalid ng-dirty': submitted && profileInfoFrom.get('alternateEmail')?.invalid,
            }"
            />
          </div>
          <div class="field col col-6 mb-2">
            <label for="joinedDate" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">
              O-Suite joined date
            </label>
            <input
              type="text"
              pInputText
              class="w-full px-2 py-2 custom-border-radius text-sm"
              formControlName="joinedDate"
              [ngClass]="{
              'ng-invalid ng-dirty': submitted && profileInfoFrom.get('joinedDate')?.invalid,
            }"
            />
          </div>
          <div class="field col col-6 mb-2 h-full">
            <label for="mobileNumber" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">
              Mobile number
              <span class="text-xs star text-color-danger-500">*</span>
            </label>
            <div class="h-full">
              <p-inputGroup>
                <p-inputGroupAddon styleClass="custom-border-radius">
                  <o-country-dropdown [countries]="countryList" [formControl]="country" />
                </p-inputGroupAddon>
                <p-inputNumber
                  [useGrouping]="false"
                  formControlName="mobileNumber"
                  [ngClass]="{
                'ng-invalid ng-dirty': submitted && profileInfoFrom.get('mobileNumber')?.invalid,
              }"
                />
              </p-inputGroup>
            </div>
          </div>
          <div class="field col col-6 mb-2 flex flex-column">
            <label for="countryDetails" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">
              Country
              <span class="text-xs star text-color-danger-500">*</span>
            </label>
            <p-dropdown
              styleClass="w-full px-2 py-2 custom-border-radius  text-sm border-1"
              [options]="countryListSelect"
              optionLabel="countryName"
              placeholder="Select a Country"
              formControlName="countryDetails"
              [ngClass]="{
                'ng-invalid ng-dirty': submitted && profileInfoFrom.get('countryDetails')?.invalid,
              }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-4 mt-2 px-4">
      <div class="w-13rem"></div>
      <div class="flex-1">
        <h3 class="my-0 text-color-tertiary-500">Billing address</h3>
        <div class="formgrid grid mt-2">
          <div class="field col col-6 mb-2">
            <label for="address1" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">
              Address 1
              <span class="text-xs star text-color-danger-500">*</span>
            </label>
            <input
              type="text"
              pInputText
              class="w-full px-2 py-2 custom-border-radius text-sm"
              formControlName="address1"
              [ngClass]="{
              'ng-invalid ng-dirty': submitted && profileInfoFrom.get('address1')?.invalid,
            }"
            />
          </div>
          <div class="field col col-6 mb-2">
            <label for="address2" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">Address 2</label>
            <input
              type="text"
              pInputText
              class="w-full px-2 py-2 custom-border-radius text-sm"
              formControlName="address2"
            />
          </div>
          <div class="field col col-6 mb-2">
            <label for="state" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">
              State/Province/Region
            </label>

            <input
              type="text"
              pInputText
              class="w-full px-2 py-2 custom-border-radius text-sm"
              formControlName="state"
              [ngClass]="{ 'ng-invalid ng-dirty': submitted &&
              profileInfoFrom.get('state')?.invalid, }"
            />
          </div>
          <div class="field col col-6 mb-2">
            <label for="countryBilling" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">
              Country
              <span class="text-xs star text-color-danger-500">*</span>
            </label>
            <p-dropdown
              styleClass="w-full px-2 py-2 custom-border-radius text-sm"
              [options]="countryListSelect"
              optionLabel="countryName"
              placeholder="Select a Country"
              formControlName="countryBilling"
            />
          </div>
          <div class="field col col-6 mb-2">
            <label for="city" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">City</label>

            <input
              type="text"
              pInputText
              class="w-full px-2 py-2 custom-border-radius text-sm"
              formControlName="city"
            />
          </div>
          <div class="field col col-6 mb-2">
            <label for="zipCode" class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">
              Zip code
              <span class="text-xs star text-color-danger-500">*</span>
            </label>
            <input
              type="text"
              pInputText
              class="w-full px-2 py-2 custom-border-radius text-sm"
              formControlName="zipCode"
              [ngClass]="{
              'ng-invalid ng-dirty': submitted && profileInfoFrom.get('zipCode')?.invalid,
            }"
            />
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="flex justify-content-end gap-3 mt-2">
    <p-button label="Cancel" [outlined]="true" styleClass="px-4 py-2 custom-border-radius" (click)="closeDialog()" />
    <p-button
      o-button
      [oButtonSeverity]="'success'"
      label="Save"
      styleClass="px-4 py-2 custom-border-radius"
      type="submit"
      (click)="onSubmit()"
      [loading]="isLoading"
      [disabled]="!this.profileInfoFrom.valid"
    />
  </div>
</p-dialog>
