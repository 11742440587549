import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordMatchValidator(oldPassword: string, newPassword: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const oldPasswordControl = control.get(oldPassword);
    const newPasswordControl = control.get(newPassword);

    if (oldPasswordControl && newPasswordControl) {
      if (oldPasswordControl.value === newPasswordControl.value) {
        const existingErrors = newPasswordControl.errors || {};
        newPasswordControl.setErrors({ ...existingErrors, passwordMatch: true });
        return { passwordMatch: true };
      } else {
        const existingErrors = newPasswordControl.errors;
        if (existingErrors && existingErrors['passwordMatch']) {
          const { passwordMatch: _, ...remainingErrors } = existingErrors;
          if (Object.keys(remainingErrors).length === 0) {
            newPasswordControl.setErrors(null);
          } else {
            newPasswordControl.setErrors(remainingErrors);
          }
        }
      }
    }
    return null;
  };
}
