import { Component, inject, input, OnInit, signal, SimpleChanges } from '@angular/core';
import { EmailHeaderComponent } from './email-header/email-header.component';
import { EmailMessageComponent } from './email-message/email-message.component';
import { CommonModule } from '@angular/common';
import { Email } from '@app/shared/models/omail';
import { ActivatedRoute, Router } from '@angular/router';
import { MAILBOX_ROUTES, TOTAL_EMAILS_IN_PAGE } from '@app/constants';
import { EmailService } from '@app/services/Email.service';
import { formatDate } from '@app/utils/format-date';
import { EmailSkeletonComponent } from './email-skeleton/email-skeleton.component';
import { SocketV2Service } from '@app/services/socket-v2.service';
import { getMailbox } from '@app/utils/mailbox';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-email-list',
  standalone: true,
  imports: [EmailHeaderComponent, EmailMessageComponent, CommonModule, EmailSkeletonComponent],
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.scss'],
})
export class EmailListComponent implements OnInit {
  listofMails = input<Email[]>([]);
  listofMailsValue = this.listofMails();
  loading = input<boolean | null>(false);
  selectedEmails: Email[] = [];
  emailId: string | null = null;
  emailType = '';
  route = inject(ActivatedRoute);
  router = inject(Router);
  emailService = inject(EmailService);
  socketService = inject(SocketV2Service);
  loadingNextPage = toSignal(this.socketService.getLoaderState());
  nextPage = signal(false);

  currentMailbox = '';
  ngOnInit() {
    const segments = this.router.url.split('/');
    const mailIndex = segments.indexOf(MAILBOX_ROUTES.MAIL);
    if (mailIndex !== -1 && mailIndex + 1 < segments.length) {
      if (segments[mailIndex + 1].includes(MAILBOX_ROUTES.NEW_EMAIL)) {
        this.currentMailbox = MAILBOX_ROUTES.INBOX;
        return;
      }
      this.currentMailbox = segments[mailIndex + 1];
      this.route.queryParams.subscribe((params) => {
        if (params['fn']) this.currentMailbox = params['fn'];
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listofMails']) {
      this.listofMailsValue = this.listofMails();
    }
  }

  onEmailClick(email: Email) {
    const currentUrl = this.router.url;

    if (currentUrl.includes(MAILBOX_ROUTES.NEW_EMAIL)) {
      this.router.navigate(['../', MAILBOX_ROUTES.INBOX, email.sno], { relativeTo: this.route });
    } else {
      this.router.navigate([email.sno], { relativeTo: this.route });
    }

    this.emailService.setSelectedEmail(email);
  }

  formatDate(date: string | number): string {
    return formatDate(date);
  }

  trackByEmailId(index: number, email: Email) {
    return email.sno;
  }

  toggleSelect(emailId: number | string) {
    const emailIndex = this.selectedEmails.findIndex((email) => email.sno == emailId);
    if (emailIndex > -1) {
      this.selectedEmails = this.selectedEmails.filter((email) => email.sno != emailId);
    } else {
      const emailToSelect = this.listofMails().find((email) => email.sno == emailId);
      if (emailToSelect) {
        this.selectedEmails = [...this.selectedEmails, emailToSelect];
      }
    }
  }

  toggleSelectAll() {
    if (this.selectedEmails.length > 0) {
      this.selectedEmails = [];
    } else {
      this.selectedEmails = [...this.listofMails()];
    }
  }

  checkIfSelected(emailId: number) {
    return this.selectedEmails.some((email) => email.sno == emailId);
  }

  loadEmails() {
    const currentPage = Math.ceil(this.listofMails().length / TOTAL_EMAILS_IN_PAGE);
    const totalPages = Math.ceil(
      (this.socketService.getFolderDetails().find((folder) => folder.folderName === getMailbox(this.currentMailbox))
        ?.totalMessagesCount ?? 0) / TOTAL_EMAILS_IN_PAGE
    );

    if (currentPage > totalPages || this.loadingNextPage()) return;
    this.nextPage.set(true);
    const mailBoxEmails = this.socketService
      .getFolderDetails()
      .find((folder) => folder.folderName === getMailbox(this.currentMailbox));
    if ((mailBoxEmails?.totalMessagesCount ?? 0) > this.listofMails().length) {
      this.socketService.requestMailboxData(
        getMailbox(this.currentMailbox),
        { size: TOTAL_EMAILS_IN_PAGE, pageNo: currentPage + 1 },
        true
      );
    }
  }

  onScroll(event: Event): void {
    const target = event.target as HTMLElement;
    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 50) {
      this.loadEmails();
    }
  }
}
