<app-sup-sidebar title="Calendar" buttonText="Create Event" (onCreateEvent)="onCreateEvent()">
  <p-calendar
    #datepicker
    class="max-w-full"
    [(ngModel)]="date"
    [inline]="true"
    [minDate]="minDate"
    (onSelect)="updateLibraryCalendar(date)"
  />

  <p-divider />

  <div class="tabs-container flex flex-row align-items-center justify-content-between mb-3">
    @for (tab of tabs; track tab; let i = $index) {
      <div
        class="tabs-container__tab border-round-xl cursor-pointer flex justify-content-center py-3"
        [class.tabs-container__selected]="selectedTab === tab.value"
        (click)="selectTab(tab.value)"
      >
        {{ tab?.key }}
      </div>
    }
  </div>

  <!--  <p-dropdown [options]="options" [(ngModel)]="selectedOption" optionLabel="name" placeholder="Select a City" />-->

  <!--  <div class="tab-view mt-4">-->
  <!--    <p-tabView styleClass="tabview-custom">-->
  <!--      <p-tabPanel header="Header I">-->
  <!--        <ng-template pTemplate="header">-->
  <!--          <fa-icon [icon]="faCalendarDays" size="lg" />-->
  <!--        </ng-template>-->
  <!--        <p>Calendar Calendar Calendar Calendar Calendar...</p>-->
  <!--      </p-tabPanel>-->
  <!--      <p-tabPanel header="Header I">-->
  <!--        <ng-template pTemplate="header">-->
  <!--          <fa-icon [icon]="faCircleXmark" size="lg" />-->
  <!--        </ng-template>-->
  <!--        <p>CircleXmark CircleXmark CircleXmark CircleXmark CircleXmark...</p>-->
  <!--      </p-tabPanel>-->
  <!--      <p-tabPanel header="Header I">-->
  <!--        <ng-template pTemplate="header">-->
  <!--          <fa-icon [icon]="faCircleCheck" size="lg" />-->
  <!--        </ng-template>-->
  <!--        <p>CircleCheck CircleCheck CircleCheck CircleCheck CircleCheck...</p>-->
  <!--      </p-tabPanel>-->
  <!--    </p-tabView>-->
  <!--  </div>-->
</app-sup-sidebar>
