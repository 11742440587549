// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --container-background: #f7fbff;
  --text-color: #344f60;
  --button-green: #22b57e;
  --border-radius-custom: 15px;
  --primary: #00acfb;
  --border-custom: 1px solid #bfc9d5;
  --light-pink: #ed389c;
  --custom-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
}

p,
h1,
h2,
h3 {
  color: var(--text-color);
}

html,
body {
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  background-color: #f8f9fb;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body::-webkit-scrollbar {
  width: 8px;
  background-color: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}

::ng-deep .p-dialog-mask {
  z-index: 1300 !important;
}`, "",{"version":3,"sources":["webpack://./src/styles.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,qBAAA;EACA,uBAAA;EACA,4BAAA;EACA,kBAAA;EACA,kCAAA;EACA,qBAAA;EACA,oDAAA;AACF;;AACA;;;;EAIE,wBAAA;AAEF;;AACA;;EAEE,YAAA;EACA,SAAA;EACA,sBAAA;EACA,kBAAA;AAEF;;AACA;EACE,yBAAA;AAEF;;AACA;;;EAGE,mBAAA;AAEF;;AACA;EACE,UAAA;EACA,yBAAA;AAEF;;AACA;EACE,mBAAA;EACA,kBAAA;AAEF;;AACA;EACE,mBAAA;AAEF;;AACA;EACE,wBAAA;AAEF","sourcesContent":[":root {\n  --container-background: #f7fbff;\n  --text-color: #344f60;\n  --button-green: #22b57e;\n  --border-radius-custom: 15px;\n  --primary: #00acfb;\n  --border-custom: 1px solid #bfc9d5;\n  --light-pink: #ed389c;\n  --custom-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);\n}\np,\nh1,\nh2,\nh3 {\n  color: var(--text-color);\n}\n\nhtml,\nbody {\n  height: 100%;\n  margin: 0;\n  box-sizing: border-box;\n  overflow-x: hidden;\n}\n\nbody {\n  background-color: #f8f9fb;\n}\n\n*,\n*::before,\n*::after {\n  box-sizing: inherit;\n}\n\nbody::-webkit-scrollbar {\n  width: 8px;\n  background-color: #f1f1f1;\n}\n\nbody::-webkit-scrollbar-thumb {\n  background: #c1c1c1;\n  border-radius: 4px;\n}\n\nbody::-webkit-scrollbar-thumb:hover {\n  background: #a1a1a1;\n}\n\n::ng-deep .p-dialog-mask {\n  z-index: 1300 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
