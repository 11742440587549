import { inject, Injectable, signal } from '@angular/core';
import { ContactsState } from '@app/pages/my-contacts/services/contacts.state';
import {
  IContactsData,
  ICountryData,
  ICountryDataWithPhoneNumber,
  IStatesData,
} from '@app/shared/models/contacts.model';
import { Observable } from 'rxjs';
import { ContactsApi } from '@app/pages/my-contacts/services/contacts.api';
import { IApiResponse, IApiResponseData } from '@app/shared/models';
import { OToastV2Service, ToasterService } from 'o-suite-lib';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactsFacade {
  private contactsState = inject(ContactsState);
  private contactsApi = inject(ContactsApi);
  private oToastV2Service = inject(OToastV2Service);

  isEditLoading$ = signal(false);
  isAddLoading$ = signal(false);
  isEditModalOpen$ = signal(false);
  isAddModalOpen$ = signal(false);
  isShowDeleteConfirmation$ = signal(false);
  readonly isShowDeleteConfirmation = this.isShowDeleteConfirmation$.asReadonly();

  setSelectedContact(contact: IContactsData) {
    this.contactsState.setSelectedContact(contact);
  }

  getSelectedContact(): Observable<IContactsData | null> {
    return this.contactsState.getSelectedContact();
  }

  getAllContacts() {
    this.contactsApi.getAllContacts().subscribe((contacts: IApiResponse<IContactsData[]>) => {
      this.setIsLoadingState(false);
      this.setAllContactsData(contacts?.body);
    });
  }

  setAllContactsData(contacts: IContactsData[]) {
    this.contactsState.setAllContactsData(contacts);
  }

  getAllContactsData(): Observable<IContactsData[] | null> {
    return this.contactsState.getAllContactsData();
  }

  getCountries() {
    this.contactsApi.getCountries().subscribe((countries: IApiResponse<ICountryData[]>) => {
      const countryList = countries.body.map((country: ICountryData) => ({
        flag_32: country.flag_32,
        id: country.id,
        round_flag: country.round_flag,
        name: country.name,
        code: country.iso2,
        number: `+${country.phoneCode}`,
      }));
      this.contactsState.setCountriesList(countryList);
    });
  }

  editContact(formData: FormData, userImageUrl: string, country: ICountryDataWithPhoneNumber) {
    this.isEditLoading$.set(true);
    this.contactsApi.editContact(formData).subscribe(
      (res) => {
        let contact = formData.get('contact');
        if (contact && typeof contact === 'string') {
          this.editContactsData(JSON.parse(contact), userImageUrl, country);
        }
        this.isEditLoading$.set(false);
        this.isEditModalOpen$.set(false);
        this.isAddModalOpen$.set(false);
        this.oToastV2Service.add({
          severity: 'success',
          summary: res?.message,
          icon: environment.publicPath + '/assets/icons/toast/success.svg',
        });
      },
      (error) => {
        console.error(error);
        this.isEditLoading$.set(false);
      }
    );
  }

  addContact(formData: FormData, userImageUrl: string, country: ICountryDataWithPhoneNumber) {
    this.isAddLoading$.set(true);
    this.contactsApi.editContact(formData).subscribe(
      (res) => {
        let contact = formData.get('contact');
        if (contact && typeof contact === 'string') {
          this.addContactsData(JSON.parse(contact), userImageUrl, country);
        }
        this.isAddLoading$.set(false);
        this.isAddModalOpen$.set(false);
        this.oToastV2Service.add({
          severity: 'success',
          summary: res?.message,
          icon: environment.publicPath + '/assets/icons/toast/success.svg',
        });
      },
      (error) => {
        console.error(error);
        this.isAddLoading$.set(false);
      }
    );
  }

  addContactsData(contact: IContactsData, userImageUrl: string, country: ICountryDataWithPhoneNumber) {
    this.contactsState.addContactsData(contact, userImageUrl, country);
  }

  editContactsData(contact: IContactsData, userImageUrl: string, country: ICountryDataWithPhoneNumber) {
    this.contactsState.editContactsData(contact, userImageUrl, country);
  }

  getCountriesList(): Observable<ICountryDataWithPhoneNumber[] | null> {
    return this.contactsState.getCountriesList();
  }

  setIsLoadingState(state: boolean) {
    this.contactsState.setIsLoadingState(state);
  }

  getIsLoadingState$(): Observable<boolean> {
    return this.contactsState.getIsLoadingState();
  }

  deleteContact(contact: IContactsData) {
    this.isShowDeleteConfirmation$.set(true);
    this.contactsApi.deleteContact([contact.contactId]).subscribe(
      (res) => {
        this.isShowDeleteConfirmation$.set(false);
        this.contactsState.deleteContact(contact);
        this.oToastV2Service.add({
          severity: 'success',
          summary: res?.message,
          icon: environment.publicPath + '/assets/icons/toast/success.svg',
        });
      },
      (error) => {
        this.isShowDeleteConfirmation$.set(false);
      }
    );
  }

  addRemoveContactFromFavorite(contact: IContactsData) {
    this.contactsApi.addRemoveContactFromFavorite([contact.contactId]).subscribe(
      (res) => {
        contact.isFavoriteLoading = false;
        this.contactsState.addRemoveContactFromFavorite(contact);
        this.oToastV2Service.add({
          severity: 'success',
          summary: res?.message,
          icon: environment.publicPath + '/assets/icons/toast/success.svg',
        });
      },
      (error) => {
        contact.favStatus = Number(!contact.favStatus);
        this.contactsState.addRemoveContactFromFavorite(contact);
        contact.isFavoriteLoading = false;
      }
    );
  }

  getStatesByCountryId(countryId: number): Observable<IApiResponse<IStatesData[]>> {
    return this.contactsApi.getStatesByCountryId(countryId);
  }

  getContactInfoByAlternativeEmail(alternateEmailId: string): Observable<IApiResponseData<IContactsData>> {
    return this.contactsApi.getContactInfoByAlternativeEmail(alternateEmailId);
  }

  formatDate(inputDate: string): string {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month}-${day}`;
  }
}
