<div class="my-contact p-3 flex flex-column gap-3 h-full">
  <div class="flex justify-content-between align-items-center">
    <div class="flex gap-2 align-items-center mt-1">
      <svg-icon name="my-contacts" class="menu-icon mt-1" />
      <span class="text-lg font-bold">My Contacts</span>
    </div>
  </div>
  <div>
    <app-filter-options [filter]="filter" (filterChange)="filterChange($event)" />
  </div>
  <div class="flex gap-3 h-full contacts-container">
    <div class="w-3 h-full max-h-full">
      <app-contact-list
        class="h-full"
        [contactList]="contactsList"
        (selectedContactChange)="handleSelectContact($event)"
        (handleFavouriteContact)="handleFavouriteContact($event)"
        [selectedContact]="selectedContact"
        (valueChange)="handleSearchChange($event)"
        [isLoading]="isContactsLoading"
      />
    </div>

    <div class="w-9">
      @if(contactsList?.records?.length){
      <app-contact-card [selectedContact]="selectedContact" (isContactDeleted)="handleRefetchAfterDelete()" />
      } @else {
      <div
        class="w-full h-full flex-1 flex flex-column justify-content-center align-items-center bg-white shadow-1 border-round-lg"
      >
        <svg-icon name="empty-contacts" />
        <p class="mt-3 mb-0 font-semibold text-xl">No contacts yet.</p>
        <p class="my-0 text-sm">Add your first contact to get started!</p>
        <p-button
          (click)="handleOpenEditContact()"
          o-button
          [oButtonSeverity]="'success'"
          label="Add contact"
          styleClass="px-4 py-2 custom-border-radius mt-3"
        />
      </div>
      }
    </div>
  </div>
</div>
@if(isEditModalOpen){
<app-edit-contact-modal [visible]="isEditModalOpen" (visibleChange)="handleCloseEditContact()" />
}
