<div
  id="suite_dashboard_total-revenue_card"
  class="total-revenue-card shadow-1 p-3 pb-0 surface-card border-round-lg flex flex-column gap-2 justify-content-between relative h-full w-full"
>
  <div id="suite_dashboard_total-revenue_header" class="header flex justify-content-between align-items-center">
    <!-- Title Section -->
    <div class="title flex align-items-center gap-2">
      <div
        id="suite_dashboard_total-revenue_icon"
        class="icon-revenue border-circle flex align-items-center justify-content-center"
      >
        <svg-icon name="revenue" />
      </div>
      <span id="suite_dashboard_total-revenue_title" class="text-md font-medium">Total revenue</span>
    </div>
  </div>
  <div id="suite_dashboard_total-revenue_content" class="content flex flex-column align-items-start mt-3">
    @if(!isLoading){
    <div
      id="suite_dashboard_div_total-revenue_amount"
      class="revenue-amount text-6xl font-bold mb-3 absolute text-center pointer-events-none z-1"
    >
      ${{ totalRevenue || 0 }}
    </div>
    }

    <div class="chart-container w-full mt-2">
      <div
        #chartDiv
        id="suite_dashboard_total-revenue_chart"
        [loading]="isLoading"
        echarts
        [options]="options"
        class="echart"
      ></div>
    </div>
  </div>
</div>
