import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { ICustomerOrder } from '@app/model';
import { TableModule } from 'primeng/table';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize } from 'rxjs';
import { CustomerTransactionEmptyComponent } from './customer-transaction-empty/customer-transaction-empty.component';
import { svgIconModule } from '@app/shared/modules';
import { SkeletonModule } from 'primeng/skeleton';
import { IApiResponseWithData } from '@app/model/api.model';

@Component({
  selector: 'app-customer-transaction-view',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ScrollPanelModule,
    CustomerTransactionEmptyComponent,
    svgIconModule,
    SkeletonModule
  ],
  templateUrl: './customer-transaction-view.component.html',
  styleUrl: './customer-transaction-view.component.scss'
})
export class CustomerTransactionViewComponent {
  myEarnings: ICustomerOrder[] = [
    {
      transactionId: 0,
      referralName: '',
      type: '',
      withdrawable: 0,
      paidamount: 0,
      planName: '',
      bonusEarned: 0,
      createdDate: '',
      productName: '',
      profilePic: '',
      nonwithdrawable: 0
    },
    {
      transactionId: 0,
      referralName: '',
      type: '',
      withdrawable: 0,
      paidamount: 0,
      planName: '',
      bonusEarned: 0,
      createdDate: '',
      productName: '',
      profilePic: '',
      nonwithdrawable: 0
    }
  ];

  private osuiteFacade = inject(OsuiteFacade);
  private destroyRef = inject(DestroyRef);
  isLoading: boolean = true;

  ngOnInit(): void {
    this.osuiteFacade
      .fetchMyTotalEarnings(0, 10, '', '', 0, '')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res: IApiResponseWithData<{ commissionDetails: ICustomerOrder[] }>) => {
          if (res) {
            this.isLoading = false;
            const data = res.data?.commissionDetails;
            data.reverse();
            this.myEarnings = data;
          }
        },
        error: () => {
          this.isLoading = false;
          this.myEarnings = [];
        }
      });
  }
}
