import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { OnboardingTourComponent } from './components/onboarding/onboarding-tour/onboarding-tour.component';

import { SidebarComponent } from './core/sidebar/sidebar.component';
import { ToasterComponent } from 'o-suite-lib';
import { ToasterService } from '@app/services/toaster.service';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { ApiResponse } from '@app/components/onboarding/models/onboarding-step.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [RouterOutlet, SidebarComponent, OnboardingTourComponent, ToasterComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public toasterService = inject(ToasterService);
  private destroyRef = inject(DestroyRef);
  private osuiteFacade = inject(OsuiteFacade);
  showOnbording: boolean = false;

  ngOnInit(): void {
    this.osuiteFacade
      .fetchFirstLogin()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: ApiResponse) => {
        this.showOnbording = res?.body?.isFirstLogin;
      });
  }

  handleCloseOnboarding() {
    this.showOnbording = false;
  }

  closeToast() {
    this.toasterService.hide();
  }
}
