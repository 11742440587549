import { CommonModule } from '@angular/common';
import { Component, effect, inject, signal } from '@angular/core';
import { EChartsOption } from 'echarts';
import { provideEcharts } from 'ngx-echarts';
import { ButtonModule } from 'primeng/button';
import { DataViewModule } from 'primeng/dataview';
import { MapChartComponent } from '../../shared/shared-components/charts/map-chart/map-chart.component';
import { PieChartComponent } from '../../shared/shared-components/charts/pie-chart/pie-chart.component';
import { CityListViewComponent } from '../../shared/shared-components/city-list-view/city-list-view.component';
import { ISocialListItemModel, ICountryCode, ICityChart, ICountryChart, INameValue, IDashboard } from '../../models';
import { SocialListViewComponent } from '../../shared/shared-components/social-list-view/social-list-view.component';
import { OtrimFacade } from '../../services/otrim.facade';

@Component({
  selector: 'app-statics',
  standalone: true,
  imports: [
    CommonModule,
    DataViewModule,
    ButtonModule,
    MapChartComponent,
    PieChartComponent,
    CityListViewComponent,
    SocialListViewComponent,
  ],
  providers: [provideEcharts()],
  templateUrl: './statics.component.html',
  styleUrl: './statics.component.scss',
})
export class StaticsComponent {
  private otrimFacade = inject(OtrimFacade);

  pieChartDeviceOptions = signal<EChartsOption>({});
  pieChartBrowserOptions = signal<EChartsOption>({});
  worldMapChartData = signal<any>(null);

  private statistics = this.otrimFacade.getDashboardStatistics();

  cities!: ICityChart[];
  socialsData: ISocialListItemModel[] | null = null;

  constructor() {
    effect(() => {
      if (!this.statistics()) return;
      const stat = {...this.statistics()} as IDashboard;
      this.setCities(stat.cityChart);
      this.setSocialsData(stat.sharesChart);
      this.setWorldMapChartData(stat.countryChart);
      this.setPieChartDeviceOptions(stat.deviceChart);
      this.setPieChartBrowserOptions(stat.browserChart);
    }, {allowSignalWrites: true});
  }

  private setCities(cityChart: ICityChart[]): void {
    this.cities = cityChart;
  }

  private setSocialsData(sharesChart: ISocialListItemModel[]): void {
    this.socialsData = sharesChart?.filter((item: ISocialListItemModel) => item.socialSource !== 'OTHER');
  }

  private setWorldMapChartData(countryChart: ICountryChart[]): void {
    const filteredData = countryChart?.filter(({ countryCode }: ICountryCode) => !!countryCode);
    this.worldMapChartData.set([...filteredData]);
  }

  private setPieChartDeviceOptions(deviceChart: any[] | null): void {
    const totalDevice = deviceChart?.reduce((sum: number, item: any) => sum + item.value, 0) || 0;

    this.pieChartDeviceOptions.set({
      title: {
        text: 'Device Type',
        textStyle: {
          color: '#344f60',
        },
      },
      tooltip: {
        trigger: 'item',
      },
      legend: [
        {
          itemGap: 18,
          orient: 'vertical',
          left: 'left',
          bottom: 30,
          align: 'auto',
          formatter: function (name: string) {
            return name;
          },
          textStyle: {
            fontWeight: 500,
            color: '#344F60',
            fontSize: 16,
          },
        },
        {
          itemGap: 18,
          orient: 'vertical',
          left: 'right',
          bottom: 30,
          formatter: function (name: string) {
            const item = deviceChart?.find((d: any) => d.name === name);
            if (item) {
              const percentage = ((item.value / totalDevice) * 100).toFixed(2);
              return `${Math.ceil(parseInt(percentage))}%`;
            }
            return name;
          },
          textStyle: {
            fontWeight: 700,
            color: '#344F60',
            fontSize: 16,
          },
          icon: 'none',
        },
      ],
      series: [
        {
          height: '75%',
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: deviceChart || [],
        },
      ]
    });
  }

  private setPieChartBrowserOptions(browserChart: INameValue[] | null): void {
    const totalBrowser = browserChart?.reduce((sum: number, item: any) => sum + item.value, 0) || 0;
    this.pieChartBrowserOptions.set({
      title: {
        text: 'Browser Type',
        textStyle: {
          color: '#344f60',
        },
      },
      tooltip: {
        trigger: 'item',
      },
      legend: [
        {
          itemGap: 18,
          orient: 'vertical',
          left: 'left',
          bottom: 0,
          formatter: function (name: string) {
            return name;
          },
          textStyle: {
            fontWeight: 500,
            color: '#344F60',
            fontSize: 16,
          },
        },
        {
          itemGap: 18,
          orient: 'vertical',
          left: 'right',
          bottom: 0,
          formatter: function (name: string) {
            const item = browserChart?.find((d: any) => d.name === name);
            if (item) {
              const percentage = ((item.value / totalBrowser) * 100).toFixed(2);
              return `${Math.ceil(parseInt(percentage))}%`;
            }
            return name;
          },
          textStyle: {
            fontWeight: 700,
            color: '#344F60',
            fontSize: 16,
          },
          icon: 'none',
        },
      ],
      series: [
        {
          height: '75%',
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: browserChart || [],
        },
      ],
    });
  }
}
