<p-dialog
  [(visible)]="showDialog"
  [modal]="true"
  [contentStyle]="{ overflow: 'visible' }"
  [draggable]="false"
  [resizable]="false"
  (onHide)="cancel()"
  class="move-folder-modal"
>
  <!-- Folder Options -->
  <div class="folder-list">
    <div class="title text-3xl font-bold text-center mb-6">Move to folder</div>
    <div class="overflow-auto max-h-20rem py-3">
      @for (folder of folderList; track $index) {
        <div
          [class]="
            'folder-option flex align-items-center gap-2 p-3 border-round cursor-pointer mb-3 mx-4  bg-' +
            folder.folderColorCode +
            '-50'
          "
          [ngClass]="{ 'selected-folder': selectedFolder?.folderName === folder.folderName }"
          (click)="selectFolder(folder)"
        >
          <span [class]="'folder-dot bg-' + folder.folderColorCode + '-500'"></span>
          <span class="folder-name font-semibold">{{ folder.folderName }}</span>
        </div>
      }
    </div>
  </div>

  <!-- Add New Folder -->
  <div
    class="add-folder flex align-items-center font-semibold gap-2 mt-3 cursor-pointer text-color-primary-500 mx-4"
    (click)="addNewFolder()"
  >
    <i class="pi pi-plus"></i>
    <span>Add new folder</span>
  </div>

  <!-- Footer Buttons -->
  <div class="flex justify-content-end gap-3 mx-4 mt-8">
    <p-button
      o-button
      label="Cancel"
      [outlined]="true"
      class="p-button-outlined p-button-info w-8rem mr-5"
      (click)="cancel()"
    ></p-button>

    <p-button
      o-button
      label="Copy"
      [outlined]="true"
      (click)="performAction('copy')"
      class="w-8rem"
      [loading]="loader['copy']"
      [disabled]="!selectedFolder"
    ></p-button>

    <p-button
      o-button
      class="action-btn w-8rem"
      [label]="'Move'"
      [oButtonSeverity]="'success'"
      [disabled]="!selectedFolder"
      (click)="performAction('move')"
      [loading]="loader['move']"
    ></p-button>
  </div>
</p-dialog>
