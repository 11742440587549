<div class="bg-white border-round-lg shadow-1 h-full">
  <div class="flex justify-content-between align-items-center p-3">
    <div class="flex gap-3 align-items-center">
      @if (selectedContact?.contactPic?.includes('contacts')) {
      <p-avatar
        [image]="environment.imgHostUrl + selectedContact?.contactPic"
        styleClass="mr-2"
        size="large"
        shape="circle"
      ></p-avatar>
      } @else {
      <p-avatar size="large" shape="circle"><fa-icon icon="user" class="text-white" /></p-avatar>
      }
      <p class="my-0 font-bold text-2xl">{{ selectedContact?.firstName + ' ' + selectedContact?.lastName }}</p>
    </div>
    <div class="flex gap-3 align-items-center">
      <p-button [outlined]="true" styleClass="border-round-2xl">
        <div class="flex gap-2 align-items-center">
          <fa-icon icon="envelope" />
          Email {{ selectedContact?.firstName }}
        </div>
      </p-button>
      <p-button [outlined]="true" [rounded]="true" (click)="op.toggle($event)" class="contact-action-button">
        <fa-icon icon="ellipsis" />
      </p-button>
      <p-overlayPanel #op>
        <div class="flex flex-column gap-1 w-9rem">
          <div class="text-xs actions-label p-2 border-round-lg">Actions</div>
          <div
            class="cursor-pointer contact-dropdown-item text-base p-2 border-round-lg text-color-tertiary-500 text-sm"
            (click)="op.toggle($event); handleOpenEditContact(selectedContact)"
          >
            Edit
          </div>
          <div
            class="cursor-pointer contact-dropdown-item text-base p-2 border-round-lg text-color-tertiary-500 text-sm"
            (click)="op.toggle($event); handleDeleteContact()"
          >
            Delete
          </div>
        </div>
      </p-overlayPanel>
    </div>
  </div>
  <p-divider styleClass="my-0" />
  <div class="p-3">
    <div>
      <h3>Contact info</h3>
      <div class="flex">
        <div class="w-2 flex gap-3 align-items-center">
          <div
            class="bg-info-50 border-round-lg text-primary-500 w-2rem h-2rem flex justify-content-center align-items-center"
          >
            <fa-icon icon="user" />
          </div>
          <div>
            <p class="my-0 text-xs contact-label">First name</p>
            @if(selectedContact?.firstName){
            <p class="my-0 text-lg font-medium">{{ selectedContact?.firstName }}</p>
            } @else {
            <p class="my-0 font-italic text-xs">{{ 'First name not available' }}</p>
            }
          </div>
        </div>
        <div class="w-2 flex gap-3 align-items-center">
          <div
            class="bg-info-50 border-round-lg text-primary-500 w-2rem h-2rem flex justify-content-center align-items-center"
          >
            <fa-icon icon="user" />
          </div>
          <div>
            <p class="my-0 text-xs contact-label">Last name</p>
            @if(selectedContact?.lastName){
            <p class="my-0 text-lg font-medium">{{ selectedContact?.lastName }}</p>
            }@else{
            <p class="my-0 font-italic text-xs">{{ 'Last name not available' }}</p>
            }
          </div>
        </div>
        <div class="w-4 flex gap-3 align-items-center">
          <div
            class="bg-info-50 border-round-lg text-primary-500 w-2rem h-2rem flex justify-content-center align-items-center"
          >
            <fa-icon icon="calendar" />
          </div>
          <div>
            <p class="my-0 text-xs contact-label">Date of birth</p>
            @if(selectedContact?.dateOfBirth){
            <p class="my-0 text-lg font-medium">{{ selectedContact?.dateOfBirth  | date:'MMMM d, y'}}</p>
            }@else {
            <p class="my-0 font-italic text-xs">{{ 'Date of birth not available' }}</p>
            }
          </div>
        </div>
        <div class="w-4 flex gap-3 align-items-center">
          <div
            class="bg-info-50 border-round-lg text-primary-500 w-2rem h-2rem flex justify-content-center align-items-center"
          >
            <fa-icon icon="phone" />
          </div>
          <div>
            <p class="my-0 text-xs contact-label">Phone number</p>
            @if(selectedContact?.contactNumber){
            <p class="my-0 text-lg font-medium">
              {{ selectedContact?.contactNumber }}
            </p>
            }@else{
            <p class="my-0 font-italic text-xs">
              {{ 'Phone number not available' }}
            </p>
            }
          </div>
        </div>
      </div>
      <div class="flex mt-4">
        <div class="w-2 flex gap-3 align-items-center">
          <div
            class="bg-info-50 border-round-lg text-primary-500 w-2rem h-2rem flex justify-content-center align-items-center"
          >
            <fa-icon icon="phone-volume" />
          </div>
          <div>
            <p class="my-0 text-xs contact-label">Company name</p>
            @if(selectedContact?.companyName){
            <p class="my-0 text-lg font-medium">{{ selectedContact?.companyName }}</p>
            }@else {
            <p class="my-0 font-italic text-xs">{{ 'Company name not available' }}</p>
            }
          </div>
        </div>
        <div class="w-2 flex gap-3 align-items-center">
          <div
            class="bg-info-50 border-round-lg text-primary-500 w-2rem h-2rem flex justify-content-center align-items-center"
          >
            <fa-icon icon="briefcase" />
          </div>
          <div>
            <p class="my-0 text-xs contact-label">Job title</p>
            @if(selectedContact?.jobTitle){
            <p class="my-0 text-lg font-medium">{{ selectedContact?.jobTitle }}</p>
            }@else {
            <p class="my-0 font-italic text-xs">{{ 'Job title not available' }}</p>
            }
          </div>
        </div>
        <div class="w-4 flex gap-3 align-items-center">
          <div
            class="bg-info-50 border-round-lg text-primary-500 w-2rem h-2rem flex justify-content-center align-items-center"
          >
            <fa-icon icon="envelope" />
          </div>
          <div>
            <p class="my-0 text-xs contact-label">OMail address</p>
            @if(selectedContact?.omailEmailId){
            <p class="my-0 text-lg font-medium">
              {{ selectedContact?.omailEmailId }}
            </p>
            }@else {
            <p class="my-0 font-italic text-xs">
              {{ 'O-Mail address not available' }}
            </p>
            }
          </div>
        </div>
        <div class="w-4 flex gap-3 align-items-center">
          <div
            class="bg-info-50 border-round-lg text-primary-500 w-2rem h-2rem flex justify-content-center align-items-center"
          >
            <fa-icon icon="envelope" />
          </div>
          <div>
            <p class="my-0 text-xs contact-label">Alternative email</p>
            @if(selectedContact?.alternateEmailId){
            <p class="my-0 text-lg font-medium">
              {{ selectedContact?.alternateEmailId }}
            </p>
            }@else {
            <p class="my-0 font-italic text-xs">
              {{ 'Alternative email not available' }}
            </p>
            }
          </div>
        </div>
      </div>
    </div>
    <div>
      <h3>Address</h3>
      <div class="flex">
        <div class="w-2 flex gap-3 align-items-center">
          <div
            class="bg-info-50 border-round-lg text-primary-500 w-2rem h-2rem flex justify-content-center align-items-center"
          >
            @if (selectedContact?.countryCode) {
              <img class="w-2rem h-2rem border-round-md country-flag"  [alt]="''"
                src="{{COUNTRY_SVG_URL + selectedContact?.countryCode+'.svg'}}"/>
            } @else {
              <fa-icon icon="location-dot" />
            }
          </div>
          <div>
            <p class="my-0 text-xs contact-label">Country</p>
            @if(selectedContact?.countryName){
            <p class="my-0 text-lg font-medium">{{ selectedContact?.countryName }}</p>
            }@else {
            <p class="my-0 font-italic text-xs">{{ 'Country not available' }}</p>
            }
          </div>
        </div>
        <div class="w-2 flex gap-3 align-items-center">
          <div
            class="bg-info-50 border-round-lg text-primary-500 w-2rem h-2rem flex justify-content-center align-items-center"
          >
            <fa-icon icon="location-dot" />
          </div>
          <div>
            <p class="my-0 text-xs contact-label">State</p>
            @if(stateName){
            <p class="my-0 text-lg font-medium">{{ stateName }}</p>
            }@else {
            <p class="my-0 font-italic text-xs">{{ 'State not available' }}</p>
            }
          </div>
        </div>
        <div class="w-2 flex gap-3 align-items-center">
          <div
            class="bg-info-50 border-round-lg text-primary-500 w-2rem h-2rem flex justify-content-center align-items-center"
          >
            <fa-icon icon="location-dot" />
          </div>
          <div>
            <p class="my-0 text-xs contact-label">Zipcode</p>
            @if(selectedContact?.zipcode){
            <p class="my-0 text-lg font-medium">{{ selectedContact?.zipcode }}</p>
            }@else {
            <p class="my-0 font-italic text-xs">{{ 'Zipcode not available' }}</p>
            }
          </div>
        </div>
      </div>
      <div class="flex mt-4">
        <div class="flex gap-3 align-items-center">
          <div
            class="bg-info-50 border-round-lg text-primary-500 w-2rem h-2rem flex justify-content-center align-items-center"
          >
            <fa-icon icon="location-dot" />
          </div>
          <div>
            <p class="my-0 text-xs contact-label">Address</p>
            @if(selectedContact?.address){
            <p class="my-0 text-lg font-medium">{{ selectedContact?.address }}</p>
            }@else {
            <p class="my-0 font-italic text-xs">{{ 'Address not available' }}</p>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
