import { inject, Injectable } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IApiResponseData } from '@app/shared/models';
import { IMyProfileData } from '@app/shared/models/settings.model';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsApi {
  oesUserApiUrl = environment.oesUserApiUrl;

  private httpClient = inject(HttpClient);

  private readonly options = {
    withCredentials: true,
  };

  getProfileInfo(): Observable<IApiResponseData<IMyProfileData>> {
    return this.httpClient.get<IApiResponseData<IMyProfileData>>(
      this.oesUserApiUrl + 'api/v2/users/profile',
      this.options
    );
  };
}
