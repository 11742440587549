import { Component, inject, OnInit } from '@angular/core';
import { DividerModule } from 'primeng/divider';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule } from 'primeng/calendar';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { EditorModule } from 'primeng/editor';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { OButtonDirective } from 'o-suite-lib';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-auto-reply',
  standalone: true,
  imports: [
    DividerModule,
    InputGroupModule,
    InputSwitchModule,
    CalendarModule,
    FaIconComponent,
    EditorModule,
    ReactiveFormsModule,
    OButtonDirective,
    NgClass,
  ],
  templateUrl: './auto-reply.component.html',
  styleUrl: './auto-reply.component.scss',
})
export class AutoReplyComponent implements OnInit {
  private fb = inject(FormBuilder);

  autoReplyForm: FormGroup = this.fb.group({
    autoReplyON: [false, Validators.required],
    startDate: [undefined, Validators.required],
    endDate: [undefined, Validators.required],
    fromTime: [undefined, Validators.required],
    toTime: [undefined, Validators.required],
    replyMessage: [null, Validators.required],
  });

  isSaveLoading: boolean = false;
  isSubmitted: boolean = false;
  minDate: Date = new Date();

  ngOnInit() {
    const todayDate = new Date();

    this.autoReplyForm.get('startDate')?.setValue(todayDate);
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);
    this.autoReplyForm.get('endDate')?.setValue(endDate);
    this.autoReplyForm.get('fromTime')?.setValue(todayDate);
    this.autoReplyForm.get('toTime')?.setValue(todayDate);
  }

  onSave() {
    this.isSubmitted = true;
    this.isSaveLoading = true;
    if (this.autoReplyForm.valid) {
      this.isSubmitted = false;
    }
    this.isSaveLoading = false;
  }
}
