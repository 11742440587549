import { Component, inject, Input, OnInit } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { EChartsOption } from 'echarts';
import { IconsModule } from '@app/shared/modules';
import { OsuiteFacade } from '@app/services/osuite.facade';

@Component({
  selector: 'app-revenue',
  standalone: true,
  imports: [NgxEchartsModule, IconsModule],
  templateUrl: './revenue.component.html',
  styleUrls: ['./revenue.component.scss']
})
export class RevenueComponent {
  @Input() averageRevenue!: number;
  @Input() options!: EChartsOption;
  @Input() isLoading!: boolean;
}
