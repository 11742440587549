import { Editor, EditorModule } from 'primeng/editor';
import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectorRef,
  input,
  SimpleChanges,
  OnChanges,
  Input,
  ViewChild,
  inject,
  output,
} from '@angular/core';
import { FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { RecordVideoComponent } from '../record-video/record-video.component';
import { IconsModule } from '@app/shared/modules/icons.module';
import { TooltipModule } from 'primeng/tooltip';
import { SpeechToTextService } from '@app/services/speech-to-text.service';
import { Email, IFile, IReadEmailResponse } from '@app/shared/models/omail';
import { EMAIL_ACTIONS } from '@app/constants';
import { ChipModule } from 'primeng/chip';
import { DomSanitizer } from '@angular/platform-browser';
import { EmailService } from '@app/services/Email.service';

@Component({
  selector: 'app-email-editor',
  standalone: true,
  imports: [
    FormsModule,
    EditorModule,
    ChipModule,
    CommonModule,
    DialogModule,
    ButtonModule,
    RecordVideoComponent,
    IconsModule,
    TooltipModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './email-editor.component.html',
  styleUrls: ['./email-editor.component.scss'],
})
export class EmailEditorComponent implements OnChanges {
  emailDetails = input<IReadEmailResponse>();
  action = input<string | undefined>(undefined);
  @Input() emailForm!: FormGroup;

  emailService = inject(EmailService);
  actionBody: string = '';
  emailAction = EMAIL_ACTIONS;
  text: string = '';
  interimText: string = '';
  isRecognizing: boolean = false;
  showVideoModal: boolean = false;
  originalText: string = '';
  attachments: IFile[] = [];
  videoUrl: string | null = null;
  sanitizer = inject(DomSanitizer);
  sendVideoUrl = output<Blob>();
  @Input() uploadedFile: IFile | null = null;
  @ViewChild('pEditor') pEditor!: Editor;
  speechToTextService = inject(SpeechToTextService);
  cdr = inject(ChangeDetectorRef);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['action'] || changes['emailDetails']) {
      if (this.action() == this.emailAction.DRAFT) {
        this.text = this.emailDetails()?.body || '';
      } else if (this.action()) {
        this.actionBody = this.emailService.getEmailDetails().body || '';
      }
    }
    if (changes['uploadedFile'] && this.uploadedFile) {
      this.appendFileToEditor(this.uploadedFile);
    }
  }

  appendFileToEditor(file: IFile): void {
    this.attachments.push({
      file: file.file,
      fileUrl: file.fileUrl,
      fileName: file.fileName,
      fileType: file.fileType,
      fileSize: file.fileSize,
    });
  }

  toggleMicrophone() {
    if (!this.isBrowserSupported()) {
      alert('Speech Recognition API is not supported in your browser.');
      return;
    }
    this.originalText = this.text;
    if (this.isRecognizing) {
      this.speechToTextService.stopRecognition();
      this.interimText = '';
      this.isRecognizing = false;
    } else {
      this.speechToTextService.startRecognition(
        (interimText: string) => this.handleInterimText(interimText),
        (finalText: string) => this.handleFinalText(finalText),
        (error: string) => {
          this.speechToTextService.stopRecognition();
          this.isRecognizing = false;
          this.cdr.detectChanges();
          alert('Error: ' + error);
        }
      );
      this.isRecognizing = true;
    }
  }

  handleInterimText(interimText: string) {
    this.interimText = interimText;
    this.updateEditor();
  }

  handleFinalText(finalText: string) {
    if (!this.originalText.endsWith(' ') && finalText.length > 0) {
      this.originalText += ' ';
    }
    this.originalText += finalText;
    this.interimText = '';
    this.updateEditor();
  }

  updateEditor() {
    this.emailForm.get('email')?.setValue(this.originalText + this.interimText);
    this.cdr.detectChanges();
  }

  handleVideoRecorded(video: Blob): void {
    this.videoUrl = URL.createObjectURL(video);
    this.sendVideoUrl.emit(video);
    this.showVideoModal = false;
    this.cdr.detectChanges();
  }
  isBrowserSupported(): boolean {
    return 'SpeechRecognition' in window || 'webkitSpeechRecognition' in window;
  }

  openMicrophone() {}

  dataURLtoBlob(dataUrl: string, mimeType: string): Blob {
    const byteString = atob(dataUrl.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeType });
  }

  getFileIcon(fileType: string) {
    if (fileType.includes('pdf')) return 'file-pdf';
    if (fileType.includes('word')) return 'file-word';
    if (fileType.includes('spreadsheet') || fileType.includes('excel')) return 'file-excel';
    if (fileType.startsWith('image/')) return 'file-image';
    if (fileType.startsWith('video/')) return 'file-video';
    return 'file'; // Generic file icon
  }

  removeAttachment(attachment: IFile): void {
    this.attachments = this.attachments.filter((att) => att.fileName !== attachment.fileName);
  }

  formatBodyHtml(body: string) {
    return this.sanitizer.bypassSecurityTrustHtml(body.replace(/\\/g, ''));
  }
}
