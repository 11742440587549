import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ITrimmedLinksData, IDashboard, IFileData, IRangeDatePayloads } from '../models';

// ToDo: we need to have multiple services each service is responsible for one state instead of centralized service state
@Injectable({
  providedIn: 'root',
})
export class OtrimState {
  private filesRecordsSignal = signal<IFileData[] | null>([]);
  filesRecords = this.filesRecordsSignal.asReadonly();

  private dashboardStatisticsSignal = signal<IDashboard | null>(null);
  dashboardStatistics = this.dashboardStatisticsSignal.asReadonly();

  private filteredLinksSignal = signal<ITrimmedLinksData[]>([]);
  filteredLinks = this.filteredLinksSignal.asReadonly();

  private trimmedLinksSignal = signal<ITrimmedLinksData[]>([]);
  trimmedLinks = this.trimmedLinksSignal.asReadonly();

  dateRange = new BehaviorSubject<IRangeDatePayloads>({ fromDate: '', toDate: '' });
  linkToEdit = signal<ITrimmedLinksData | null>(null);

  setDashboardStatistics(stat: IDashboard) {
    this.dashboardStatisticsSignal.set({ ...stat });
  }

  setFilteredLinks(data: ITrimmedLinksData[]): void {
    this.filteredLinksSignal.set([...data]);
  }

  setFilesRecords(data: IFileData[]) {
    this.filesRecordsSignal.set([...data]);
  }

  deleteFileRecord(fileId: number): void {
    const updatedRecords = this.filesRecords()?.filter((record) => record.fileId !== fileId) || [];
    this.filesRecordsSignal.set([...updatedRecords]);
  }

  setDateRange(data: IRangeDatePayloads) {
    this.dateRange.next(data);
  }

  getDateRange(): Observable<IRangeDatePayloads> {
    return this.dateRange.asObservable();
  }

  setTrimmedLinks(data: ITrimmedLinksData[]) {
    this.trimmedLinksSignal.set([...data]);
  }

  deleteTrimmedLinkById(trimUrlId: number): void {
    const updatedRecords = this.trimmedLinksSignal()?.filter((record) => record.trimUrlId !== trimUrlId) || [];
    this.trimmedLinksSignal.set([...updatedRecords]);
  }

  setLinkToEdit(linkData: ITrimmedLinksData | null) {
    this.linkToEdit.set(linkData);
  }

  addTrimmedLink(trimmedLink: ITrimmedLinksData) {
    const currentLinks = this.trimmedLinks() as [];
    this.trimmedLinksSignal.set([trimmedLink, ...currentLinks]);
  }
}
