import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  inject,
  input,
  Input,
  OnChanges,
  OnInit,
  output,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from '@app/shared/modules/icons.module';
import { PriorityIconComponent } from '@app/shared/svg/priority-icon/priority-icon.component';
import { EMAIL_ACTIONS } from '@app/constants';
import { Email, IReadEmailResponse } from '@app/shared/models/omail';
import { EmailChipInputComponent } from '../../../../shared/components/email-chip-input/email-chip-input.component';
import { EmailService } from '@app/services/Email.service';
import { ContactsFacade } from '@app/pages/my-contacts/services/contacts.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-create-email-header',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IconsModule,
    ReactiveFormsModule,
    PriorityIconComponent,
    EmailChipInputComponent,
  ],
  templateUrl: './create-email-header.component.html',
  styleUrl: './create-email-header.component.scss',
})
export class CreateEmailHeaderComponent implements OnChanges, OnInit {
  private emailService = inject(EmailService);
  private contactsFacade = inject(ContactsFacade);
  private destroyRef = inject(DestroyRef);

  @Input({ required: true }) action!: string;
  @Input() emailForm!: FormGroup;
  close = output();

  highPriority: boolean = false;
  draft = input<IReadEmailResponse>();

  changePriority(): void {
    this.highPriority = !this.highPriority;
    this.emailForm.get('priority')?.setValue(this.highPriority ? 1 : 0);
  }

  emailTitle: string = 'New';
  subject: string | undefined = '';
  to: string[] = [];
  cc: string[] = [];
  bcc: string[] = [];
  showCC: boolean = false;
  showBCC: boolean = false;

  ngOnInit() {
    this.contactsFacade
      .getSelectedContact()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((contact) => {
        if (contact?.omailEmailId) {
          this.to = [contact?.omailEmailId];
        } else if (contact?.alternateEmailId) {
          this.to = [contact?.alternateEmailId];
        }
        this.emailForm.get('toEmail')?.setValue(this.to.join(','));
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['action'] || changes['draft']) {
      this.handleAction();
    }
  }

  updateEmails(emails: string[], type: string): void {
    if (type === 'to') {
      this.to = emails;
      this.emailForm.get('toEmail')?.setValue(emails.join(','));
    } else if (type === 'cc') {
      this.cc = emails;
      this.emailForm.get('ccEmail')?.setValue(emails.join(','));
    } else if (type === 'bcc') {
      this.bcc = emails;
      this.emailForm.get('bccEmail')?.setValue(emails.join(','));
    }
  }

  private handleAction(): void {
    const emailDetails = this.emailService.getEmailDetails();
    if (this.action === EMAIL_ACTIONS.REPLY) {
      this.emailTitle = 'Reply';
      this.emailForm.get('subject')?.setValue(`Re: ${emailDetails?.subject}`);
      this.emailForm.get('toEmail')?.setValue(emailDetails?.from);
      this.to = [emailDetails?.from];
    } else if (this.action === EMAIL_ACTIONS.FORWARD) {
      this.emailTitle = 'Forward';
      this.emailForm.get('subject')?.setValue(`Fwd: ${emailDetails?.subject}`);
    } else if (this.action === EMAIL_ACTIONS.REPLY_ALL) {
      this.emailTitle = 'Reply All';
      this.emailForm.get('toEmail')?.setValue(this.getEmailsTo([emailDetails?.from, ...emailDetails?.to]).join(','));
      this.emailForm.get('ccEmail')?.setValue(emailDetails?.cc?.join(','));
      this.emailForm.get('subject')?.setValue(`Re: ${emailDetails?.subject}`);
      this.showCC = (emailDetails?.cc?.length ?? 0) > 0;
      this.cc = emailDetails?.cc || [];
      this.to = this.getEmailsTo([emailDetails?.from, ...emailDetails?.to]);
    } else if (this.action === EMAIL_ACTIONS.DRAFT) {
      this.emailTitle = 'Draft';
      this.subject = this.draft()?.subject;
      this.to = this.draft()?.to || [];
      this.cc = this.draft()?.cc || [];
      this.bcc = this.draft()?.bcc || [];
      this.showCC = this.cc.length > 0;
      this.showBCC = this.bcc.length > 0;
    }
  }

  toggleCC() {
    if (this.showCC) {
      this.cc = [];
      this.emailForm.get('ccEmail')?.setValue('');
    }
    this.showCC = !this.showCC;
  }

  toggleBCC() {
    if (this.showBCC) {
      this.bcc = [];
      this.emailForm.get('bccEmail')?.setValue('');
    }
    this.showBCC = !this.showBCC;
  }

  getEmailsTo(emails: string[]) {
    return Array.from(new Set(emails));
  }

  onClose() {
    this.close.emit();
  }
  parseEmailAddresses(commaSeparated: string[] | undefined | null): string[] {
    if (!commaSeparated) {
      return [];
    }

    return commaSeparated.map((email) => email.trim()).filter((email) => email.length > 0);
  }
}
