import { IContactInfoStructure } from '@app/shared/models/contacts.model';
import {
  faBriefcase,
  faBuilding,
  faCalendar,
  faEarth,
  faEnvelope,
  faLocationDot,
  faPhoneVolume,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

export const allContactsStructure: Array<IContactInfoStructure> = [
  {
    iconName: faUser,
    name: 'First name',
    key: 'firstName',
  },
  {
    iconName: faUser,
    name: 'Last name',
    key: 'lastName',
  },
  {
    iconName: faBuilding,
    name: 'Job title',
    key: 'jobTitle',
  },
  {
    iconName: faBriefcase,
    name: 'Company name',
    key: 'companyName',
  },
  {
    iconName: faEnvelope,
    name: 'Alternate email address',
    key: 'alternateEmailId',
  },
  {
    iconName: faEnvelope,
    name: 'OMail email',
    key: 'omailEmailId',
  },
  {
    iconName: faEarth,
    name: 'Country',
    key: 'countryName',
  },
  {
    iconName: faLocationDot,
    name: 'Country code',
    key: 'countryCode',
  },
  {
    iconName: faPhoneVolume,
    name: 'Phone number',
    key: 'contactNumber',
  },
  {
    iconName: faCalendar,
    name: 'Date of birth',
    key: 'dateOfBirth',
  },
  {
    iconName: faLocationDot,
    name: 'State',
    key: 'stateId',
  },
  {
    iconName: faLocationDot,
    name: 'Zip code',
    key: 'zipcode',
  },
  {
    iconName: faLocationDot,
    name: 'Address',
    key: 'address',
  },
];
