import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { CanDeactivateService } from './services/CanDeactivateService.service';

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard implements CanDeactivate<unknown> {
  constructor(private canDeactivateService: CanDeactivateService) {}

  canDeactivate(): boolean | Observable<boolean> {
    return this.canDeactivateService.getCanDeactivate()();
  }
}
