import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ITimeline } from '@app/model';
import { IconsModule } from '@app/shared/modules';
import { SkeletonModule } from 'primeng/skeleton';

interface GroupedTransaction {
  date: string;
  transactions: {
    transactionId: number;
    customerName: string;
    bonusEarned: number;
    time: string;
  }[];
}

@Component({
  selector: 'app-active-timeline',
  standalone: true,
  imports: [IconsModule, CommonModule, SkeletonModule],
  templateUrl: './active-timeline.component.html',
  styleUrl: './active-timeline.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActiveTimelineComponent implements OnChanges {
  @Input() timelineData!: ITimeline[];
  @Input() isLoading!: boolean;

  groupedTransactions!: GroupedTransaction[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['timelineData']) {
      if (this.timelineData[0].transactionId !== 0) {
        this.groupedTransactions = this.groupTransactionsByDate();
      }
    }
  }

  groupTransactionsByDate(): GroupedTransaction[] {
    return this.timelineData?.reduce((acc: GroupedTransaction[], transaction) => {
      const [date] = transaction.createdDate?.split('T');

      let dateGroup = acc?.find((group) => group.date.startsWith(date));

      if (!dateGroup) {
        dateGroup = { date: transaction.createdDate, transactions: [] };
        acc.push(dateGroup);
      }

      dateGroup.transactions?.push({
        transactionId: transaction.transactionId,
        customerName: transaction.customerName,
        bonusEarned: transaction.bonusEarned,
        time: transaction.createdDate
      });

      return acc;
    }, []);
  }
}
