<div class="app-group">
  <!-- <p class="text-lg mb-3 text-gray-400">{{ title() }}</p> -->
  <div class="flex flex-wrap  mt-4">
    @for (app of apps(); track app.id) {
      <div (click)="openApp(app.id)" class="p-2 border-round-lg flex align-items-center gap-2 w-6 cursor-pointer transition-ease-in application-container">
        <div class="border-circle w-2rem h-2rem flex justify-content-center align-items-center" [style]="{ 'background': app.backgroundColor }">
          <fa-icon [icon]="app.icon" class="text-white"/>
        </div>
        <span>{{ app.name }}</span>
      </div>
    }
  </div>
</div>
