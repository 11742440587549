<div class="my-contacts flex">
  <aside>
    <app-my-contacts-sidebar />
  </aside>
  <aside>
    <app-all-contacts-sidebar></app-all-contacts-sidebar>
  </aside>
  <main class="content">
    <router-outlet></router-outlet>
  </main>
</div>
