import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { PaginatedResponse } from '@app/model/api.model';
import { IContact } from '@app/model/my-contacts.model';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { OsuiteState } from '@app/services/osuite.state';
import { OButtonDirective } from 'o-suite-lib';
import { SkeletonModule } from 'primeng/skeleton';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-contact-list',
  standalone: true,
  imports: [
    DividerModule,
    AvatarModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    FaIconComponent,
    CommonModule,
    ButtonModule,
    SkeletonModule
  ],
  templateUrl: './contact-list.component.html',
  styleUrl: './contact-list.component.scss'
})
export class ContactListComponent {
  @Input() contactList!: PaginatedResponse<IContact> | null;
  @Input() selectedContact!: IContact | null;
  @Input() isLoading!: boolean;

  @Output() selectedContactChange = new EventEmitter<IContact>();
  @Output() handleFavouriteContact = new EventEmitter<number>();
  @Output() valueChange = new EventEmitter<string>();
  private osuiteState = inject(OsuiteState);

  handleSelectContact(contact: IContact) {
    this.selectedContactChange.emit(contact);
  }

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.valueChange.emit(inputElement.value);
  }

  favouriteContact(event: MouseEvent, contactId: number) {
    event.stopPropagation();
    this.handleFavouriteContact.emit(contactId);
  }

  handleOpenEditContact() {
    this.osuiteState.setEditModalData(null);
    this.osuiteState.setIsEditModalOpen(true);
  }

  protected readonly environment = environment;
}
