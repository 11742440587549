import { Component, inject } from '@angular/core';
import { SupSidebarComponent } from '@app/shared/components/sup-sidebar/sup-sidebar.component';
import { MENU_SETTINGS_ITEMS } from '@app/constants';
import { SidebarMenuItemComponent } from '@app/shared/components/sidebar-menu-item/sidebar-menu-item.component';
import { filter, Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { findMatchingItem } from '@app/utils/matching-item';

@Component({
  selector: 'app-settings-sidebar',
  standalone: true,
  imports: [SupSidebarComponent, SidebarMenuItemComponent],
  templateUrl: './settings-sidebar.component.html',
  styleUrl: './settings-sidebar.component.scss',
})
export class SettingsSidebarComponent {
  activeItem = 'inbox';
  menuItems = MENU_SETTINGS_ITEMS;

  private routerEventsSubscription!: Subscription;
  router = inject(Router);
  route = inject(ActivatedRoute);

  ngOnInit(): void {
    this.routerEventsSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setActiveIconBasedOnRoute();
      });

    this.setActiveIconBasedOnRoute();
  }

  ngOnDestroy(): void {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  private setActiveIconBasedOnRoute(): void {
    const currentRoute = this.router.url;

    const matchingItem = findMatchingItem(this.menuItems, currentRoute);

    if (matchingItem) {
      this.activeItem = matchingItem.id;
    }
  }
}
