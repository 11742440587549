import { Component, DestroyRef, inject } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  RevenueOverviewComponent,
  TimelineComponent,
  TopCustomersComponent,
  TotalCustomersComponent,
  TotalRevenueComponent,
  UpcomingProductsComponent,
} from '@app/components/dashboard';
import { assetUrl } from '@src/single-spa/asset-url';
import { CarouselComponent } from '@app/components/marketplace';
import { SvgIconComponent } from 'angular-svg-icon';
import { svgIconModule } from '@app/shared/modules';
import { Button } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { OButtonDirective } from 'o-suite-lib';
import { TimelineInfoComponent } from '@app/components/dashboard/timeline/timeline-info/timeline-info.component';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { Product } from '@app/model/product.model';
import { PackageDetail } from '@app/model/package-detail.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, finalize } from 'rxjs';
import { FilterType } from '@app/shared/enums/ filter.enum';
import { SkeletonModule } from 'primeng/skeleton';
import { ApiResponse } from '@app/components/onboarding/models/onboarding-step.model';
import { NavigationEnd, Router } from '@angular/router';
import { ToasterService } from '@app/services/toaster.service';

interface SaveFav {
  targetProductIdOrCustomerAccountId: number;
}

@Component({
  selector: 'app-marketplace',
  standalone: true,
  imports: [
    FaIconComponent,
    RevenueOverviewComponent,
    TimelineComponent,
    TopCustomersComponent,
    TotalCustomersComponent,
    TotalRevenueComponent,
    UpcomingProductsComponent,
    CarouselComponent,
    SvgIconComponent,
    svgIconModule,
    Button,
    DividerModule,
    OButtonDirective,
    TimelineInfoComponent,
    SkeletonModule,
  ],
  templateUrl: './marketplace.component.html',
  styleUrl: './marketplace.component.scss',
})
export class MarketplaceComponent {

  protected readonly FilterType = FilterType;
  protected readonly assetUrl = assetUrl;
  filter: FilterType = FilterType.ALL;
  filterFavorite = false;
  cards: Product[] = [];
  private destroyRef = inject(DestroyRef);
  private osuiteFacade = inject(OsuiteFacade);
  private router = inject(Router);
  private toasterService = inject(ToasterService);
  loading = false;
  isKycVerified = false;
  isloading = false;

  ngOnInit(): void {
    this.getMarketplaceProducts();
    this.getUserInfo();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('kyc') === 'true') {
          this.getUserInfo();
        }
      });
  }

  getMarketplaceProducts() {
    this.loading = true;
    this.osuiteFacade.getMarketplaceProducts(this.filter, this.filterFavorite).pipe(
      takeUntilDestroyed(this.destroyRef),
      finalize(() => {
        this.loading = false;
      }),
    ).subscribe({
      next: (data) => {
        this.cards = [];
        if (Array.isArray(data.body)) {
          data.body.forEach(p => {
            const monthlyProduct = p.packageDetails.find((packageDetail: PackageDetail) => packageDetail.plan_name === 'Monthly' || packageDetail.plan_name === 'LIFE-TIME');
            if (monthlyProduct) {
              p.packageDetail = monthlyProduct;
              p.isloading = false;
              this.cards.push(p);
            }
          });
        }
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  addFav(product: Product) {
    this.loading = true;
    if (product.isFavorite) {
      this.removeFavorite(product.productId);
    } else {
      this.addFavorite(product.productId);
    }
  }

  private removeFavorite(productId: number) {
    this.osuiteFacade.deleteFavProducts(productId).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe({
      next: () => {
        this.getMarketplaceProducts();
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  private addFavorite(productId: number) {
    const body: SaveFav = {
      targetProductIdOrCustomerAccountId: productId,
    };

    this.osuiteFacade.setMarketplaceProducts(body).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe({
      next: () => {
        this.getMarketplaceProducts();
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  fillterAll() {
    this.filter = FilterType.ALL;
    this.getMarketplaceProducts();
  }

  fillterPurchased() {
    this.filter = FilterType.PURCHASED;
    this.getMarketplaceProducts();
  }

  fillterFavorites() {
    this.filterFavorite = !this.filterFavorite;
    this.getMarketplaceProducts();
  }

  subscribeFun(card: Product) {
    card.isloading = true;
    if (!this.isKycVerified){
      this.toasterService.setToasterState(true, 'error', 'Your account is not verified. Please complete the verification.');
      this.getUserInfo(card);
      return;
    }
    this.toasterService.setToasterState(true, 'success', 'Verified!');
    card.isloading = false;
  }

  getUserInfo(card?: Product) {
    this.osuiteFacade
      .fetchFirstLogin()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: ApiResponse) => {
        this.isKycVerified = res?.body?.isKycVerified;
        if (!this.isKycVerified){
          this.createKYCSession(card);
        }
      });
  }

  createKYCSession(card?: Product){
      this.osuiteFacade
        .createKYCSession()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res: any) => {
          const url = res?.body?.url;
          if (url){
            card!.isloading = false;
            window.location.href = url;
          }
        });
  }
}
