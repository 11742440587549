import { Component, DestroyRef, inject, signal } from '@angular/core';
import { EmailListComponent } from '@app/components/mail/email-list/email-list.component';
import { CreateEmailComponent } from '@app/components/mail/create-email/create-email.component';
import { SocketV2Service } from '@app/services/socket-v2.service';
import { Email } from '@app/shared/models/omail';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-email',
  standalone: true,
  imports: [EmailListComponent, CreateEmailComponent],
  templateUrl: './new-email.component.html',
  styleUrl: './new-email.component.scss',
})
export class NewEmailComponent {
  socketService = inject(SocketV2Service);

  loading = signal(false);
  boxName = 'INBOX';
  listofMails = signal<Email[]>([]);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.fetchFolderData();
    this.socketService
      .getMailboxDataStream(this.boxName)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((emailResponse) => {
        this.listofMails.set(emailResponse.emails);
        this.loading.set(false);
      });
  }

  private fetchFolderData(): void {
    const cachedData = this.socketService.getFolderData(this.boxName);

    if (cachedData) {
      this.listofMails.set(cachedData.emails);
      this.loading.set(false);
    } else {
      this.loading.set(true);
      this.socketService.requestMailboxData(this.boxName);
    }
  }
}
