import { HttpInterceptorFn } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

export const encryptInterceptor: HttpInterceptorFn = (req, next) => {
  const encryptableEndpoints: string[] = [];

  const shouldEncrypt = encryptableEndpoints.some((endpoint) => req.url.includes(endpoint));

  if (shouldEncrypt && req.body) {
    const encryptionKey = environment.DEFAULT_KEY;
    const encryptedBody = CryptoJS.AES.encrypt(JSON.stringify(req.body), encryptionKey).toString();

    const encryptedRequest = req.clone({
      body: encryptedBody,
    });

    return next(encryptedRequest);
  }

  return next(req);
};
