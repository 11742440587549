<div class="updates-banner relative flex align-items-center justify-content-between p-2 surface-border">
  <div class="flex gap-2 w-full pr-4">
    <span class="icon text-sm font-bold flex align-items-center">
      <span class="icon flipped-icon">📣</span>
      Updates:
    </span>
    <div class="relative w-full">
      @for(text of visibleTexts;track text){
      <p class="text my-0 text-sm text-color-tertiary-500 white-space-nowrap overflow-hidden text-overflow-ellipsis">
        @if(showSeeMore ){
        <span>{{ text?.headLine?.slice(0, 149) + '...' }}</span>
        <span class="ml-0 text-color-primary-500 font-semibold cursor-pointer" (click)="handleShowUpdate(text)">
          Read more
        </span>
        } @else {
        <span>
          {{ text?.headLine }}
        </span>
        <span class="ml-0 text-color-primary-500 font-semibold cursor-pointer" (click)="handleShowUpdate(text)">
          Read more
        </span>
        }
      </p>
      }
    </div>
  </div>
  <div
    (click)="onCloseClick()"
    class="close-btn relative text-xs flex align-items-center justify-content-center border-circle cursor-pointer border-1"
  >
    <fa-icon icon="times" />
  </div>
</div>
<div class="gradient-component w-full"></div>
<o-update-dialog
  [steps]="steps"
  [visible]="visible"
  (visibleChange)="handleClose($event)"
  [currentStep]="currentStep"
/>
