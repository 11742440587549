import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { CommonModule } from '@angular/common';
import { ShortifyUrlPanelComponent } from '../../components/shortify-url-panel/shortify-url-panel.component';
import { AnalyticsCardComponent } from '../../shared/shared-components/analytics-card/analytics-card-component';
import { TableOptionsComponent } from '../../components/table-options/table-options.component';
import { ShortifiedUrlTableComponent } from '../../components/shortified-url-table/shortified-url-table.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ShortifiedFilesTableComponent } from '../../components/shortified-files-table/shortified-files-table.component';
import { StaticsComponent } from '../../components/statics/statics.component';
import { IAnalyticCard, IDashboardPayload, ITrimDropdownOption } from '../../models';
import { ANALYTIC_CARDS_DATA, TRIM_DROPDOWN_OPTIONS } from '../../shared/constants';
import { OtrimFacade } from '../../services/otrim.facade';
import { mergeMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    FontAwesomeModule,
    TableModule,
    ButtonModule,
    DividerModule,
    CommonModule,
    ShortifyUrlPanelComponent,
    AnalyticsCardComponent,
    TableOptionsComponent,
    ShortifiedUrlTableComponent,
    BreadcrumbModule,
    StaticsComponent,
    ShortifiedFilesTableComponent,
    SkeletonModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  private otrimFacade = inject(OtrimFacade);
  private destroyRef = inject(DestroyRef);

  isDashboardDataLoading = this.otrimFacade.isDashboardDataLoading;

  showList = true;
  totalLinks: number = 0;
  analyticsCards: IAnalyticCard[] = ANALYTIC_CARDS_DATA;
  selectedOption: ITrimDropdownOption = TRIM_DROPDOWN_OPTIONS.ALL_LINK;
  selecteOptions = TRIM_DROPDOWN_OPTIONS;

  onTabChange(show: boolean) {
    this.showList = show;
  }

  ngOnInit() {
    const params = {
      type: 'trim',
      fromDate: '',
      toDate: '',
      campaignId: '',
    };

    this.getDashboardData(params);
  }

  getDashboardData(params: IDashboardPayload) {
    this.otrimFacade
      .getDateRange()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        mergeMap((res: any) => {
          return this.otrimFacade.getDashboardData({ ...params, ...res });
        })
      )
      .subscribe(
        (res: any) => {
          this.analyticsCards = this.analyticsCards.map((card) => ({
            ...card,
            data: res.result[card.key],
          }));
          this.totalLinks = res?.result?.totalLinks;
          this.otrimFacade.setDashboardStatistics(res?.result);
          this.otrimFacade.setDashboardDataLoadingState(false);
        },
        () => {
          this.otrimFacade.setDashboardDataLoadingState(false);
        }
      );
  }

  navigateToFilesList() {
    this.onTabChange(true);
    this.selectedOption = TRIM_DROPDOWN_OPTIONS.ALL_LINK;
  }
}
