import { Component } from '@angular/core';
import { SkeletonModule } from 'primeng/skeleton';
@Component({
  selector: 'app-email-skeleton',
  standalone: true,
  imports: [SkeletonModule],
  templateUrl: './email-skeleton.component.html',
  styleUrl: './email-skeleton.component.scss',
})
export class EmailSkeletonComponent {}
