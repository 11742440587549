import { Injectable } from '@angular/core';
import RecordRTC from 'recordrtc';

@Injectable({
  providedIn: 'root',
})
export class RecordRTCService {
  private recorder: any;
  private stream: MediaStream | null = null;

  async openCamera(videoElement: HTMLVideoElement): Promise<void> {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
      videoElement.srcObject = this.stream;
      videoElement.play();
    } catch (error) {
      console.error('Error accessing camera:', error);
      throw new Error('Unable to access camera. Please check your permissions.');
    }
  }

  async startRecording(): Promise<void> {
    if (!this.stream) {
      throw new Error('Camera is not initialized. Please call openCamera first.');
    }
    this.recorder = new RecordRTC(this.stream, {
      type: 'video',
      mimeType: 'video/webm',
    });
    this.recorder.startRecording();
  }

  pauseRecording(): void {
    if (this.recorder) {
      this.recorder.pauseRecording();
    }
  }

  resumeRecording(): void {
    if (this.recorder) {
      this.recorder.resumeRecording();
    }
  }

  async stopRecording(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      if (this.recorder) {
        this.recorder.stopRecording(() => {
          const blob = this.recorder.getBlob();
          this.cleanup();
          resolve(blob);
        });
      } else {
        reject('Recorder is not initialized.');
      }
    });
  }

  cleanup(): void {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
      this.stream = null;
    }
    this.recorder = null;
  }
}
