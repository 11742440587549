import { Component, inject, OnInit } from '@angular/core';
import { IconsModule } from '@app/shared/modules/icons.module';
import { SettingsFacade } from '@app/pages/settings/services/settings.facade';
import { OLoaderComponent } from 'o-suite-lib';
import { AvatarModule } from 'primeng/avatar';
import { environment } from '../../../../environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [IconsModule, OLoaderComponent, AvatarModule, DatePipe],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent implements OnInit {
  private settingsFacade = inject(SettingsFacade);

  ngOnInit() {
    this.settingsFacade.getProfileInfo();
  }

  protected isMyProfileLoading = this.settingsFacade.isMyProfileLoading$;
  protected myProfileData = this.settingsFacade.myProfileData;
  protected readonly environment = environment;
}
