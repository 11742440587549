<div class="timeline-info-container text-center py-5 px-3">
  <div class="mb-4">
    <svg-icon name="timeline" />
  </div>

  <!-- Title -->
  <h2 class="text-color-tertiary-500 font-bold text-xl">Stay on top of your timeline!</h2>

  <!-- Description -->
  <p class="text-color-tertiary-500 text-base line-height-3 m-0">
    Effortlessly track your transaction orders with real-time updates!
  </p>
</div>
