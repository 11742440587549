import { Routes } from '@angular/router';
import { MailComponent, SettingsComponent, CalendarComponent, MyContactsComponent } from './pages';
import {
  DraftComponent,
  InboxComponent,
  PriorityComponent,
  SentComponent,
  OutboxComponent,
  ScheduledComponent,
  DeletedComponent,
  NewEmailComponent,
} from '@app/pages/mail';
import {
  DeletedComponent as DeletedContacts,
  CreateContactComponent,
  AllContactsComponent,
  FavoritesComponent,
  GroupsComponent,
} from './pages/my-contacts';
import {
  EmailComponent,
  AutoReplyComponent,
  EmailRulesComponent,
  FolderLockComponent,
  AutoFollowUpComponent,
  MyEmailDashboardComponent,
  StorageComponent,
  ProfileComponent,
} from './pages/settings';
import { CALENDER_ROUTES, CONTACTS_ROUTES, MAILBOX_ROUTES, SETTINGS_ROUTES } from './constants';
import { EmailBodyContentComponent } from '@app/components/mail/email-body-content/email-body-content.component';
import { NoEmailSelectedComponent } from './pages/mail/no-email-selected/no-email-selected.component';
import { CreateEmailComponent } from './components/mail/create-email/create-email.component';
import { FolderComponent } from './pages/mail/folder/folder.component';
import { CanDeactivateGuard } from './CanDeactivateGuard.guard';

const MAIL_CHILD_ROUTES = [
  {
    path: ':emailId',
    component: EmailBodyContentComponent,
  },
  { path: '', outlet: 'aux', component: NoEmailSelectedComponent },
];

export const routes: Routes = [
  { path: '', redirectTo: MAILBOX_ROUTES.MAIL, pathMatch: 'full' },
  {
    path: MAILBOX_ROUTES.MAIL,
    component: MailComponent,
    children: [
      { path: '', redirectTo: MAILBOX_ROUTES.INBOX, pathMatch: 'full' },
      {
        path: MAILBOX_ROUTES.INBOX,
        component: InboxComponent,
        children: MAIL_CHILD_ROUTES,
      },
      {
        path: MAILBOX_ROUTES.DRAFT,
        component: DraftComponent,
        children: [
          {
            path: ':emailId',
            component: CreateEmailComponent,
            canDeactivate: [CanDeactivateGuard],
          },
          { path: '', outlet: 'aux', component: NoEmailSelectedComponent },
        ],
      },
      {
        path: MAILBOX_ROUTES.PRIORITY,
        component: PriorityComponent,
        children: MAIL_CHILD_ROUTES,
      },
      {
        path: MAILBOX_ROUTES.SENT,
        component: SentComponent,
        children: MAIL_CHILD_ROUTES,
      },
      {
        path: MAILBOX_ROUTES.OUTBOX,
        component: OutboxComponent,
        children: MAIL_CHILD_ROUTES,
      },
      {
        path: MAILBOX_ROUTES.SCHEDULED,
        component: ScheduledComponent,
        children: MAIL_CHILD_ROUTES,
      },
      {
        path: MAILBOX_ROUTES.DELETED,
        component: DeletedComponent,
        children: MAIL_CHILD_ROUTES,
      },
      {
        path: MAILBOX_ROUTES.Folder,
        component: FolderComponent,
        children: MAIL_CHILD_ROUTES,
      },
      {
        path: MAILBOX_ROUTES.NEW_EMAIL,
        component: NewEmailComponent,
        canDeactivate: [CanDeactivateGuard],

        children: [
          {
            path: ':emailId',
            component: EmailBodyContentComponent,
          },
        ],
      },
    ],
  },
  {
    path: SETTINGS_ROUTES.SETTINGS,
    component: SettingsComponent,
    children: [
      { path: '', redirectTo: SETTINGS_ROUTES.PROFILE, pathMatch: 'full' },
      { path: SETTINGS_ROUTES.PROFILE, component: ProfileComponent },
      { path: SETTINGS_ROUTES.EMAIL, component: EmailComponent },
      { path: SETTINGS_ROUTES.AUTO_REPLY, component: AutoReplyComponent },
      { path: SETTINGS_ROUTES.EMAIL_RULES, component: EmailRulesComponent },
      { path: SETTINGS_ROUTES.FOLDER_LOCK, component: FolderLockComponent },
      { path: SETTINGS_ROUTES.AUTO_FOLLOW_UP, component: AutoFollowUpComponent },
      { path: SETTINGS_ROUTES.MY_EMAIL_DASHBOARD, component: MyEmailDashboardComponent },
      { path: SETTINGS_ROUTES.STORAGE, component: StorageComponent },
    ],
  },
  { path: CALENDER_ROUTES.CALENDER, component: CalendarComponent },
  {
    path: CONTACTS_ROUTES.MY_CONTACTS,
    component: MyContactsComponent,
    children: [
      { path: '', redirectTo: CONTACTS_ROUTES.CONTACTS, pathMatch: 'full' },
      { path: CONTACTS_ROUTES.CONTACTS, component: AllContactsComponent },
      { path: CONTACTS_ROUTES.FAVORITES, component: FavoritesComponent },
      { path: CONTACTS_ROUTES.GROUPS, component: GroupsComponent },
      { path: CONTACTS_ROUTES.DELETED, component: DeletedContacts },
      {
        path: CONTACTS_ROUTES.CREATE_CONTACT,
        component: CreateContactComponent,
      },
    ],
  },
];
