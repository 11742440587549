import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpeechToTextService {
  private recognition: any;
  private recognizing: boolean = false;

  constructor() {
    const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

    if (SpeechRecognition) {
      this.recognition = new SpeechRecognition();
      this.recognition.lang = 'en-US';
      this.recognition.interimResults = true;
      this.recognition.maxAlternatives = 1;

      this.recognition.onend = () => {
        if (this.recognizing) {
          this.recognition.start();
        } else {
          this.recognizing = false;
        }
      };
    } else {
      throw new Error('Speech Recognition API is not supported in this browser.');
    }
  }

  startRecognition(
    onInterimResult: (interimText: string) => void,
    onFinalResult: (finalText: string) => void,
    onError: (error: string) => void
  ): void {
    if (!this.recognition) {
      onError('Speech Recognition API is not supported in your browser.');
      return;
    }

    if (!this.recognizing) {
      this.recognition.start();
      this.recognizing = true;

      this.recognition.onresult = (event: any) => {
        let interimText = '';
        let finalText = '';
        for (const result of event.results) {
          if (result.isFinal) {
            finalText += result[0].transcript;
          } else {
            interimText += result[0].transcript;
          }
        }
        if (interimText) {
          onInterimResult(interimText);
        }
        if (finalText) {
          onFinalResult(finalText);
        }
      };

      this.recognition.onerror = (event: any) => {
        this.recognizing = false;
        onError(`Speech recognition error: ${event.error}`);
      };
    } else {
      this.stopRecognition();
    }
  }

  stopRecognition(): void {
    if (this.recognition && this.recognizing) {
      this.recognition.stop();
      this.recognizing = false;
    }
  }
}
