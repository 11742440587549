import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateService {
  private canDeactivateFn: (() => boolean | Observable<boolean>) | null = null;

  setCanDeactivate(fn: () => boolean | Observable<boolean>): void {
    this.canDeactivateFn = fn;
  }

  getCanDeactivate(): () => boolean | Observable<boolean> {
    return this.canDeactivateFn ?? (() => true);
  }
}
