import { inject, Injectable } from '@angular/core';
import { OToastV2Service } from 'o-suite-lib';
import { ContactsFacade } from '@app/pages/my-contacts/services/contacts.facade';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  oToastV2Service = inject(OToastV2Service);
  contactsFacade = inject(ContactsFacade);

  handleError(error: any): void {
    if (!error.url.includes('alternateEmailId')) {
      const errorMessage = error.error?.message ?? 'Something went wrong!';
      this.oToastV2Service.add({
        severity: 'error',
        summary: errorMessage,
        icon: environment.publicPath + '/assets/icons/toast/error.svg',
      });

      this.contactsFacade.setIsLoadingState(false);
    }
  }
}
