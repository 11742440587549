import { inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppInitializationService {
  sso_ApiUrl = environment.apiUrlSSO;

  private httpClient = inject(HttpClient);
  isLoggedIn = signal<boolean>(true);

  getUserInfo(): Observable<void > {
    return this.httpClient
      .get<void>(`${this.sso_ApiUrl}api/user/user-info`, {
        withCredentials: true
      });
  }

  async initializeApp(): Promise<void> {
    try {
      await lastValueFrom(this.getUserInfo());
      sessionStorage.setItem('isLoggedIn','true');
      this.isLoggedIn.set(true);
    } catch (error) {
      window.location.replace(environment.frontendUrlSSO + '/login');
      this.isLoggedIn.set(false);
      sessionStorage.setItem('isLoggedIn','false');
    }
  }
}
