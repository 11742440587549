import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IconsModule } from '@app/shared/modules';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CountrySelectComponent, OButtonDirective } from 'o-suite-lib';
import { UploadComponent } from '../../../shared/components/upload/upload.component';
import { CommonModule } from '@angular/common';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputGroupModule } from 'primeng/inputgroup';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize, forkJoin, mergeMap } from 'rxjs';
import { ICountry, ICountryCustom, IProfileInfo } from '@app/model/myProfile.model';

@Component({
  selector: 'app-edit-profile-modal',
  standalone: true,
  imports: [
    IconsModule,
    DividerModule,
    DialogModule,
    InputTextModule,
    ButtonModule,
    OButtonDirective,
    UploadComponent,
    ReactiveFormsModule,
    CommonModule,
    InputGroupAddonModule,
    InputGroupModule,
    DropdownModule,
    FormsModule,
    InputNumberModule,
    CountrySelectComponent
  ],
  templateUrl: './edit-profile-modal.component.html',
  styleUrl: './edit-profile-modal.component.scss'
})
export class EditProfileModal implements OnInit {
  @Input() visible!: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

  submitted: boolean = false;
  profileInfoFrom!: FormGroup;
  countryList: ICountryCustom[] = [];
  countryListSelect: any = [];

  country = new FormControl();
  userImage!: string | null;
  isLoading: boolean = false;
  buttonDisabled = false;
  countryCode = '';

  private fb = inject(FormBuilder);
  private osuiteFacade = inject(OsuiteFacade);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.profileInfoFrom = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      omailAddress: [{ value: '', disabled: true }],
      alternateEmail: ['', [Validators.required]],
      joinedDate: [{ value: '', disabled: true }],
      mobileNumber: ['', [Validators.required]],
      address1: ['', [Validators.required]],
      address2: [''],
      state: [''],
      countryBilling: ['', [Validators.required]],
      countryDetails: ['', [Validators.required]],
      city: [''],
      zipCode: ['', [Validators.required]]
    });

    this.osuiteFacade
      .getProfileInfo()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        mergeMap((res) => {
          if (res) {
            this.profileInfoFrom.patchValue({
              firstName: res.firstName,
              lastName: res.lastName,
              omailAddress: res.emailId,
              alternateEmail: res.alternateEmail,
              joinedDate: res.joinedDate.split('T')[0],
              mobileNumber: res.mobileNumber,
              countryDetails: {
                countryName: res.Nationality
              }
            });

            if (res.alternateEmail) {
              this.profileInfoFrom.get('alternateEmail')?.disable();
            }

            this.userImage = res.profilePic;
            this.countryCode = res.countryCode;
          }
          return this.osuiteFacade.getCountries();
        })
      )
      .subscribe((res: ICountry[] | null) => {
        if (res) {
          this.countryList = res.map((item: ICountry) => ({
            name: item.countryName,
            code: item.countryCode,
            number: '+' + item.phoneCode
          }));

          this.countryListSelect = res.map((item: ICountry) => ({
            countryName: item.countryName
          }));

          const currentCountry = res.find((item) => item.countryCode === this.countryCode);

          this.country.setValue({
            name: currentCountry?.countryName,
            code: currentCountry?.countryCode,
            number: '+' + currentCountry?.phoneCode
          });
        }
      });

    this.osuiteFacade
      .getBillingAddress()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: any | null) => {
        if (res) {
          this.profileInfoFrom.patchValue({
            address1: res.primaryAddress,
            address2: res.secondaryAddress,
            state: res.state,
            countryBilling: {
              countryName: res.country
            },
            city: res.city,
            zipCode: res.zipCode
          });
        }
      });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.profileInfoFrom.valid) {
      this.isLoading = true;
      const profileUpdates = {
        firstName: this.profileInfoFrom.getRawValue().firstName,
        lastName: this.profileInfoFrom.getRawValue().lastName,
        alternateEmail: this.profileInfoFrom.getRawValue().alternateEmail,
        mobileNumber: this.profileInfoFrom.getRawValue().mobileNumber.toString(),
        countryCode: this.country.getRawValue().code,
        nationality: this.profileInfoFrom.getRawValue().countryDetails.countryName
      };
      const billingUpdates = {
        country: this.profileInfoFrom.getRawValue().countryBilling.countryName,
        city: this.profileInfoFrom.getRawValue().city || '',
        state: this.profileInfoFrom.getRawValue().state || '',
        zipCode: this.profileInfoFrom.getRawValue().zipCode,
        primaryAddress: this.profileInfoFrom.getRawValue().address1,
        secondaryAddress: this.profileInfoFrom.getRawValue().address2
      };

      forkJoin([
        this.osuiteFacade.updateProfileInfo(profileUpdates),
        this.osuiteFacade.updateBillingAddress(billingUpdates)
      ])
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(() => {
          this.isLoading = false;
          let result = {};
          this.osuiteFacade.getProfileInfo().subscribe((res: any) => {
            result = res;
          });
          this.osuiteFacade.setProfileInfo({
            ...result,
            firstName: this.profileInfoFrom.getRawValue().firstName,
            lastName: this.profileInfoFrom.getRawValue().lastName,
            alternateEmail: this.profileInfoFrom.getRawValue().alternateEmail,
            mobileNumber: this.profileInfoFrom.getRawValue().mobileNumber,
            countryCode: this.country.getRawValue().code,
            nationality: this.profileInfoFrom.getRawValue().countryDetails.countryName
          });

          let resultBilling = {};

          this.osuiteFacade.getBillingAddress().subscribe((res: any) => {
            resultBilling = res;
          });
          this.osuiteFacade.setBillingAddress({
            ...resultBilling,
            country: this.profileInfoFrom.getRawValue().countryBilling.countryName,
            city: this.profileInfoFrom.getRawValue().city || '',
            state: this.profileInfoFrom.getRawValue().state || '',
            zipCode: this.profileInfoFrom.getRawValue().zipCode,
            primaryAddress: this.profileInfoFrom.getRawValue().address1,
            secondaryAddress: this.profileInfoFrom.getRawValue().address2
          });
          this.visibleChange.emit(false);
        });
    }
  }

  closeDialog() {
    this.visibleChange.emit(false);
  }

  handleUserImageChange(file: File): void {
    let formData = new FormData();
    formData.append('file', file);

    this.osuiteFacade
      .uploadProfilePic(formData)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: any) => {
        if (res) {
          let temp = {};
          this.osuiteFacade
            .getProfileInfo()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((profileInfo: any) => {
              temp = profileInfo;
            });
          this.osuiteFacade.setProfileInfo({
            ...temp,
            profilePic: res.data
          });
        }
      });
  }
}
