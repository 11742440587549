import { Injectable } from '@angular/core';
import { ICustomerOrder } from '@app/model';
import {
  ICustomerInfo,
  IDashboardRevenue,
  ITimeline,
  ITopCustomer,
  IUpcomingProduct
} from '@app/model/dashboard.model';
import { IContact } from '@app/model/my-contacts.model';
import { IBillingInfo, ICountry, IProfileInfo } from '@app/model/myProfile.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OsuiteState {
  dashboardRevenue = new BehaviorSubject<IDashboardRevenue | null>(null);
  dashboardTotalRevenue = new BehaviorSubject<any | null>(null);

  timelineData = new BehaviorSubject<ITimeline[] | null>(null);
  topCustomers = new BehaviorSubject<ITopCustomer[] | null>(null);
  customerInfo = new BehaviorSubject<ICustomerInfo | null>(null);
  myEarnings = new BehaviorSubject<ICustomerOrder[] | null>(null);
  upcominProduct = new BehaviorSubject<IUpcomingProduct[] | null>(null);
  isEditModalOpen = new BehaviorSubject<boolean>(false);
  editModalData = new BehaviorSubject<null | IContact>(null);
  isContactFavourite = new BehaviorSubject<boolean>(false);

  setUpcomingProducts(upcomingProducts: IUpcomingProduct[] | null) {
    this.upcominProduct.next(upcomingProducts);
  }
  getUpcomingProducts(): Observable<IUpcomingProduct[] | null> {
    return this.upcominProduct.asObservable();
  }
  setDashboardRevenue(stat: IDashboardRevenue | null) {
    this.dashboardRevenue.next(stat);
  }
  getDashboardRevenue(): Observable<IDashboardRevenue | null> {
    return this.dashboardRevenue.asObservable();
  }

  setDashboardTotalRevenue(stat: any | null) {
    this.dashboardTotalRevenue.next(stat);
  }
  getDashboardTotalRevenue(): Observable<any | null> {
    return this.dashboardTotalRevenue.asObservable();
  }
  setTimelineData(timelineData: ITimeline[] | null) {
    this.timelineData.next(timelineData);
  }
  getTimelineData(): Observable<ITimeline[] | null> {
    return this.timelineData.asObservable();
  }
  setTopCustomers(customers: ITopCustomer[] | null) {
    this.topCustomers.next(customers);
  }
  getTopCustomers(): Observable<ITopCustomer[] | null> {
    return this.topCustomers.asObservable();
  }
  setCustomerInfo(customerInfo: ICustomerInfo | null) {
    this.customerInfo.next(customerInfo);
  }
  getCustomerInfo(): Observable<ICustomerInfo | null> {
    return this.customerInfo.asObservable();
  }
  setMyTotalEarnings(data: ICustomerOrder[] | null) {
    this.myEarnings.next(data);
  }
  getMyTotalEarnings(): Observable<ICustomerOrder[] | null> {
    return this.myEarnings.asObservable();
  }

  //MyProfile

  profileInfo = new BehaviorSubject<IProfileInfo | null>(null);
  billingAddress = new BehaviorSubject<IBillingInfo | null>(null);
  countries = new BehaviorSubject<ICountry[] | null>(null);

  setProfileInfo(data: IProfileInfo | null) {
    this.profileInfo.next(data);
  }
  getProfileInfo(): Observable<IProfileInfo | null> {
    return this.profileInfo.asObservable();
  }
  setBillingAddress(data: IBillingInfo | null) {
    this.billingAddress.next(data);
  }
  getBillingAddress(): Observable<IBillingInfo | null> {
    return this.billingAddress.asObservable();
  }

  setCountries(data: ICountry[] | null) {
    this.countries.next(data);
  }
  getCountries(): Observable<ICountry[] | null> {
    return this.countries.asObservable();
  }

  setIsEditModalOpen(isEditModalOpen: boolean) {
    this.isEditModalOpen.next(isEditModalOpen);
  }

  //My Contacts

  stateList = new BehaviorSubject<any>(false);

  getIsEditModalOpen(): Observable<boolean> {
    return this.isEditModalOpen.asObservable();
  }

  setEditModalData(data: any) {
    this.editModalData.next(data);
  }

  getEditModalData() {
    return this.editModalData.asObservable();
  }

  getIsContactFavourite(): Observable<boolean> {
    return this.isContactFavourite.asObservable();
  }

  setIsContactFavourite(data: boolean) {
    this.isContactFavourite.next(data);
  }

  getStateList(): Observable<any> {
    return this.stateList.asObservable();
  }

  setStateList(data: any) {
    this.stateList.next(data);
  }
}
