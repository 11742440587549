import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, inject, signal, ViewChild } from '@angular/core';
import { CalendarSidebarComponent } from '@app/components/calendar/calendar-sidebar/calendar-sidebar.component';
import { FullCalendarComponent, FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions, DateSelectArg, EventApi, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { CommonModule, DatePipe } from '@angular/common';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { DividerModule } from 'primeng/divider';
import { IconsModule } from '@app/shared/modules/icons.module';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { InputNumberModule } from 'primeng/inputnumber';
import { IApiResponseData, ICalendarEventsData } from '@app/shared/models';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CreateEventPopupComponent } from '@app/pages/calendar/create-event-popup/create-event-popup.component';
import { CalendarFacade } from '@app/pages/calendar/services/calendar.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IMyProfileData } from '@app/shared/models/settings.model';
import { ViewEventPopupComponent } from '@app/pages/calendar/view-event-popup/view-event-popup.component';
import { debounceTime } from 'rxjs';
import { Tooltip } from 'primeng/tooltip';
import { start } from 'single-spa';

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [
    CalendarSidebarComponent,
    CommonModule,
    FullCalendarModule,
    DialogModule,
    InputTextModule,
    CheckboxModule,
    FormsModule,
    ConfirmDialogModule,
    CalendarModule,
    DropdownModule,
    ReactiveFormsModule,
    DividerModule,
    IconsModule,
    ConfirmPopupModule,
    InputNumberModule,
    RadioButtonModule,
    CreateEventPopupComponent,
    ViewEventPopupComponent,
  ],
  providers: [ConfirmationService, DatePipe],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss',
})
export class CalendarComponent implements AfterViewInit {
  private changeDetector = inject(ChangeDetectorRef);
  private calendarFacade = inject(CalendarFacade);
  private destroyRef = inject(DestroyRef);
  private datePipe = inject(DatePipe);

  calendarVisible = signal(true);
  calendarOptions = signal<CalendarOptions>({
    dateAlignment: undefined,
    editable: undefined,
    eventClassNames: undefined,
    eventLongPressDelay: undefined,
    monthStartFormat: undefined,
    moreLinkClick: undefined,
    slotLabelClassNames: undefined,
    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
    headerToolbar: {
      left: 'today',
      center: 'prev title next',
      right: '',
    },
    initialView: 'dayGridMonth',
    weekends: true,
    nowIndicator: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    allDaySlot: false,
    displayEventEnd: true,
    displayEventTime: true,

    selectAllow: (selectInfo) => {
      if (this.isBeforeToday(selectInfo.start) || this.isBeforeNowTime(selectInfo.start)) return false;

      const start = selectInfo.start;
      const end = selectInfo.end;
      const dayDifference = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);
      if (dayDifference > 1) this.selectedDayInfo.isOpenPopup = false;
      return dayDifference <= 1;
    },
    eventDidMount: (info) => {
      const tooltipContent = `${info.event.title}\n${info.event.start?.toLocaleTimeString()}\n${info.event.end?.toLocaleTimeString()}`;
      info.el.setAttribute('title', tooltipContent);
    },

    nowIndicatorClassNames: 'now-indicator',
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      meridiem: true,
    },
    dayCellClassNames: this.addBackgroundToSelectedDay.bind(this),
    customButtons: {
      today: {
        text: 'Today',
        click: () => {
          this.handleTodayClick();
          this.setRangeDate();
        },
      },
      prev: {
        text: 'Prev',
        click: () => {
          const calendarApi = this.calendarComponent?.getApi();
          calendarApi.prev();
          this.setRangeDate();
        },
      },
      next: {
        text: 'Next',
        click: () => {
          const calendarApi = this.calendarComponent?.getApi();
          calendarApi.next();
          this.setRangeDate();
        },
      },
    },
  });
  currentEvents = signal<EventApi[]>([]);
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;

  selectedDate: string = new Date().toString();
  returnToToday: boolean = false;
  displayModal = false;
  eventTitle = '';
  eventAllDay = false;
  selectedDates: DateSelectArg | null = null;
  customRepeatVisibility: boolean = false;
  selectedDayInfo!: { selectedDayInfo: DateSelectArg; isOpenPopup: boolean };
  selectedEventInfo!: { selectedEventInfo: EventClickArg; isOpenPopup: boolean };
  profileEmailId: string = '';
  startDate: string = '';
  endDate: string = '';

  isMonthView() {
    if (this.calendarComponent) {
      const calendarApi = this.calendarComponent.getApi();
      return calendarApi.view.type === 'dayGridMonth';
    }
    return true;
  }

  ngAfterViewInit() {
    this.calendarFacade
      .getCalendarEvents()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: ICalendarEventsData[] | null) => {
        if (data) {
          const calendarApi = this.calendarComponent.getApi();
          data.forEach((event: ICalendarEventsData) => {
            calendarApi.addEvent({
              id: event.id,
              title: event.name,
              start: event.startDateTime,
              end: event.endDateTime,
            });
          });
        }
      });
  }

  getCalendarEvents() {
    const calendarApi = this.calendarComponent?.getApi();
    calendarApi.removeAllEvents();

    const startDate = this.datePipe.transform(this.startDate, 'yyyy-MM-ddTHH:mm:ss') || '';
    const endDate = this.datePipe.transform(this.endDate, 'yyyy-MM-ddTHH:mm:ss') || '';

    this.profileEmailId = sessionStorage.getItem('profileEmailId') || '';

    if (!!this.profileEmailId) {
      this.calendarFacade.getCalendarEventsData({
        emailId: this.profileEmailId,
        status: 'all',
        fromDate: startDate,
        pageNo: null,
        pageSize: null,
        toDate: endDate,
      });
    }
  }

  handleTodayClick(): void {
    const calendarApi = this.calendarComponent?.getApi();
    if (calendarApi) {
      this.returnToToday = !this.returnToToday;
      calendarApi.today();
    }
  }

  setSelectedOption(view: string) {
    const calendarApi = this.calendarComponent?.getApi();
    if (calendarApi) {
      calendarApi?.changeView(view);
      this.setRangeDate();
    }

    if (this.isMonthView()) {
      this.calendarOptions().displayEventEnd = true;
      this.calendarOptions().displayEventTime = true;
    } else {
      this.calendarOptions().displayEventEnd = false;
      this.calendarOptions().displayEventTime = false;
    }
  }

  setRangeDate() {
    const calendarApi = this.calendarComponent?.getApi();
    if (calendarApi) {
      const startDate = calendarApi.view.activeStart;
      const endDate = calendarApi.view.activeEnd;
      this.startDate = startDate.toISOString();
      this.endDate = endDate.toISOString();

      if (this.startDate === this.endDate) {
        endDate.setDate(endDate.getDate() - 1);
      }

      this.getCalendarEvents();
    }
  }

  setSelectedDate(selectedDate: string) {
    if (selectedDate) {
      this.selectedDate = selectedDate;
      const calendarApi = this.calendarComponent?.getApi();
      if (calendarApi) {
        calendarApi?.gotoDate(selectedDate);
        this.setRangeDate();
      }
    }
  }

  addBackgroundToSelectedDay(arg: { date: Date }): string {
    if (this.selectedDate) {
      const selectedDateString = this.selectedDate;
      const currentCellDate = this.formatDateToLocal(arg.date);

      if (selectedDateString === currentCellDate) {
        return 'selected-day';
      }
    }
    return '';
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    this.selectedDates = selectInfo;
    this.eventTitle = '';
    this.eventAllDay = selectInfo.allDay || false;

    if (this.isMonthView()) {
      selectInfo.start.setHours(new Date().getHours());
      selectInfo.end.setHours(new Date().getHours() + 1);
    }

    this.selectedDayInfo = {
      selectedDayInfo: selectInfo,
      isOpenPopup: false,
    };
  }

  createEvent() {
    const calendarApi = this.calendarComponent.getApi();
    const selectedDate = new Date(this.selectedDate);

    const dayCellElement = document.querySelector(`[data-date="${this.formatDateToLocal(selectedDate)}"]`);

    if (dayCellElement) {
      const mockEvent = {
        target: dayCellElement,
      } as unknown as MouseEvent;

      const endDate = new Date(selectedDate);
      endDate.setHours(endDate.getHours() + 1);

      this.selectedDayInfo = {
        selectedDayInfo: {
          start: selectedDate,
          end: endDate,
          allDay: true,
          jsEvent: mockEvent,
          startStr: selectedDate.toISOString(),
          endStr: selectedDate.toISOString(),
          view: calendarApi.view,
        },
        isOpenPopup: true,
      };
    } else {
      console.warn('Could not find the day cell for the selected date.');
    }
  }

  saveEvent() {
    if (this.selectedDates && this.eventTitle) {
      const calendarApi = this.selectedDates.view.calendar;
      calendarApi.addEvent({
        id: String(new Date().getTime()),
        title: this.eventTitle,
        start: this.selectedDates.startStr,
        end: this.selectedDates.endStr,
        allDay: this.eventAllDay,
      });
    }
    this.closeModal();
  }

  closeModal() {
    this.displayModal = false;
    this.selectedDates = null;
    this.eventTitle = '';
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.selectedEventInfo = {
      selectedEventInfo: clickInfo,
      isOpenPopup: true,
    };
  }

  formatDateToLocal(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents.set(events);
    this.changeDetector.detectChanges();
  }

  isBeforeToday(date: Date): boolean {
    const today = new Date();
    const inputDate = new Date(date);
    today.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);
    return inputDate.getTime() < today.getTime();
  }

  isBeforeNowTime(date: Date): boolean {
    const calendarApi = this.calendarComponent.getApi();
    const today = new Date();
    const inputDate = new Date(date);
    return inputDate.getTime() < today.getTime() && calendarApi.view.type !== 'dayGridMonth';
  }
}
