import { IConfirmDialogData } from 'o-suite-lib';
import { ITrimmedLinksData } from '../../models';

export const deleteUrlConfirmationData: IConfirmDialogData = {
  header: 'Delete URL',
  message: 'Do you want to proceed with deleting this URL?',
  acceptBtnLabel: 'Delete',
  rejectBtnLabel: 'Cancel',
};

export const loadingData: ITrimmedLinksData = {
  aliasName: '',
  clickCount: 0,
  createdDate: '',
  destinationUrl: '',
  isBroken: false,
  isEnabled: false,
  noOfLeads: '',
  qrCount: '',
  shortEndUrl: '',
  customName: '',
  trimUrlId: 0,
  updatedDate: '',
  urlType: '',
};
