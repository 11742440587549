<div id="suite_dashboard_emptyRevenue_container" class="unlock-potential-container text-center">
  <div id="suite_dashboard_emptyRevenue_icon" class="icon mb-2">
    <svg-icon name="emptyRevenue"></svg-icon>
  </div>

  <!-- Title -->
  <h2 id="suite_dashboard_emptyRevenue_title" class="text-color-tertiary-500 font-bold my-1">Unlock Your Potential!</h2>

  <!-- Description -->
  <p id="suite_dashboard_emptyRevenue_description" class="text-color-tertiary-500 text-sm">
    Let’s turn these numbers into a story of success. With every sale, you’re not just contributing to revenue; you’re
    building a brighter future for yourself and our team.
  </p>

  <!-- Highlighted Footer Text -->
  <p id="suite_dashboard_emptyRevenue_highlight" class="highlight my-3">
    Every great achievement starts with a single step. Let’s fill this chart with our success!
  </p>
</div>
