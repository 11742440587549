import { Component, DestroyRef, effect, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IContact } from '@app/model/my-contacts.model';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { OsuiteState } from '@app/services/osuite.state';
import { environment } from '@src/environments/environment';
import { SkeletonModule } from 'primeng/skeleton';
import { COUNTRY_SVG_URL } from '@app/shared/constants';
import { DatePipe } from '@angular/common';
import { ToasterService } from '@app/services/toaster.service';

@Component({
  selector: 'app-contact-card',
  standalone: true,
  imports: [DividerModule, AvatarModule, ButtonModule, FontAwesomeModule, OverlayPanelModule, SkeletonModule, DatePipe],
  templateUrl: './contact-card.component.html',
  styleUrl: './contact-card.component.scss'
})
export class ContactCardComponent implements OnInit {
  @Input() selectedContact!: IContact | null;
  @Input() stateList: any = [];
  stateName!: string | null;

  private osuiteState = inject(OsuiteState);
  @Output() isContactDeleted = new EventEmitter<boolean>();
  private toasterService = inject(ToasterService);

  private osuiteFacade = inject(OsuiteFacade);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.osuiteState.getStateList().subscribe((res) => {
      if (res) {
        this.stateName = res;
      } else {
        this.stateName = null;
      }
    });
  }

  handleOpenEditContact(data: any) {
      this.osuiteState.setEditModalData(data);
      this.osuiteState.setIsEditModalOpen(true);
  }

  handleDeleteContact() {
      if (this.selectedContact) {
        this.osuiteFacade
          .deleteContact([this.selectedContact.contactId])
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (res) => {
              this.toasterService.setToasterState(true, 'success',res?.message );
              this.isContactDeleted.emit(true);
            }
          });
      }
  }

  handleRerouteMail() {}

  protected readonly environment = environment;
  protected readonly COUNTRY_SVG_URL = COUNTRY_SVG_URL;
}
