import { Folder } from '@app/shared/models/omail';
import type { MenuItem } from '@app/shared/models/menu-items-sidebar';

export const TOTAL_EMAILS_IN_PAGE = 20;

export const EMAIL_ACTIONS = {
  REPLY: 'Reply',
  FORWARD: 'Forward',
  REPLY_ALL: 'Reply_All',
  NEW_EMAIL: 'New_Email',
  DRAFT: 'Draft',
  SENT: 'Sent',
};

export const MAILBOX_ROUTES = {
  MAIL: 'o-mailo',
  NEW_EMAIL: 'new-email',
  INBOX: 'inbox',
  PRIORITY: 'priority',
  DRAFT: 'draft',
  SENT: 'sent',
  OUTBOX: 'outbox',
  SCHEDULED: 'scheduled',
  DELETED: 'deleted',
  Folder: 'folder',
};

export const CONTACTS_ROUTES = {
  MY_CONTACTS: 'o-mailo/my-contacts',
  CONTACTS: 'all-contacts',
  FAVORITES: 'favorites',
  GROUPS: 'groups',
  DELETED: 'deleted',
  CREATE_CONTACT: 'create-contact',
};

export const SETTINGS_ROUTES = {
  SETTINGS: 'o-mailo/settings',
  PROFILE: 'profile',
  EMAIL: 'email',
  AUTO_REPLY: 'auto-reply',
  EMAIL_RULES: 'email-rules',
  FOLDER_LOCK: 'folder-lock',
  AUTO_FOLLOW_UP: 'auto-follow-up',
  MY_EMAIL_DASHBOARD: 'my-email-dashboard',
  STORAGE: 'storage',
};

export const CALENDER_ROUTES = {
  CALENDER: 'o-mailo/calendar',
};

export const MENU_MAIL_ITEMS: MenuItem[] = [
  {
    id: MAILBOX_ROUTES.INBOX,
    text: 'Inbox',
    icon: 'inbox',
    // badge: 2,
  },
  {
    id: MAILBOX_ROUTES.PRIORITY,
    text: 'Priority',
    icon: 'bookmark',
  },
  {
    id: MAILBOX_ROUTES.DRAFT,
    text: 'Draft',
    icon: 'file-lines',
  },
  {
    id: MAILBOX_ROUTES.SENT,
    text: 'Sent',
    icon: 'box',
  },
  {
    id: MAILBOX_ROUTES.OUTBOX,
    text: 'Outbox',
    icon: 'box-open',
  },
  {
    id: MAILBOX_ROUTES.SCHEDULED,
    text: 'Scheduled',
    icon: 'envelope-circle-check',
  },
  {
    id: MAILBOX_ROUTES.DELETED,
    text: 'Deleted',
    icon: 'trash-can',
  },
];

export const MENU_CONTACT_ITEMS: MenuItem[] = [
  {
    id: CONTACTS_ROUTES.CONTACTS,
    text: 'All Contacts',
    icon: 'id-badge',
  },
  // {
  //   id: CONTACTS_ROUTES.FAVORITES,
  //   text: 'Favorites',
  //   icon: 'star',
  //   comingSoon: true,
  // },
  // {
  //   id: CONTACTS_ROUTES.GROUPS,
  //   text: 'Groups',
  //   icon: 'users',
  //   comingSoon: true,
  // },
  //
  // {
  //   id: CONTACTS_ROUTES.DELETED,
  //   text: 'Deleted',
  //   icon: 'trash-can',
  //   comingSoon: true,
  // },
];

export const MENU_SETTINGS_ITEMS: MenuItem[] = [
  {
    id: SETTINGS_ROUTES.PROFILE,
    text: 'Profile',
    icon: 'id-badge',
  },
  // {
  //   id: SETTINGS_ROUTES.EMAIL,
  //   text: 'Email',
  //   icon: 'star',
  // },
  {
    id: SETTINGS_ROUTES.AUTO_REPLY,
    text: 'Auto Reply',
    icon: 'users',
  },
  // {
  //   id: SETTINGS_ROUTES.EMAIL_RULES,
  //   text: 'Email Rules',
  //   icon: 'trash-can',
  // },
  // {
  //   id: SETTINGS_ROUTES.FOLDER_LOCK,
  //   text: 'Folder Lock',
  //   icon: 'trash-can',
  // },
  // {
  //   id: SETTINGS_ROUTES.AUTO_FOLLOW_UP,
  //   text: 'Auto Follow Up',
  //   icon: 'trash-can',
  // },
  // {
  //   id: SETTINGS_ROUTES.MY_EMAIL_DASHBOARD,
  //   text: 'My Email Dashboard',
  //   icon: 'trash-can',
  // },
  // {
  //   id: SETTINGS_ROUTES.STORAGE,
  //   text: 'Storage',
  //   icon: 'trash-can',
  // },
];
