import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AngularSvgIconModule, SvgIconRegistryService } from 'angular-svg-icon';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { assetUrl } from '../../../single-spa/asset-url';

@NgModule({
  imports: [CommonModule, AngularSvgIconModule.forRoot()],
  exports: [CommonModule, AngularSvgIconModule],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class svgIconModule {
  constructor(private iconReg: SvgIconRegistryService) {
    this.iconReg.loadSvg(assetUrl('icons/contact-empty-state.svg'), 'contact-empty-state');
  }
}
