import { MAILBOX_ROUTES } from '@app/constants';

export const getMailbox = (mailbox: string) => {
  if (mailbox == MAILBOX_ROUTES.DELETED) {
    return 'Trash';
  } else if (mailbox == MAILBOX_ROUTES.DRAFT) {
    return 'Drafts';
  } else if (mailbox == MAILBOX_ROUTES.PRIORITY) {
    return 'Important';
  } else if (mailbox == MAILBOX_ROUTES.INBOX) {
    return 'INBOX';
  } else if (mailbox == MAILBOX_ROUTES.SENT) {
    return 'Sent';
  } else if (mailbox == MAILBOX_ROUTES.OUTBOX) {
    return 'Outbox';
  }
  return mailbox;
};
