import { Component, inject, input, output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { OButtonDirective } from 'o-suite-lib';
import { AddContactDialogComponent } from '@app/pages/my-contacts/add-contact-dialog/add-contact-dialog.component';
import { EditContactDialogComponent } from '@app/pages/my-contacts/edit-contact-dialog/edit-contact-dialog.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { ContactsFacade } from '@app/pages/my-contacts/services/contacts.facade';

@Component({
  selector: 'app-sup-sidebar',
  standalone: true,
  imports: [ButtonModule, OButtonDirective, AddContactDialogComponent, EditContactDialogComponent],
  templateUrl: './sup-sidebar.component.html',
  styleUrl: './sup-sidebar.component.scss',
})
export class SupSidebarComponent {
  private contactsFacade = inject(ContactsFacade);

  title = input.required();
  buttonText = input<string>();
  onCreateEvent = output();

  isLoadingState = toSignal(this.contactsFacade.getIsLoadingState$());

  onButtonClick() {
    this.onCreateEvent.emit();
  }
}
