import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value || '';
    const errors: { [key: string]: string } = {};

    if (value.length < 8 || value.length > 16) {
      errors['length'] = 'Password must be between 8 and 16 characters.';
    }
    if (!/[a-z]/.test(value)) {
      errors['lowercase'] = 'Password must include at least one lowercase letter.';
    }
    if (!/[A-Z]/.test(value)) {
      errors['uppercase'] = 'Password must include at least one uppercase letter.';
    }
    if (!/\d/.test(value)) {
      errors['digit'] = 'Password must include at least one digit.';
    }
    if (!/[@$!%*?&]/.test(value)) {
      errors['specialChar'] = 'Password must include at least one special character (e.g., @$!%*?&).';
    }

    return Object.keys(errors).length > 0 ? errors : null;
  };
}
