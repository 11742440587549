// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* You can add global styles to this file, and also import other style files */
body,
html {
  padding: 0;
  margin: 0;
  height: 100%;
}

.custom-border-radius {
  border-radius: 13px;
}`, "",{"version":3,"sources":["webpack://./src/styles.scss"],"names":[],"mappings":"AAAA,8EAAA;AAEA;;EAEE,UAAA;EACA,SAAA;EACA,YAAA;AAAF;;AAGA;EACE,mBAAA;AAAF","sourcesContent":["/* You can add global styles to this file, and also import other style files */\n\nbody,\nhtml {\n  padding: 0;\n  margin: 0;\n  height: 100%;\n}\n\n.custom-border-radius {\n  border-radius: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
