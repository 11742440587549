import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { OsuiteState } from '@app/services/osuite.state';
import { MyContactsFilterType } from '@app/shared/enums/ filter.enum';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';

@Component({
  selector: 'app-filter-options',
  standalone: true,
  imports: [ButtonModule, DividerModule, FaIconComponent],
  templateUrl: './filter-options.component.html',
  styleUrl: './filter-options.component.scss'
})
export class FilterOptionsComponent {
  protected readonly FilterType = MyContactsFilterType;

  @Input() filter: MyContactsFilterType = MyContactsFilterType.CONTACTS;
  filterFavorite: boolean = false;
  @Output() filterChange = new EventEmitter<MyContactsFilterType>();

  private osuiteState = inject(OsuiteState);

  filterContacts() {
    this.filterChange.emit(MyContactsFilterType.CONTACTS);
  }
  filterGroups() {
    this.filterChange.emit(MyContactsFilterType.GROUPS);
  }
  filterFavourites() {
    this.osuiteState.setIsContactFavourite(!this.filterFavorite);
    this.filterFavorite = !this.filterFavorite;
  }

  exportCSV() {}
}
