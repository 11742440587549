<p-dialog
  [modal]="true"
  (onHide)="closeDialog()"
  [(visible)]="visible"
  [draggable]="false"
  [dismissableMask]="true"
  id="suite_myProfile_dialog_resetPassword"
>
  <div class="flex flex-column item h-full">
    <h2 class="text-center px-4">Reset password</h2>

    <form
      [formGroup]="resetPasswordForm"
      class="w-full flex-1 flex flex-column justify-content-between"
      id="suite_myProfile_form_resetPassword"
    >
      <div>
        <div class="px-8">
          <label class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">
            Old password
            <span class="text-xs star">*</span>
          </label>
          <p-password
            id="suite_myProfile_input_confirmPassword"
            placeholder="Old password"
            [toggleMask]="true"
            formControlName="oldPassword"
            [feedback]="false"
            [ngClass]="{ 'ng-invalid ng-dirty': submitted && resetPasswordForm.get('oldPassword')?.invalid }"
          />
          @if (resetPasswordForm.get('oldPassword')?.invalid && submitted ) {
          <small class="text-color-danger-500">Old password is required</small>
          }
        </div>
        <div
          class="mt-4 px-8"
          [ngClass]="{
            'remove-label': !(
              hasError('lowercase') ||
              hasError('uppercase') ||
              hasError('digit') ||
              hasError('specialChar') ||
              hasError('length')
            )
          }"
        >
          <label class="text-sm pl-2 font-medium text-color-tertiary-400">
            New password
            <span class="text-xs star">*</span>
          </label>
          <p-password
            id="sso_signup_input_password"
            [toggleMask]="true"
            placeholder="New password"
            formControlName="newPassword"
            [ngClass]="{ 'ng-invalid ng-dirty': submitted && resetPasswordForm.get('newPassword')?.invalid }"
          >
            <ng-template pTemplate="footer">
              <p-divider
                *ngIf="
                  hasError('lowercase') ||
                  hasError('uppercase') ||
                  hasError('digit') ||
                  hasError('specialChar') ||
                  hasError('length')
                "
              />
              <p
                *ngIf="
                  hasError('lowercase') ||
                  hasError('uppercase') ||
                  hasError('digit') ||
                  hasError('specialChar') ||
                  hasError('length')
                "
                class="mt-2"
              >
                Must have
              </p>

              <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5; list-style-type: none">
                <ng-container *ngFor="let error of passwordErrors">
                  <li *ngIf="hasError(error.key)" [ngClass]="{ 'text-danger': hasError(error.key) }">
                    ❌ {{ error.message }}
                  </li>
                </ng-container>
              </ul>
            </ng-template>
          </p-password>

          @if(submitted && resetPasswordForm.get('newPassword')?.errors?.['required']){
          <small class="text-color-danger-500">New password is required</small>
          } @else if (submitted && resetPasswordForm.errors?.['passwordMatch']){
          <small class="text-color-danger-500">Can't use old password</small>
          } @else if(submitted && resetPasswordForm.get('newPassword')?.errors){
          <small class="text-color-danger-500">Invalid password</small>
          }
        </div>
        <div class="mt-4 px-8">
          <label class="text-sm mb-1 pl-2 font-medium text-color-tertiary-400">
            Confirm password
            <span class="text-xs star">*</span>
          </label>
          <p-password
            id="suite_myProfile_input_confirmPassword"
            placeholder="Confirm password"
            [toggleMask]="true"
            formControlName="confirmPassword"
            [feedback]="false"
            [ngClass]="{ 'ng-invalid ng-dirty': submitted && resetPasswordForm.get('confirmPassword')?.invalid }"
          />
          @if (submitted && resetPasswordForm.errors?.['passwordNotMatch'] ) {
          <small class="text-color-danger-500">Passwords do not match.</small>
          } @else if (submitted && resetPasswordForm.get('confirmPassword')?.errors?.['required'] ) {
          <small class="text-color-danger-500">Confirm password is required</small>
          }
        </div>
      </div>

      <div class="flex justify-content-end gap-3">
        <p-button
          label="Cancel"
          [outlined]="true"
          styleClass="px-4 py-2 custom-border-radius font-semibold"
          (click)="closeDialog()"
        />
        <p-button
          o-button
          [oButtonSeverity]="'success'"
          label="Save"
          styleClass="px-4 py-2 custom-border-radius font-semibold"
          type="submit"
          (click)="onSubmit()"
          [loading]="isLoading"
        />
      </div>
    </form>
  </div>
</p-dialog>
