import { HttpInterceptorFn } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';

export const encryptInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.body && !req.url.includes('checkFileName') && !req.url.includes('csvFileupload2')) {
    const encryptionKey = environment.DEFAULT_KEY;
    const encryptedBody = CryptoJS.AES.encrypt(JSON.stringify(req.body), encryptionKey).toString();

    const encryptedRequest = req.clone({
      body: encryptedBody,
    });

    return next(encryptedRequest);
  }

  return next(req);
};
