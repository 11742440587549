import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { TextToSpeechService } from '@app/services/text-to-speech.service';
import { IconsModule } from '@app/shared/modules/icons.module';
import { MenuItem } from 'primeng/api';
import { SplitButtonModule } from 'primeng/splitbutton';
import { assetUrl } from '../../../../../../single-spa/asset-url';

@Component({
  selector: 'app-text-to-speech',
  standalone: true,
  imports: [IconsModule, CommonModule, SplitButtonModule],
  templateUrl: './text-to-speech.component.html',
  styleUrls: ['./text-to-speech.component.scss'],
})
export class TextToSpeechComponent implements OnInit {
  @Input() textToRead: string = 'This is the default text to read.';
  isVoiceListVisible: boolean = false;
  voices: SpeechSynthesisVoice[] = [];
  voiceMenuItems: MenuItem[] = [];
  ttsService = inject(TextToSpeechService);

  ngOnInit(): void {
    this.loadVoices();
  }

  loadVoices(): void {
    this.ttsService.getVoices().then((voices) => {
      this.ttsService.setVoice(this.voices[0]);
      this.voices = voices;
      this.voiceMenuItems = this.voices
        .filter((voice) => voice.lang.includes('en') || voice.lang.includes('us'))
        .slice(0, 4)
        .map((voice) => ({
          label: `${voice.name} (${voice.lang})`,
          command: () => this.selectVoice(voice),
        }));
    });
  }

  startTextToSpeech(): void {
    if (window.speechSynthesis.speaking) {
      this.ttsService.stopSpeech();
    } else {
      this.ttsService.speakText(this.textToRead);
    }
  }

  toggleVoiceList(): void {
    this.isVoiceListVisible = !this.isVoiceListVisible;
  }

  selectVoice(voice: SpeechSynthesisVoice): void {
    this.ttsService.setVoice(voice);
    this.isVoiceListVisible = false;
  }

  protected readonly assetUrl = assetUrl;
}
