import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule } from 'primeng/button';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { IUpcomingProduct } from '@app/model/dashboard.model';
import { IconsModule, svgIconModule } from '@app/shared/modules';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IApiResponseWithData } from '@app/model/api.model';
import { assetUrl } from '@src/single-spa/asset-url';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-upcoming-products',
  standalone: true,
  imports: [CommonModule, CarouselModule, ButtonModule, IconsModule, svgIconModule, SkeletonModule],
  templateUrl: './upcoming-products.component.html',
  styleUrls: ['./upcoming-products.component.scss']
})
export class UpcomingProductsComponent implements OnInit {
  products: IUpcomingProduct[] | null = null;
  activeIndex = 0;
  isLoading: boolean = true;

  private osuiteFacade = inject(OsuiteFacade);
  private destroyRef = inject(DestroyRef);

  onIndicatorClick(index: number): void {
    this.activeIndex = index;
  }

  ngOnInit(): void {
    this.osuiteFacade
      .fetchUpcomingProducts()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res: IApiResponseWithData<IUpcomingProduct[]>) => {
          if (res) {
            this.products = res.data;
            this.isLoading = false;
          }
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  protected readonly assetUrl = assetUrl;
}
