import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ICustomerInfo } from '@app/model';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { IconsModule } from '@app/shared/modules';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-total-customers',
  standalone: true,
  imports: [IconsModule, CommonModule],
  templateUrl: './total-customers.component.html',
  styleUrl: './total-customers.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TotalCustomersComponent implements OnInit {
  customerInfo = signal<ICustomerInfo | null>(null);

  private osuiteFacade = inject(OsuiteFacade);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.osuiteFacade
      .getCustomerInfo()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res: ICustomerInfo | null) => {
          if (res) {
            this.customerInfo.set(res);
            return;
          }
          const initValue = {
            totalUsers: '0'
          };
          this.customerInfo.set(initValue);
        }
      });
  }
}
