<div class="menu-container">
  <div
    (click)="toggleDialog()"
    class="menu-button relative flex align-items-center justify-content-center border-circle icon-size cursor-pointer border-1"
    [ngClass]="{
      'border-primary': visible,
      'border-gray-300': !visible,
      'bg-white': visible
    }"
  >
    @if(!visible){
    <div class="menu-icon--inactive">
      <svg-icon name="menu-icon" />
    </div>
    }@else {
    <div class="menu-icon--active">
      <fa-icon [icon]="faTimes" [ngClass]="{ 'menu-icon--active': visible }" class="menu-icon text-red" />
    </div>
    }
  </div>

  <p-dialog
    header="Edit Profile"
    [modal]="true"
    (onHide)="closeDialog()"
    [(visible)]="visible"
    [draggable]="false"
    [dismissableMask]="true"
    position="topleft"
    [styleClass]="dialogStyleClass"
  >
    <div>
      <h3 class="m-0">Apps</h3>
      <app-apps-list [apps]="onFusionApps.frequentlyUsed" title="frequently used" (appClickedChange)="closeDialog()" />
    </div>
  </p-dialog>
</div>
