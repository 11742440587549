import { AfterViewInit, Component, DestroyRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { ECharts, EChartsOption } from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { IRevenueByDate } from '@app/model/dashboard.model';
import { SvgIconComponent } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize } from 'rxjs';
import * as echarts from 'echarts';

@Component({
  selector: 'app-total-revenue',
  standalone: true,
  imports: [NgxEchartsModule, SvgIconComponent, CommonModule],
  templateUrl: './total-revenue.component.html',
  styleUrls: ['./total-revenue.component.scss']
})
export class TotalRevenueComponent implements OnInit,AfterViewInit  {
  options: EChartsOption = {};
  totalRevenue!: number;
  isLoading: boolean = false;

  private osuiteFacade = inject(OsuiteFacade);
  private destroyRef = inject(DestroyRef);
  @ViewChild('chartDiv') chartDiv: any; // Reference to the div

  chartInstance: ECharts | undefined; // To hold the chart instance

  ngAfterViewInit() {
    // Initialize chartInstance when the view is ready
    this.chartInstance = echarts.init(this.chartDiv.nativeElement);
    this.chartInstance.setOption(this.options);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.osuiteFacade
      .getDashboardRevenue()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (res) => {
          this.totalRevenue =
            res?.earningsDistributedByMonthAndYear?.reduce(
              (sum: number, item: IRevenueByDate) => sum + item.totalRevenue,
              0
            ) || 0;
          this.options = {
            tooltip: {
              show: false
            },
            grid: {
              left: '0%',
              right: '0%',
              bottom: '0%',
              top: '0%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              show: false, // Hide the X-axis
              boundaryGap: false,
              data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            },
            yAxis: {
              type: 'value',
              show: false // Hide the Y-axis
            },
            series: [
              {
                data: res?.earningsDistributedByMonthAndYear?.map((item: IRevenueByDate) => item.totalRevenue) || [
                  0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 1
                ],
                type: 'line',
                smooth: false, // Makes the curve smooth
                areaStyle: {
                  color: '#E6F7FF' // Light blue gradient fill under the curve
                },
                lineStyle: {
                  color: '#00b5ea'
                },
                symbol: 'none' // Hide any points
              }
            ]
          };
          this.chartInstance?.setOption(this.options);
          this.chartInstance?.resize();
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }
}
