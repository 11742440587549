<div class="mx-8 my-4 link__container">
  <div class="flex flex-row align-items-center">
    <p-breadcrumb [model]="breadcrumbItems">
      <ng-template let-item pTemplate="item">
        @if (item.isRoot) {
          <img [src]=" assetUrl('icons/back-arrow.svg')" />
          <span class="text-2xl font-bold">{{ item.label }}</span>
        } @else {
          <span class="text-2xl">{{ item.label }}</span>
        }
      </ng-template>
      <ng-template pTemplate="separator">/</ng-template>
    </p-breadcrumb>
    <span class="text-2xl short-url ml-2">({{ shortEndUrl }})</span>
  </div>
  <p-calendar
    placeholder="Choose date"
    [(ngModel)]="date"
    [showIcon]="true"
    [iconDisplay]="'input'"
    selectionMode="range"
  />
  <p-divider />

  <div class="flex gap-4 flex-wrap">
    @for (card of analyticsCards; track card.key) {
      <app-analytics-card-component
        class="flex-1"
        [header]="card.header"
        [data]="card.data"
        [icon]="card.icon"
        [backgroundColor]="card.background"
        [iconColor]="card.iconColor"
      />
    }
  </div>

  <app-statics />
</div>
