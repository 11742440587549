<div class="h-full w-full p-4" id="suite_myProfile_container">
  <div class="flex justify-content-between align-items-center">
    <div class="flex align-items-center gap-2">
      <svg-icon name="myProfileIcon" />
      <h3 class="my-0">My Profile</h3>
    </div>
    <div class="flex gap-4">
      <p-button
        id="suite_myProfile_button_reset"
        label="Reset password"
        [outlined]="true"
        styleClass="py-1 px-3 border-1 custom-border-radius bg-white font-semibold"
        (click)="handleOpenChangePassword()"
      />
      <p-button
        id="suite_myProfile_button_editProfile"
        label="Edit profile"
        [outlined]="true"
        styleClass="p-1 px-3 custom-border-radius bg-white font-semibold"
        (click)="handleOpenEditProfile()"
      />
    </div>
  </div>
  <app-user-card />
</div>
@if(isEditModalOpen){
<app-edit-profile-modal [(visible)]="isEditModalOpen" />
} @if(isChangePasswordModalOpen){
<app-reset-password-modal [(visible)]="isChangePasswordModalOpen" />
}
