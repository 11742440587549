import { NgModule } from '@angular/core';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faUsers,
  faChartColumn,
  faClock,
  faLink,
  faQuestionCircle,
  faSquare,
  faHeart,
  faLock,
  faPhone,
  faUser,
  faX,
  faCamera,
  faEnvelope,
  faEllipsis,
  faCalendar,
  faPhoneVolume,
  faBriefcase,
  faLocationDot,
  faLocation,
  faPlus,
  faFilter,
  faMagnifyingGlass
} from '@fortawesome/free-solid-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule]
})
export class IconsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faUsers,
      faChartColumn,
      faClock,
      faLink,
      faQuestionCircle,
      faSquare,
      faHeart,
      faLock,
      faPhone,
      faUser,
      faX,
      faCamera,
      faUser,
      faHeart,
      faEnvelope,
      faEllipsis,
      faCalendar,
      faPhoneVolume,
      faBriefcase,
      faLocationDot,
      faLocation,
      faPlus,
      faFilter,
      faMagnifyingGlass
    );
  }
}
