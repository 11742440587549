<div
  id="suite_dashboard_topCustomers_container"
  class="top-customers shadow-1 bg-white pb-4 relative overflow-hidden border-round-lg h-full"
>
  <div class="title flex gap-2 align-items-center px-3 pt-3">
    <fa-icon
      id="suite_dashboard_topCustomers_icon"
      icon="users"
      class="logo flex justify-content-center align-items-center shadow-1 text-sm border border-circle"
    />
    <h2 id="suite_dashboard_topCustomers_title" class="text-base font-medium m-0">Top customers</h2>
  </div>
  @if(isLoading){
  <div class="flex gap-4 w-full justify-content-center mt-5">
    <p-skeleton width="3rem" height="3rem" />
    <p-skeleton width="3rem" height="3rem" />
    <p-skeleton width="3rem" height="3rem" />
    <p-skeleton width="3rem" height="3rem" />
    <p-skeleton width="3rem" height="3rem" />
    <p-skeleton width="3rem" height="3rem" />
  </div>
  } @else {
  <div id="suite_dashboard_topCustomers_content" class="px-4">
    @if (customers.length) { @if(customers.length>6){
    <p-carousel
      id="suite_dashboard_topCustomers_carousel"
      [value]="customers"
      [numVisible]="itemsPerPage"
      [numScroll]="itemsPerPage"
      [circular]="false"
      [showIndicators]="false"
      (onPage)="onPageChange($event)"
      [ngClass]="{
        'last-page': isLastPage,
        'first-page': currentPage === 0,
        'both-pages': currentPage !== 0 && !isLastPage
      }"
      styleClass="mt-4"
    >
      <ng-template let-customer let-i="index" pTemplate="item">
        <div id="suite_dashboard_topCustomers_customer" class="flex flex-column align-items-center">
          @if (customer.profilePic) {
          <div class="font-medium w-3rem h-3rem border-round-lg">
            <img
              [src]="imgHostUrl + customer.profilePic"
              class="carousel-img w-full h-full border-round-lg"
              alt="Customer image"
            />
          </div>
          } @else {

          <p-avatar id="suite_dashboard_topCustomers_customer-avatar" styleClass="border-round-lg" size="large">
            <fa-icon icon="user" class="text-white" />
          </p-avatar>

          }
          <p
            id="suite_dashboard_topCustomers_customer-name"
            class="mt-1 mb-0 text-sm"
            [ngClass]="{ 'overlay-name': customer.customerName.length > 7 }"
            [pTooltip]="customer.customerName"
          >
            {{ customer.customerName.slice(0, 7) }}
          </p>
        </div>
      </ng-template>
      <ng-template pTemplate="previousicon">
        @if (currentPage !== 0) {
        <div id="suite_dashboard_topCustomers_previous-icon" class="flex flex-column align-items-center">
          <fa-icon [icon]="faChevronLeft" [size]="'sm'" />
        </div>
        }
      </ng-template>

      <ng-template pTemplate="nexticon">
        @if (!isLastPage && customers.length>itemsPerPage) {
        <div id="suite_dashboard_topCustomers_next-icon" class="flex flex-column align-items-center">
          <fa-icon [icon]="faChevronRight" [size]="'sm'" />
        </div>
        }
      </ng-template>
    </p-carousel>
    }@else {
    <div class="flex justify-content-start mt-4 gap-3">
      @for(customer of customers; track customer.customerName){
      <div id="suite_dashboard_topCustomers_customer" class="flex flex-column align-items-center">
        @if (customer.profilePic) {
        <div class="font-medium w-3rem h-3rem border-round-lg">
          <img
            [src]="imgHostUrl + customer.profilePic"
            class="carousel-img w-full h-full border-round-lg"
            alt="Customer image"
          />
        </div>
        } @else {

        <p-avatar id="suite_dashboard_topCustomers_customer-avatar" styleClass="border-round-lg" size="large">
          <fa-icon icon="user" class="text-white" />
        </p-avatar>

        }
        <p
          id="suite_dashboard_topCustomers_customer-name"
          class="mt-1 mb-0 text-sm"
          [ngClass]="{ 'overlay-name': customer.customerName.length > 7 }"
          [pTooltip]="customer.customerName"
        >
          {{ customer.customerName.slice(0, 7) }}
        </p>
      </div>
      }
    </div>
    } } @else {
    <div id="suite_dashboard_topCustomers_empty-state" class="flex flex-column align-items-center h-full">
      <div id="suite_dashboard_topCustomers_empty-image">
        <img [src]="assetUrl('top-customers.png')" alt="top customers" class="empty-state-image" width="90px" />
      </div>
      <p id="suite_dashboard_topCustomers_empty-message" class="text-color-tertiary-500 text-xs text-center my-0">
        It seems there are currently no top customers to display!
        <br />
        This is a great opportunity to focus on building your customer base!
      </p>
    </div>
    }
  </div>
  }
</div>
