<p-dataView #dv [value]="cities" class="city-list-view__container">
  <ng-template pTemplate="list" let-cities>
    <div class="grid grid-nogutter mt-2">
      @for (city of cities; track city['CITY']; let index = $index) {
        <div class="col-12 city__container">
          <div class="flex justify-content-between align-items-center">
            <div class="flex gap-2 align-items-center my-2">
              <div>
                @if (!city?.isSrcError) {
                  <img
                    class="w-3rem h-3rem border-circle bg-cover flag-img"
                    [alt]="city?.CITY"
                    [src]="countryFlagUrl + city?.countryCode + '.svg'"
                    (error)="onImageError(index)"
                  />
                } @else {
                  <fa-icon [icon]="faFlag"></fa-icon>
                }
              </div>
              <span class="text-lg">{{ city['CITY'] }}</span>
            </div>

            <span class="text-primary text-lg font-bold">{{ city?.count }}</span>
          </div>
        </div>
      }
    </div>
  </ng-template>
</p-dataView>
