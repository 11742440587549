import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { IDialogStep } from '../../model/header.model';

@Component({
  selector: 'o-update-dialog',
  standalone: true,
  imports: [ButtonModule, DialogModule, TooltipModule, CommonModule],
  templateUrl: './update-dialog.component.html',
  styleUrl: './update-dialog.component.scss'
})
export class UpdateDialogComponent {
  @Input() visible: boolean = false;
  @Input() steps!: IDialogStep[];
  @Input() currentStep: number = 0;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

  handleBack() {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  handleNext() {
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep++;
    }
  }

  handleFinish() {
    this.currentStep = 0;
    this.visibleChange.emit(false);
  }
}
