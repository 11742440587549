<div class="auto-reply-container overflow-auto h-screen">
  <div class="auto-reply-container__header px-4">
    <p class="text-lg font-bold text-color-tertiary-500">Auto reply</p>
  </div>
  <p-divider />

  <div class="auto-reply-container__body p-5">
    <p class="text-lg color-denim-gray-90">
      Use automatic replies to inform contacts that you are out of the office or otherwise unable to respond.
    </p>

    <form [formGroup]="autoReplyForm" class="" (ngSubmit)="onSave()">
      <p-inputGroup class="flex flex-row align-items-center mt-6">
        <p-inputSwitch formControlName="autoReplyON" />
        <p class="m-0 text-base text-color-tertiary-500 ml-2">Auto reply on</p>
      </p-inputGroup>

      <p class="font-medium color-denim-gray-90 m-0 mb-2 ml-2 mt-4 text-sm">Start *</p>

      <p-inputGroup styleClass="flex flex-row align-items-center">
        <p-calendar
          formControlName="startDate"
          [iconDisplay]="'input'"
          [showIcon]="true"
          [minDate]="minDate"
          inputId="startDate"
          styleClass="w-18rem auto-reply-date-picker"
        />

        <p-calendar
          formControlName="fromTime"
          [iconDisplay]="'input'"
          [showIcon]="true"
          [showTime]="true"
          [timeOnly]="true"
          [hourFormat]="'12'"
          inputId="startTime"
          styleClass="w-18rem auto-reply-date-picker ml-4"
        >
          <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
            <fa-icon icon="clock" class="text-color-primary-500 pointer-events-none absolute clock-icon"></fa-icon>
          </ng-template>
        </p-calendar>
      </p-inputGroup>

      <p class="font-medium color-denim-gray-90 m-0 mb-2 ml-2 mt-4 text-sm">End *</p>

      <p-inputGroup styleClass="flex flex-row align-items-center">
        <p-calendar
          formControlName="endDate"
          [iconDisplay]="'input'"
          [showIcon]="true"
          [minDate]="minDate"
          inputId="startDate"
          styleClass="w-18rem auto-reply-date-picker"
        />

        <p-calendar
          formControlName="toTime"
          [iconDisplay]="'input'"
          [showIcon]="true"
          [showTime]="true"
          [timeOnly]="true"
          [hourFormat]="'12'"
          inputId="toTime"
          styleClass="w-18rem auto-reply-date-picker ml-4"
        >
          <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
            <fa-icon icon="clock" class="text-color-primary-500 pointer-events-none absolute clock-icon"></fa-icon>
          </ng-template>
        </p-calendar>
      </p-inputGroup>

      <p-editor
        #pEditor
        id="editDoc"
        class="auto-reply-custom-editor w-full inline-block"
        styleClass="mt-5 auto-reply-custom-editor__container"
        formControlName="replyMessage"
        [ngClass]="{
          'ng-invalid ng-dirty':
            (autoReplyForm.get('replyMessage')?.touched || isSubmitted) && autoReplyForm.get('replyMessage')?.invalid,
        }"
      >
        <ng-template pTemplate="header">
          <span class="ql-formats">
            <button class="ql-bold" aria-label="Bold"></button>
            <button class="ql-italic" aria-label="Italic"></button>
            <button class="ql-underline" aria-label="Underline"></button>
          </span>
          <span class="ql-formats">
            <button class="ql-list" value="ordered" aria-label="Ordered List"></button>
            <button class="ql-list" value="bullet" aria-label="Bullet List"></button>
          </span>
          <span class="ql-formats">
            <button class="ql-align" value="" aria-label="Align Left"></button>
            <button class="ql-align" value="center" aria-label="Align Center"></button>
            <button class="ql-align" value="right" aria-label="Align Right"></button>
          </span>
          <span class="ql-formats">
            <button class="ql-image" aria-label="Insert Image"></button>
          </span>
        </ng-template>
      </p-editor>

      @if ((autoReplyForm.get('replyMessage')?.touched || isSubmitted) && autoReplyForm.get('replyMessage')?.invalid) {
        <small class="text-red-500">Auto reply message is required!</small>
      }

      <div class="flex flex-row justify-content-end mt-5">
        <p-button
          o-button
          [oButtonSeverity]="'success'"
          [loading]="isSaveLoading"
          label="Save"
          type="submit"
          styleClass="border-round-2xl w-7rem"
        ></p-button>
      </div>
    </form>
  </div>
</div>
