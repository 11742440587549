import { Injectable } from '@angular/core';
import { Email, IReadEmailResponse } from '@app/shared/models/omail';

@Injectable({
  providedIn: 'root',
})
export class EmailFormatterService {
  formatEmailForReading(email: IReadEmailResponse | undefined): string {
    if (!email) {
      return '';
    }
    const formattedDate = new Date(email.date).toLocaleString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });

    // Header Section
    const headerParts: string[] = [
      `Email Topic: ${email.subject || 'No Subject'}`,
      `From: ${email.fromAliasName || 'Unknown Sender'} <${email.from}>`,
      `To: ${email.toAliasName || 'Unknown Recipient'} <${email.to?.join(', ')}>`,
    ];

    if (email.cc?.length) {
      headerParts.push(`CC: ${email.cc?.join(', ')}`);
    }

    if (email.bcc?.length) {
      headerParts.push(`BCC: ${email.bcc?.join(', ')}`);
    }

    headerParts.push(`Date: ${formattedDate}`);
    headerParts.push(`Priority: ${this.getPriorityLabel(email.priority)}`);

    // Body Section
    const bodySection = email.body ? `\nBody:\n${email.body}` : '\nBody:\n[No Content]';

    // Attachments Section
    const attachmentsSection =
      email.attachments && email.attachments.length > 0
        ? `Attachments: ${email.attachments.join(', ')}`
        : 'Attachments: None';

    // Combine all sections
    return [...headerParts, bodySection, attachmentsSection].join('\n');
  }

  private getPriorityLabel(priority: string): string {
    switch (priority) {
      case '1':
        return 'High';
      case '2':
        return 'Medium';
      case '3':
        return 'Low';
      default:
        return 'Normal';
    }
  }
}
