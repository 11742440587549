<div id="main" style="display: none"> </div>

<o-toast-v2 />

<div class="sidebar">
  <app-sidebar></app-sidebar>
</div>
<div class="flex-1">
  <router-outlet></router-outlet>
</div>
