import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';
import { FloatLabelModule } from 'primeng/floatlabel';
import { DividerModule } from 'primeng/divider';
import { passwordValidator } from '@app/shared/validators/password-validator';
import { OButtonDirective } from 'o-suite-lib';
import { OsuiteFacade } from '@app/services/osuite.facade';
import { ToasterService } from '@app/services/toaster.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize } from 'rxjs';
import { passwordMatchValidator } from '@app/shared/validators/passwordMatchValidator';
import { passwordNotMatchValidator } from '@app/shared/validators/passwordNotMatchValidator';

@Component({
  selector: 'app-reset-password-modal',
  standalone: true,
  imports: [
    DialogModule,
    ButtonModule,
    FormsModule,
    PasswordModule,
    CommonModule,
    ReactiveFormsModule,
    FloatLabelModule,
    DividerModule,
    OButtonDirective
  ],
  templateUrl: './reset-password-modal.component.html',
  styleUrl: './reset-password-modal.component.scss'
})
export class ResetPasswordModal implements OnInit {
  @Input() visible!: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

  resetPasswordForm!: FormGroup;
  submitted: boolean = false;
  value!: string;
  showErrorConfirm: boolean = false;
  showErrorOld: boolean = false;
  isLoading: boolean = false;

  private fb = inject(FormBuilder);
  private osuiteFacade = inject(OsuiteFacade);
  private toasterService = inject(ToasterService);
  private destroyRef = inject(DestroyRef);
  passwordErrors = [
    { key: 'lowercase', message: 'One lowercase letter' },
    { key: 'uppercase', message: 'One uppercase letter' },
    { key: 'digit', message: 'One digit' },
    { key: 'specialChar', message: 'One special character (!@#..)' },
    { key: 'length', message: 'Minimum 8 characters' }
  ];

  hasError(errorKey: string): boolean {
    return this.resetPasswordForm.get('newPassword')?.errors?.[errorKey];
  }

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group(
      {
        oldPassword: ['', [Validators.required]],
        newPassword: ['', [Validators.required, passwordValidator()]],
        confirmPassword: ['', [Validators.required]]
      },
      {
        validators: [
          passwordMatchValidator('oldPassword', 'newPassword'),
          passwordNotMatchValidator('newPassword', 'confirmPassword')
        ]
      }
    );
  }

  closeDialog() {
    this.visibleChange.emit(false);
  }

  onSubmit() {
    this.submitted = true;
    const { newPassword, confirmPassword, oldPassword } = this.resetPasswordForm.getRawValue();

    if (!this.resetPasswordForm.valid) {
      return;
    }

    if (!this.showErrorOld && !this.showErrorConfirm) {
      this.isLoading = true;
      this.osuiteFacade
        .resetPassword({ newPassword, confirmPassword, oldPassword })
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: (res) => {
            this.toasterService.setToasterState(true, 'success', res.message);
            this.closeDialog();
          }
        });
    }
  }
}
