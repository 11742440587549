import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { SupSidebarComponent } from '@app/shared/components/sup-sidebar/sup-sidebar.component';
import { CONTACTS_ROUTES, MENU_CONTACT_ITEMS } from '@app/constants';
import { SidebarMenuItemComponent } from '@app/shared/components/sidebar-menu-item/sidebar-menu-item.component';
import { filter, Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { findMatchingItem } from '@app/utils/matching-item';
import { AddContactDialogComponent } from '@app/pages/my-contacts/add-contact-dialog/add-contact-dialog.component';
import { IApiResponse, ICountryData, ICountryDataWithPhoneNumber } from '@app/shared/models';
import { ContactsFacade } from '@app/pages/my-contacts/services/contacts.facade';
import { EditContactDialogComponent } from '@app/pages/my-contacts/edit-contact-dialog/edit-contact-dialog.component';
import { environment } from '../../../../environments/environment';
import { OToastV2Service } from 'o-suite-lib';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-my-contacts-sidebar',
  standalone: true,
  imports: [SupSidebarComponent, SidebarMenuItemComponent, AddContactDialogComponent, EditContactDialogComponent],
  templateUrl: './my-contacts-sidebar.component.html',
  styleUrl: './my-contacts-sidebar.component.scss',
})
export class MyContactsSidebarComponent implements OnInit, OnDestroy {
  activeItem = '';
  menuItems = MENU_CONTACT_ITEMS;
  private routerEventsSubscription!: Subscription;
  countryList: ICountryDataWithPhoneNumber[] = [];

  private contactsFacade = inject(ContactsFacade);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  isLoadingState = toSignal(this.contactsFacade.getIsLoadingState$());

  ngOnInit(): void {
    this.routerEventsSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setActiveIconBasedOnRoute();
      });

    this.setActiveIconBasedOnRoute();

    this.contactsFacade.getCountries();
  }

  ngOnDestroy(): void {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  private setActiveIconBasedOnRoute(): void {
    const currentRoute = this.router.url;

    const matchingItem = findMatchingItem(this.menuItems, currentRoute);

    if (matchingItem) {
      this.activeItem = matchingItem.id;
    }
  }

  onFolderSelect(event: any) {}

  onFolderExpand(event: any) {}

  private oToastV2Service = inject(OToastV2Service);

  onCreateContact() {
    if (this.isLoadingState()) return;
    // this.router.navigate([`/${CONTACTS_ROUTES.MY_CONTACTS}/${CONTACTS_ROUTES.CREATE_CONTACT}`]);
    // this.activeItem = '';
    this.contactsFacade.isAddModalOpen$.set(true);
  }

  protected isAddModalOpen$ = this.contactsFacade.isAddModalOpen$;
}
