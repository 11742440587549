<div
  class="sidebar flex flex-column justify-content-between align-items-center h-full bg-white border-right-1 surface-border py-3"
>
  <div>
    @for (icon of icons; track $index) {
      <div
        class="sidebar__icon-container flex align-items-center justify-content-center mb-3 w-2rem h-2rem cursor-pointer relative border-round-md border-1 text-color-tertiary-200"
        [ngClass]="{
          'sidebar__icon-container--active bg-primary-500 border-primary-500 text-white ': activeIcon === icon.id,
        }"
        (click)="onIconClick(icon)"
      >
        <fa-icon [icon]="icon.iconName"></fa-icon>
      </div>
    }
  </div>
  <!-- Divider -->
  <fa-icon icon="life-ring" class="sidebar__footer border-top-2 pt-3 px-2 text-2xl text-color-tertiary-200"></fa-icon>
</div>
