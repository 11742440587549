import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { SettingsState } from '@app/pages/settings/services/settings.state';
import { SettingsApi } from '@app/pages/settings/services/settings.api';
import { IMyProfileData } from '@app/shared/models/settings.model';
import { IApiResponseData } from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class SettingsFacade {
  private settingsState = inject(SettingsState);
  private settingsApi = inject(SettingsApi);

  myProfileData: WritableSignal<IMyProfileData | null> = signal(null);
  isMyProfileLoading$ = signal(false);

  getProfileInfo() {
    this.isMyProfileLoading$.set(true);
    return this.settingsApi.getProfileInfo().subscribe((res: IApiResponseData<IMyProfileData>) => {
      this.isMyProfileLoading$.set(false);
      this.myProfileData.set(res.data);
    });
  }
}
