import { TreeNode } from '@app/shared/models/menu-items-sidebar';
import { EmailResponse } from '@app/shared/models/omail';

export const folderNodes: TreeNode[] = [
  {
    id: '1',
    label: 'Inbox',
    color: 'primary',
  },
  {
    id: '2',
    label: 'Sent',
    color: 'success',
  },
  {
    id: '3',
    label: 'Drafts',
    color: 'warning',
  },
  {
    id: '4',
    label: 'Trash',
    color: 'danger',
  },
];

export const mockEmailResponse: EmailResponse = {
  pageNo: 1,
  totalMails: 2,
  size: 30,
  folderName: null,
  unreadMailsCount: 2,
  starredMailsCount: null,
  importantMailsCount: null,
  reply: null,
  listofMails: [
    {
      userEmail: null,
      cc: 'rand.khashan5@onfusiont.net, qusai.sso5@onfusion.net',
      bcc: null,
      sno: 2,
      from: 'wafa3@onfusiont.net',
      to: 'merei@onfusiont.net, rand.khashan5@onfusiont.net, qusai.sso5@onfusion.net',
      subject: 'test-socket-merei-with-multiple-cc',
      date: 1734861920000,
      folderName: 'INBOX',
      attachment: false,
      seen: false,
      starred: false,
      important: false,
      reply: false,
      replyAll: false,
      forward: false,
      messageId: '<1607549642.7.1734861907676@mailboxapi-deployment-7b8f6c844b-6gnxx>',
      priority: '0',
      fromAliasName: 'wafa afaneh',
      toAliasName: 'merei@onfusiont.net',
      body: null,
      ccAliasName: null,
      replyTo: null,
      attachments: null,
      category: null,
      readRecipent: false,
      rule: false,
      moveMail: null,
      delteMail: null,
      videoMail: false,
      deleteTime: null,
      attachmentCount: 0,
      status: 'Pending',
      calendarEventMail: false,
      threadReferences: null,
      isTemplateMail: false,
      mailSize: 0,
      isMidPaneParent: null,
      new: false,
    },
    {
      userEmail: null,
      cc: null,
      bcc: null,
      sno: 1,
      from: 'wafa3@onfusiont.net',
      to: 'merei@onfusiont.net',
      subject: 'test-socket-with-merei',
      date: 1734861536000,
      folderName: 'INBOX',
      attachment: false,
      seen: false,
      starred: false,
      important: false,
      reply: false,
      replyAll: false,
      forward: false,
      messageId: '<1863464536.3.1734861523958@mailboxapi-deployment-7b8f6c844b-6gnxx>',
      priority: '0',
      fromAliasName: 'wafa afaneh',
      toAliasName: 'merei@onfusiont.net',
      body: null,
      ccAliasName: null,
      replyTo: null,
      attachments: null,
      category: null,
      readRecipent: false,
      rule: false,
      moveMail: null,
      delteMail: null,
      videoMail: false,
      deleteTime: null,
      attachmentCount: 0,
      status: 'Pending',
      calendarEventMail: false,
      threadReferences: null,
      isTemplateMail: false,
      mailSize: 0,
      isMidPaneParent: null,
      new: false,
    },
  ],
  folderDetails: [
    {
      folderName: 'INBOX',
      folderNameOrg: 'INBOX',
      totalMessagesCount: 2,
      totalUnReadMessagesCount: 2,
      url: 'INBOX',
      icon: 'Inbox',
      email: null,
      subFolders: null,
      folderOrder: 1,
      parentFolderName: null,
      folderColorCode: null,
      folderId: 0,
      passCode: false,
      authorised: false,
      subFolder: false,
      delete: false,
    },
    {
      folderName: 'Important',
      folderNameOrg: 'Important',
      totalMessagesCount: 0,
      totalUnReadMessagesCount: 0,
      url: 'Important',
      icon: 'Important',
      email: null,
      subFolders: null,
      folderOrder: 2,
      parentFolderName: null,
      folderColorCode: null,
      folderId: 0,
      passCode: false,
      authorised: false,
      subFolder: false,
      delete: false,
    },
    {
      folderName: 'Drafts',
      folderNameOrg: 'Drafts',
      totalMessagesCount: 0,
      totalUnReadMessagesCount: 0,
      url: 'Drafts',
      icon: 'Drafts',
      email: null,
      subFolders: null,
      folderOrder: 3,
      parentFolderName: null,
      folderColorCode: null,
      folderId: 0,
      passCode: false,
      authorised: false,
      subFolder: false,
      delete: false,
    },
    {
      folderName: 'Sent',
      folderNameOrg: 'Sent',
      totalMessagesCount: 0,
      totalUnReadMessagesCount: 0,
      url: 'Sent',
      icon: 'Sent',
      email: null,
      subFolders: null,
      folderOrder: 4,
      parentFolderName: null,
      folderColorCode: null,
      folderId: 0,
      passCode: false,
      authorised: false,
      subFolder: false,
      delete: false,
    },
    {
      folderName: 'Outbox',
      folderNameOrg: 'Outbox',
      totalMessagesCount: 0,
      totalUnReadMessagesCount: 0,
      url: 'Outbox',
      icon: 'Outbox',
      email: null,
      subFolders: null,
      folderOrder: 5,
      parentFolderName: null,
      folderColorCode: null,
      folderId: 0,
      passCode: false,
      authorised: false,
      subFolder: false,
      delete: false,
    },
    {
      folderName: 'Scheduled',
      folderNameOrg: 'Scheduled',
      totalMessagesCount: 0,
      totalUnReadMessagesCount: 0,
      url: 'Scheduled',
      icon: 'Scheduled',
      email: null,
      subFolders: null,
      folderOrder: 6,
      parentFolderName: null,
      folderColorCode: null,
      folderId: 0,
      passCode: false,
      authorised: false,
      subFolder: false,
      delete: false,
    },
    {
      folderName: 'Trash',
      folderNameOrg: 'Trash',
      totalMessagesCount: 0,
      totalUnReadMessagesCount: 0,
      url: 'Trash',
      icon: 'Trash',
      email: null,
      subFolders: null,
      folderOrder: 7,
      parentFolderName: null,
      folderColorCode: null,
      folderId: 0,
      passCode: false,
      authorised: false,
      subFolder: false,
      delete: false,
    },
  ],
  dynamicfolderDetails: [],
  foldermongoDetails: null,
  listofjobMails: null,
  trashImapFolders: [],
  listofThreadMails: null,
  singleThread: false,
  pageMailIds: null,
  searchBy: null,
  sortBy: null,
  sortOrder: null,
  searchValue: null,
};
