import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AppsListComponent } from './apps-list/apps-list.component';
import { onFusionApps } from '../../mocks/onfusion-apps';
import { BlockUIModule } from 'primeng/blockui';
import { SvgIconComponent } from 'angular-svg-icon';
import { assetUrl } from '../../../single-spa/asset-url';
import { DialogModule } from 'primeng/dialog';
import { OheaderFacade } from '../../services/oheader.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-onfusions-apps',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, AppsListComponent, BlockUIModule, SvgIconComponent, DialogModule],
  templateUrl: './onfusions-apps.component.html',
  styleUrl: './onfusions-apps.component.scss'
})
export class OnfusionsAppsComponent implements OnInit {
  visible: boolean = false;
  faBars = faBars;
  faTimes = faTimes;
  buttonHover = false;
  onFusionApps = onFusionApps;
  dialogStyleClass: string = '';

  private oheaderFacade = inject(OheaderFacade);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.oheaderFacade
      .getShowUpdatesBanner()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res: boolean) => {
          res ? (this.dialogStyleClass = 'header-offset') : (this.dialogStyleClass = '');
        }
      });
  }

  toggleDialog() {
    this.visible = !this.visible;
  }

  closeDialog() {
    this.visible = false;
  }

  protected readonly assetUrl = assetUrl;
}
